import { useRef } from 'react';
import useSelectFile from '../../../../hooks/useSelectFile';
import { IAdvertisement } from '../../../../types/ApiTypes';
import AddImgIcon from '../../../icons/AddImgIcon';
import TrashIcon from '../../../icons/TrashIcon';
import IconButton from '../../buttons/IconButton';
import ImagePickerCard from '../ImagePickerCard';

interface MultiImagePickerProps {
  title: string;
  subtitle?: string;
  oldImageUrls: IAdvertisement[];
  removeOldImage: (id: number) => void;
  selectedFiles: File[];
  setSelectedFiles: (newFiles: File[]) => void;
}

const MultiImagePicker = ({
  title,
  subtitle,
  oldImageUrls,
  removeOldImage,
  selectedFiles,
  setSelectedFiles,
}: MultiImagePickerProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { selectFile } = useSelectFile();

  const handleClickChangeImg = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveSelectedImage = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const getImages = () => {
    const images = [];

    for (let i = 0; i < oldImageUrls.length; i++) {
      images.push(
        <ImagePickerCard key={i}>
          <img src={oldImageUrls[i]?.ad} alt={`Image ${i}`} className="" />
          <IconButton
            icon={<TrashIcon width={16} />}
            onClick={() => removeOldImage(oldImageUrls[i]?.id as number)}
            className="absolute top-1.5 right-1.5 bg-white900 bg-opacity-90 w-6 h-6"
          />
        </ImagePickerCard>,
      );
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      if (!!selectedFiles[i]) {
        images.push(
          <ImagePickerCard key={i}>
            <img src={URL.createObjectURL(selectedFiles[i]!)} alt={`Image ${i}`} className="" />
            <IconButton
              icon={<TrashIcon width={16} />}
              onClick={() => handleRemoveSelectedImage(i)}
              className="absolute top-1.5 right-1.5 bg-white900 bg-opacity-90 w-6 h-6 "
            />
          </ImagePickerCard>,
        );
      }
    }

    images.push(
      <ImagePickerCard key="new">
        <button onClick={handleClickChangeImg} className="w-full h-full flex items-center justify-center ">
          <div className="bg-white900 border border-grey700 rounded-full p-3">
            <AddImgIcon />
          </div>
        </button>
      </ImagePickerCard>,
    );

    return images;
  };

  return (
    <div className="flex flex-col gap-4">
      <h5 className="text-sm text-black700 leading-tight">
        {title} <span className="text-black500 normal-case font-medium">{subtitle}</span>
      </h5>

      <div className="flex gap-1">{getImages()}</div>

      <input
        ref={fileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={async event => {
          await selectFile({
            event,
            onSelectFile: file => {
              if (file) {
                setSelectedFiles([...selectedFiles, file]);
              }
            },
            sizeLimitKb: 2048,
            allowedFileTypes: ['image/jpeg', 'image/png'],
          });
        }}
        className="hidden"
      />
    </div>
  );
};

export default MultiImagePicker;

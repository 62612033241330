import { MouseEvent, useEffect, useState } from 'react';
import { Controller, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';

import useSingleQuestion from '../../../../../../hooks/api/questions/useSingleQuestion';
import { coordinatesValidationSchema } from '../../../../../../static/validation/schemas/coordinatesValidationSchema';
import { colors } from '../../../../../../styles/variables';
import { ApiQuestion, IQuestion } from '../../../../../../types/ApiTypes';
import { Coordinates } from '../../../../../../types/GlobalTypes';
import { QueryAliases } from '../../../../../../types/QueryAliases';
import { CallbackSomeData } from '../../../../../../types/Types';
import { transformQuestion } from '../../../../../../utils/api/dataTransformers/transformQuestion/transformQuestion';
import { getCoordinatesFromString } from '../../../../../../utils/general/getCoordinatesFromString/getCoordinatesFromString';
import { getCoordinatesStringFromLatLng } from '../../../../../../utils/general/getCoordinatesStringFromLatLng/getCoordinatesStringFromLatLng';
import getInputState from '../../../../../../utils/getInputState/getInputState';
import { LabeledStateInput } from '../../../../../common/inputs/LabeledStateInput';
import EditQuestionModal from '../../../../../common/modals/editQuestionModal/EditQuestionModal';
import HoverableTooltip from '../../../../../dataTable/components/HoverableTooltip';
import ArrowLeft from '../../../../../icons/ArrowLeft';
import EditIcon from '../../../../../icons/EditIcon';
import SingleQuestionTile from './SingleQuestionTile';

interface SelectedQuestionInputs {
  coordinates: string;
}

export type SelectedQuestionInputsKeys = keyof SelectedQuestionInputs;

interface SelectedQuestionProps {
  question: IQuestion;
  index: number;
  onTileClick: CallbackSomeData;
  isSelected: boolean;
  showDivider: boolean;
  canMoveUp: boolean;
  canMoveDown: boolean;
  onClickMoveUp: ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => void;
  onClickMoveDown: ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => void;
  onUpdateQuestionCoordinates: ({
    newCoordinates,
    questionId,
  }: {
    newCoordinates: Coordinates;
    questionId: number;
  }) => void;
  onUpdateSelectedQuestion: (question: IQuestion) => void;
}

const SelectedQuestion = ({
  question,
  index,
  onTileClick,
  isSelected,
  showDivider,
  canMoveUp,
  canMoveDown,
  onClickMoveUp,
  onClickMoveDown,
  onUpdateQuestionCoordinates,
  onUpdateSelectedQuestion,
}: SelectedQuestionProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { refetch } = useSingleQuestion({
    enabled: false,
    questionId: question.id.toString(),
  });

  const isWithCoordinates = !!question.coordinates;

  const [isExpanded, setIsExpanded] = useState(!isWithCoordinates);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm<SelectedQuestionInputs>({
    mode: 'all',
    resolver: yupResolver(coordinatesValidationSchema),
    defaultValues: {
      coordinates: !!question.coordinates ? getCoordinatesStringFromLatLng(question.coordinates) : undefined,
    },
  });

  const onToggleExpandedClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsExpanded(prev => !prev);
  };

  const getErrorMessage = (field: SelectedQuestionInputsKeys) => get(errors, field);

  const onEditSuccess = async () => {
    queryClient.refetchQueries([QueryAliases.QUESTIONS]);
    const res = await refetch();
    const resData: { question: ApiQuestion; relatedQuestion: ApiQuestion | null } = res.data?.data;

    const newQuestion = transformQuestion(resData.question);

    onUpdateSelectedQuestion(newQuestion);
  };

  useEffect(() => {
    setValue('coordinates', `${question.coordinates?.lat}, ${question.coordinates?.lng}`);
  }, [question.coordinates, setValue]);

  return (
    <>
      <SingleQuestionTile
        question={question}
        onClick={onTileClick}
        isSelected={isSelected}
        showDivider={showDivider}
        isArcade={question.isArcade}
        tileActions={
          <div className="flex">
            <HoverableTooltip tooltipContent={<p>{t('templateForm.questions.editTooltip')}</p>}>
              {isHovered => (
                <div className="h-full flex items-center">
                  <button onClick={() => setIsEditModalOpen(true)} className="p-2">
                    <EditIcon color={isHovered ? colors.red500 : colors.grey700} width={'24'} height={'24'} />
                  </button>
                </div>
              )}
            </HoverableTooltip>

            {!isWithCoordinates && (
              <div className="place-self-center">
                <p className="text-error">{t('templateForm.questions.addCoordinates')}</p>
              </div>
            )}

            <div className="flex flex-col items-center justify-between h-full gap-1.5 mx-4">
              <button
                onClick={e => onClickMoveUp({ e, currentIndex: index })}
                disabled={!canMoveUp}
                className="rotate-90 p-1"
              >
                <ArrowLeft color={canMoveUp ? colors.black300 : colors.grey400} size={16} />
              </button>
              <button
                onClick={e => onClickMoveDown({ e, currentIndex: index })}
                disabled={!canMoveDown}
                className="-rotate-90 p-1"
              >
                <ArrowLeft color={canMoveDown ? colors.black300 : colors.grey400} size={16} />
              </button>
            </div>

            <div className="flex items-center justify-center">
              <button onClick={onToggleExpandedClick} className="pl-4 py-4">
                <ChevronDownIcon color="black" width={16} className={`${isExpanded && 'rotate-180'}`} />
              </button>
            </div>
          </div>
        }
      >
        {isExpanded && (
          <div className="px-5 pb-5 pt-2 max-w-md">
            <Controller
              name="coordinates"
              control={control}
              render={({ field }) => (
                <LabeledStateInput
                  id="template-question-coordinates"
                  state={getInputState(getErrorMessage('coordinates'))}
                  labeledProps={{
                    wrapperClassName: 'w-full',
                    label: t('templateForm.general.coordinatesLabel'),
                    errorMessage: t(`errorMessages.${getErrorMessage('coordinates')?.message}`),
                  }}
                  inputProps={{
                    placeholder: t('templateForm.general.coordinatesPlacholder'),
                    value: field.value,
                    onChange: e => {
                      const newValue = e.target.value;
                      const coordinates = getCoordinatesFromString(newValue);

                      if (!!coordinates) {
                        onUpdateQuestionCoordinates({ newCoordinates: coordinates, questionId: question.id });
                      }

                      field.onChange(newValue);
                    },
                  }}
                />
              )}
            />
          </div>
        )}
      </SingleQuestionTile>

      <EditQuestionModal
        questionId={question.id}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        onSucceess={onEditSuccess}
      />
    </>
  );
};

export default SelectedQuestion;

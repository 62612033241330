import { useTranslation } from 'react-i18next';
import FilterChip from '../../../../../../common/dropdowns/FilterDropdown/components/FilterChip';

interface FilterChipSectionProps {
  isSomeFilterActive: boolean;
  questionTypeFilter: string;
  cityFilter: string;
  tagFilters: string[];
  isArcadeFilter: string;
  setQuestionTypeFilter: (value: string) => void;
  setCityFilter: (value: string) => void;
  handleDeleteTagFilter: (tag: string) => void;
  setIsArcade: (value: string) => void;
  ownerFilter: string;
  setOwnerFilter: (value: string) => void;
}

function FilterChipSection({
  isSomeFilterActive,
  questionTypeFilter,
  cityFilter,
  tagFilters,
  isArcadeFilter,
  setQuestionTypeFilter,
  setCityFilter,
  handleDeleteTagFilter,
  setIsArcade,
  ownerFilter,
  setOwnerFilter,
}: FilterChipSectionProps) {
  const { t } = useTranslation();

  return (
    <div className={`flex gap-2 overflow-x-scroll no-scrollbar ${isSomeFilterActive && 'mt-4'}`}>
      {!!questionTypeFilter && (
        <FilterChip label={t(`questionTypes.${questionTypeFilter}`)} onDelete={() => setQuestionTypeFilter('')} />
      )}
      {!!cityFilter && <FilterChip label={cityFilter} onDelete={() => setCityFilter('')} />}
      {tagFilters.length > 0 &&
        tagFilters.map(singleTag => (
          <FilterChip key={singleTag} label={singleTag} onDelete={() => handleDeleteTagFilter(singleTag)} />
        ))}
      {!!isArcadeFilter && <FilterChip label={t('questions.table.filters.arcade')} onDelete={() => setIsArcade('')} />}
      {!!ownerFilter && <FilterChip label={t('questions.table.filters.owner')} onDelete={() => setOwnerFilter('')} />}
    </div>
  );
}

export default FilterChipSection;

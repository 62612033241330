import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from '../../../../../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../../../../../components/common/buttons/SecondaryButton';
import TableCell from '../../../../../../../../components/dataTable/components/TableCell';
import useAcceptAnswer from '../../../../../../../../hooks/api/games/useAcceptAnswer';
import useRejectAnswer from '../../../../../../../../hooks/api/games/useRejectAnswer';
import { ButtonSize } from '../../../../../../../../types/ButtonSizes';

interface SingleReviewAnswerActionButtonsProps {
  answerId: number;
}

const SingleReviewAnswerActionButtons = ({ answerId }: SingleReviewAnswerActionButtonsProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const gameId = params.id as string;

  const { acceptAnswer, isLoading: isLoadingAccept, isSuccess: isSuccessAccept } = useAcceptAnswer({ gameId });
  const { rejectAnswer, isLoading: isLoadingReject, isSuccess: isSuccessReject } = useRejectAnswer({ gameId });

  const handleRejectAnswer = () => {
    rejectAnswer({ answerId });
  };

  const handleAcceptAnswer = () => {
    acceptAnswer({ answerId });
  };

  const isLoading = isLoadingAccept || isLoadingReject;
  const isSuccess = isSuccessAccept || isSuccessReject;

  return (
    <div className="w-full flex gap-2.5 justify-end">
      {isSuccess ? (
        <div className="">
          {isSuccessAccept && <TableCell>{t('singleActiveGame.answers.table.accepted')}</TableCell>}
          {isSuccessReject && <TableCell>{t('singleActiveGame.answers.table.rejected')}</TableCell>}
        </div>
      ) : (
        <>
          <SecondaryButton onClick={handleRejectAnswer} size={ButtonSize.Medium} disabled={isLoading}>
            {t('singleActiveGame.answers.table.incorrect')}
          </SecondaryButton>
          <PrimaryButton onClick={handleAcceptAnswer} size={ButtonSize.Medium} disabled={isLoading} loading={isLoading}>
            {t('singleActiveGame.answers.table.correct')}
          </PrimaryButton>
        </>
      )}
    </div>
  );
};

export default SingleReviewAnswerActionButtons;

import { Checkbox, FormControlLabel } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutocompleteListItem from '../../../components/common/autocompleteList/AutocompleteListItem';
import AutocompleteListWrapper from '../../../components/common/autocompleteList/AutocompleteListWrapper';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/common/buttons/SecondaryButton';
import { HorizontalDivider } from '../../../components/common/dividers/HorizontalDivider';
import FormSectionTitle from '../../../components/common/formSectionTitle/FormSectionTitle';
import { Modal, ModalProps } from '../../../components/common/modals/Modal';
import XMarkIcon from '../../../components/icons/XmarkIcon';
import { IRelatedUser } from '../../../types/ApiTypes';

const getUserDisplayValue = (email: string | null) => email?.split('@')[0];

interface CurrentGameUser {
  id: number;
  teamName: string | null;
}

interface EditGameDevicesModalProps extends Omit<ModalProps, 'children'> {
  initAllowJoinByCode: boolean;
  initUsersIds: number[];
  currentGameUsers: CurrentGameUser[];
  relatedUsers: IRelatedUser[];
  onSubmitForm: (data: { users: number[]; allowJoinByCode: boolean }) => Promise<void>;
  isLoading: boolean;
  hideJoinByCodeCheckbox?: boolean;
}

const EditGameDevicesModal = ({
  isOpen,
  setIsOpen,
  initAllowJoinByCode,
  initUsersIds,
  currentGameUsers,
  relatedUsers,
  onSubmitForm,
  isLoading,
  hideJoinByCodeCheckbox = false,
}: EditGameDevicesModalProps) => {
  const { t } = useTranslation();

  const initSelectedRelatedUsers = relatedUsers.filter(relatedUser => initUsersIds.some(id => id === relatedUser.id));

  const [allowJoinByCode, setAllowJoinByCode] = useState(initAllowJoinByCode);
  const [selectedRelatedUsers, setSelectedRelatedUsers] = useState<IRelatedUser[]>(initSelectedRelatedUsers);
  const [selectedCurrentGameUsers, setSelectedCurrentGameUsers] = useState<CurrentGameUser[]>(currentGameUsers);

  const [searchValue, setSearchValue] = useState('');

  const handleToggleSelectedRelatedUser = (newUser: IRelatedUser) => {
    setSelectedRelatedUsers(prevUsers => {
      const isSelected = prevUsers.some(prevUser => prevUser.id === newUser.id);

      if (isSelected) {
        return prevUsers.filter(prevUser => prevUser.id !== newUser.id);
      } else {
        return [...prevUsers, newUser];
      }
    });
  };

  const handleToggleSelectedGuestUser = (newUser: CurrentGameUser) => {
    setSelectedCurrentGameUsers(prevUsers => {
      const isSelected = prevUsers.some(prevUser => prevUser.id === newUser.id);

      if (isSelected) {
        return prevUsers.filter(prevUser => prevUser.id !== newUser.id);
      } else {
        return [...prevUsers, newUser];
      }
    });
  };

  const relatedUsersFiltered = relatedUsers?.filter(user => user.login?.includes(searchValue));

  const currentGameUsersFiltered = currentGameUsers?.filter(gameUser => {
    if (!searchValue) {
      return gameUser.teamName;
    } else {
      return gameUser.teamName?.includes(searchValue);
    }
  });

  const handleSubmit = () => {
    const allSelectedUsers = [...selectedRelatedUsers, ...selectedCurrentGameUsers];
    onSubmitForm({ users: allSelectedUsers.map(user => user.id), allowJoinByCode });
  };

  const onCloseModal = () => {
    setSelectedRelatedUsers(initSelectedRelatedUsers);
    setSelectedCurrentGameUsers(currentGameUsers);
    setAllowJoinByCode(initAllowJoinByCode);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onCloseModal}>
      <div>
        <div className="p-6 relative border-b border-grey300">
          <h2 className="text-center text-[1.375rem] font-medium">
            {t('singleActiveGame.info.editDevicesModal.title')}
          </h2>
          <div
            onClick={() => {
              onCloseModal();
              setIsOpen(false);
            }}
            className="w-8 h-8 min-w-8 absolute top-6 right-6 lg:left-6 flex justify-center items-center cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>

        <div className="p-6">
          {!hideJoinByCodeCheckbox && (
            <div className="my-9">
              <FormControlLabel
                control={<Checkbox checked={allowJoinByCode} onChange={e => setAllowJoinByCode(e.target.checked)} />}
                label={t('singleActiveGame.info.editDevicesModal.allowJoinWithCode')}
              />
            </div>
          )}

          <FormSectionTitle title={t('singleActiveGame.info.editDevicesModal.devices.title')} />
          <p className="mt-6 mb-3">{t('singleActiveGame.info.editDevicesModal.devices.subtitle')}</p>
          <AutocompleteListWrapper
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selectedItemsNumber={[...selectedRelatedUsers, ...selectedCurrentGameUsers].length}
          >
            <ul>
              {relatedUsersFiltered?.map(user => (
                <Fragment key={user.id}>
                  <AutocompleteListItem
                    onClick={() => handleToggleSelectedRelatedUser(user)}
                    isActive={selectedRelatedUsers.some(selectedUser => selectedUser.id === user.id)}
                  >
                    {getUserDisplayValue(user.login)}
                  </AutocompleteListItem>
                  <HorizontalDivider className="my-2" />
                </Fragment>
              ))}

              {currentGameUsersFiltered?.map(user => (
                <Fragment key={user.id}>
                  <AutocompleteListItem
                    onClick={() => handleToggleSelectedGuestUser(user)}
                    isActive={selectedCurrentGameUsers.some(selectedUser => selectedUser.id === user.id)}
                  >
                    {`${t('singleActiveGame.info.editDevicesModal.devices.guest')} - ${
                      user.teamName ?? t('singleActiveGame.info.editDevicesModal.devices.noTeamName')
                    }`}
                  </AutocompleteListItem>
                  <HorizontalDivider className="my-2" />
                </Fragment>
              ))}
            </ul>
          </AutocompleteListWrapper>

          <div className="flex justify-end mt-12 gap-3">
            <SecondaryButton
              type="button"
              onClick={() => {
                onCloseModal();
                setIsOpen(false);
              }}
              className="w-fit"
            >
              {t('singleActiveGame.info.editDevicesModal.cancelBtn')}
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmit} className="w-fit" disabled={isLoading} loading={isLoading}>
              {t('singleActiveGame.info.editDevicesModal.submitBtn')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditGameDevicesModal;

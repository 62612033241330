import { GridRowId } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import MaterialTable from '../../components/dataTable/MaterialTable/MaterialTable';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useActiveGames from '../../hooks/api/games/useActiveGames';
import useDeleteGame from '../../hooks/api/games/useDeleteGame';
import { elementsIds } from '../../static/elementsIds';
import { IGame, ITranslation } from '../../types/ApiTypes';
import { Coordinates } from '../../types/GlobalTypes';
import { QueryAliases } from '../../types/QueryAliases';
import ActiveGamesTableSelectionPanel from './components/ActiveGamesTableSelectionPanel';
import ActiveGamesMap from './components/activeGamesMap/ActiveGamesMap';
import useActiveGamesTableHeaders from './hooks/useActiveGamesTableHeaders';
import { sortGamesByStatus } from './utils/sortGamesByStatus/sortGamesByStatus';

export interface GameTableItem extends IGame {
  templateName?: ITranslation[];
  templateCity?: string;
  templateCoordinates?: Coordinates;
  ownerEmail?: string;
}

interface ActiveGamesViewProps {
  gameTypes: string[];
}

const ActiveGamesView = ({ gameTypes }: ActiveGamesViewProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { games, isLoading: isLoadingGames } = useActiveGames();
  const { deleteGame, isLoading: isLoadingDelete } = useDeleteGame({
    onSuccess: () => {
      queryClient.refetchQueries([QueryAliases.ACTIVE_GAMES]);
    },
  });

  const [selectedGamesIds, setSelectedGamesIds] = useState<number[]>([]);
  const [isMapExpanded, setIsMapExpanded] = useState(false);

  const gamesWithTemplates =
    games
      ?.filter(game => gameTypes.includes(game.template.gameType))
      .map(game => {
        const gameItem: GameTableItem = {
          ...game,
          templateName: game.template.name.translations,
          templateCity: game.template.city,
          templateCoordinates: game.template.coordinates,
          ownerEmail: game.owner.email,
        };

        return gameItem;
      }) ?? [];

  const gamesToShow = sortGamesByStatus(gamesWithTemplates);

  const { headers } = useActiveGamesTableHeaders({
    showAuthorColumn: !!gamesWithTemplates?.some(game => !!game?.ownerEmail),
  });

  const handleDeleteSelectedGame = async (ids: GridRowId[]) => {
    ids.forEach(async id => {
      deleteGame(id.toString());
    });
  };

  return (
    <TitleWrapper title={t('activeGames.title')}>
      <div className="flex mb-8 gap-3">
        <div id={elementsIds.activeGamesTable} className={`w-2/3 ${isMapExpanded ? 'hidden' : ''}`}>
          <MaterialTable
            columns={headers}
            rows={gamesToShow ?? []}
            checkboxSelection
            rowSelectionModel={selectedGamesIds}
            onRowSelectionModelChange={val => {
              setSelectedGamesIds(val as number[]);
            }}
            showPagination
            pageSizeOptions={[20]}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 } },
            }}
            loading={isLoadingGames}
          />
        </div>

        <ActiveGamesMap
          allGames={gamesToShow ?? []}
          isMapExpanded={isMapExpanded}
          setIsMapExpanded={setIsMapExpanded}
          selectedGamesIds={selectedGamesIds}
          setSelectedGamesIds={setSelectedGamesIds}
        />
      </div>
      {selectedGamesIds.length > 0 && (
        <ActiveGamesTableSelectionPanel
          tableId={elementsIds.activeGamesTable}
          selectedRowsNumber={selectedGamesIds.length}
          onDelete={() => {
            handleDeleteSelectedGame(selectedGamesIds);
          }}
          isLoadingDelete={isLoadingDelete}
        />
      )}
    </TitleWrapper>
  );
};

export default ActiveGamesView;

import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiMonthlyStatsResponse, GamesMonthlyStatsParams, IMonthlyStats } from '../../../../types/GameTypes';
import { QueryAliases } from '../../../../types/QueryAliases';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface useCreatedTemplatesStatisticsProps {
  params: GamesMonthlyStatsParams;
}

const useCreatedTemplatesStatistics = ({ params }: useCreatedTemplatesStatisticsProps) => {
  const axiosInstance = useAxiosInstance();

  const { email, gameType, year } = params;

  const getQueryParams = () => ({
    ...(!!email && { email }),
    ...(!!gameType && { gameType }),
    ...(!!year && { year }),
  });

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.ALL_GAME_STATISTICS.CREATED_TEMPLATES, email, gameType, year],
    () =>
      axiosInstance.get(api.endpoints.ALL_GAME_STATISTICS.CREATED_TEMPLATES(), {
        params: getQueryParams(),
      }),
  );

  const apiStatistics: ApiMonthlyStatsResponse | undefined = data?.data;

  const iStatistics: IMonthlyStats | undefined = !!apiStatistics
    ? {
        totalCount: apiStatistics.totalCount,
        countByMonth: apiStatistics.countByMonth.map(monthItem => ({
          month: monthItem.month,
          count: monthItem.count,
        })),
      }
    : undefined;

  return {
    createdTemplatesStatistics: iStatistics,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useCreatedTemplatesStatistics;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../static/routes';
import { colors } from '../../styles/variables';
import ActiveGameIcon from '../icons/ActiveGameIcon';
import CalendarIcon from '../icons/CalendarIcon';
import DashboardIcon from '../icons/DashboardIcon';
import GameIcon from '../icons/GameIcon';
import QuestionsNavIcon from '../icons/QuestionsNavIcon';
import { Navbar } from '../navigation/Navbar';
import NavButton from '../navigation/components/NavButton';
import NavButtonAccount from '../navigation/components/NavButtons/NavButtonAccount';
import NavButtonLogout from '../navigation/components/NavButtons/NavButtonLogout';
import LayoutWrapper from './LayoutWrapper';

export interface LayoutPartnerProps {
  children: React.ReactNode;
}

export const LayoutPartner = (props: LayoutPartnerProps) => {
  const { children } = props;
  const { t } = useTranslation();

  const buttonsTop = (
    <>
      <NavButton
        href={routes.dashboard}
        text={t('navigation.dashboard')}
        icons={{
          default: <DashboardIcon width={24} height={24} />,
          active: <DashboardIcon color={colors.white900} width={24} height={24} />,
        }}
      />
      <NavButton
        href={routes.activeGames}
        text={t('navigation.active-games')}
        icons={{
          default: <ActiveGameIcon width={24} height={24} />,
          active: <ActiveGameIcon color={colors.white900} width={24} height={24} />,
        }}
      />
      <NavButton
        href={routes.questions()}
        text={t('navigation.questions')}
        icons={{
          default: <QuestionsNavIcon width={24} height={24} />,
          active: <QuestionsNavIcon color={colors.white900} width={24} height={24} />,
        }}
      />
      <NavButton
        href={routes.templates()}
        text={t('navigation.templates')}
        icons={{
          default: <GameIcon width={24} height={24} />,
          active: <GameIcon color={colors.white900} width={24} height={24} />,
        }}
      />
      <NavButton
        href={routes.historyGames}
        text={t('navigation.history-games')}
        icons={{
          default: <CalendarIcon width={24} height={24} />,
          active: <CalendarIcon color={colors.white900} width={24} height={24} />,
        }}
      />
    </>
  );

  const buttonsBottom = (
    <>
      <NavButtonAccount />
      <NavButtonLogout />
    </>
  );

  return (
    <LayoutWrapper navbar={<Navbar buttonsTop={buttonsTop} buttonsBottom={buttonsBottom} />}>{children}</LayoutWrapper>
  );
};

export default LayoutPartner;

import { useTranslation } from 'react-i18next';
import { GameCharacter, GameStatus, GameStatuses } from '../../../types/GlobalTypes';
import GameStatusIcon from '../../activeGames/components/GameStatusIcon';
import GameStatusText from '../../activeGames/components/GameStatusText';

interface SingleActiveGameDetailsCardProps {
  status: GameStatus;
  gameCharacter: GameCharacter;
  city: string;
  code: string;
}

const SingleActiveGameDetailsCard = ({ status, gameCharacter, city, code }: SingleActiveGameDetailsCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="px-6 py-4 rounded-md border border-grey400 flex justify-between gap-10">
      <div>
        <h5 className="mb-1.5 text-black700 text-sm font-bold leading-4 uppercase whitespace-nowrap">
          {t('singleActiveGame.detailsCard.status')}
        </h5>
        <div className="flex gap-1.5 items-center">
          <GameStatusIcon status={status} />
          <GameStatusText status={status} className="text-sm" />
        </div>
      </div>

      <div>
        <h5 className="mb-1.5 text-black700 text-sm font-bold leading-4 uppercase whitespace-nowrap">
          {t('singleActiveGame.detailsCard.character')}
        </h5>
        <p className="text-black400 text-sm font-medium leading-5">{t(`gameCharacters.${gameCharacter}`)}</p>
      </div>

      <div>
        <h5 className="mb-1.5 text-black700 text-sm font-bold leading-4 uppercase whitespace-nowrap">
          {t('singleActiveGame.detailsCard.city')}
        </h5>
        <p className="text-black400 text-sm font-medium leading-5">{city}</p>
      </div>

      {status !== GameStatuses.FINISHIED && (
        <div>
          <h5 className="mb-1.5 text-black700 text-sm font-bold leading-4 uppercase whitespace-nowrap">
            {t('singleActiveGame.detailsCard.code')}
          </h5>
          <p className="text-black400 text-sm font-medium leading-5">{code}</p>
        </div>
      )}
    </div>
  );
};

export default SingleActiveGameDetailsCard;

import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiGameQuestion, IGameQuestion } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { transformGameQuestion } from '../../../utils/api/dataTransformers/transformGameQuestion/transformGameQuestion';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useGameQuestions = ({ gameId }: { gameId: string }) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError, isFetching } = useQuery(
    [QueryAliases.GAME_QUESTIONS(gameId)],
    () => axiosInstance.get(api.endpoints.GAME_QUESTIONS({ gameId })),
  );

  const apiQuestions: ApiGameQuestion[] | undefined = data?.data;

  const iQuestions: IGameQuestion[] | undefined = !!apiQuestions
    ? apiQuestions.map(question => transformGameQuestion(question))
    : [];

  return {
    gameQuestions: iQuestions,
    refetch,
    isSuccess,
    isLoading,
    isError,
    isFetching,
  };
};

export default useGameQuestions;

import type { MouseEvent } from 'react';
import SelectedQuestion from '../../components/SelectedQuestion';
import type { IQuestion } from '../../../../../../../types/ApiTypes';
import type { Coordinates } from '../../../../../../../types/GlobalTypes';

interface QuestionListProps {
  filteredQuestions: IQuestion[];
  selectedToRemoveQuestions: IQuestion[];
  selectedQuestions: IQuestion[];
  toggleSelectedToRemoveQuestions: (event: MouseEvent, question: IQuestion) => void;
  handleMoveUp: ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => void;
  handleMoveDown: ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => void;
  onUpdateQuestionCoordinates: ({
    newCoordinates,
    questionId,
  }: {
    newCoordinates: Coordinates;
    questionId: number;
  }) => void;
  onUpdateSelectedQuestion: (question: IQuestion) => void;
}

function QuestionList({
  filteredQuestions,
  selectedToRemoveQuestions,
  selectedQuestions,
  toggleSelectedToRemoveQuestions,
  handleMoveUp,
  handleMoveDown,
  onUpdateQuestionCoordinates,
  onUpdateSelectedQuestion,
}: QuestionListProps) {
  return (
    <ul className="p pr-3">
      {filteredQuestions.map((question, index) => {
        const isSelected = selectedToRemoveQuestions.some(selectedQuestion => selectedQuestion.id === question.id);

        const canMoveUp = index !== 0;
        const canMoveDown = index !== selectedQuestions.length - 1;

        return (
          <SelectedQuestion
            key={question.id}
            question={question}
            index={index}
            isSelected={isSelected}
            onTileClick={(event: MouseEvent) => toggleSelectedToRemoveQuestions(event, question)}
            showDivider
            canMoveUp={canMoveUp}
            canMoveDown={canMoveDown}
            onClickMoveUp={handleMoveUp}
            onClickMoveDown={handleMoveDown}
            onUpdateQuestionCoordinates={onUpdateQuestionCoordinates}
            onUpdateSelectedQuestion={onUpdateSelectedQuestion}
          />
        );
      })}
    </ul>
  );
}

export default QuestionList;

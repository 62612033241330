import { Combobox, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { colors } from '../../../styles/variables';
import { DropdownOption } from '../../../types/Dropdown.types';

export interface AutocompleteDropdownProps {
  autocompleteValue: string;
  setAutocompleteValue: (newValue: string) => void;
  allOptions: DropdownOption[];
  placeholder?: string;
}

const AutocompleteDropdown = ({
  autocompleteValue,
  setAutocompleteValue,
  allOptions,
  placeholder,
}: AutocompleteDropdownProps) => {
  const optionsToShow =
    autocompleteValue === ''
      ? allOptions
      : allOptions.filter(option => option.value.toLowerCase().includes(autocompleteValue.toLowerCase()));

  return (
    <div
      className={`relative  rounded-md text-sm text-black500 font-medium w-full placeholder:text-grey500 outline outline-1.5 outline-grey500`}
    >
      <Combobox value={autocompleteValue} onChange={e => setAutocompleteValue(e)}>
        <div className="flex pl-4 pr-12 py-2.5 w-full">
          <Combobox.Input
            className="border-none outline-0 rounded-md text-sm leading-5 text-black500 pr-4 py-2 w-auto min-w-[9rem] grow"
            value={autocompleteValue}
            onChange={event => {
              const value = event.target.value;
              setAutocompleteValue(value);
            }}
            placeholder={placeholder}
          />

          <Combobox.Button className="absolute inset-y-0 right-2 flex items-center p-3">
            {({ open }) => (
              <>
                {open ? (
                  <ChevronUpIcon color={colors.black700} className="w-8 h-4" />
                ) : (
                  <ChevronDownIcon color={colors.black700} className="w-8 h-4" />
                )}
              </>
            )}
          </Combobox.Button>
        </div>

        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {optionsToShow?.map(option => {
              return (
                <Combobox.Option
                  key={option.id}
                  className={`relative cursor-pointer select-none p-4 hover:bg-redSelectionBg `}
                  value={option.value}
                >
                  {option.value}
                </Combobox.Option>
              );
            })}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
};

export default AutocompleteDropdown;

import { useTranslation } from 'react-i18next';
import { QuestionTypes } from '../types/GlobalTypes';

const useQuestionTypeDropdownOptions = () => {
  const { t } = useTranslation();

  const dropdownOptions = Object.keys(QuestionTypes).map(key => ({
    id: QuestionTypes[key as keyof typeof QuestionTypes],
    value: t(`questionTypes.${QuestionTypes[key as keyof typeof QuestionTypes]}`),
  }));

  return { questionTypeDropdownOptions: dropdownOptions };
};

export default useQuestionTypeDropdownOptions;

import { colors } from '../../styles/variables';

const PartnersIcon = ({ color, width, height }: { color?: string; width?: number; height?: number }) => {
  return (
    <svg width={width ?? 32} height={height ?? 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3334 4.62368C23.309 5.60548 24.6667 7.64419 24.6667 10C24.6667 12.3558 23.309 14.3945 21.3334 15.3763M24.0001 22.3552C26.0154 23.2671 27.8301 24.7533 29.3334 26.6667M2.66675 26.6667C5.26207 23.3634 8.78565 21.3333 12.6667 21.3333C16.5478 21.3333 20.0714 23.3634 22.6667 26.6667M18.6667 10C18.6667 13.3137 15.9805 16 12.6667 16C9.35304 16 6.66675 13.3137 6.66675 10C6.66675 6.68629 9.35304 4 12.6667 4C15.9805 4 18.6667 6.68629 18.6667 10Z"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PartnersIcon;

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useTemplateQuestionsFilters from '../../components/forms/templateForm/hooks/useTemplateQuestionsFilters';
import TemplateForm from '../../components/forms/templateForm/TemplateForm';
import { TemplateFormOnSubmit } from '../../components/forms/templateForm/types/TemplateFormTypes';
import { prepareTemplateRequestData } from '../../components/forms/templateForm/utils/prepareTemplateRequestData';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import useQuestions from '../../hooks/api/questions/useQuestions';
import useCreateTemplate from '../../hooks/api/templates/useCreateTemplates';
import useSendTemplateAds from '../../hooks/api/templates/useSendTemplateAds';
import useSingleTemplate from '../../hooks/api/templates/useSingleTemplate';
import useTemplateGameCharacters from '../../hooks/api/templates/useTemplateGameCharacters';
import useTemplateGameTypes from '../../hooks/api/templates/useTemplateGameTypes';
import useToast from '../../hooks/useToast';
import { routes } from '../../static/routes';
import { ITemplateDetails } from '../../types/ApiTypes';
import { Langs } from '../../types/GlobalTypes';

const TemplateFormWithLoading = withLoading(TemplateForm);

const CopyTemplateView = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const templateId = params.templateId as string;

  const { template, isLoading: isLoadingTemplate } = useSingleTemplate({
    templateId,
    onErrorCb: () => {
      showErrorToast(t('errorMessages.somethingWentWrong'));
      navigate(routes.templates());
    },
  });

  const {
    searchValue,
    setSearchValue,
    debouncedSearchValue,
    questionTypeFilter,
    setQuestionTypeFilter,
    tagFilters,
    setTagFilters,
    cityFilter,
    setCityFilter,
    arcadeFilter,
    setArcadeFilter,
  } = useTemplateQuestionsFilters();

  const { questions } = useQuestions({
    search: debouncedSearchValue,
    filters: {
      tags: tagFilters,
      questionType: questionTypeFilter,
      city: cityFilter,
      isArcade: arcadeFilter,
    },
  });
  const { gameTypes } = useTemplateGameTypes();
  const { gameCharacters } = useTemplateGameCharacters();
  const { createTemplate, isLoading: isLoadingCreate } = useCreateTemplate();
  const { sendTemplateAds } = useSendTemplateAds();

  const handleSubmitForm = async ({ data, questions, newAds }: TemplateFormOnSubmit) => {
    const requestData = prepareTemplateRequestData({ data, questions });

    const res = await createTemplate(requestData);
    const templateId = res.data.id;

    if (!!templateId && newAds.length !== 0) {
      sendTemplateAds({ templateId, ads: newAds });
    }

    navigate(routes.templates());
  };

  const breadcrumbsProps = {
    limit: 2,
    extraBreadcrumbs: [{ text: t('copyTemplate.breadcrumb') ?? '' }],
  };

  const initTemplate: ITemplateDetails | undefined = !!template
    ? {
        ...template,
        advertisements: [],
        name: {
          translations: [
            { lang: Langs.PL, value: '' },
            { lang: Langs.EN, value: '' },
            { lang: Langs.UA, value: '' },
          ],
        },
      }
    : undefined;

  return (
    <TitleWrapper title={t('copyTemplate.title')} breadcrumbsProps={breadcrumbsProps}>
      <TemplateFormWithLoading
        isLoading={isLoadingTemplate}
        onSubmitForm={handleSubmitForm}
        isLoadingSubmit={isLoadingCreate}
        submitBtnText={t('copyTemplate.submitBtn')}
        allQuestions={questions?.data ?? []}
        gameCharactersOptions={gameCharacters ?? []}
        gameTypesOptions={gameTypes ?? []}
        initialTemplate={initTemplate}
        questionFiltersProps={{
          searchValue,
          setSearchValue,
          questionType: questionTypeFilter,
          setQuestionType: setQuestionTypeFilter,
          tags: tagFilters,
          setTags: setTagFilters,
          city: cityFilter,
          setCity: setCityFilter,
          isArcade: arcadeFilter,
          setIsArcade: setArcadeFilter,
        }}
      />
    </TitleWrapper>
  );
};

export default CopyTemplateView;

import { useState } from 'react';

import useDebounce from '../../../../hooks/useDebounce';

const useTemplateQuestionsFilters = () => {
  const [searchValue, setSearchValue] = useState('');
  const { debouncedValue: debouncedSearchValue } = useDebounce({ value: searchValue, delay: 500 });
  const [questionTypeFilter, setQuestionTypeFilter] = useState('');
  const [tagFilters, setTagFilters] = useState<string[]>([]);
  const [cityFilter, setCityFilter] = useState('');
  const [arcadeFilter, setArcadeFilter] = useState('');

  return {
    searchValue,
    setSearchValue,
    debouncedSearchValue,
    questionTypeFilter,
    setQuestionTypeFilter,
    tagFilters,
    setTagFilters,
    cityFilter,
    setCityFilter,
    arcadeFilter,
    setArcadeFilter,
  };
};

export default useTemplateQuestionsFilters;

import { Coordinates } from '../../../types/GlobalTypes';

export const getCoordinatesFromString = (coordinatesString: string) => {
  const trimmedInput = coordinatesString.trim();

  const coordinatesData = trimmedInput.split(',').map(item => {
    const trimmedItem = item.trim();

    if (trimmedItem[trimmedItem.length - 1] === '.') {
      return null;
    }

    return Number(trimmedItem);
  });

  const lat = coordinatesData?.[0];
  const lng = coordinatesData?.[1];

  if (!lat || !lng || isNaN(lat) || isNaN(lng)) {
    return;
  }

  const coordinates: Coordinates = {
    lat,
    lng,
  };

  return coordinates;
};

import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ResetPasswordForm from '../../../../components/forms/resetPasswordForm/ResetPasswordForm';
import XMarkIcon from '../../../../components/icons/XmarkIcon';
import useChangePassword from '../../../../hooks/api/auth/useChangePassword';
import { ApiResetPasswordBody } from '../../../../types/ApiTypes';

type ChangePasswordModalProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};
const ChangePasswordModal = ({ setIsOpen }: ChangePasswordModalProps) => {
  const { t } = useTranslation();
  const { changePassword, isLoading } = useChangePassword();

  const handleResetPasswordWithoutToken = ({ newPassword, confirmationPassword }: ApiResetPasswordBody) => {
    changePassword({ newPassword, confirmationPassword });
    closeModal();
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col justify-center max-h-fit h-auto">
      <h2 className="p-8 border-b border-grey300 text-center text-xl font-medium relative">
        <button className="absolute top-1/2 -translate-y-1/2 left-8 cursor-pointer" onClick={closeModal}>
          <XMarkIcon />
        </button>

        {t('account.personalData.changePassword')}
      </h2>
      <div className="px-8 py-8 mx-auto flex flex-col max-w-xl justify-center">
        <p className="mt-4 text-black500 font-medium leading-snug">{t('resetPassword.description')}</p>
        <ResetPasswordForm
          onFormSubmit={handleResetPasswordWithoutToken}
          onCancelText={t('resetPassword.cancelBtn')}
          onCancel={closeModal}
          isLoading={isLoading}
          passwordLabel={t('resetPassword.newPasswordLabel')}
          confirmationPasswordLabel={t('resetPassword.confirmationPasswordLabel')}
          submitBtnText={t('resetPassword.submitBtn')}
        />
      </div>
    </div>
  );
};

export default ChangePasswordModal;

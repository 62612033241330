import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import PlayIcon from '../../../../../components/icons/PlayIcon';
import { colors } from '../../../../../styles/variables';
import TemplatesListTableStartGameModal from './templatesListTableStartGameModal/TemplatesListTableStartGameModal';

interface TemplatesListTableStartGameBtnProps {
  templateId: string;
  gameType: string;
}

const TemplatesListTableStartGameBtn = ({ templateId, gameType }: TemplatesListTableStartGameBtnProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('templates.table.buttons.startGame')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <PlayIcon color={isHovered ? colors.red500 : colors.grey700} />
          </button>
        )}
      </HoverableTooltip>

      <TemplatesListTableStartGameModal
        templateId={templateId}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        gameType={gameType}
      />
    </>
  );
};

export default TemplatesListTableStartGameBtn;

import { GameStatuses } from '../../../../types/GlobalTypes';
import { GameTableItem } from '../../ActiveGamesView';

export const sortGamesByStatus = (games: GameTableItem[]) => {
  const ongoingGamesSorted = games
    .filter(game => game.status === GameStatuses.ONGOING)
    .sort((gameA, gameB) => gameA.endDate.getTime() - gameB.endDate.getTime());

  const scheduledGamesSorted = games
    .filter(game => game.status === GameStatuses.SCHEDULED)
    .sort((gameA, gameB) => gameA.startDate.getTime() - gameB.startDate.getTime());

  const finishedGamesSorted = games
    .filter(game => game.status === GameStatuses.FINISHIED)
    .sort((gameA, gameB) => gameB.startDate.getTime() - gameA.startDate.getTime());

  return [...ongoingGamesSorted, ...scheduledGamesSorted, ...finishedGamesSorted];
};

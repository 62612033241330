import Cookies from 'js-cookie';
import { Cookie } from '../../types/Cookies';

export const setAccessToken = (_accessToken: string) => {
  Cookies.set(Cookie.AccessToken, _accessToken);
};

export const removeAccessToken = () => {
  Cookies.remove(Cookie.AccessToken);
};

export const getAccessToken = () => Cookies.get(Cookie.AccessToken);

export const setRefreshToken = (_refreshToken: string) => {
  Cookies.set(Cookie.RefreshToken, _refreshToken);
};

export const removeRefreshToken = () => {
  Cookies.remove(Cookie.RefreshToken);
};

export const getRefreshToken = () => Cookies.get(Cookie.RefreshToken);

export const setResetPasswordToken = (_token: string) => {
  Cookies.set(Cookie.ResetPasswordToken, _token);
};

export const removeResetPasswordToken = () => {
  Cookies.remove(Cookie.ResetPasswordToken);
};

export const getResetPasswordToken = () => Cookies.get(Cookie.ResetPasswordToken);

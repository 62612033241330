import { InitialPartnerSubscription } from '../../../types/ApiPartnerTypes';
import useHandleCreateSubscription from '../../addPartner/hooks/useHandleCreateSubscription';
import useHandleEditSubscription from './useHandleEditSubscription';

const useHandleSubscription = () => {
  const { handleEditSubscription, handleEditSubscriptionIsLoading } = useHandleEditSubscription();
  const { handleCreateSubscription, handleCreateSubscriptionIsLoading } = useHandleCreateSubscription();

  const handleSubscription = async ({
    subscription,
    partnerId,
  }: {
    subscription: InitialPartnerSubscription | undefined;
    partnerId: number;
  }) => {
    if (subscription && subscription.id) {
      const updatedSubscription = await handleEditSubscription({ subscription });

      return updatedSubscription;
    } else if (subscription) {
      const createdSubscription = await handleCreateSubscription({ subscription, partnerId });

      return createdSubscription;
    }

    return;
  };

  const isLoading = handleEditSubscriptionIsLoading || handleCreateSubscriptionIsLoading;

  return { handleSubscription, handleSubscriptionIsLoading: isLoading };
};

export default useHandleSubscription;

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TableCell from '../../../components/dataTable/components/TableCell';
import { ISingleBundle, LimitValuesWithTranslation } from '../../../types/ApiBundleTypes';
import DeleteBundleButton from '../components/DeleteBundleButton';
import EditBundleButton from '../components/EditBundleButton';

const useBundlesTableHeaders = () => {
  const { t } = useTranslation();

  const headers: GridColDef[] = [
    {
      field: 'bundleName',
      headerName: t('partners.tableHeaders.name'),
      minWidth: 100,
      maxWidth: 180,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<ISingleBundle>) => {
        return <TableCell>{params.row?.bundleName}</TableCell>;
      },
    },
    {
      field: 'gameCharacters',
      headerName: t('bundleLimitsDictionary.gameCharacters'),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ISingleBundle>) => {
        const limitValue = params.row?.gameCharacters?.limitValue;
        const isArray = Array.isArray(limitValue);

        return (
          <div>
            {isArray ? (
              limitValue?.map((entry: LimitValuesWithTranslation) => (
                <TableCell key={entry?.id} className="py-0">
                  {entry.value}
                </TableCell>
              ))
            ) : (
              <TableCell>{limitValue}</TableCell>
            )}
          </div>
        );
      },
    },
    {
      field: 'questionTypes',
      headerName: t('bundleLimitsDictionary.questionTypes'),
      minWidth: 100,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<ISingleBundle>) => {
        const limitValue = params.row?.questionTypes?.limitValue;
        const isArray = Array.isArray(limitValue);

        return (
          <div>
            {isArray ? (
              limitValue?.map((entry: LimitValuesWithTranslation) => (
                <TableCell key={entry.id} className="py-0">
                  {entry.value}
                </TableCell>
              ))
            ) : (
              <TableCell>{limitValue}</TableCell>
            )}
          </div>
        );
      },
    },
    {
      field: 'gamersPerGame',
      headerName: t('bundleLimitsDictionary.gamersPerGame'),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ISingleBundle>) => {
        return <TableCell>{params.row?.gamersPerGame?.limitValue}</TableCell>;
      },
    },
    {
      field: 'gamesPerMonth',
      headerName: t('bundleLimitsDictionary.gamesPerMonth'),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ISingleBundle>) => {
        return <TableCell>{params.row?.gamesPerMonth?.limitValue}</TableCell>;
      },
    },
    {
      field: 'activeGames',
      headerName: t('bundleLimitsDictionary.activeGames'),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ISingleBundle>) => {
        return <TableCell>{params.row?.activeGames?.limitValue}</TableCell>;
      },
    },
    {
      field: 'buttons',
      headerName: '',
      maxWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ISingleBundle>) => {
        return (
          <div className="flex w-full justify-end items-center gap-4">
            <EditBundleButton id={params.row?.id} />
            <DeleteBundleButton id={params.row?.id} bundleName={params.row.bundleName} />
          </div>
        );
      },
    },
  ];
  return { headers };
};

export default useBundlesTableHeaders;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { ApiResetPasswordBody } from '../../../types/ApiTypes';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useChangePassword = () => {
  const axiosInstance = useAxiosInstance();
  const { showSuccessToast, showErrorToast } = useToast();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    ({ newPassword, confirmationPassword }: ApiResetPasswordBody) =>
      axiosInstance.post(api.endpoints.RESET_PASSWORD_WITHOUT_TOKEN, { newPassword, confirmationPassword }),
    {
      onSuccess: async () => {
        showSuccessToast(t('resetPassword.successMessage'));
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.resetPasswordFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { changePassword: mutate, isLoading };
};

export default useChangePassword;

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BreadcrumbsProps } from '../../components/common/breadcrumbs/Breadcrumbs';
import QuestionForm from '../../components/forms/questionForm/QuestionForm';
import { OnSubmitFormProps } from '../../components/forms/questionForm/types/QuestionFormTypes';
import PlusIcon from '../../components/icons/PlusIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import useAddQuestionImages from '../../hooks/api/questions/useAddQuestionImages';
import useCreateQuestion from '../../hooks/api/questions/useCreateQuestion';
import useSingleQuestion from '../../hooks/api/questions/useSingleQuestion';
import useToast from '../../hooks/useToast';
import { routes } from '../../static/routes';
import { IQuestion } from '../../types/ApiTypes';
import { Langs } from '../../types/GlobalTypes';
import { prepareQuestionRequestData } from '../../utils/questions/prepareQuestionRequestData/prepareQuestionRequestData';

const QuestionFormWithLoading = withLoading(QuestionForm);

const CopyQuestionView = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const questionId = params.questionId as string;
  const location = useLocation();
  const prevPath = location.state?.prevPath;

  const { questionResponse, isLoading: isLoadingQuestion } = useSingleQuestion({
    questionId,
    onErrorCb: () => {
      showErrorToast(t('errorMessages.somethingWentWrong'));
      navigate(routes.questions());
    },
  });
  const question = questionResponse?.question;

  const { createQuestion, isLoading: isLoadingCreateQuestion } = useCreateQuestion();
  const { addQuestionImages, isLoading: isLoadingAddImages } = useAddQuestionImages();

  const handleSubmitQuestion = async ({
    formData,
    mainImage,
    correctAnswerImage,
    incorrectAnswerImage,
    markerIcon,
    goToRelatedQuestion,
    isDirtyAnyImage,
  }: OnSubmitFormProps) => {
    const requestData = prepareQuestionRequestData(formData);

    const res = await createQuestion(requestData);
    const questionId = res.data.id;

    if (isDirtyAnyImage && !!questionId) {
      addQuestionImages({
        questionId: questionId as string,
        mainImage,
        correctAnswerImage,
        incorrectAnswerImage,
        markerIcon,
      });
    }

    let redirectRoute = routes.questions();

    if (goToRelatedQuestion) {
      redirectRoute = routes.addRelatedQuestion({ parentQuestionId: questionId });
    } else if (!!prevPath && typeof prevPath === 'string') {
      redirectRoute = prevPath;
    }

    navigate(redirectRoute);
  };

  const breadcrumbsProps: BreadcrumbsProps = {
    limit: 2,
    extraBreadcrumbs: [{ text: t('copyQuestion.breadcrumb') ?? '' }],
  };

  const initQuestion: IQuestion | undefined = !!question
    ? {
        ...question,
        name: [
          { lang: Langs.PL, value: '' },
          { lang: Langs.EN, value: '' },
          { lang: Langs.UA, value: '' },
        ],

        image: null,
        correctAnswerImage: null,
        incorrectAnswerImage: null,
      }
    : undefined;

  return (
    <TitleWrapper title={t('copyQuestion.title')} breadcrumbsProps={breadcrumbsProps}>
      <div className="pb-20">
        <QuestionFormWithLoading
          isLoading={isLoadingQuestion}
          isLoadingSubmit={isLoadingCreateQuestion || isLoadingAddImages}
          onSubmitForm={handleSubmitQuestion}
          submitBtnIcon={<PlusIcon />}
          submitBtnText={t('copyQuestion.submitBtn')}
          initialQuestion={initQuestion}
        />
      </div>
    </TitleWrapper>
  );
};

export default CopyQuestionView;

import { MouseEventHandler } from 'react';

interface MapMarkerQuestionTypeWrapperProps {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  color: string;
}

const MapMarkerQuestionTypeWrapper = ({ children, onClick, color }: MapMarkerQuestionTypeWrapperProps) => {
  return (
    <div
      className="relative w-[42px] h-[42px] p-2 cursor-pointer rounded-full flex justify-center items-center"
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      {children}
      <svg
        className="absolute left-[13px] -bottom-[6px]"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
      >
        <path
          d="M16 0.299805H0L6.58579 6.88559C7.36684 7.66664 8.63317 7.66664 9.41421 6.88559L16 0.299805Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default MapMarkerQuestionTypeWrapper;

const ChartTooltip = ({
  title,
  children,
  isActive,
}: {
  title?: string;
  children: React.ReactNode;
  isActive: boolean;
}) => {
  if (isActive) {
    return (
      <div className="bg-white rounded-md p-4 border border-grey700">
        {title && <p className="text-grey700">{title}</p>}
        <div className="mt-3">{children}</div>
      </div>
    );
  }

  return null;
};

export default ChartTooltip;

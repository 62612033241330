import { RectangleAreaCoordinates } from '../../../types/GlobalTypes';

export const getGoogleMapAreaCoordinates = (areaBounds: google.maps.LatLngBounds) => {
  const ne = areaBounds.getNorthEast();
  const sw = areaBounds.getSouthWest();
  const se = new google.maps.LatLng(sw.lat(), ne.lng());
  const nw = new google.maps.LatLng(ne.lat(), sw.lng());

  const coordinates: RectangleAreaCoordinates = {
    ne: {
      lat: ne.lat(),
      lng: ne.lng(),
    },
    sw: {
      lat: sw.lat(),
      lng: sw.lng(),
    },
    se: {
      lat: se.lat(),
      lng: se.lng(),
    },
    nw: {
      lat: nw.lat(),
      lng: nw.lng(),
    },
  };

  return coordinates;
};

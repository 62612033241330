import _ from 'lodash';
import { useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import useQuestionTypeDropdownOptions from '../../../hooks/useQuestionTypeDropdownOptions';
import { questionValidationSchema } from '../../../static/validation/schemas/questionValidationSchema';
import { QuestionType, QuestionTypes } from '../../../types/GlobalTypes';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import LangTabSwitcher from '../../common/tabSwitchers/langTabSwitcher/LangTabSwitcher';
import { defaultMapCenter } from '../../map/static/mapDefaults';
import QuestionFormAnswerMessages from './components/QuestionFormAnswerMessages';
import QuestionFormAnswers from './components/QuestionFormAnswers';
import QuestionFormGeneral from './components/QuestionFormGeneral';
import QuestionFormLocation from './components/QuestionFormLocation';
import QuestionFormMarkerIcon from './components/QuestionFormMarkerIcon';
import QuestionFormPoints from './components/QuestionFormPoints';
import QuestionFormRelatedQuestionButton from './components/QuestionFormRelatedQuestionButton';
import QuestionFormTags from './components/QuestionFormTags';
import useDefaultQuestionValues from './hooks/useDefaultQuestionValues';
import { QuestionFormInputs, QuestionFormInputsKeys, QuestionFormProps } from './types/QuestionFormTypes';
import { checkIfAnswerMessagesRequired } from './utils/checkIfAnswerMessagesRequired';
import { useTrackUnsavedChange } from '../../../context/FormChangeProvider';

const QuestionForm = ({
  onSubmitForm,
  submitBtnIcon,
  submitBtnText,
  initialQuestion,
  doesRelatedQuestionExist,
  isLoadingSubmit,
  isEditing,
  isRelatedQuestionForm,
  isModal,
}: QuestionFormProps) => {
  const { t } = useTranslation();
  const { questionTypeDropdownOptions } = useQuestionTypeDropdownOptions();
  const { getDefaultQuestionValues } = useDefaultQuestionValues();

  const [questionType, setQuestionType] = useState<QuestionType>(
    !!initialQuestion ? initialQuestion.questionType.code : QuestionTypes.CLOSED,
  );

  const oldMainImageUrl = initialQuestion?.image;
  const [newMainImage, setNewMainImage] = useState<File | null>(null);

  const oldCorrectAnswerImageUrl = initialQuestion?.correctAnswerImage;
  const [newCorrectAnswerImage, setNewCorrectAnswerImage] = useState<File | null>(null);
  const oldIncorrectAnswerImageUrl = initialQuestion?.incorrectAnswerImage;
  const [newIncorrectAnswerImage, setNewIncorrectAnswerImage] = useState<File | null>(null);
  const oldMarkerIconUrl = initialQuestion?.icon;
  const [newMarkerIcon, setNewMarkerIcon] = useState<File | null>(null);
  const [isImageDeleted, setIsImageDeleted] = useState<boolean>(false);

  const [isLocation, setIsLocation] = useState(
    !!initialQuestion ? !!initialQuestion.coordinates : !isRelatedQuestionForm,
  );

  const areAnswerMessagesRequired = checkIfAnswerMessagesRequired(questionType);

  const methods = useForm<QuestionFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(
      questionValidationSchema({ isLocation, questionType: questionType, areAnswerMessagesRequired }),
    ),
    defaultValues: getDefaultQuestionValues({
      question: initialQuestion,
      questionTypeOptions: questionTypeDropdownOptions,
    }),
  });

  useTrackUnsavedChange(methods.formState.isDirty);

  const onSubmit: SubmitHandler<{
    data: QuestionFormInputs;
    goToRelatedQuestion?: boolean;
  }> = useCallback(
    async ({ data, goToRelatedQuestion }) => {
      if (!isLocation) {
        data.coordinates = undefined;
        data.city = '';
      }

      if (!areAnswerMessagesRequired) {
        delete data.correctAnswerText;
        delete data.incorrectAnswerText;
      }

      const isDirtyForm = !_.isEmpty(methods.formState.dirtyFields);
      const isDirtyAnyImage =
        !!newMainImage || !!newCorrectAnswerImage || !!newIncorrectAnswerImage || !!newMarkerIcon || isImageDeleted;

      onSubmitForm({
        formData: data,
        mainImage: newMainImage,
        correctAnswerImage: newCorrectAnswerImage,
        incorrectAnswerImage: newIncorrectAnswerImage,
        markerIcon: newMarkerIcon,
        goToRelatedQuestion,
        isDirtyForm,
        isDirtyAnyImage,
      });
    },
    [
      areAnswerMessagesRequired,
      isLocation,
      methods.formState.dirtyFields,
      newCorrectAnswerImage,
      newIncorrectAnswerImage,
      newMainImage,
      newMarkerIcon,
      onSubmitForm,
      isImageDeleted,
    ],
  );

  const getErrorMessage = (field: QuestionFormInputsKeys) => get(methods.formState.errors, field);

  const submitButtonComponent = (
    <PrimaryButton
      onClick={methods.handleSubmit(data => onSubmit({ data }))}
      disabled={isLoadingSubmit}
      loading={isLoadingSubmit}
      className="w-fit self-end"
      prefixIcon={submitBtnIcon}
    >
      {submitBtnText}
    </PrimaryButton>
  );

  const canHandleRelatedQuestion =
    !isRelatedQuestionForm && (questionType === QuestionTypes.CLOSED || questionType === QuestionTypes.OPEN_SHORT_TEXT);

  return (
    <LangTabSwitcher sufixElement={!isModal && submitButtonComponent} hideBackButton={isModal}>
      {({ activeLang }) => (
        <FormProvider {...methods}>
          <div className="bg-white900 pt-14">
            <div className={isModal ? '' : 'pl-8'}>
              <QuestionFormTags />

              <QuestionFormGeneral
                activeLang={activeLang}
                oldMainImageUrl={oldMainImageUrl}
                newMainImage={newMainImage}
                setNewMainImage={setNewMainImage}
                getErrorMessage={getErrorMessage}
                setQuestionType={setQuestionType}
                isQuestionTypeDisabled={isEditing}
              />

              <QuestionFormAnswers
                activeLang={activeLang}
                getErrorMessage={getErrorMessage}
                questionType={questionType}
              />

              <QuestionFormPoints getErrorMessage={getErrorMessage} questionType={questionType} />

              {!isRelatedQuestionForm && (
                <QuestionFormLocation
                  getErrorMessage={getErrorMessage}
                  isLocation={isLocation}
                  setIsLocation={setIsLocation}
                  mapCenter={!!initialQuestion?.coordinates ? initialQuestion.coordinates : defaultMapCenter}
                  mapZoom={!!initialQuestion?.coordinates ? 12 : 6}
                  initialCity={initialQuestion?.city ?? ''}
                />
              )}

              {areAnswerMessagesRequired && (
                <QuestionFormAnswerMessages
                  activeLang={activeLang}
                  getErrorMessage={getErrorMessage}
                  oldCorrectAnswerImageUrl={oldCorrectAnswerImageUrl}
                  newCorrectAnswerImage={newCorrectAnswerImage}
                  setNewCorrectAnswerImage={setNewCorrectAnswerImage}
                  oldIncorrectAnswerImageUrl={oldIncorrectAnswerImageUrl}
                  newIncorrectAnswerImage={newIncorrectAnswerImage}
                  setNewIncorrectAnswerImage={setNewIncorrectAnswerImage}
                />
              )}

              {!isRelatedQuestionForm && (
                <QuestionFormMarkerIcon
                  oldMarkerIconUrl={oldMarkerIconUrl}
                  newMarkerIcon={newMarkerIcon}
                  setNewMarkerIcon={setNewMarkerIcon}
                  onDeletedImage={setIsImageDeleted}
                />
              )}

              {canHandleRelatedQuestion && (
                <QuestionFormRelatedQuestionButton
                  onSaveCurrentForm={methods.handleSubmit(data => onSubmit({ data, goToRelatedQuestion: true }))}
                  isLoading={isLoadingSubmit}
                  text={
                    doesRelatedQuestionExist
                      ? t('questionForm.relatedQuestion.editBtn')
                      : t('questionForm.relatedQuestion.addBtn')
                  }
                />
              )}

              {isModal && <div className="flex justify-end mt-8">{submitButtonComponent}</div>}
            </div>
          </div>
        </FormProvider>
      )}
    </LangTabSwitcher>
  );
};

export default QuestionForm;

import { colors } from '../../styles/variables';

interface PlusIconProps {
  color?: string;
  size?: number;
}

const PlusIcon = ({ color, size }: PlusIconProps) => {
  return (
    <svg width={size ?? '24'} height={size ?? '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5V19M5 12H19" stroke={color ?? colors.white900} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PlusIcon;

import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from '../../../../components/common/modals/Modal';
import XMarkIcon from '../../../../components/icons/XmarkIcon';
import useRegisterPlayer from '../../../../hooks/api/players/useRegisterPlayer';
import { ApiRegisterPlayer } from '../../../../types/ApiTypes';
import RegisterRelatedUserForm from './RegisterRelatedUserForm';

type AddPlayerModalContent = Omit<ModalProps, 'children'>;

const AddPlayerModalContent = ({ isOpen, setIsOpen }: AddPlayerModalContent) => {
  const { t } = useTranslation();

  const closeModal = () => {
    setIsOpen(false);
  };
  const { registerPlayer, isLoading } = useRegisterPlayer(closeModal);

  const handleSubmitForm = async ({ login, newPassword, confirmationPassword }: ApiRegisterPlayer) => {
    registerPlayer({ login, newPassword, confirmationPassword });
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="flex flex-col justify-center max-h-fit h-auto">
        <h2 className="p-8 border-b-[1.5px] border-gray-400 text-center text-xl font-medium relative">
          <button className="absolute top-1/2 -translate-y-1/2 left-8 cursor-pointer" onClick={() => setIsOpen(false)}>
            <XMarkIcon size="18" />
          </button>

          {t('account.players.addButton')}
        </h2>
        <div className="px-8 py-8 mx-auto flex flex-col max-w-xl justify-center">
          <RegisterRelatedUserForm onFormSubmit={handleSubmitForm} isLoading={isLoading} onCancel={closeModal} />
        </div>
      </div>
    </Modal>
  );
};

export default AddPlayerModalContent;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { CallbackDefault } from '../../../types/Types';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

export interface updateTemplateAvailableQuestionsRequest {
  availableQuestions: {
    index: number;
    id: number;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  }[];
}

const useUpdateTemplateAvailableQuestions = ({ onUpdateSuccess }: { onUpdateSuccess?: CallbackDefault } = {}) => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const { mutateAsync, isLoading } = useMutation(
    ({ templateId, data }: { templateId: string; data: updateTemplateAvailableQuestionsRequest }) =>
      axiosInstance.patch(api.endpoints.SINGLE_TEMPLATE({ id: templateId }), { ...data }),
    {
      async onSuccess() {
        if (!!onUpdateSuccess) {
          onUpdateSuccess();
        }
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.editTemplateFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { updateTemplateAvailableQuestions: mutateAsync, isLoading };
};

export default useUpdateTemplateAvailableQuestions;

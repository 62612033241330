import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../../../components/common/modals/ConfirmModal';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import TrashIcon from '../../../../../components/icons/TrashIcon';
import { colors } from '../../../../../styles/variables';

const SingleActiveGamePlayersTableDeleteBtn = ({
  userId,
  onDelete,
  isLoadingDelete,
}: {
  userId: number;
  onDelete: ({ userId }: { userId: number }) => void;
  isLoadingDelete?: boolean;
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('singleActiveGame.players.table.buttons.delete')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            <TrashIcon color={isHovered ? colors.red500 : colors.grey700} width={24} height={24} />
          </button>
        )}
      </HoverableTooltip>

      <ConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={t('singleActiveGame.players.delete.title')}
        confirmQuestion={t('singleActiveGame.players.delete.confirmQuestion')}
        confirmBtnText={t('singleActiveGame.players.delete.confirmBtnText')}
        confirmBtnAction={() => onDelete({ userId })}
        cancelBtnText={t('singleActiveGame.players.delete.cancelBtnText')}
        isConfirmBtnDisabled={isLoadingDelete}
        isConfirmBtnLoading={isLoadingDelete}
      />
    </>
  );
};

export default SingleActiveGamePlayersTableDeleteBtn;

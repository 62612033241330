import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '../../../../components/dataTable/MaterialTable/MaterialTable';
import useDeleteQuestion from '../../../../hooks/api/questions/useDeleteQuestion';
import { elementsIds } from '../../../../static/elementsIds';
import { routes } from '../../../../static/routes';
import { IQuestion } from '../../../../types/ApiTypes';
import QuestionsListTableSelectionPanel from './components/QuestionsListTableSelectionPanel';
import useQuestionsTableHeaders from './hooks/useQuestionsTableHeaders';

interface QuestionsViewListProps {
  questions: IQuestion[];
  isLoading?: boolean;
  pageSize: number;
  page: number;
  onPageChange: (newVal: number) => void;
  rowCount: number;
}

const QuestionsViewList = ({
  questions,
  isLoading,
  pageSize,
  page,
  onPageChange,
  rowCount,
}: QuestionsViewListProps) => {
  const navigate = useNavigate();

  const { headers } = useQuestionsTableHeaders();

  const [selectedQuestionIds, setSelectedQuestionIds] = useState<GridRowSelectionModel>([]);
  const { deleteQuestion, isLoading: isLoadingDelete } = useDeleteQuestion();

  const handleDuplicate = (id: GridRowId) => {
    const currentPath = `${location.pathname}${location.search}`;

    navigate(routes.copyQuestion(id.toString()), { state: { prevPath: currentPath } });
  };

  const handleDeleteSelectedQuestions = async (ids: GridRowId[]) => {
    ids.forEach(async id => {
      await deleteQuestion(id.toString());
    });
  };

  return (
    <>
      <div id={elementsIds.questionsTable}>
        <MaterialTable
          columns={headers}
          rows={questions}
          checkboxSelection
          onRowSelectionModelChange={setSelectedQuestionIds}
          loading={isLoading}
          showPagination
          rowCount={rowCount}
          paginationMode="server"
          paginationModel={{
            page: page - 1,
            pageSize,
          }}
          onPaginationModelChange={e => {
            onPageChange(e.page + 1);
          }}
          disableRowSelectionOnClick
        />
      </div>

      {selectedQuestionIds?.length > 0 && (
        <QuestionsListTableSelectionPanel
          tableId={elementsIds.questionsTable}
          selectedRowsNumber={selectedQuestionIds.length}
          hideDuplicate={selectedQuestionIds?.length !== 1}
          onDuplicate={() => {
            if (selectedQuestionIds?.length === 1) {
              handleDuplicate(selectedQuestionIds[0]!);
            }
          }}
          onDelete={() => {
            handleDeleteSelectedQuestions(selectedQuestionIds);
          }}
          isLoadingDelete={isLoadingDelete}
        />
      )}
    </>
  );
};

export default QuestionsViewList;

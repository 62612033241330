import { useEffect, useState } from 'react';
import { getTimeDifference } from '../utils/dates/getRemainingTime/getRemainingTime';

const EMPTY_TIME_STRING = '00:00:00';

export const useGameTimer = ({ endDate }: { endDate: Date }) => {
  const [remainingTime, setRemainingTime] = useState(getTimeDifference({ startDate: new Date(), endDate }));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(getTimeDifference({ startDate: new Date(), endDate }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endDate]);

  const { hours, minutes, seconds } = remainingTime;

  const displayRemainingTime =
    hours < 1 && minutes < 1 && seconds < 1
      ? EMPTY_TIME_STRING
      : `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return {
    displayRemainingTime,
  };
};

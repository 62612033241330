export const api = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  AWS_URL: process.env.REACT_APP_AWS_URL,
  endpoints: {
    LOGIN: '/auth/login-mobile',
    REFRESH: '/auth/refresh-mobile',
    REQUEST_RESET_PASSWORD: '/auth/reset-password-link',
    RESET_PASSWORD: ({ token }: { token: string }) => `/auth/reset-password/${token}`,
    USER_DETAILS: '/users/details',
    ALL_USERS: '/users',
    QUESTIONS: '/questions',
    SINGLE_QUESTION: ({ id }: { id: string }) => `/questions/${id}`,
    QUESTION_IMAGES: ({ id }: { id: string }) => `/questions/${id}/images`,
    QUESTION_POINTS_OPTIONS: '/questions/question-points',
    QUESTION_TYPES_OPTIONS: '/questions/question-types',
    QUESTIONS_CITIES: '/questions/cities',
    TEMPLATES: '/templates',
    SINGLE_TEMPLATE: ({ id }: { id: string }) => `/templates/${id}`,
    TEMPLATES_GAME_TYPES: '/templates/game-types',
    TEMPLATES_GAME_CHARACTERS: '/templates/game-characters',
    TEMPLATES_CITIES: '/templates/cities',
    TEMPLATE_ADS: ({ templateId }: { templateId: string }) => `/templates/${templateId}/advertisements`,
    TEMPLATE_QUESTION: ({ templateId, questionId }: { templateId: number; questionId: number }) =>
      `/templates/${templateId}/question/${questionId}`,
    TEMPLATE_STATISTICS: ({ templateId }: { templateId: string }) => `/templates/${templateId}/statistics`,
    SINGLE_AD: ({ id }: { id: number }) => `/advertisements/${id}`,
    TAGS: (tag: string) => `/tags?tag=${tag}`,
    GAMES: '/games',
    INDIVIDUAL_PAID_GAMES: '/games/individual-paid',
    GAME_PARTNER_LOGO: ({ gameId }: { gameId: string }) => `/games/${gameId}/partner-logo`,
    ACTIVE_GAMES: '/games/active',
    HISTORY_GAMES: '/games/history',
    SINGLE_GAME: (id: string) => `/games/${id}`,
    GAME_MESSAGE_CREATE: ({ gameId }: { gameId: string }) => `/messages/${gameId}`,
    GAME_RECEIVED_MESSAGES: ({ gameId }: { gameId: string }) => `/messages/${gameId}/received`,
    GAME_SENT_MESSAGES: ({ gameId }: { gameId: string }) => `/messages/${gameId}/sent`,
    GAME_USERS: ({ gameId }: { gameId: string }) => `/games/${gameId}/users`,
    GAME_QUESTIONS: ({ gameId }: { gameId: string }) => `/games/${gameId}/questions`,
    GAME_CODES: ({ gameId }: { gameId: string }) => `/games/${gameId}/codes`,
    NEW_CODES: ({ gameId }: { gameId: string }) => `/games/${gameId}/codes`,
    GAME_REVIEW_ANSWERS: ({ gameId }: { gameId: string }) => `/games/${gameId}/answers`,
    GAME_CHECKED_ANSWERS: ({ gameId }: { gameId: string }) => `/games/${gameId}/answers/checked`,
    SINGLE_GAME_STATISTICS: ({ gameId }: { gameId: string }) => `/games/${gameId}/statistics`,
    ALL_GAME_STATISTICS: {
      SUMMARY: () => `/games/statistics`,
      CREATED_GAMES: () => `/games/statistics/created-games`,
      GAMERS: () => `/games/statistics/gamers-count`,
      CREATED_QUESTIONS: () => `/games/statistics/created-questions`,
      CREATED_TEMPLATES: () => `/games/statistics/created-templates`,
    },
    GAME_REPORT: ({ gameId }: { gameId: number }) => `/games/${gameId}/report`,
    GAME_IMAGES: ({ gameId }: { gameId: number }) => `/games/${gameId}/images`,
    GAME_VIDEOS: ({ gameId }: { gameId: number }) => `/games/${gameId}/videos`,
    ACCEPT_ANSWER: ({ answerId }: { answerId: string }) => `/games/answers/accept/${answerId}`,
    REJECT_ANSWER: ({ answerId }: { answerId: string }) => `/games/answers/reject/${answerId}`,
    MARK_MESSAGE_READ: ({ messageId }: { messageId: number }) => `/messages/${messageId}/read`,
    RESET_PASSWORD_WITHOUT_TOKEN: '/auth/reset-password',
    RELATED_USERS: '/users/related',
    REGISTER_RELATED_USER: '/auth/register/related',
    DELETE_RELETED_USER: ({ id }: { id: string }) => `users/${id}/related`,
    SUBSCRIPTION_DETAILS: `/subscriptions/details`,
    PARTNERS: '/users/partners',
    BUNDLES: '/subscriptions/bundles/',
    SINGLE_BUNDLE: ({ id }: { id: number | string }) => `/subscriptions/bundles/${id}`,
    LIMITS_DICTIONARY: '/subscriptions/bundles/limits',
    REGISTER_PARTNER: '/auth/register/partner',
    SINGLE_USER: ({ id }: { id: string | number }) => `/users/${id}`,
    CREATE_SUBSCRIPTION: '/subscriptions/',
    VERIFY_PARTNER: ({ id }: { id: number }) => `/auth/verify-partner/${id}`,
    ASIGN_TEMPLATES_TO_PARTNER: ({ partnerId }: { partnerId: number }) => `/users/partners/${partnerId}/templates`,
    UPDATE_SUBSCRIPTION: ({ subscriptionId }: { subscriptionId: number }) => `/subscriptions/${subscriptionId}`,
    ALL_GAMERS: '/users/gamers',
    ADD_USER_AVATAR: `/users/avatar`,
    SPECTATOR: {
      SINGLE_GAME: ({ gameId }: { gameId: string }) => `/games/${gameId}/spectacor`,
      GAME_USERS: ({ gameId }: { gameId: string }) => `/games/${gameId}/spectacor/users`,
      GAME_QUESTIONS: ({ gameId }: { gameId: string }) => `/games/${gameId}/spectacor/questions`,
    },
    GOOGLE_AUTOCOMPLETE: (value: string) => `/places/autocomplete/${value}`,
    GOOGLE_PLACE_DETAILS: (id: string) => `/places/${id}`,
  },
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TypeToConfirmModal from '../../../components/common/modals/TypeToConfirmModal';
import TableSelectionPanel, {
  TableSelectionPanelProps,
} from '../../../components/dataTable/components/TableSelectionPanel';
import TrashIcon from '../../../components/icons/TrashIcon';
import { colors } from '../../../styles/variables';
import { CallbackDefault } from '../../../types/Types';

interface ActiveGamesTableSelectionPanelProps extends Omit<TableSelectionPanelProps, 'children'> {
  onDelete: CallbackDefault;
  isLoadingDelete?: boolean;
}

const ActiveGamesTableSelectionPanel = (props: ActiveGamesTableSelectionPanelProps) => {
  const { onDelete, isLoadingDelete } = props;

  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteGame = () => {
    onDelete();
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <TableSelectionPanel {...props}>
        <button
          onClick={() => setIsDeleteModalOpen(true)}
          className="text-red700 font-semibold text-sm flex items-center gap-1.5"
        >
          <TrashIcon color={colors.red700} width={16} />
          {t('activeGames.table.selectionPanel.delete')}
        </button>
      </TableSelectionPanel>

      <TypeToConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        textToType={t('activeGames.table.delete.textToType')}
        title={t('activeGames.table.selectionPanel.deleteModal.title')}
        confirmBtnText={t('activeGames.table.selectionPanel.deleteModal.confirmBtnText')}
        confirmBtnAction={handleDeleteGame}
        cancelBtnText={t('activeGames.table.selectionPanel.deleteModal.cancelBtnText')}
        isConfirmBtnDisabled={isLoadingDelete}
        isConfirmBtnLoading={isLoadingDelete}
      />
    </>
  );
};

export default ActiveGamesTableSelectionPanel;

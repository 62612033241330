import { useState } from 'react';

export function useStreetViewVisibility(mapRef: React.RefObject<google.maps.Map | null>) {
  const [isStreetViewVisible, setIsStreetViewVisible] = useState(false);

  const handleMapVisibleChanged = () => {
    if (!mapRef.current) {
      return;
    }

    const visible = mapRef.current.getStreetView()?.getVisible();
    setIsStreetViewVisible(visible);
  };

  return { isStreetViewVisible, handleMapVisibleChanged };
}

import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LightTooltip } from '../../../components/common/tooltips/LightTooltip';
import HoverableTooltip from '../../../components/dataTable/components/HoverableTooltip';
import TableCell from '../../../components/dataTable/components/TableCell';
import ArrowRight from '../../../components/icons/ArrowRight';
import useFindTranslation from '../../../hooks/useFindTranslation';
import { routes } from '../../../static/routes';
import { colors } from '../../../styles/variables';
import ActiveGameDeleteBtn from '../components/ActiveGameDeleteBtn';
import GameStatusIcon from '../components/GameStatusIcon';
import GameStatusText from '../components/GameStatusText';
import { GameTypes } from '../../../types/GlobalTypes';

const useActiveGamesTableHeaders = ({ showAuthorColumn }: { showAuthorColumn: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { findTranslation } = useFindTranslation();

  const headers = [
    {
      field: 'name',
      headerName: t('activeGames.table.headers.name'),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <TableCell>{!!params.row.templateName ? findTranslation(params.row.templateName) : ''}</TableCell>;
      },
    },

    ...(showAuthorColumn
      ? [
          {
            field: 'author',
            headerName: t('activeGames.table.headers.author'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
              return <TableCell className="break-all pr-4">{params.row.ownerEmail}</TableCell>;
            },
          },
        ]
      : []),

    {
      field: 'city',
      headerName: t('activeGames.table.headers.city'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <TableCell>{params.row.templateCity ?? ''}</TableCell>;
      },
    },
    {
      field: 'code',
      headerName: t('activeGames.table.headers.code'),
      minWidth: 90,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <TableCell>{params.row.code}</TableCell>;
      },
    },
    {
      field: 'status',
      headerName: t('activeGames.table.headers.status'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const startDate: Date = params.row.startDate;

        return (
          <LightTooltip
            title={
              <p>{`${startDate.toLocaleDateString()}, ${t('common.hourShort')} ${startDate.toLocaleTimeString()}`}</p>
            }
            placement="top"
            arrow
          >
            <div className="flex items-center gap-1.5 cursor-pointer">
              <GameStatusIcon status={params.row.status} />
              <GameStatusText status={params.row.status} />
            </div>
          </LightTooltip>
        );
      },
    },
    {
      field: 'buttons',
      headerName: '',
      minWidth: 100,
      maxWidth: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex justify-end">
            <ActiveGameDeleteBtn gameId={params.row.id} />

            <HoverableTooltip tooltipContent={<p>{t('activeGames.table.showDetails')}</p>}>
              {isHovered => (
                <button
                  className="p-3"
                  onClick={e => {
                    e.stopPropagation();
                    const navigateTo =
                      params.row.template.gameType === GameTypes.BUSINESS
                        ? routes.singleActiveGame.info(params.row.id)
                        : routes.singleActiveIndividualGames.info(params.row.id);
                    navigate(navigateTo);
                  }}
                >
                  <ArrowRight color={isHovered ? colors.red500 : colors.grey700} />
                </button>
              )}
            </HoverableTooltip>
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useActiveGamesTableHeaders;

import { NavLink } from 'react-router-dom';
import { TabSwitcherRoute } from './RouteTabSwitcher';

interface RouteTabSwitcherButtonProps {
  route: TabSwitcherRoute;
}

const RouteTabSwitcherButton = ({ route }: RouteTabSwitcherButtonProps) => {
  return (
    <NavLink className="flex-1" to={route.routeTo} key={route.text}>
      {({ isActive }) => (
        <button
          className={`whitespace-nowrap uppercase relative flex-1 text-center font-medium text-sm p-4 cursor-pointer w-full rounded-none flex items-center justify-center gap-1.5 ${
            isActive ? 'text-red500 bg-transparent' : 'text-grey700 '
          }`}
        >
          {isActive && (
            <div className="absolute hidden lg:block w-full h-[3px] bg-red500 bottom-0 left-0 rounded-tl-[100px] rounded-tr-[100px]" />
          )}
          {route.text}
          {route.suffixElement}
        </button>
      )}
    </NavLink>
  );
};

export default RouteTabSwitcherButton;

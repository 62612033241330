import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../../static/routes';
import DashboardIcon from '../../icons/DashboardIcon';

interface ModifiedBreadcrumb {
  path: string;
  text: string;
}

interface ExtraBreadcrumb {
  routeTo?: string;
  text: string;
}

export interface BreadcrumbsProps {
  limit?: number;
  modifiedBreadcrumbs?: ModifiedBreadcrumb[];
  extraBreadcrumbs?: ExtraBreadcrumb[];
}

const Breadcrumbs = ({ limit, modifiedBreadcrumbs, extraBreadcrumbs }: BreadcrumbsProps) => {
  const location = useLocation();
  const { t } = useTranslation();

  const pathSegments = location.pathname.split('/').filter(segment => segment !== '');

  const breadcrumbs = pathSegments.map((segment, index) => {
    const path = `/${pathSegments.slice(0, index + 1).join('/')}`;

    const modifiedBreadcrumb = modifiedBreadcrumbs?.find(bc => segment === bc.path);

    const breadcrumbText = modifiedBreadcrumb?.text || t(`navigation.${segment}`);

    return (
      <div key={index} className="flex items-center ml-3">
        <ChevronRightIcon height={16} />
        <Link to={path} className="ml-3 text-sm">
          {breadcrumbText}
        </Link>
      </div>
    );
  });

  const displayedBreadcrumbs = limit ? breadcrumbs.slice(0, limit - 1) : breadcrumbs;

  const displayedExtraBreadcrumbs = extraBreadcrumbs?.map((bc, index) => {
    const isLink = !!bc.routeTo;

    return (
      <div key={index} className="flex items-center ml-3">
        <ChevronRightIcon height={16} />

        {isLink ? (
          <Link to={bc.routeTo!} className="ml-3 text-sm">
            {bc.text}
          </Link>
        ) : (
          <p className="ml-3 text-sm">{bc.text}</p>
        )}
      </div>
    );
  });

  return (
    <div className="flex">
      <Link to={routes.home}>
        <div className="flex gap-1.5 items-center">
          <DashboardIcon width={16} height={16} />
          <p className="text-sm">{t('navigation.dashboard')}</p>
        </div>
      </Link>
      {displayedBreadcrumbs}
      {displayedExtraBreadcrumbs}
    </div>
  );
};

export default Breadcrumbs;

import { type Dispatch, type SetStateAction, useEffect, useRef, useState } from 'react';
import type { IQuestion } from '../../../../../types/ApiTypes';
import type { Coordinates } from '../../../../../types/GlobalTypes';
import type { CallbackDefault } from '../../../../../types/Types';
import Map from '../../../../map/Map';
import MapDrawingManager, { type MapSelectableItem } from '../../../../map/components/MapDrawingManager';
import MapFullScreenButton from '../../../../map/components/MapFullScreenButton';
import MapQuestionsLegend from '../../../../mapQuestionsLegend/MapQuestionsLegend';
import TemplateFormMapQuestionMarker from './components/TemplateFormMapQuestionMarker';
import TemplateFormMapSelectionPanel from './components/TemplateFormMapSelectionPanel';

interface TemplateFormMapProps {
  mapCenter?: Coordinates;
  isMapExpanded: boolean;
  setIsMapExpanded: Dispatch<SetStateAction<boolean>>;
  allPossibleQuestions: IQuestion[];
  preSelectedQuestions: IQuestion[];
  setPreSelectedQuestions: Dispatch<SetStateAction<IQuestion[]>>;
  selectedQuestions: IQuestion[];
  onAddToGame: CallbackDefault;
  addToPreSelectedQuestions: (question: IQuestion) => void;
  removeFromPreSelectedQuestions: (question: IQuestion) => void;
  removeFromSelectedQuestions: (questions: IQuestion[]) => void;
  updateQuestionsCoordinates: ({
    newCoordinates,
    questionId,
  }: {
    newCoordinates: Coordinates;
    questionId: number;
  }) => void;
  onMapLoad?: (map: google.maps.Map) => void;
}

const TemplateFormMap = ({
  mapCenter,
  isMapExpanded,
  setIsMapExpanded,
  allPossibleQuestions,
  preSelectedQuestions,
  setPreSelectedQuestions,
  selectedQuestions,
  onAddToGame,
  addToPreSelectedQuestions,
  removeFromPreSelectedQuestions,
  removeFromSelectedQuestions,
  updateQuestionsCoordinates,
  onMapLoad,
}: TemplateFormMapProps) => {
  const [mapHeight, setMapHeight] = useState(438);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const [isMapDragDisabled, setIsMapDragDisabled] = useState(false);

  useEffect(() => {
    if (mapContainerRef.current) {
      const height = mapContainerRef.current.clientHeight;

      setMapHeight(height);
    }
  }, []);

  const mapStyles = {
    width: '100%',
    height: isMapExpanded ? 'calc(660px * 1.5)' : `${mapHeight - 28}px`,
  };

  const handleSelectItemsFromMap = (mapItems: MapSelectableItem[]) => {
    const questionsFromMap = allPossibleQuestions.filter(question =>
      mapItems.some(selectedItem => selectedItem.id === question.id),
    );

    const questionsWithoutSelected = questionsFromMap.filter(
      item => !selectedQuestions.some(questionToCompare => questionToCompare.id === item.id),
    );

    setPreSelectedQuestions(questionsWithoutSelected);
  };

  const filteredAllQuestion = allPossibleQuestions.filter(
    question => !selectedQuestions.some(q => q.id === question.id),
  );
  const questionsToShow = [...filteredAllQuestion, ...selectedQuestions];

  return (
    <div ref={mapContainerRef} className="w-full">
      <Map
        onMapLoad={onMapLoad}
        center={mapCenter}
        initialZoom={!!mapCenter ? undefined : 6}
        containerStyle={mapStyles}
        hideZoomButtons={!isMapExpanded}
        options={{ gestureHandling: isMapDragDisabled ? 'none' : undefined }}
      >
        <MapDrawingManager allItems={allPossibleQuestions} onSelectItems={handleSelectItemsFromMap} />

        <div className="z-10 absolute bottom-3 left-2 flex flex-col gap-1.5">
          <MapQuestionsLegend />
        </div>
        <div className={`z-10 absolute right-2 flex flex-col gap-1.5 ${isMapExpanded ? 'top-3' : 'bottom-3'}`}>
          <MapFullScreenButton isFullScreen={isMapExpanded} onClick={() => setIsMapExpanded(prev => !prev)} />
        </div>
        {questionsToShow?.map(question => {
          const isPreSelected = preSelectedQuestions.some(
            preSelectedQuestion => preSelectedQuestion.id === question.id,
          );
          const isSelected = selectedQuestions.some(selectedQuestion => selectedQuestion.id === question.id);

          return (
            <TemplateFormMapQuestionMarker
              key={question.id}
              question={question}
              mapHeight={mapHeight}
              isPreSelected={isPreSelected}
              isSelected={isSelected}
              onRemoveFromPreSelectedQuestions={() => removeFromPreSelectedQuestions(question)}
              onAddToPreSelectedQuestions={() => addToPreSelectedQuestions(question)}
              onRemoveFromSelectedQuestions={() => removeFromSelectedQuestions([question])}
              setIsMapDragDisabled={setIsMapDragDisabled}
              onUpdateQuestionCoordinates={newCoordinates => {
                updateQuestionsCoordinates({
                  newCoordinates,
                  questionId: question.id,
                });
              }}
            />
          );
        })}

        {preSelectedQuestions?.length > 0 && (
          <TemplateFormMapSelectionPanel selectedItemsNumber={preSelectedQuestions.length} onAddToGame={onAddToGame} />
        )}
      </Map>
    </div>
  );
};

export default TemplateFormMap;

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomToastContainer = () => {
  const contextClass = {
    success: 'bg-successBg text-success',
    error: 'bg-errorBg text-error',
    info: 'bg-gray-600',
    warning: 'bg-orange-400',
    default: 'bg-indigo-600',
    dark: 'bg-white-600 font-gray-300',
  };

  return (
    <ToastContainer
      position="bottom-center"
      hideProgressBar
      closeButton={false}
      autoClose={3000}
      toastClassName={typeOptions =>
        contextClass[typeOptions?.type || 'default'] +
        ' relative flex px-3 py-4 mt-2 min-h-10 rounded-[.625rem] justify-between overflow-hidden cursor-pointer shadow-toast w-fit mx-auto text-sm font-medium mb-6'
      }
    />
  );
};

export default CustomToastContainer;

import { toString } from 'lodash';
import useAllBundles from '../../../../hooks/api/bundle/useAllBundles';

const useBundlesDropdownOptions = () => {
  const { bundles } = useAllBundles();
  const bundleDropdownOptions = bundles?.map(bundle => ({ id: toString(bundle.id), value: bundle.bundleName }));

  return { bundleDropdownOptions };
};

export default useBundlesDropdownOptions;

import { useQuery } from 'react-query';

import { api } from '../../../../static/api';
import { ITeamMessage } from '../../../../types/ApiTypes';
import { QueryAliases } from '../../../../types/QueryAliases';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface ApiReceivedMessage {
  createdAt: string;
  game: number;
  id: number;
  message: string;
  receiver: string;
  sender: {
    user: {
      id: number;
      email: string | null;
    };
    teamName: string | null;
  };
  wasRead: boolean;
}

interface useGameReceivedMessagesProps {
  gameId: string;
}

const useGameReceivedMessages = ({ gameId }: useGameReceivedMessagesProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, isLoading, isError } = useQuery(
    [QueryAliases.GAME_RECEIVED_MESSAGES(gameId)],
    () => axiosInstance.get(api.endpoints.GAME_RECEIVED_MESSAGES({ gameId })),
    {
      refetchOnMount: true,
      refetchInterval: 15000,
    },
  );

  const apiMessages: ApiReceivedMessage[] | undefined = data?.data;

  const iMessages = !!apiMessages
    ? apiMessages.map(apiMessage => {
        const iMessage: ITeamMessage = {
          createdAt: new Date(apiMessage.createdAt),
          gameId: apiMessage.game,
          id: apiMessage.id,
          message: apiMessage.message,
          team: {
            id: apiMessage.sender?.user.id,
            email: apiMessage.sender?.user.email,
            name: apiMessage.sender?.teamName,
          },
          isReceived: true,
          wasRead: apiMessage.wasRead,
        };

        return iMessage;
      })
    : undefined;

  return { messages: iMessages, isLoading, isError };
};

export default useGameReceivedMessages;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import EditQuestionModal from '../../../../../components/common/modals/editQuestionModal/EditQuestionModal';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import EditIcon from '../../../../../components/icons/EditIcon';
import { colors } from '../../../../../styles/variables';
import { IQuestion } from '../../../../../types/ApiTypes';
import { QueryAliases } from '../../../../../types/QueryAliases';
import { CallbackDefault } from '../../../../../types/Types';

interface EditQuestionBtnProps {
  question: IQuestion;
  onEditQuestion?: CallbackDefault;
}

const EditQuestionBtn = ({ question }: EditQuestionBtnProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const params = useParams();
  const gameId = params.id as string;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onEditSuccess = () => {
    queryClient.refetchQueries([QueryAliases.GAME_QUESTIONS(gameId)]);
    queryClient.invalidateQueries([QueryAliases.SINGLE_QUESTION(question.id.toString())]);
  };

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('singleActiveGame.questions.table.buttons.edit')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            <EditIcon color={isHovered ? colors.red500 : colors.grey700} width="24" height="24" />
          </button>
        )}
      </HoverableTooltip>

      <EditQuestionModal
        questionId={question.id}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onSucceess={onEditSuccess}
      />
    </>
  );
};

export default EditQuestionBtn;

import { useMemo, useState } from 'react';
import type { IQuestion } from '../../../../../../../types/ApiTypes';
import type { DropdownOption } from '../../../../../../../types/Dropdown.types';
import { useOwnerFilter } from '../../hooks/useOwnerFilter';

function useFilteredQuestions(selectedQuestions: IQuestion[]) {
  const [searchValue, setSearchValue] = useState('');
  const [questionTypeFilter, setQuestionTypeFilter] = useState('');
  const [tagFilters, setTagFilters] = useState<string[]>([]);
  const [cityFilter, setCityFilter] = useState('');
  const [isArcadeFilter, setIsArcade] = useState('');
  const { ownerFilter, setOwnerFilter, activeOwnerIds } = useOwnerFilter();

  const handleChangeTagFilters = (option: DropdownOption | null) => {
    if (!option?.value) {
      return;
    }

    const isExisting = tagFilters.some(tagItem => tagItem === option.value);

    if (isExisting) {
      const remainingTagString = tagFilters.filter(item => item !== option.value);
      setTagFilters(remainingTagString);
    } else {
      setTagFilters([...tagFilters, option.value]);
    }
  };

  const handleDeleteTagFilter = (tagToRemove: string) => {
    const remainingTagString = tagFilters.filter(item => item !== tagToRemove);
    setTagFilters(remainingTagString);
  };

  const filteredQuestions = useMemo(() => {
    return selectedQuestions.filter(question => {
      const matchesSearch =
        question.name.some(name => name.value.toLowerCase().includes(searchValue.toLowerCase())) ||
        question?.content?.some(content => content.value.toLowerCase().includes(searchValue.toLowerCase()));

      const matchesTag =
        tagFilters.length === 0 || tagFilters.some(tag => question.tags.some(qTag => qTag.tagName === tag));

      const matchesQuestionType = !questionTypeFilter || question.questionType.code === questionTypeFilter;

      const matchesCity = !cityFilter || question.city === cityFilter;

      const matchesArcade = !isArcadeFilter || question.isArcade === (isArcadeFilter === 'isArcade');

      const matchOwner = !ownerFilter || question.owner?.email === ownerFilter;

      return matchesSearch && matchesTag && matchesQuestionType && matchesCity && matchesArcade && matchOwner;
    });
  }, [selectedQuestions, searchValue, questionTypeFilter, tagFilters, cityFilter, isArcadeFilter, ownerFilter]);

  const isSomeFilterActive =
    !!searchValue || !!questionTypeFilter || !!tagFilters.length || !!cityFilter || !!isArcadeFilter || !!ownerFilter;

  return {
    ownerFilter,
    setOwnerFilter,
    activeOwnerIds,
    searchValue,
    setSearchValue,
    questionTypeFilter,
    setQuestionTypeFilter,
    tagFilters,
    cityFilter,
    setCityFilter,
    isArcadeFilter,
    setIsArcade,
    handleChangeTagFilters,
    handleDeleteTagFilter,
    filteredQuestions,
    isSomeFilterActive,
  };
}

export default useFilteredQuestions;

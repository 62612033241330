import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/common/buttons/SecondaryButton';
import { LabeledStateDatepicker } from '../../../components/common/datepicker/LabeledStateDatepicker';
import FormSectionTitle from '../../../components/common/formSectionTitle/FormSectionTitle';
import { Modal, ModalProps } from '../../../components/common/modals/Modal';
import { LabeledStateTimepicker } from '../../../components/common/timepicker/LabeledStateTimepicker';
import XMarkIcon from '../../../components/icons/XmarkIcon';
import { editGameDateSchema } from '../../../static/validation/schemas/editGameDateSchema';
import getInputState from '../../../utils/getInputState/getInputState';
import { getInitHour } from '../utils/getInitHour';

interface EditGameDatesModalInputs {
  date: Date;
  startHour: Date;
  endHour: Date;
}

type StartGameModalInputsKeys = keyof EditGameDatesModalInputs;

interface EditGameDatesModalProps extends Omit<ModalProps, 'children'> {
  initStartDate: Date;
  initEndDate: Date;
  onSubmitForm: (data: EditGameDatesModalInputs) => Promise<void>;
  isLoading: boolean;
}

const EditGameDatesModal = ({
  isOpen,
  setIsOpen,
  initStartDate,
  initEndDate,
  onSubmitForm,
  isLoading,
}: EditGameDatesModalProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<EditGameDatesModalInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(editGameDateSchema),
    defaultValues: {
      date: initStartDate,
      startHour: getInitHour(initStartDate),
      endHour: getInitHour(initEndDate),
    },
  });

  const onSubmit: SubmitHandler<EditGameDatesModalInputs> = async data => {
    onSubmitForm(data);
  };

  const getErrorMessage = (field: StartGameModalInputsKeys) => get(errors, field);

  const onCloseModal = () => {
    reset();
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onCloseModal}>
      <div>
        <div className="p-6 relative border-b border-grey300">
          <h2 className="text-center text-[1.375rem] font-medium">{t('singleActiveGame.info.editTimeModal.title')}</h2>
          <div
            onClick={() => {
              onCloseModal();
              setIsOpen(false);
            }}
            className="w-8 h-8 min-w-8 absolute top-6 right-6 lg:left-6 flex justify-center items-center cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6">
          <FormSectionTitle className="mb-6" title={t('singleActiveGame.info.editTimeModal.date.title')} />
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <LabeledStateDatepicker
                id="date"
                state={getInputState(getErrorMessage('date'))}
                labeledProps={{
                  label: t('singleActiveGame.info.editTimeModal.date.label'),
                  wrapperClassName: 'w-full max-w-xs',
                  errorMessage: t(`errorMessages.${getErrorMessage('date')?.message}`),
                }}
                datepickerProps={{
                  date: field.value,
                  setDate: field.onChange,
                  minDate: new Date(),
                }}
              />
            )}
          />

          <FormSectionTitle className="mb-6 mt-9" title={t('singleActiveGame.info.editTimeModal.hour.title')} />

          <div className="flex gap-8">
            <Controller
              name="startHour"
              control={control}
              render={({ field }) => (
                <LabeledStateTimepicker
                  id="start-hour"
                  state={getInputState(getErrorMessage('startHour'))}
                  labeledProps={{
                    label: t('singleActiveGame.info.editTimeModal.hour.startHourLabel'),
                    wrapperClassName: 'w-full max-w-xs',
                    errorMessage: t(`errorMessages.${getErrorMessage('startHour')?.message}`),
                  }}
                  timePickerProps={{
                    date: field.value,
                    setDate: field.onChange,
                  }}
                />
              )}
            />
            <Controller
              name="endHour"
              control={control}
              render={({ field }) => (
                <LabeledStateTimepicker
                  id="start-hour"
                  state={getInputState(getErrorMessage('endHour'))}
                  labeledProps={{
                    label: t('singleActiveGame.info.editTimeModal.hour.endHourLabel'),
                    wrapperClassName: 'w-full max-w-xs',
                    errorMessage: t(`errorMessages.${getErrorMessage('endHour')?.message}`),
                  }}
                  timePickerProps={{
                    date: field.value,
                    setDate: field.onChange,
                  }}
                />
              )}
            />
          </div>
          <div className="flex justify-end mt-12 gap-3">
            <SecondaryButton
              type="button"
              onClick={() => {
                onCloseModal();
                setIsOpen(false);
              }}
              className="w-fit"
            >
              {t('singleActiveGame.info.editTimeModal.cancelBtn')}
            </SecondaryButton>
            <PrimaryButton type="submit" className="w-fit" disabled={isLoading} loading={isLoading}>
              {t('singleActiveGame.info.editTimeModal.submitBtn')}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditGameDatesModal;

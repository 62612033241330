import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../../../../../../../components/dataTable/MaterialTable/MaterialTable';
import TableCell from '../../../../../../../components/dataTable/components/TableCell';
import { IGameAnswer } from '../../../../../../../types/ApiTypes';
import { QuestionTypes } from '../../../../../../../types/GlobalTypes';
import { isQuestionTextAnswer } from '../../../../../../../utils/questions/isQuestionTextAnswer/isQuestionTextAnswer';
import { IAnswersGroup } from '../../../SingleActiveGameAnswersView';
import AnswerQuestionLabel from '../../answerQuestionLabel/AnswerQuestionLabel';
import ImageAnswer from '../../imageAnswer/ImageAnswer';
import VideoAnswer from '../../videoAnswer/VideoAnswer';
import SingleReviewAnswerActionButtons from './components/SingleReviewAnswerActionButtons';

interface QuestionReviewAnswersGroupProps {
  answersGroup: IAnswersGroup;
}

const QuestionReviewAnswersGroup = ({ answersGroup }: QuestionReviewAnswersGroupProps) => {
  const { t } = useTranslation();

  return (
    <div className="mb-10">
      <AnswerQuestionLabel question={answersGroup.question} />

      <MaterialTable
        disableRowSelectionOnClick
        columns={[
          {
            field: 'teamName',
            headerName: t('singleActiveGame.answers.table.teamName'),
            minWidth: 120,
            flex: 1,
            sortable: true,
            renderCell: (params: GridRenderCellParams<IGameAnswer>) => {
              return <TableCell>{params.row.userGame.teamName}</TableCell>;
            },
          },
          {
            field: 'answer',
            headerName: t('singleActiveGame.answers.table.answer'),
            minWidth: 120,
            flex: 1,
            sortable: true,
            renderCell: (params: GridRenderCellParams<IGameAnswer>) => {
              const questionType = params.row.question.questionType.code;

              if (isQuestionTextAnswer({ questionType })) {
                return <TableCell>{params.row.answerValue}</TableCell>;
              } else if (questionType === QuestionTypes.IMAGE) {
                return (
                  <ImageAnswer
                    src={params.row.answerValue}
                    fullscreenTitle={t('singleActiveGame.answers.imageFulscreenTitle')}
                  />
                );
              } else if (questionType === QuestionTypes.VIDEO) {
                return <VideoAnswer src={params.row.answerValue} />;
              }

              return <></>;
            },
          },
          {
            field: 'buttons',
            headerName: '',
            minWidth: 120,
            maxWidth: 250,
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams<IGameAnswer>) => {
              return <SingleReviewAnswerActionButtons answerId={params.row.id} />;
            },
          },
        ]}
        rows={answersGroup.answers}
      />
    </div>
  );
};

export default QuestionReviewAnswersGroup;

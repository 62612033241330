const VideoAnswer = ({ src }: { src: string }) => {
  return (
    <div className="border border-grey300 rounded-md w-40 h-28">
      <video className="object-contain w-full h-full rounded-md" controls={true}>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoAnswer;

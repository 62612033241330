import GameFinishedIcon from '../../../components/icons/games/GameFinishedIcon';
import GameOngoingIcon from '../../../components/icons/games/GameOngoingIcon';
import GameScheduledIcon from '../../../components/icons/games/GameScheduledIcon';
import { gameStatusesData } from '../../../static/games/gameStatusesData';
import { GameStatus, GameStatuses } from '../../../types/GlobalTypes';

interface GameStatusIconProps {
  status: GameStatus;
}

const GameStatusIcon = ({ status }: GameStatusIconProps) => {
  const iconsData = {
    [GameStatuses.ONGOING]: <GameOngoingIcon color={gameStatusesData[GameStatuses.ONGOING]?.color} />,
    [GameStatuses.FINISHIED]: <GameFinishedIcon color={gameStatusesData[GameStatuses.FINISHIED]?.color} />,
    [GameStatuses.SCHEDULED]: <GameScheduledIcon color={gameStatusesData[GameStatuses.SCHEDULED]?.color} />,
  };

  return <>{iconsData[status]}</>;
};

export default GameStatusIcon;

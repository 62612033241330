import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiRelatedUser, IRelatedUser } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { formatDateString } from '../../../utils/dates/formatDate/formatDateString';
import { findLocaleByLanguage } from '../../../utils/findLocaleByLanguage/findLocaleByLanguage';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useRelatedUsers = () => {
  const axiosInstance = useAxiosInstance();
  const {
    i18n: { language },
  } = useTranslation();

  const locale = findLocaleByLanguage(language);

  const { data, isSuccess, isLoading } = useQuery([QueryAliases.RELATED_USERS], () =>
    axiosInstance.get(api.endpoints.RELATED_USERS),
  );

  const relatedUsers: IRelatedUser[] =
    data?.data.map((user: ApiRelatedUser) => ({
      id: user.id,
      login: user.email,
      createdAt: {
        date: formatDateString.toOnlyDate(user.createdAt, locale),
        time: formatDateString.toOnlyTime(user.createdAt, locale),
      },
    })) ?? [];

  return { relatedUsers, isLoading, isSuccess };
};

export default useRelatedUsers;

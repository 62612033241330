import { useTranslation } from 'react-i18next';
import { colors } from '../../../../../../styles/variables';
import { CallbackDefault } from '../../../../../../types/Types';
import SelectionPanelCard from '../../../../../cards/selectionPanelCard/SelectionPanelCard';
import PlusIcon from '../../../../../icons/PlusIcon';

interface TemplateFormMapSelectionPanelProps {
  selectedItemsNumber: number;
  onAddToGame: CallbackDefault;
}

const TemplateFormMapSelectionPanel = ({ selectedItemsNumber, onAddToGame }: TemplateFormMapSelectionPanelProps) => {
  const { t } = useTranslation();

  return (
    <SelectionPanelCard className="z-10 absolute bottom-3 left-1/2 -translate-x-1/2">
      <div className="pr-6 border-r border-grey400">
        <p className="text-black400 font-medium text-sm whitespace-nowrap">
          <span className="text-black700 font-bold">{`(${selectedItemsNumber}) `}</span>
          {t('templateForm.map.selectedItems')}
        </p>
      </div>

      <button
        onClick={onAddToGame}
        className="text-red700 font-semibold text-sm flex items-center gap-1.5 ml-10 whitespace-nowrap"
      >
        <PlusIcon color={colors.red700} />
        {t('templateForm.map.addToTemplateBtn')}
      </button>
    </SelectionPanelCard>
  );
};

export default TemplateFormMapSelectionPanel;

import { useEffect, useRef, useState } from 'react';

import { OverlayView, OverlayViewF } from '@react-google-maps/api';

import useQuestionPointsOptions from '../../../../hooks/api/questions/useQuestionPointsOptions';
import QuestionSnippet from '../../../../pages/questions/components/QuestionsViewMap/components/QuestionSnippet';
import { elementsIds } from '../../../../static/elementsIds';
import { colors } from '../../../../styles/variables';
import { mapColorFromPoints } from '../../../../utils/colors/mapColorFromPoints/mapColorFromPoints';
import XMarkIcon from '../../../icons/XmarkIcon';
import MapMarkerQuestionTypeWrapper from '../MapMarkerQuestionTypeWrapper';
import { LATITUDE_OFFSET, Z_INDEX_SCALE } from './static/consts';

import type { IQuestion } from '../../../../types/ApiTypes';
interface MapMarkerQuesionTypeProps {
  children?: ({ isExpanded }: { isExpanded: boolean }) => React.ReactNode;
  question: IQuestion;
  mapHeight: number;
  isPreSelected?: boolean;
  isSelected?: boolean;
  onExpandChange?: (isExpanded: boolean) => void;
  onQuestionEditSuccess?: (id: number) => void;
  disableQuestionEdit?: boolean;
}

const MapMarkerQuestionType = ({
  children,
  question,
  mapHeight,
  isPreSelected,
  isSelected,
  onExpandChange,
  onQuestionEditSuccess,
  disableQuestionEdit,
}: MapMarkerQuesionTypeProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const markerRef = useRef(null);

  const { pointsOptions, pointsFallbackColor } = useQuestionPointsOptions();

  useEffect(() => {
    if (!!onExpandChange) {
      onExpandChange(isExpanded);
    }
  }, [isExpanded, onExpandChange]);

  if (!question.coordinates) {
    return <></>;
  }

  const map = document.getElementById(elementsIds.map);

  const zIndex = Math.round((LATITUDE_OFFSET - question.coordinates.lat) * Z_INDEX_SCALE);

  //TODO: refactor welcome. isExpanded, isPreSelected, isSelected - a lot of things going on
  return (
    <OverlayViewF position={question.coordinates} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      {isExpanded && (
        <div
          className="bg-grey500 bg-opacity-10 z-10"
          style={{
            position: 'fixed',
            width: map?.offsetWidth,
            height: map?.offsetHeight,
            top: `-${mapHeight / 2}px`,
            left: '-50%',
          }}
        />
      )}
      <div
        ref={markerRef}
        className={`relative bottom-[47px] -left-1/2  ${isExpanded ? 'z-20' : ''} ${
          (isPreSelected || isSelected) &&
          'bottom-[57px] px-4 pb-4 pt-2.5 rounded-[.25rem] outline outline-2 outline-red500 z-30'
        }`}
        style={{ zIndex }}
      >
        <MapMarkerQuestionTypeWrapper color={mapColorFromPoints(question, pointsOptions, pointsFallbackColor)}>
          {isExpanded && (
            <div className="absolute bottom-full -translate-y-2">
              <QuestionSnippet
                color={mapColorFromPoints(question, pointsOptions, pointsFallbackColor)}
                question={question}
                onQuestionEditSuccess={onQuestionEditSuccess}
                disableQuestionEdit={disableQuestionEdit}
              />
              <button
                onClick={() => setIsExpanded(false)}
                className="flex justify-center items-center bg-grey100 rounded-full border border-grey300 w-5 h-5 absolute -top-1 -right-1"
              >
                <XMarkIcon size="8" color={colors.black700} />
              </button>
            </div>
          )}

          <div onClick={() => setIsExpanded(true)}>
            <img
              src={question.icon ?? question.questionType.icon}
              alt={question.questionType.code}
              className="!max-w-[1.5rem] max-h-6"
            />
          </div>
        </MapMarkerQuestionTypeWrapper>

        {!!children && children({ isExpanded })}
      </div>
    </OverlayViewF>
  );
};

export default MapMarkerQuestionType;

import { useGoogleMap } from '@react-google-maps/api';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import DragIcon from '../../../../../../../components/icons/DragIcon';
import MapMarkerQuestionType from '../../../../../../../components/map/components/MapMarkerQuestionType';
import { IQuestion } from '../../../../../../../types/ApiTypes';
import { Coordinates } from '../../../../../../../types/GlobalTypes';
import { QueryAliases } from '../../../../../../../types/QueryAliases';
import ActiveGameQuestionsSelectionPanel from './ActiveGameQuestionsSelectionPanel';

interface ActiveGameQuestionMarkerProps {
  gameId: string;
  question: IQuestion;
  isMapDragDisabled: boolean;
  setIsMapDragDisabled: Dispatch<SetStateAction<boolean>>;
  onApplyNewCoordinates: (question: IQuestion) => void;
  mapHeight: number;
}

const ActiveGameQuestionMarker = ({
  gameId,
  question,
  setIsMapDragDisabled,
  onApplyNewCoordinates,
  mapHeight,
}: ActiveGameQuestionMarkerProps) => {
  const queryClient = useQueryClient();

  const map = useGoogleMap();

  const [isExpanded, setIsExpanded] = useState(false);
  const [tempQuestion, setTempQuestion] = useState(question);
  const [canDragQuestion, setCanDragQuestion] = useState(false);

  useEffect(() => {
    setIsMapDragDisabled(canDragQuestion);
  }, [canDragQuestion, setIsMapDragDisabled]);

  const handleMouseMove = useCallback(
    (e: google.maps.MapMouseEvent, questionId: string) => {
      const latLng = e.latLng;

      if (!latLng || question.id.toString() !== questionId) {
        return;
      }

      const newCoordinates: Coordinates = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      };

      setTempQuestion(prev => ({
        ...prev,
        coordinates: newCoordinates,
      }));
    },
    [question.id],
  );

  const handleMouseDown = useCallback(
    (e: google.maps.MapMouseEvent) => {
      const domEvent = e.domEvent as MouseEvent;
      const targetElement = domEvent.target as HTMLElement;

      const closestWithQuestionId = targetElement.closest('[data-question-id]');
      const questionId = closestWithQuestionId?.getAttribute('data-question-id');

      let mouseMoveListener: google.maps.MapsEventListener | null = null;
      let mouseUpListener: google.maps.MapsEventListener | null = null;

      const handleMouseUp = () => {
        if (mouseMoveListener) {
          mouseMoveListener.remove();
        }
        if (mouseUpListener) {
          mouseUpListener.remove();
        }
      };

      if (map && !!questionId) {
        mouseMoveListener = map.addListener('mousemove', (e: google.maps.MapMouseEvent) =>
          handleMouseMove(e, questionId),
        );
        mouseUpListener = map.addListener('mouseup', handleMouseUp);
      }
    },
    [map, handleMouseMove],
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    const mouseDownListener = map.addListener('mousedown', handleMouseDown);

    return () => mouseDownListener.remove();
  }, [map, handleMouseDown]);

  const handleCancelChanges = () => {
    setCanDragQuestion(false);
    setTempQuestion(question);
  };

  const handleApplyChanges = () => {
    setCanDragQuestion(false);
    onApplyNewCoordinates(tempQuestion);
  };

  const handleQuestionEditSuccess = () => {
    queryClient.refetchQueries([QueryAliases.GAME_QUESTIONS(gameId)]);
    queryClient.invalidateQueries([QueryAliases.SINGLE_QUESTION(question.id.toString())]);
  };

  const handleMarkerExpandChange = (isExpanded: boolean) => {
    setIsExpanded(isExpanded);

    if (!isExpanded) {
      handleCancelChanges();
    }
  };

  return (
    <>
      <MapMarkerQuestionType
        key={tempQuestion.id}
        question={tempQuestion}
        mapHeight={mapHeight}
        onExpandChange={handleMarkerExpandChange}
        onQuestionEditSuccess={handleQuestionEditSuccess}
      >
        {() => (
          <>
            {canDragQuestion && (
              <div
                className="flex justify-center items-center bg-grey100 rounded-full border border-grey300 w-6 h-6 absolute -bottom-2 -right-1"
                data-question-id={question.id}
              >
                <DragIcon />
              </div>
            )}
          </>
        )}
      </MapMarkerQuestionType>

      {isExpanded && (
        <ActiveGameQuestionsSelectionPanel
          question={question}
          canDragQuestion={canDragQuestion}
          setCanDragQuestion={setCanDragQuestion}
          currentPosition={tempQuestion.coordinates as Coordinates}
          onCancel={handleCancelChanges}
          onApply={handleApplyChanges}
        />
      )}
    </>
  );
};

export default ActiveGameQuestionMarker;

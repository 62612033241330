import { colors } from '../../styles/variables';

interface MinusIconProps {
  color?: string;
  size?: number;
}

const MinusIcon = ({ color, size }: MinusIconProps) => {
  return (
    <div className="flex justify-center items-center">
      <svg
        width={size ?? '24'}
        height={size ?? '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 12H19" stroke={color ?? colors.black700} strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default MinusIcon;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { api } from '../../../static/api';
import { ApiAddQuestionRequest } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useUpdateQuestion = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    ({ id, data }: { id: string; data: ApiAddQuestionRequest }) =>
      axiosInstance.patch(api.endpoints.SINGLE_QUESTION({ id }), { ...data }),

    {
      async onSuccess() {
        await queryClient.refetchQueries([QueryAliases.QUESTIONS]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.editQuestionFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { updateQuestion: mutateAsync, isLoading };
};

export default useUpdateQuestion;

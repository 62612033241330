import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HoverableTooltip from '../../../components/dataTable/components/HoverableTooltip';
import TableCell from '../../../components/dataTable/components/TableCell';
import ArrowRight from '../../../components/icons/ArrowRight';
import ReportIcon from '../../../components/icons/ReportIcon';
import useFindTranslation from '../../../hooks/useFindTranslation';
import { routes } from '../../../static/routes';
import { colors } from '../../../styles/variables';
import { IGame } from '../../../types/ApiTypes';
import { formatHour } from '../../../utils/dates/formatHour/formatHour';
import HistoryGameDeleteBtn from '../components/HistoryGameDeleteBtn';

const useHistoryGamesTableHeaders = ({ onGetReportClick }: { onGetReportClick: (gameId: number) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { findTranslation } = useFindTranslation();

  const headers = [
    {
      field: 'name',
      headerName: t('historyGames.table.headers.name'),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGame>) => {
        return <TableCell>{findTranslation(params.row.template.name.translations)}</TableCell>;
      },
    },

    {
      field: 'city',
      headerName: t('historyGames.table.headers.city'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IGame>) => {
        return <TableCell>{params.row.template.city}</TableCell>;
      },
    },

    {
      field: 'date',
      headerName: t('historyGames.table.headers.date'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IGame>) => {
        return (
          <div>
            <TableCell className="p-0">{params.row.startDate.toLocaleDateString()}</TableCell>
            <TableCell className="p-0 text-grey700">
              {formatHour(params.row.startDate)}-{formatHour(params.row.endDate)}
            </TableCell>
          </div>
        );
      },
    },

    {
      field: 'numOfQuestions',
      headerName: t('historyGames.table.headers.numOfQuestions'),
      minWidth: 120,
      maxWidth: 160,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IGame>) => {
        return <TableCell>{params.row.template.questionToTemplate?.length}</TableCell>;
      },
    },

    {
      field: 'numOfDevices',
      headerName: t('historyGames.table.headers.numOfDevices'),
      minWidth: 120,
      maxWidth: 160,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IGame>) => {
        return <TableCell>{params.row.numberOfPlayers}</TableCell>;
      },
    },

    {
      field: 'buttons',
      headerName: '',
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGame>) => {
        return (
          <div className="flex justify-end">
            <HoverableTooltip tooltipContent={<p>{t('historyGames.table.downloadReport')}</p>}>
              {isHovered => (
                <button
                  className="p-3"
                  onClick={e => {
                    e.stopPropagation();
                    onGetReportClick(params.row.id);
                  }}
                >
                  <ReportIcon color={isHovered ? colors.red500 : colors.grey700} />
                </button>
              )}
            </HoverableTooltip>

            <HistoryGameDeleteBtn gameId={params.row.id.toString()} />

            <HoverableTooltip tooltipContent={<p>{t('historyGames.table.showDetails')}</p>}>
              {isHovered => (
                <button
                  className="p-3"
                  onClick={e => {
                    e.stopPropagation();
                    navigate(routes.singleActiveGame.info(params.row.id.toString()));
                  }}
                >
                  <ArrowRight color={isHovered ? colors.red500 : colors.grey700} />
                </button>
              )}
            </HoverableTooltip>
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useHistoryGamesTableHeaders;

import useHandleAddTemplatesToPartner from '../../../components/forms/partnerForm/hooks/useHandleAddTemplatesToPartner';
import useToastSuccessAndNavigateOut from '../../../components/forms/partnerForm/hooks/useToastSuccessAndNavigateOut';
import { PartnerFormSubmitProps } from '../../../components/forms/partnerForm/types/partnerFormTypes';
import useHandleEditPartnerBaseData from './useHandleEditPartnerBaseData';
import useHandleSubscription from './useHandleSubscription';

const useHandleEditPartner = () => {
  const { handleEditPartnerBaseData, handleEditPartnerBaseDataIsLoading } = useHandleEditPartnerBaseData();
  const { handleSubscription, handleSubscriptionIsLoading } = useHandleSubscription();
  const { handleAddTemplates, handleAddTemplatesIsLoading } = useHandleAddTemplatesToPartner();
  const { toastSuccessAndNavigateOut } = useToastSuccessAndNavigateOut();

  const isLoading = handleEditPartnerBaseDataIsLoading || handleSubscriptionIsLoading || handleAddTemplatesIsLoading;

  const initEditingPartnerData = async ({
    partner,
    baseDataHasChanged,
    subscriptionHasChanged,
    templatesHasChanged,
  }: PartnerFormSubmitProps) => {
    const partnerId = partner.id as number;
    const partnerVerified = partner.isActive;

    if (baseDataHasChanged) {
      const newPartnerId = await handleEditPartnerBaseData({
        baseData: partner.baseData,
        partnerId: partner.id,
      });

      if (!newPartnerId) {
        return;
      }
    }

    if (!partnerVerified) {
      toastSuccessAndNavigateOut({ messageType: 'editPartnerSuccessNotVerified', partnerId });
      return;
    }

    if (subscriptionHasChanged) {
      const newSubscriptionId = await handleSubscription({ subscription: partner.subscription, partnerId });

      if (!newSubscriptionId) {
        return;
      }
    }

    if (templatesHasChanged) {
      const newTemplatesIds = await handleAddTemplates({ templates: partner.templates, partnerId });

      if (!newTemplatesIds) {
        return;
      }
    }

    toastSuccessAndNavigateOut({ messageType: 'editPartnerSuccess', partnerId });
  };
  return { initEditingPartnerData, editingPartnerInProgress: isLoading };
};

export default useHandleEditPartner;

import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiPartner } from '../../../types/ApiPartnerTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

const usePartnerById = ({ partnerId }: { partnerId: string }) => {
  const axiosInstance = useAxiosInstance();

  const { data, isLoading, refetch } = useQuery([QueryAliases.GET_PARTNER(partnerId)], () =>
    axiosInstance.get(api.endpoints.SINGLE_USER({ id: partnerId })),
  );

  const partner: ApiPartner = data?.data.id == partnerId ? data?.data : undefined;

  return { partner, isLoading, refetch };
};

export default usePartnerById;

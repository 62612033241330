import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import useQuestionsCities from '../../../../../../../hooks/api/questions/useQuestionsCities';
import useTags from '../../../../../../../hooks/api/useTags';
import useQuestionTypeDropdownOptions from '../../../../../../../hooks/useQuestionTypeDropdownOptions';
import { colors } from '../../../../../../../styles/variables';
import type { DropdownOption } from '../../../../../../../types/Dropdown.types';
import FilterDropdown from '../../../../../../common/dropdowns/FilterDropdown/FilterDropdown';
import FilterIcon from '../../../../../../icons/FilterIcon';
import { useOwnerFilter } from '../../hooks/useOwnerFilter';

interface FilterDropdownComponentProps {
  isSomeFilterActive: boolean;
  tagFilters: string[];
  questionTypeFilter: string;
  cityFilter: string;
  isArcadeFilter: string;
  handleChangeTagFilters: (option: DropdownOption | null) => void;
  setQuestionTypeFilter: (value: string) => void;
  setCityFilter: (value: string) => void;
  setIsArcade: (value: string) => void;
  setOwnerFilter: (value: string) => void;
  activeOwnerIds: string[];
}

function FilterDropdownComponent({
  setOwnerFilter,
  activeOwnerIds,
  isSomeFilterActive,
  tagFilters,
  questionTypeFilter,
  cityFilter,
  isArcadeFilter,
  handleChangeTagFilters,
  setQuestionTypeFilter,
  setCityFilter,
  setIsArcade,
}: FilterDropdownComponentProps) {
  const { t } = useTranslation();
  const { tags } = useTags({ searchValue: '', queryMinLength: 0 });
  const { questionTypeDropdownOptions } = useQuestionTypeDropdownOptions();
  const { questionsCities } = useQuestionsCities();
  const { ownersOptions } = useOwnerFilter();

  return (
    <FilterDropdown
      renderButton={() => <FilterIcon color={isSomeFilterActive ? colors.red500 : colors.grey700} />}
      className="border-none"
      optionGroups={[
        {
          id: uuidv4(),
          label: t('questions.table.filters.tag'),
          options:
            tags?.map(tag => ({
              id: tag.tagName,
              value: tag.tagName,
            })) ?? [],
          onSetNewOption: handleChangeTagFilters,
          activeOptionIds: [...tagFilters],
        },
        {
          id: uuidv4(),
          label: t('questions.table.filters.questionType'),
          options: questionTypeDropdownOptions,
          onSetNewOption: option => setQuestionTypeFilter(option?.id ?? ''),
          activeOptionIds: [questionTypeFilter],
        },
        {
          id: uuidv4(),
          label: t('questions.table.filters.city'),
          options:
            questionsCities?.map(city => ({
              id: city,
              value: city,
            })) ?? [],
          onSetNewOption: option => setCityFilter(option?.value ?? ''),
          activeOptionIds: [cityFilter],
        },
        {
          id: uuidv4(),
          label: t('questions.table.filters.localization'),
          options: [{ id: 'isArcade', value: t('questions.table.filters.arcade') }],
          onSetNewOption: option => setIsArcade(option?.id ?? ''),
          activeOptionIds: [isArcadeFilter],
        },
        {
          id: uuidv4(),
          label: t('questions.table.filters.owner'),
          options:
            ownersOptions?.map(owner => ({
              id: owner.id,
              value: owner.email,
            })) ?? [],
          onSetNewOption: (option: DropdownOption | null) => setOwnerFilter(option?.value ?? ''),
          activeOptionIds: activeOwnerIds,
        },
      ]}
    />
  );
}

export default FilterDropdownComponent;

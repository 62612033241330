import { useQuery } from 'react-query';

import { api } from '../../../../static/api';
import { ITeamMessage } from '../../../../types/ApiTypes';
import { QueryAliases } from '../../../../types/QueryAliases';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface ApiSentMessage {
  createdAt: string;
  game: number;
  id: number;
  message: string;
  receiver: {
    user: {
      id: number;
      email: string;
    };
    teamName: string | null;
  };
  sender: string;
  wasRead: boolean;
}

interface useGameSentMessagesProps {
  gameId: string;
}

const useGameSentMessages = ({ gameId }: useGameSentMessagesProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, isLoading, isError } = useQuery(
    [QueryAliases.GAME_SENT_MESSAGES(gameId)],
    () => axiosInstance.get(api.endpoints.GAME_SENT_MESSAGES({ gameId })),
    { refetchOnMount: true },
  );

  const apiMessages: ApiSentMessage[] | undefined = data?.data;

  const iMessages = !!apiMessages
    ? apiMessages.map(apiMessage => {
        const iMessage: ITeamMessage = {
          createdAt: new Date(apiMessage.createdAt),
          gameId: apiMessage.game,
          id: apiMessage.id,
          message: apiMessage.message,
          team: {
            id: apiMessage.receiver?.user.id,
            email: apiMessage.receiver?.user.email,
            name: apiMessage.receiver?.teamName,
          },
          isReceived: false,
          wasRead: apiMessage.wasRead,
        };

        return iMessage;
      })
    : undefined;

  return { messages: iMessages, isLoading, isError };
};

export default useGameSentMessages;

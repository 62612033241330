import { Dispatch, SetStateAction } from 'react';
import { useQueryClient } from 'react-query';
import useUpdateTemplateAvailableQuestions from '../../../../../../hooks/api/templates/useUpdateTemplateAvailableQuestions';
import { IQuestion, ITemplateDetails } from '../../../../../../types/ApiTypes';
import { QueryAliases } from '../../../../../../types/QueryAliases';
import ActiveGameQuestionMarker from './components/ActiveGameQuestionMarker';

interface ActiveGameQuestionsProps {
  templateId: string;
  gameId: string;
  templateQuestions: ITemplateDetails['questionToTemplate'];
  mapHeight: number;
  isMapDragDisabled: boolean;
  setIsMapDragDisabled: Dispatch<SetStateAction<boolean>>;
}

const ActiveGameQuestions = ({
  templateId,
  gameId,
  templateQuestions,
  mapHeight,
  isMapDragDisabled,
  setIsMapDragDisabled,
}: ActiveGameQuestionsProps) => {
  const queryClient = useQueryClient();

  const { updateTemplateAvailableQuestions } = useUpdateTemplateAvailableQuestions({
    onUpdateSuccess: () => {
      queryClient.refetchQueries([QueryAliases.GAME_QUESTIONS(gameId)]);
    },
  });

  const handleUpdateQuestionCoordinates = ({ updatedQuestion }: { updatedQuestion: IQuestion }) => {
    const newCoordinates = !!updatedQuestion.coordinates
      ? {
          latitude: updatedQuestion.coordinates?.lat,
          longitude: updatedQuestion.coordinates?.lng,
        }
      : undefined;

    if (!newCoordinates) {
      return;
    }

    const currentAvailableQuestions = templateQuestions.map(question => ({
      index: question.index,
      id: question.question.id,
      coordinates: {
        latitude: question.coordinates.lat,
        longitude: question.coordinates.lng,
      },
    }));

    const changedQuestion = currentAvailableQuestions.find(question => question.id === updatedQuestion.id);

    if (!changedQuestion) {
      return;
    }

    changedQuestion.coordinates = newCoordinates;

    updateTemplateAvailableQuestions({
      templateId,
      data: {
        availableQuestions: currentAvailableQuestions,
      },
    });
  };

  return (
    <>
      {templateQuestions.map(templateQuestion => {
        return (
          <ActiveGameQuestionMarker
            gameId={gameId}
            key={templateQuestion.id}
            question={{
              ...templateQuestion.question,
              coordinates: templateQuestion.coordinates,
            }}
            isMapDragDisabled={isMapDragDisabled}
            setIsMapDragDisabled={setIsMapDragDisabled}
            onApplyNewCoordinates={updatedQuestion => handleUpdateQuestionCoordinates({ updatedQuestion })}
            mapHeight={mapHeight}
          />
        );
      })}
    </>
  );
};

export default ActiveGameQuestions;

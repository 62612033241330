import { createContext, useContext, useMemo, useState } from 'react';

interface FormChangeContextProps {
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (hasChanges: boolean) => void;
}

const FormChangeContext = createContext<FormChangeContextProps | undefined>(undefined);

type FormChangeProviderProps = {
  children: React.ReactNode;
};

export function FormChangeProvider({ children }: FormChangeProviderProps) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const value = useMemo(() => ({ hasUnsavedChanges, setHasUnsavedChanges }), [hasUnsavedChanges]);

  return <FormChangeContext.Provider value={value}>{children}</FormChangeContext.Provider>;
}

export function useFormChange() {
  const context = useContext(FormChangeContext);
  if (!context) {
    throw new Error('useFormChange must be used within a FormChangeProvider');
  }
  return context;
}

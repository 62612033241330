/* eslint-disable @typescript-eslint/no-explicit-any */
export const convertArrayOfObjectsToObjectByGivenKey = (array: any, key: string) => {
  const initialValue = {};
  return array.reduce((obj: any, item: any) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import ArrowRight from '../../../../components/icons/ArrowRight';
import CheckIcon from '../../../../components/icons/CheckIcon';
import MapMarkerBasic from '../../../../components/map/components/MapMarkerBasic';
import MapMarkerSnippetCard from '../../../../components/map/components/MapMarkerSnippetCard';
import Map from '../../../../components/map/Map';
import useFindTranslation from '../../../../hooks/useFindTranslation';
import { routes } from '../../../../static/routes';
import { colors } from '../../../../styles/variables';
import { IAllTemplatesItem } from '../../../../types/ApiTypes';
import { Coordinates } from '../../../../types/GlobalTypes';

interface TemplatesViewMapProps {
  templates: IAllTemplatesItem[];
  selectedTemplateIds: number[];
  setSelectedTemplateIds: Dispatch<SetStateAction<number[]>>;
}

const TemplatesViewMap = ({ templates, selectedTemplateIds, setSelectedTemplateIds }: TemplatesViewMapProps) => {
  const { findTranslation } = useFindTranslation();

  const toggleSelectedTemplate = (templateId: number) => {
    setSelectedTemplateIds(prevIds => {
      const foundGame = prevIds.find(id => id === templateId);

      if (foundGame) {
        return prevIds.filter(id => id !== templateId);
      } else {
        return [...prevIds, templateId];
      }
    });
  };

  const isTemplateSelected = (templateId: number) => {
    return selectedTemplateIds.some(id => id === templateId);
  };

  const templatesToShow = templates.filter(template => !!template.coordinates);

  return (
    <div>
      <Map containerStyle={{ width: '100%', height: '1000px' }} initialZoom={7}>
        {templatesToShow?.map(template => {
          return (
            <MapMarkerBasic
              key={template.id}
              coordinates={template.coordinates as Coordinates}
              onClick={() => toggleSelectedTemplate(template.id)}
            >
              {isTemplateSelected(template.id) && (
                <>
                  <div className="absolute bottom-full -translate-y-2 -translate-x-[110px] z-30">
                    <MapMarkerSnippetCard>
                      <div className="flex justify-between items-center">
                        <div className="flex justify-between gap-2">
                          {!!template.name && (
                            <h6 className="text-[0.8125rem] font-semibold leading-4 overflow-hidden">
                              {findTranslation(template.name.translations)}
                            </h6>
                          )}
                        </div>
                        <Link to={routes.editTemplate(template.id.toString())}>
                          <ArrowRight color={colors.grey700} />
                        </Link>
                      </div>
                    </MapMarkerSnippetCard>
                  </div>
                  <div className="flex justify-center items-center bg-success rounded-full border border-grey300 w-5 h-5 absolute -bottom-2 -right-1">
                    <CheckIcon />
                  </div>
                </>
              )}
            </MapMarkerBasic>
          );
        })}
      </Map>
    </div>
  );
};

export default TemplatesViewMap;

import { twMerge } from 'tailwind-merge';

interface BorderCardProps {
  className?: string;
  children: React.ReactNode;
}

const BorderCard = ({ className, children }: BorderCardProps) => {
  return <div className={twMerge(`border border-grey400 p-8 rounded-xl ${className}`)}>{children}</div>;
};

export default BorderCard;

import { colors } from '../../styles/variables';

const AreaIcon = ({ color, size }: { color?: string; size?: number }) => {
  return (
    <svg width={size ?? 20} height={size ?? 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66675 7.49984V5.4165C1.66675 3.3415 3.34175 1.6665 5.41675 1.6665H7.50008"
        stroke={color ?? colors.black700}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 1.6665H14.5833C16.6583 1.6665 18.3333 3.3415 18.3333 5.4165V7.49984"
        stroke={color ?? colors.black700}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 13.3335V14.5835C18.3333 16.6585 16.6583 18.3335 14.5833 18.3335H13.3333"
        stroke={color ?? colors.black700}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50008 18.3333H5.41675C3.34175 18.3333 1.66675 16.6583 1.66675 14.5833V12.5"
        stroke={color ?? colors.black700}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AreaIcon;

import { colors } from '../../styles/variables';

interface FilterIconProps {
  color?: string;
}

const FilterIcon = ({ color }: FilterIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 12H18M3 6H21M9 18H15"
        stroke={color ?? colors.black700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;

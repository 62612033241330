import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';
import { Modal } from '../../../../components/common/modals/Modal';
import ChangePasswordModal from './ChangePasswordModal';

const ChangePassword = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className="flex flex-col gap-4 max-w-[16rem] w-full">
      <p className="text-base uppercase font-bold pb-3">{t('account.personalData.password')}</p>
      <div className="max-w-fit">
        <SecondaryButton onClick={handleIsOpen}>{t('account.personalData.changePassword')}</SecondaryButton>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <ChangePasswordModal setIsOpen={setIsOpen} />
      </Modal>
    </div>
  );
};

export default ChangePassword;

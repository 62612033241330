import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import useFormatSubscription from '../../pages/account/subscriptions/hooks/useFormatSubscription';
import { api } from '../../static/api';
import { ApiSingleSubscriptionDetails, ISingleSubscriptionDetails } from '../../types/ApiBundleTypes';
import { QueryAliases } from '../../types/QueryAliases';
import { getApiErrorMessage } from '../../utils/api/getApiErrorMessage';
import useUserDetails from '../api/users/useUserDetails';
import useAxiosInstance from '../axios/useAxiosInstance';
import useToast from '../useToast';

const useSubscriptionDetails = () => {
  const { user } = useUserDetails();
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const id = user?.id;

  const { formatSubscription } = useFormatSubscription();

  const { data, isLoading } = useQuery(
    [QueryAliases.USER_SUBSCRIPTION(id as number)],
    () => axiosInstance.get(api.endpoints.SUBSCRIPTION_DETAILS),
    {
      enabled: !!id,
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) as string;

        showErrorToast(errorMessage);
      },
    },
  );

  const subscription: ApiSingleSubscriptionDetails = data?.data;

  let formatedSubscription: ISingleSubscriptionDetails | undefined = undefined;

  if (subscription?.user?.id === id) {
    formatedSubscription = formatSubscription(subscription);
  }

  return { subscription: formatedSubscription, isLoading };
};

export default useSubscriptionDetails;

import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTrackUnsavedChange } from '../../../context/FormChangeProvider';
import useGameTypeDropdownOptions from '../../../hooks/useGameCharactersDropdownOptions';
import useQuestionTypeDropdownOptions from '../../../hooks/useQuestionTypeDropdownOptions';
import { bundleValidationSchema } from '../../../static/validation/schemas/bundleValidationSchema';
import type { IBundleFormInputs } from '../../../types/ApiBundleTypes';
import type { DropdownOption } from '../../../types/Dropdown.types';
import getInputState from '../../../utils/getInputState/getInputState';
import BackButton from '../../common/buttons/BackButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { LabeledStateMultiDropdown } from '../../common/dropdowns/LabeledStateMultiDropdown';
import { LabeledStateInput } from '../../common/inputs/LabeledStateInput';

interface BundleFormProps {
  submitForm: (data: IBundleFormInputs) => void;
  submitBtnIcon: React.ReactNode;
  submitBtnText: string;
  initialBundle: IBundleFormInputs | undefined;
}

const BundleForm = ({ initialBundle, submitForm, submitBtnIcon, submitBtnText }: BundleFormProps) => {
  const { t } = useTranslation();
  const { questionTypeDropdownOptions } = useQuestionTypeDropdownOptions();

  const { gameCharactersDropdownOptions } = useGameTypeDropdownOptions();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IBundleFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(bundleValidationSchema),
    defaultValues: initialBundle,
  });

  const [currentQuestionOptions, setCurrentQuestionOptions] = useState<DropdownOption[]>(
    initialBundle?.questionTypes.limitValue ?? [],
  );
  const [currentGameCharacterOptions, setCurrentGameCharacterOptions] = useState<DropdownOption[]>(
    initialBundle?.gameCharacters.limitValue ?? [],
  );

  useTrackUnsavedChange(isDirty);

  const getErrorMessage = (field: keyof IBundleFormInputs) => {
    return get(errors, field);
  };

  return (
    <div className="mb-10">
      <form onSubmit={handleSubmit(submitForm)} className="w-full flex flex-col gap-7 mb-7">
        <div className="flex mb-8 justify-between items-end">
          <BackButton />
          <PrimaryButton type="submit" className="w-fit self-end" prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </div>
        <LabeledStateInput
          id="bundle_bundle-name"
          state={getInputState(getErrorMessage('bundleName'))}
          labeledProps={{
            wrapperClassName: 'max-w-xl',
            label: t('bundle.bundleForm.label.bundleName'),
            errorMessage: t(`errorMessages.${getErrorMessage('bundleName')?.message}`),
          }}
          inputProps={{
            placeholder: t('bundle.bundleForm.placeholder.bundleName'),
            type: 'text',
            register: {
              ...register(`bundleName`),
            },
          }}
        />
        <div className="max-w-xl">
          <Controller
            name="gameCharacters.limitValue"
            control={control}
            render={({ field }) => (
              <LabeledStateMultiDropdown
                id="game-type"
                state={getInputState(getErrorMessage('gameCharacters'))}
                labeledProps={{
                  label: t('bundle.bundleForm.label.gameCharacters'),
                  wrapperClassName: 'w-full',
                  errorMessage: t(`errorMessages.${getErrorMessage('gameCharacters')?.limitValue?.message}`),
                }}
                dropdownProps={{
                  placeholder: t('bundle.bundleForm.placeholder.gameCharacters'),
                  options: gameCharactersDropdownOptions,
                  currentOptions: currentGameCharacterOptions,
                  setNewOptions: val => {
                    field.onChange(val);
                    setCurrentGameCharacterOptions(val);
                  },
                }}
              />
            )}
          />
        </div>
        <div className="max-w-xl">
          <Controller
            name="questionTypes.limitValue"
            control={control}
            render={({ field }) => (
              <LabeledStateMultiDropdown
                id="question-type"
                state={getInputState(getErrorMessage('questionTypes'))}
                labeledProps={{
                  label: t('bundle.bundleForm.label.questionTypes'),
                  wrapperClassName: 'w-full',
                  errorMessage: t(`errorMessages.${getErrorMessage('questionTypes')?.limitValue?.message}`),
                }}
                dropdownProps={{
                  placeholder: t('bundle.bundleForm.placeholder.questionTypes'),
                  options: questionTypeDropdownOptions,
                  currentOptions: currentQuestionOptions,
                  setNewOptions: val => {
                    field.onChange(val);
                    setCurrentQuestionOptions(val);
                  },
                }}
              />
            )}
          />
        </div>
        <LabeledStateInput
          id={`bundle_gamers-per-game`}
          state={getInputState(getErrorMessage('gamersPerGame'))}
          labeledProps={{
            wrapperClassName: 'max-w-xl',
            label: t('bundle.bundleForm.label.gamersPerGame'),
            errorMessage: t(`errorMessages.${getErrorMessage('gamersPerGame')?.limitValue?.message}`),
          }}
          inputProps={{
            placeholder: t('bundle.bundleForm.placeholder.gamersPerGame'),
            type: 'number',
            register: {
              ...register(`gamersPerGame.limitValue`),
            },
          }}
        />
        <LabeledStateInput
          id={`bundle_games-per-month`}
          state={getInputState(getErrorMessage('gamesPerMonth'))}
          labeledProps={{
            wrapperClassName: 'max-w-xl',
            label: t('bundle.bundleForm.label.gamesPerMonth'),
            errorMessage: t(`errorMessages.${getErrorMessage('gamesPerMonth')?.limitValue?.message}`),
          }}
          inputProps={{
            placeholder: t('bundle.bundleForm.placeholder.gamesPerMonth'),
            type: 'number',
            register: {
              ...register(`gamesPerMonth.limitValue`),
            },
          }}
        />
        <LabeledStateInput
          id={`bundle_active-games`}
          state={getInputState(getErrorMessage('activeGames'))}
          labeledProps={{
            wrapperClassName: 'max-w-xl',
            label: t('bundle.bundleForm.label.activeGames'),
            errorMessage: t(`errorMessages.${getErrorMessage('activeGames')?.limitValue?.message}`),
          }}
          inputProps={{
            placeholder: t('bundle.bundleForm.placeholder.activeGames'),
            type: 'number',
            register: {
              ...register(`activeGames.limitValue`),
            },
          }}
        />
        <LabeledStateInput
          id={`bundle_spot-radius`}
          state={getInputState(getErrorMessage('spotRadius'))}
          labeledProps={{
            wrapperClassName: 'max-w-xl',
            label: t('bundle.bundleForm.label.spotRadius'),
            errorMessage: t(`errorMessages.${getErrorMessage('spotRadius')?.limitValue?.message}`),
          }}
          inputProps={{
            placeholder: t('bundle.bundleForm.placeholder.spotRadius'),
            type: 'number',
            register: {
              ...register(`spotRadius.limitValue`),
            },
          }}
        />
      </form>
    </div>
  );
};

export default BundleForm;

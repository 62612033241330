import { CallbackDefault } from '../../../types/Types';
import IconButton from '../../common/buttons/IconButton';
import FullScreenCloseIcon from '../../icons/FullScreenCloseIcon';
import FullScreenOpenIcon from '../../icons/FullScreenOpenIcon';

interface MapFullScreenButtonProps {
  isFullScreen: boolean;
  onClick: CallbackDefault;
}

const MapFullScreenButton = ({ isFullScreen, onClick }: MapFullScreenButtonProps) => {
  return (
    <IconButton
      icon={isFullScreen ? <FullScreenCloseIcon /> : <FullScreenOpenIcon />}
      onClick={onClick}
      className="rounded-md bg-grey100 border border-grey300 bg-opacity-1"
    />
  );
};

export default MapFullScreenButton;

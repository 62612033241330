import useCreateSubscription from '../../../hooks/api/subscription/useCreateSubscription';
import { InitialPartnerSubscription } from '../../../types/ApiPartnerTypes';
import { prepareAddSubscriptionRequestData } from '../utils/prepareAddSubscriptionRequestData';

const useHandleCreateSubscription = () => {
  const { createSubscription, isLoading } = useCreateSubscription();

  const handleCreateSubscription = async ({
    subscription,
    partnerId,
  }: {
    subscription: InitialPartnerSubscription;
    partnerId: number;
  }) => {
    const newSubscription = prepareAddSubscriptionRequestData(subscription, partnerId);

    const { data } = await createSubscription({ newSubscription });

    const subscriptionId: number | undefined = data?.id;

    return subscriptionId;
  };

  return { handleCreateSubscription, handleCreateSubscriptionIsLoading: isLoading };
};

export default useHandleCreateSubscription;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useAsignTemplatesToPartner = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { isLoading, mutateAsync } = useMutation(
    ({ partnerId, templateIds }: { partnerId: number; templateIds: string[] }) =>
      axiosInstance.patch(api.endpoints.ASIGN_TEMPLATES_TO_PARTNER({ partnerId }), { templateIds }),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('partner.toasts.editPartnerError');

        showErrorToast(errorMessage);
      },
    },
  );

  return {
    asignTemplates: mutateAsync,
    isLoading,
  };
};

export default useAsignTemplatesToPartner;

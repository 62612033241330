import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { LabeledStateInput } from '../../../components/common/inputs/LabeledStateInput';
import { requestResetPasswordValidationSchema } from '../../../static/validation/schemas/requestResetPasswordValidationSchema';
import getInputState from '../../../utils/getInputState/getInputState';

type IFormInput = {
  email: string;
};

interface RequestResetPasswordFormProps {
  onFormSubmit: (data: { email: string }) => Promise<void>;
  isLoading: boolean;
}

const RequestResetPasswordForm = ({ onFormSubmit, isLoading }: RequestResetPasswordFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(requestResetPasswordValidationSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({ email }) => {
    await onFormSubmit({ email });

    reset();
  };

  const getErrorMessage = (field: keyof IFormInput) => get(errors, field);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 w-full">
        <LabeledStateInput
          id="request-reset-password-email"
          state={getInputState(getErrorMessage('email'))}
          labeledProps={{
            wrapperClassName: 'mb-8',
            label: t('requestResetPassword.emailLabel'),
            errorMessage: t(`errorMessages.${getErrorMessage('email')?.message}`),
          }}
          inputProps={{
            placeholder: t('requestResetPassword.emailPlaceholder') as string,
            register: {
              ...register('email'),
            },
          }}
        />

        <PrimaryButton type="submit" disabled={isLoading} loading={isLoading}>
          {t('requestResetPassword.submitBtn')}
        </PrimaryButton>
      </form>
    </>
  );
};

export default RequestResetPasswordForm;

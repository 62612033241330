import { Coordinates, RectangleAreaCoordinates } from '../../../types/GlobalTypes';

export const filterItemsWithinArea = <T extends { coordinates?: Coordinates }>({
  items,
  areaCoordinates,
}: {
  items: T[];
  areaCoordinates: RectangleAreaCoordinates;
}) => {
  const itemsWithCoordinates = items.filter(question => !!question.coordinates);

  return itemsWithCoordinates.filter(question => {
    const lat = question.coordinates!.lat;
    const lng = question.coordinates!.lng;

    const { ne, sw, se, nw } = areaCoordinates;

    return (
      lat >= sw.lat &&
      lat >= se.lat &&
      lat <= nw.lat &&
      lat <= ne.lat &&
      lng >= nw.lng &&
      lng >= sw.lng &&
      lng <= ne.lng &&
      lng <= se.lng
    );
  });
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Langs, LangType } from '../../../../types/GlobalTypes';
import BackButton from '../../buttons/BackButton';
import LangTabButton from './LangTabButton';

interface LangTabSwitcherProps {
  children: ({ activeLang }: { activeLang: LangType }) => React.ReactNode;
  sufixElement: React.ReactNode;
  hideBackButton?: boolean;
}

const LangTabSwitcher = ({ children, sufixElement, hideBackButton }: LangTabSwitcherProps) => {
  const { t } = useTranslation();

  const [activeLang, setActiveLang] = useState<LangType>(Langs.PL);

  const tabs = Object.values(Langs);

  return (
    <>
      <div className="flex gap-10 bg-white700 sticky top-0 z-20 pt-3">
        {!hideBackButton && <BackButton className="place-self-center" />}

        <nav
          className={`flex gap-2.5 mr-auto px-4 overflow-x-scroll no-scrollbar w-full lg:overflow-x-scroll lg:border-b lg:border-grey200`}
        >
          {tabs.map(tab => (
            <LangTabButton
              key={tab}
              isActive={tab === activeLang}
              text={t(`langTabSwitcher.${tab}`)}
              onClick={() => setActiveLang(tab)}
            />
          ))}
        </nav>

        {sufixElement}
      </div>
      <div>{children({ activeLang })}</div>
    </>
  );
};

export default LangTabSwitcher;

import { useLayoutEffect, useRef, useState } from 'react';

interface LayoutWrapperProps {
  children: React.ReactNode;
  navbar: React.ReactNode;
}

const LayoutWrapper = ({ children, navbar }: LayoutWrapperProps) => {
  const navbarWrapperRef = useRef<HTMLDivElement>(null);

  const [navWidth, setNavWidth] = useState(0);

  useLayoutEffect(() => {
    setNavWidth(navbarWrapperRef.current?.clientWidth ?? 0);
  }, [setNavWidth]);

  return (
    <main className="flex h-screen">
      <div ref={navbarWrapperRef} className="fixed z-30">
        {navbar}
      </div>
      <div
        style={{
          marginLeft: navWidth + 'px',
          maxWidth: `calc(100% - ${navWidth}px)`,
        }}
        className={`relative grow w-fit pt-0 h-auto`}
      >
        {children}
      </div>
    </main>
  );
};

export default LayoutWrapper;

import { CallbackDefault } from '../../../types/Types';

interface AutocompleteListItemProps {
  children: React.ReactNode;
  onClick: CallbackDefault;
  isActive: boolean;
  className?: string;
}

const AutocompleteListItem = ({ children, onClick, isActive, className = '' }: AutocompleteListItemProps) => {
  return (
    <>
      <li
        onClick={onClick}
        className={`px-4 min-h-[3.5rem] flex items-center cursor-pointer text-black700 font-medium text-sm leading-[1.125rem]  ${
          isActive ? 'bg-redSelectionBg' : 'hover:bg-grey100'
        } ${className}`}
      >
        {children}
      </li>
    </>
  );
};

export default AutocompleteListItem;

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import ArrowRight from '../../../../components/icons/ArrowRight';
import CheckIcon from '../../../../components/icons/CheckIcon';
import MapFullScreenButton from '../../../../components/map/components/MapFullScreenButton';
import MapMarkerBasic from '../../../../components/map/components/MapMarkerBasic';
import MapMarkerSnippetCard from '../../../../components/map/components/MapMarkerSnippetCard';
import Map from '../../../../components/map/Map';
import useFindTranslation from '../../../../hooks/useFindTranslation';
import { gameStatusesData } from '../../../../static/games/gameStatusesData';
import { routes } from '../../../../static/routes';
import { colors } from '../../../../styles/variables';
import { Coordinates } from '../../../../types/GlobalTypes';
import { GameTableItem } from '../../ActiveGamesView';

interface ActiveGamesMapProps {
  mapCenter?: Coordinates;
  isMapExpanded: boolean;
  setIsMapExpanded: Dispatch<SetStateAction<boolean>>;
  allGames: GameTableItem[];
  selectedGamesIds: number[];
  setSelectedGamesIds: Dispatch<SetStateAction<number[]>>;
}

const ActiveGamesMap = ({
  mapCenter,
  isMapExpanded,
  setIsMapExpanded,
  allGames,
  selectedGamesIds,
  setSelectedGamesIds,
}: ActiveGamesMapProps) => {
  const { findTranslation } = useFindTranslation();

  const [mapHeight, setMapHeight] = useState(438);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      const distance = window.innerHeight - mapContainerRef.current.offsetTop;

      setMapHeight(distance);
    }
  }, []);

  const isGameSelected = (gameId: number) => {
    return selectedGamesIds.some(id => id === gameId);
  };

  const toggleSelectedGame = (gameId: number) => {
    setSelectedGamesIds(prevIds => {
      const foundGame = prevIds.find(id => id === gameId);

      if (foundGame) {
        return prevIds.filter(id => id !== gameId);
      } else {
        return [...prevIds, gameId];
      }
    });
  };

  const mapStyles = { width: '100%', height: `${mapHeight - 28}px` };

  const gamesToShow = allGames.filter(game => !!game.templateCoordinates);

  return (
    <div ref={mapContainerRef} className={`${isMapExpanded ? 'w-full' : 'w-1/3'}`}>
      <Map
        center={mapCenter ?? allGames?.[0]?.templateCoordinates}
        containerStyle={mapStyles}
        hideZoomButtons={!isMapExpanded}
      >
        {gamesToShow.map(game => {
          return (
            <MapMarkerBasic
              key={game.id}
              coordinates={game.templateCoordinates as Coordinates}
              markerColor={gameStatusesData[game.status]?.color}
              onClick={() => toggleSelectedGame(game.id)}
            >
              {isGameSelected(game.id) && (
                <>
                  <div className="absolute bottom-full -translate-y-2 -translate-x-[110px] z-30">
                    <MapMarkerSnippetCard>
                      <div className="flex justify-between items-center">
                        <div className="flex justify-between gap-2">
                          {!!game.templateName && (
                            <h6 className="text-[0.8125rem] font-semibold leading-4 overflow-hidden">
                              {findTranslation(game?.templateName)}
                            </h6>
                          )}
                        </div>
                        <Link to={routes.singleActiveGame.info(game.id.toString())}>
                          <ArrowRight color={colors.grey700} />
                        </Link>
                      </div>
                    </MapMarkerSnippetCard>
                  </div>
                  <div className="flex justify-center items-center bg-success rounded-full border border-grey300 w-5 h-5 absolute -bottom-2 -right-1">
                    <CheckIcon />
                  </div>
                </>
              )}
            </MapMarkerBasic>
          );
        })}
        <div className={`z-10 absolute right-2 flex flex-col gap-1.5 ${isMapExpanded ? 'top-3' : 'bottom-3'}`}>
          <MapFullScreenButton isFullScreen={isMapExpanded} onClick={() => setIsMapExpanded(prev => !prev)} />
        </div>
      </Map>
    </div>
  );
};

export default ActiveGamesMap;

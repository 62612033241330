import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActiveGameIcon from '../../../../../components/icons/ActiveGameIcon';
import EditIcon from '../../../../../components/icons/EditIcon';
import useUpdateGame from '../../../../../hooks/api/games/useUpdateGame';
import { useGameTimer } from '../../../../../hooks/useGameTimer';
import { formatHour } from '../../../../../utils/dates/formatHour/formatHour';
import { getGameDate } from '../../../../templates/components/templatesViewList/components/templatesListTableStartGameModal/utils/getGameDate/getGameDate';
import EditGameDatesModal from '../../../components/EditGameDatesModal';

interface SingleActiveGameMapTimeProps {
  gameId: string;
  startDate: Date;
  endDate: Date;
}

const SingleActiveGameMapTime = ({ gameId, startDate, endDate }: SingleActiveGameMapTimeProps) => {
  const { t } = useTranslation();

  const { displayRemainingTime } = useGameTimer({ endDate });

  const [isEditDateModalOpen, setIsEditDateModalOpen] = useState(false);

  const { updateGame, isLoading } = useUpdateGame({
    onSuccess: () => {
      setIsEditDateModalOpen(false);
    },
  });

  const handleEditGameDates = async (data: { date: Date; startHour: Date; endHour: Date }) => {
    const startDate = getGameDate({ dateInput: data.date, hourInput: data.startHour }).toString();
    const endDate = getGameDate({ dateInput: data.date, hourInput: data.endHour }).toString();

    updateGame({
      id: gameId,
      data: {
        startDate,
        endDate,
      },
    });
  };

  const displayStartHour = formatHour(startDate);
  const displayEndHour = formatHour(endDate);

  return (
    <>
      <div className="relative bg-white900 rounded-md px-3 pt-3.5 pb-2 border border-grey300">
        <div className="flex gap-[2px] justify-between items-center rounded-md px-1 bg-white900 absolute -top-2.5">
          <ActiveGameIcon width={14} />
          <p className="text-xs text-black900 leading-4 ">{t('singleActiveGame.map.gameTimeLabel')}</p>
        </div>

        <h2 className="text-2xl text-black700 font-medium">{displayRemainingTime}</h2>
        <div className="flex justify-between items-center mt-1.5 gap-1.5">
          <p className="text-grey700 text-sm">{`${displayStartHour} - ${displayEndHour}`}</p>
          <button onClick={() => setIsEditDateModalOpen(true)}>
            <EditIcon width="18" />
          </button>
        </div>
      </div>

      <EditGameDatesModal
        isOpen={isEditDateModalOpen}
        setIsOpen={setIsEditDateModalOpen}
        initStartDate={startDate}
        initEndDate={endDate}
        onSubmitForm={handleEditGameDates}
        isLoading={isLoading}
      />
    </>
  );
};

export default SingleActiveGameMapTime;

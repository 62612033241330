import { useTranslation } from 'react-i18next';

import { Checkbox } from '@mui/material';
import { DataGrid, DataGridProps, GridRowIdGetter } from '@mui/x-data-grid';

import { colors } from '../../../styles/variables';

export interface MaterialTableProps extends DataGridProps {
  getRowId?: GridRowIdGetter;
  showPagination?: boolean;
}

const MaterialTable = ({ ...props }: MaterialTableProps) => {
  const { t } = useTranslation();

  return (
    <DataGrid
      localeText={{
        noRowsLabel: t('table.noRowsLabel'),
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) => {
            return `${from}-${to} ${t('table.of')} ${count}`;
          },
        },
      }}
      getRowId={props.getRowId && props.getRowId}
      autoHeight
      disableColumnMenu={true}
      hideFooterSelectedRowCount={true}
      initialState={{
        ...props.initialState,
        pagination: { paginationModel: { pageSize: 100 } },
      }}
      pageSizeOptions={[100]}
      getRowHeight={() => 'auto'}
      classes={{
        columnHeaders: 'bg-white900 border-b-black900 text-gray-500 font-bold uppercase',
        cell: 'border-b-black900 p-4',
        'cell--withRenderer': 'border-b-black900 border-b-2',
        iconSeparator: 'text-black900',
        main: 'border-b-[1px] border-b-grey300',
        row: 'min-h-16',
      }}
      slots={{
        baseCheckbox: Checkbox,
      }}
      sx={{
        width: '100%',
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '.MuiDataGrid-columnHeader--moving': {
          backgroundColor: 'transparent',
        },
        '.MuiDataGrid-cell': {
          padding: `${props.checkboxSelection ? '0' : '1rem 1.5rem'}`,
          '&:focus': {
            outline: 'transparent',
          },
        },
        '	.MuiDataGrid-footerContainer': {
          justifyContent: 'center',
          ...(!props.showPagination && {
            display: 'none',
          }),
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'normal',
          lineHeight: 'normal',
        },
        '& .MuiDataGrid-columnHeader': {
          height: 'unset !important',
          padding: `${props.checkboxSelection ? '0' : '1rem 1.5rem'}`,
        },
        '& .MuiDataGrid-columnHeaders': {
          maxHeight: '168px !important',
        },
        '.MuiDataGrid-row.Mui-selected': {
          backgroundColor: colors.redButtonBg,
        },
      }}
      {...props}
    />
  );
};

export default MaterialTable;

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from '../../../../../../../../components/common/buttons/PrimaryButton';
import TableCell from '../../../../../../../../components/dataTable/components/TableCell';
import useAcceptAnswer from '../../../../../../../../hooks/api/games/useAcceptAnswer';
import { ButtonSize } from '../../../../../../../../types/ButtonSizes';

interface SingleCheckedAnswerActionButtonsProps {
  answerId: number;
}

const SingleCheckedAnswerActionButtons = ({ answerId }: SingleCheckedAnswerActionButtonsProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const gameId = params.id as string;

  const { acceptAnswer, isLoading: isLoadingAccept, isSuccess: isSuccessAccept } = useAcceptAnswer({ gameId });

  const handleAcceptAnswer = async () => {
    await acceptAnswer({ answerId });
  };

  return (
    <div className="w-full flex gap-2.5 justify-end">
      {isSuccessAccept ? (
        <div>{isSuccessAccept && <TableCell>{t('singleActiveGame.answers.table.accepted')}</TableCell>}</div>
      ) : (
        <PrimaryButton
          onClick={handleAcceptAnswer}
          size={ButtonSize.Medium}
          disabled={isLoadingAccept}
          loading={isLoadingAccept}
          className="w-fit"
        >
          {t('singleActiveGame.answers.table.acceptWrongAnswer')}
        </PrimaryButton>
      )}
    </div>
  );
};

export default SingleCheckedAnswerActionButtons;

import ActiveGameIcon from '../../../components/icons/ActiveGameIcon';
import { useGameTimer } from '../../../hooks/useGameTimer';
import { colors } from '../../../styles/variables';

interface GamePreviewTimerProps {
  endDate: Date;
}

const GamePreviewTimer = ({ endDate }: GamePreviewTimerProps) => {
  const { displayRemainingTime } = useGameTimer({ endDate });

  return (
    <div className="relative bg-white900 rounded-md px-3 py-2 border border-grey300 flex items-center gap-2">
      <ActiveGameIcon color={colors.red500} />
      <h2 className="text-2xl text-black700 font-medium">{displayRemainingTime}</h2>
    </div>
  );
};

export default GamePreviewTimer;

const MessageIcon = ({ color, width = 17, height = 16 }: { color: string; width?: number; height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.834 7.19334V9.86001C12.834 10.0333 12.8273 10.2 12.8073 10.36C12.654 12.16 11.594 13.0533 9.64067 13.0533H9.374C9.20734 13.0533 9.04733 13.1333 8.94733 13.2667L8.14734 14.3333C7.79401 14.8067 7.22066 14.8067 6.86733 14.3333L6.06732 13.2667C5.98066 13.1533 5.78733 13.0533 5.64067 13.0533H5.374C3.24734 13.0533 2.18066 12.5267 2.18066 9.86001V7.19334C2.18066 5.24001 3.08067 4.18001 4.874 4.02667C5.034 4.00667 5.20067 4 5.374 4H9.64067C11.7673 4 12.834 5.06667 12.834 7.19334Z"
        stroke={color}
        strokeWidth="0.666667"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5007 4.52537V7.19204C15.5007 9.15204 14.6007 10.2054 12.8074 10.3587C12.8274 10.1987 12.834 10.032 12.834 9.8587V7.19204C12.834 5.06537 11.7674 3.9987 9.64069 3.9987H5.37402C5.20069 3.9987 5.03402 4.00537 4.87402 4.02537C5.02736 2.23204 6.08736 1.33203 8.04069 1.33203H12.3074C14.434 1.33203 15.5007 2.3987 15.5007 4.52537Z"
        stroke={color}
        strokeWidth="0.666667"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.84401 8.83333H9.85001"
        stroke={color}
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.511 8.83333H7.517"
        stroke={color}
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.1775 8.83333H5.1835"
        stroke={color}
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageIcon;

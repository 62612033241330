import { ApiGameCode, IGameCode } from '../../../../types/ApiTypes';

export const transformGameCode = (apiGameCode: ApiGameCode) => {
  const iGameCode: IGameCode = {
    code: apiGameCode.code,
    active: apiGameCode.active,
  };

  return iGameCode;
};

import { Navigate, Outlet, Route } from 'react-router-dom';
import LayoutPartner from '../components/layout/LayoutPartner';
import AccountView from '../pages/account/AccountView';
import PersonalDataView from '../pages/account/personalData/PersonalDataView';
import PlayersView from '../pages/account/players/PlayersView';
import SubscriptionView from '../pages/account/subscriptions/SubscriptionView';
import ActiveGamesView from '../pages/activeGames/ActiveGamesView';
import AddQuestionView from '../pages/addQuestion/AddQuestionView';
import AddRelatedQuestionView from '../pages/addRelatedQuestion/AddRelatedQuestionView';
import AddTemplateView from '../pages/addTemplate/AddTemplateView';
import CopyQuestionView from '../pages/copyQuestion/CopyQuestionView';
import CopyTemplateView from '../pages/copyTemplate/CopyTemplateView';
import DashboardView from '../pages/dashboard/DashboardView';
import EditQuestionView from '../pages/editQuestion/EditQuestionView';
import EditRelatedQuestionView from '../pages/editRelatedQuestion/EditRelatedQuestionView';
import EditTemplateView from '../pages/editTemplate/EditTemplateView';
import HistoryGamesView from '../pages/historyGames/HistoryGamesView';
import QuestionsView from '../pages/questions/QuestionsView';
import SingleActiveGameAnswersView from '../pages/singleActiveGame/subpages/answers/SingleActiveGameAnswersView';
import SingleActiveGameView from '../pages/singleActiveGame/subpages/info/SingleActiveGameInfoView';
import SingleActiveGameMapView from '../pages/singleActiveGame/subpages/map/SingleActiveGameMapView';
import SingleActiveGameMessagesView from '../pages/singleActiveGame/subpages/messages/SingleActiveGameMessagesView';
import SingleActiveGamePlayersView from '../pages/singleActiveGame/subpages/players/SingleActiveGamePlayersView';
import SingleActiveGameQuestionsView from '../pages/singleActiveGame/subpages/questions/SingleActiveGameQuestionsView';
import TemplatesView from '../pages/templates/TemplatesView';
import { paths, routes } from '../static/routes';
import { ProtectedRoute } from './ProtectedRoute';
import NavigateToSingleActiveGame from './components/NavigateToSingleActiveGame';
import { GameTypes } from '../types/GlobalTypes';

const withLayout = (Component: React.ReactNode) => <LayoutPartner>{Component}</LayoutPartner>;

const PartnerRoutes = () => {
  return (
    <>
      {/* TODO: consider moving to AppRoutes - depending on the user type content */}
      <Route element={<ProtectedRoute />}>
        <Route path={paths.home} element={withLayout(<Navigate to={routes.dashboard} replace />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.dashboard} element={withLayout(<DashboardView />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.activeGames} element={withLayout(<ActiveGamesView gameTypes={[GameTypes.BUSINESS]} />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.singleActiveGame.index} element={withLayout(<Outlet />)}>
          <Route index element={<NavigateToSingleActiveGame />} />
          <Route path={paths.singleActiveGame.info} element={<SingleActiveGameView />} />
          <Route path={paths.singleActiveGame.map} element={<SingleActiveGameMapView />} />
          <Route path={paths.singleActiveGame.questions} element={<SingleActiveGameQuestionsView />} />
          <Route path={paths.singleActiveGame.players} element={<SingleActiveGamePlayersView />} />
          <Route path={paths.singleActiveGame.messages} element={<SingleActiveGameMessagesView />} />
          <Route path={paths.singleActiveGame.answers} element={<SingleActiveGameAnswersView />} />
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.historyGames} element={withLayout(<HistoryGamesView />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.questions} element={withLayout(<QuestionsView />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.addQuestion} element={withLayout(<AddQuestionView />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.editQuestion} element={withLayout(<EditQuestionView />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.copyQuestion} element={withLayout(<CopyQuestionView />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.addRelatedQuestion} element={withLayout(<AddRelatedQuestionView />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.editRelatedQuestion} element={withLayout(<EditRelatedQuestionView />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.templates} element={withLayout(<TemplatesView />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.addTemplate} element={withLayout(<AddTemplateView />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.editTemplate} element={withLayout(<EditTemplateView />)} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={paths.copyTemplate} element={withLayout(<CopyTemplateView />)} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path={paths.account} element={withLayout(<AccountView />)}>
          <Route element={<ProtectedRoute />}>
            <Route path={paths.accountPersonalData} element={<PersonalDataView />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={paths.accountSubscriptions} element={<SubscriptionView />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={paths.accountPlayers} element={<PlayersView />} />
          </Route>
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="*" element={<Navigate replace to={routes.home} />} />
      </Route>
    </>
  );
};

export default PartnerRoutes;

import { IAnswersGroup } from '../SingleActiveGameAnswersView';

export const filterAnswerGroupsByTeamName = ({
  answerGroups,
  teamName,
}: {
  answerGroups: IAnswersGroup[];
  teamName: string;
}) => {
  const groupsFiltered: IAnswersGroup[] = [];

  answerGroups.forEach(answerGroup => {
    const teamAnswers = answerGroup.answers.filter(answer => answer.userGame.teamName === teamName);

    if (teamAnswers.length === 0) {
      return;
    }

    groupsFiltered.push({
      ...answerGroup,
      answers: teamAnswers,
    });
  });

  return groupsFiltered;
};

import { useTranslation } from 'react-i18next';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import GamersList from './GamersList';

const GamersView = () => {
  const { t } = useTranslation();

  return (
    <TitleWrapper title={t('gamers.title')}>
      <GamersList />
    </TitleWrapper>
  );
};

export default GamersView;

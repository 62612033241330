import { IGameAnswer } from '../../../../../types/ApiTypes';
import { IAnswersGroup } from '../SingleActiveGameAnswersView';

export const groupAnswersByQuestion = (answers: IGameAnswer[]) => {
  const answersGrouped = answers.reduce((groups: IAnswersGroup[], answer: IGameAnswer) => {
    const existingGroup = groups.find(group => group.question.id === answer.question.id);

    if (!existingGroup) {
      const newGroup: IAnswersGroup = {
        question: answer.question,
        answers: [answer],
      };

      groups.push(newGroup);
    } else {
      existingGroup.answers.push(answer);
    }

    return groups;
  }, []);

  return answersGrouped;
};

import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles/variables';
import type { IUserGame } from '../../types/ApiTypes';
import IconButton from '../common/buttons/IconButton';
import GameRankingIcon from '../icons/GameRankingIcon';
import MapTooltipCard from '../map/components/MapTooltipCard';
import { calculateCardHeight } from './core/calculateCardHeight';
import { isLandscape } from './utils/isLandscape';
import { isSmallHeight } from './utils/isSamllDeviceHeight';

interface MapTeamsRankingProps {
  teams: IUserGame[];
  mapHeight?: number;
}

const MapTeamsRanking = ({ teams, mapHeight }: MapTeamsRankingProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const cardHeight = calculateCardHeight(mapHeight);
  const teamsRanked = teams.sort((teamA, teamB) => teamB.points - teamA.points);

  return isOpen ? (
    <MapTooltipCard
      className={classNames('absolute left-0 overflow-hidden z-10', {
        '-bottom-14': isLandscape() && isSmallHeight(),
        'bottom-0': !isLandscape() || !isSmallHeight(),
      })}
      style={{ height: cardHeight }}
      header={
        <h5 className="text-black500 text-[1.375rem] font-medium leading-6">
          {t('singleActiveGame.map.ranking.title')}
        </h5>
      }
      onCloseClick={() => setIsOpen(false)}
    >
      <div className="flex flex-col gap-6 overflow-y-auto h-full">
        {teamsRanked.map((team, index) => (
          <div key={team.id} className="flex items-center">
            <div
              className="rounded-full w-6 h-6 min-w-[1.5rem] flex justify-center items-center mr-2"
              style={{ backgroundColor: team.color }}
            >
              <p className="text-white900 text-sm font-bold">{index + 1}.</p>
            </div>
            <p className="whitespace-nowrap text-black500 text-sm font-semibold">{team.teamName ?? team.user.email}</p>
            <p className="whitespace-nowrap ml-auto pl-6 text-sm font-semibold text-grey700">{`${team.points} ${t(
              'singleActiveGame.map.ranking.points',
            )}`}</p>
          </div>
        ))}
      </div>
    </MapTooltipCard>
  ) : (
    <IconButton
      icon={<GameRankingIcon color={colors.grey700} />}
      className="bg-opacity-100 bg-grey100 border-grey300"
      onClick={() => setIsOpen(true)}
    />
  );
};

export default MapTeamsRanking;

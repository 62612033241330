import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiGameUser } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { transformGameUser } from '../../../utils/api/dataTransformers/transformGameUser/transformGameUser';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useGameUsers = ({ gameId }: { gameId: string }) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.GAME_USERS(gameId)],
    () => axiosInstance.get(api.endpoints.GAME_USERS({ gameId })),
    { refetchOnMount: true },
  );

  const apiUsers: ApiGameUser[] | undefined = data?.data;

  const iGameUsers = !!apiUsers ? apiUsers.map((apiUser, index) => transformGameUser({ apiUser, index })) : undefined;

  return {
    gameUsers: iGameUsers,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useGameUsers;

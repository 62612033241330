import type { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import TableCell from '../../../../../components/dataTable/components/TableCell';
import EditIcon from '../../../../../components/icons/EditIcon';
import useFindTranslation from '../../../../../hooks/useFindTranslation';
import { routes } from '../../../../../static/routes';
import { colors } from '../../../../../styles/variables';
import type { IAllTemplatesItem } from '../../../../../types/ApiTypes';
import { GameTypes } from '../../../../../types/GlobalTypes';
import TemplatesListTableDeleteBtn from '../components/TemplatesListTableDeleteBtn';
import TemplatesListTableStartGameBtn from '../components/TemplatesListTableStartGameBtn';
import TemplatesListTableStatsBtn from '../components/TemplatesListTableStatsBtn';
import { shouldShowAdditionalActions } from '../core/gameTypeUtils';

const useTemplatesTableHeaders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { findTranslation } = useFindTranslation();

  const headers = [
    {
      field: 'name',
      headerName: t('templates.table.headers.name'),
      minWidth: 120,
      flex: 1,
      sortable: false,
      valueGetter: (params: GridRenderCellParams<IAllTemplatesItem>) => findTranslation(params.row.name.translations),
      renderCell: (params: GridRenderCellParams<IAllTemplatesItem>) => {
        const showInactiveMessage = params.row.gameType === GameTypes.INDIVIDUAL && !params.row.isActive;

        return (
          <TableCell>
            {findTranslation(params.row.name.translations)}
            {showInactiveMessage && (
              <span className="font-bold text-red700">{` (${t(
                'templates.table.headers.individualInactiveMessage',
              )})`}</span>
            )}
          </TableCell>
        );
      },
    },
    {
      field: 'owner',
      headerName: t('questions.table.headers.owner'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IAllTemplatesItem>) => {
        return <TableCell>{params.row.owner?.email}</TableCell>;
      },
    },
    {
      field: 'location',
      headerName: t('templates.table.headers.location'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IAllTemplatesItem>) => {
        return <TableCell>{params.row.city}</TableCell>;
      },
    },
    {
      field: 'numOfQuestions',
      headerName: t('templates.table.headers.numOfQuestions'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IAllTemplatesItem>) => {
        const numOfQuestions = params.row.questionToTemplate.length;

        return <TableCell>{numOfQuestions}</TableCell>;
      },
    },
    {
      field: 'gameTime',
      headerName: t('templates.table.headers.gameTime'),
      minWidth: 64,
      maxWidth: 120,
      valueGetter: (params: GridRenderCellParams) => params.row?.averageTime,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IAllTemplatesItem>) => {
        return <TableCell>{params.row?.averageTime}</TableCell>;
      },
    },
    {
      field: 'buttons',
      headerName: '',
      minWidth: 160,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IAllTemplatesItem>) => {
        const gameType = params.row.gameType;

        return (
          <div className="flex justify-end w-full">
            <TemplatesListTableStatsBtn
              templateId={params.row.id.toString()}
              templateName={params.row.name.translations}
            />

            {shouldShowAdditionalActions(gameType) && (
              <TemplatesListTableStartGameBtn templateId={params.row.id.toString()} gameType={gameType} />
            )}
            <HoverableTooltip tooltipContent={<p>{t('templates.table.buttons.edit')}</p>}>
              {isHovered => (
                <button
                  className="p-3"
                  onClick={e => {
                    e.stopPropagation();
                    navigate(routes.editTemplate(params.row.id.toString()));
                  }}
                >
                  <EditIcon color={isHovered ? colors.red500 : colors.grey700} />
                </button>
              )}
            </HoverableTooltip>
            <TemplatesListTableDeleteBtn templateId={params.row.id.toString()} />
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useTemplatesTableHeaders;

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BreadcrumbsProps } from '../../components/common/breadcrumbs/Breadcrumbs';
import QuestionForm from '../../components/forms/questionForm/QuestionForm';
import { QuestionFormInputs } from '../../components/forms/questionForm/types/QuestionFormTypes';
import PlusIcon from '../../components/icons/PlusIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import useAddQuestionImages from '../../hooks/api/questions/useAddQuestionImages';
import useSingleQuestion from '../../hooks/api/questions/useSingleQuestion';
import useUpdateRelatedQuestion from '../../hooks/api/questions/useUpdateRelatedQuestion';
import useToast from '../../hooks/useToast';
import { routes } from '../../static/routes';
import { Langs } from '../../types/GlobalTypes';
import { findValueByLang } from '../../utils/findValueByLang/findValueByLang';
import { prepareQuestionRequestData } from '../../utils/questions/prepareQuestionRequestData/prepareQuestionRequestData';

const QuestionFormWithLoading = withLoading(QuestionForm);

const AddRelatedQuestionView = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const params = useParams();
  const parentQuestionId = params.parentQuestionId as string;

  const {
    questionResponse: parentQuestionResponse,
    isLoading: isLoadingParentQuestion,
    refetch: refetchParentQuestion,
  } = useSingleQuestion({
    questionId: parentQuestionId,
    onErrorCb: () => {
      showErrorToast(t('errorMessages.somethingWentWrong'));
      navigate(routes.questions());
    },
  });
  const { updateRelatedQuestion, isLoading: isLoadingSubmit } = useUpdateRelatedQuestion({
    onSuccess: () => {
      refetchParentQuestion();
    },
  });
  const { addQuestionImages, isLoading: isLoadingAddImages } = useAddQuestionImages();

  const handleSubmit = async ({
    formData,
    mainImage,
    correctAnswerImage,
    incorrectAnswerImage,
  }: {
    formData: QuestionFormInputs;
    mainImage: File | null;
    correctAnswerImage: File | null;
    incorrectAnswerImage: File | null;
  }) => {
    const relatedQuestionData = prepareQuestionRequestData(formData);

    const parentCoordinates = !!parentQuestionResponse?.question.coordinates
      ? {
          latitude: parentQuestionResponse?.question.coordinates?.lat,
          longitude: parentQuestionResponse?.question.coordinates?.lng,
        }
      : undefined;

    const res = await updateRelatedQuestion({
      parentQuestionId,
      data: {
        relatedQuestion: { ...relatedQuestionData, coordinates: parentCoordinates },
      },
    });

    //TODO: tu tablica przychodzi zamiast obiektu
    const createdRelatedQuestionId = res.data.relatedQuestion[0].id;

    if (!!mainImage || !!correctAnswerImage || !!incorrectAnswerImage) {
      addQuestionImages({
        questionId: createdRelatedQuestionId as string,
        mainImage,
        correctAnswerImage,
        incorrectAnswerImage,
      });
    }

    navigate(routes.editQuestion(parentQuestionId));
  };

  const questionName = findValueByLang({
    lang: i18n.resolvedLanguage ?? Langs.PL,
    array: parentQuestionResponse?.question?.name ?? [],
  });

  const breadcrumbsProps: BreadcrumbsProps = {
    limit: 2,
    extraBreadcrumbs: [{ text: questionName ?? '' }, { text: t('addRelatedQuestion.title') }],
  };

  return (
    <TitleWrapper title={t('addRelatedQuestion.title')} breadcrumbsProps={breadcrumbsProps}>
      <QuestionFormWithLoading
        isLoading={isLoadingParentQuestion}
        isLoadingSubmit={isLoadingSubmit || isLoadingAddImages}
        onSubmitForm={handleSubmit}
        submitBtnIcon={<PlusIcon />}
        submitBtnText={t('addRelatedQuestion.submitBtn')}
        isRelatedQuestionForm
      />
    </TitleWrapper>
  );
};

export default AddRelatedQuestionView;

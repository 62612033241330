import { useTranslation } from 'react-i18next';
import { IAdvertisement } from '../../../../../types/ApiTypes';
import BorderCard from '../../../../common/cards/BorderCard';
import MultiImagePicker from '../../../../common/imagePickers/multiImagePicker/MultiImagePicker';

interface TemplateFormAdsProps {
  existingAds: IAdvertisement[];
  onRemoveExistingAd: (id: number) => void;
  newAds: File[];
  setNewAds: (ads: File[]) => void;
}

const TemplateFormAds = ({ existingAds, onRemoveExistingAd, newAds, setNewAds }: TemplateFormAdsProps) => {
  const { t } = useTranslation();

  return (
    <BorderCard>
      <h5 className="text-sm uppercase font-bold text-grey500 mb-3">{t('templateForm.ads.title')}</h5>

      <MultiImagePicker
        title={t('templateForm.ads.picker.title')}
        subtitle={t('templateForm.ads.picker.subtitle')}
        oldImageUrls={existingAds}
        removeOldImage={id => onRemoveExistingAd(id)}
        selectedFiles={newAds}
        setSelectedFiles={setNewAds}
      />
    </BorderCard>
  );
};

export default TemplateFormAds;

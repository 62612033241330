import { PhotoIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../../components/common/buttons/SecondaryButton';
import BorderCard from '../../../../../components/common/cards/BorderCard';
import SingleImagePicker from '../../../../../components/common/imagePickers/singleImagePicker/SingleImagePicker';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import CopyIcon from '../../../../../components/icons/CopyIcon';
import EditIcon from '../../../../../components/icons/EditIcon';
import ReportIcon from '../../../../../components/icons/ReportIcon';
import useAddGamePartnerLogo from '../../../../../hooks/api/games/useAddGamePartnerLogo';
import useGameImages from '../../../../../hooks/api/games/useGameImages';
import useGameReport from '../../../../../hooks/api/games/useGameReport';
import useGameVideos from '../../../../../hooks/api/games/useGameVideos';
import useUpdateGame from '../../../../../hooks/api/games/useUpdateGame';
import useFindTranslation from '../../../../../hooks/useFindTranslation';
import useToast from '../../../../../hooks/useToast';
import { routes } from '../../../../../static/routes';
import { colors } from '../../../../../styles/variables';
import { IGame, IGameUser, IRelatedUser } from '../../../../../types/ApiTypes';
import { GameStatuses } from '../../../../../types/GlobalTypes';
import { QueryAliases } from '../../../../../types/QueryAliases';
import { UserTypes } from '../../../../../types/user/userTypes';
import { formatHour } from '../../../../../utils/dates/formatHour/formatHour';
import { getCoordinatesStringFromLatLng } from '../../../../../utils/general/getCoordinatesStringFromLatLng/getCoordinatesStringFromLatLng';
import { getGameDate } from '../../../../templates/components/templatesViewList/components/templatesListTableStartGameModal/utils/getGameDate/getGameDate';
import EditGameDatesModal from '../../../components/EditGameDatesModal';
import EditGameDevicesModal from '../../../components/EditGameDevicesModal';
import EditGameQuestionDistanceModal from '../../../components/EditGameQuestionDistanceModal';
import GameContentDownloadBtn from './GameContentDownloadBtn';

interface SingleGameInfoCardProps {
  game: IGame;
  gameUsers: IGameUser[] | undefined;
  relatedUsers: IRelatedUser[];
}

const SingleGameInfoCard = ({ game, gameUsers, relatedUsers }: SingleGameInfoCardProps) => {
  const { t } = useTranslation();
  const { findTranslation } = useFindTranslation();
  const { showSuccessToast } = useToast();
  const queryClient = useQueryClient();

  const [isEditDatesModalOpen, setIsEditDatesModalOpen] = useState(false);
  const [isEditQuestionDistanceModalOpen, setIsEditQuestionDistanceModalOpen] = useState(false);
  const [isEditDevicesModalOpen, setIsEditDevicesModalOpen] = useState(false);
  const [selectedPartnerLogoImg, setSelectedPartnerLogoImg] = useState<File | null>(null);

  const { downloadReport, isLoading: isLoadingReport } = useGameReport();
  const { downloadImages, isLoading: isLoadingImages } = useGameImages();
  const { downloadVideos, isLoading: isLoadingVideos } = useGameVideos();
  const { addGamePartnerLogo, isLoading: isLoadingAddPartnerLogo } = useAddGamePartnerLogo({
    onSuccess: () => {
      showSuccessToast(t('singleActiveGame.info.partnerLogo.successMessage'));
      queryClient.invalidateQueries([QueryAliases.SINGLE_GAME(game.id.toString())]);
    },
  });

  const currentGameUsers = gameUsers?.filter(
    gameUser => gameUser.userGame.user.role === UserTypes.GUEST || gameUser.userGame.user.role === UserTypes.GAMER,
  );
  const currentGameUsersSimplified =
    currentGameUsers?.map(gameUser => ({
      id: gameUser.userGame.user.id,
      teamName: gameUser.userGame.teamName,
    })) ?? [];

  const { updateGame, isLoading } = useUpdateGame({
    onSuccess: () => {
      setIsEditDatesModalOpen(false);
      setIsEditDevicesModalOpen(false);
      setIsEditQuestionDistanceModalOpen(false);
    },
  });

  const isGameFinished = game.status === GameStatuses.FINISHIED;

  const gamePreviewLink =
    !!game.spectatorToken && !isGameFinished
      ? `${window.location.origin}${routes.gamePreview({ gameId: game.id.toString(), token: game.spectatorToken })}`
      : null;

  const handleEditDates = async (data: { date: Date; startHour: Date; endHour: Date }) => {
    const startDate = getGameDate({ dateInput: data.date, hourInput: data.startHour }).toString();
    const endDate = getGameDate({ dateInput: data.date, hourInput: data.endHour }).toString();

    updateGame({
      id: game.id.toString(),
      data: {
        startDate,
        endDate,
      },
    });
  };

  const handleEditUsers = async (data: { users: number[]; allowJoinByCode: boolean }) => {
    updateGame({
      id: game.id.toString(),
      data: {
        users: data.users,
        isAvailableToJoin: data.allowJoinByCode,
      },
    });
  };

  const handleEditQuestionDistance = async (newDistance: number) => {
    updateGame({
      id: game.id.toString(),
      data: {
        questionUnlockDistance: newDistance,
      },
    });
  };

  const handleGetGameReport = () => {
    downloadReport({ gameId: game.id });
  };

  const handleGetGameImages = () => {
    downloadImages({ gameId: game.id });
  };

  const handleGetGameVideos = () => {
    downloadVideos({ gameId: game.id });
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    showSuccessToast(t('singleActiveGame.info.previewLinkCopiedMessage'));
  };

  const handleSubmitNewPartnerLogo = () => {
    if (!!selectedPartnerLogoImg)
      addGamePartnerLogo({ gameId: game.id.toString(), partnerLogo: selectedPartnerLogoImg });
  };

  return (
    <>
      <BorderCard className="flex grow">
        <div className="pl-2 py-2 pr-6 border-r border-r-grey300 w-full max-w-[50%]">
          <h5 className="text-sm uppercase font-bold text-grey500 mb-3">{t('singleActiveGame.info.gameName')}</h5>
          <p className="text-black700 text-sm font-medium">{findTranslation(game.template.name.translations)}</p>
          <h5 className="mt-6 text-sm uppercase font-bold text-grey500 mb-3">
            {t('singleActiveGame.info.gameLocation')}
          </h5>
          <p className="text-black700 text-sm font-medium">{game.template.city}</p>
          <p className="text-black700 text-sm font-medium">
            {getCoordinatesStringFromLatLng(game.template.coordinates)}
          </p>

          <>
            <div className="mb-3 mt-8 flex items-start gap-10">
              <div>
                <h5 className="text-sm uppercase font-bold text-grey500 ">
                  {t('singleActiveGame.info.partnerLogo.title')}
                </h5>
                <SingleImagePicker
                  title={t('singleActiveGame.info.partnerLogo.imagePicker.title')}
                  subtitle={t('singleActiveGame.info.partnerLogo.imagePicker.subtitle')}
                  oldImageUrl={game.partnerLogo}
                  selectedFile={selectedPartnerLogoImg}
                  setSelectedFile={setSelectedPartnerLogoImg}
                />
              </div>

              {!!selectedPartnerLogoImg && (
                <SecondaryButton
                  onClick={handleSubmitNewPartnerLogo}
                  className="w-fit"
                  disabled={!selectedPartnerLogoImg || isLoadingAddPartnerLogo}
                >
                  {t('singleActiveGame.info.partnerLogo.submitBtn')}
                </SecondaryButton>
              )}
            </div>
          </>
        </div>

        <div className="pr-2 py-2 pl-6 w-full ">
          <div className=" flex justify-between gap-5">
            <div>
              <h5 className="text-sm uppercase font-bold text-grey500 mb-3">{t('singleActiveGame.info.gameTime')}</h5>
              <p className="text-black700 text-sm font-medium">{game.startDate.toLocaleDateString()}</p>
              <div className="flex items-center justify-between gap-1">
                <p className="text-grey700 text-sm font-medium">
                  {`${formatHour(game.startDate)}-${formatHour(game.endDate)}`}
                </p>
                <button onClick={() => setIsEditDatesModalOpen(true)}>
                  <EditIcon width="18" height="18" />
                </button>
              </div>
            </div>

            <PrimaryButton onClick={() => setIsEditDevicesModalOpen(true)} className="w-fit h-fit">
              {t('singleActiveGame.info.editDevices')}
            </PrimaryButton>
          </div>
          <div className="max-w-sm">
            <h5 className="text-sm uppercase font-bold text-grey500 mb-3 mt-8">
              {t('singleActiveGame.info.questionDistance')}
            </h5>
            <div className="flex items-center justify-between gap-1">
              <p className="text-grey700 text-sm font-medium">{`${game.questionUnlockDistance} ${t(
                'singleActiveGame.info.questionDistanceUnit',
              )}`}</p>
              <button onClick={() => setIsEditQuestionDistanceModalOpen(true)}>
                <EditIcon width="18" height="18" />
              </button>
            </div>

            {!!gamePreviewLink && (
              <>
                <h5 className="text-sm uppercase font-bold text-grey500 mb-3 mt-8">
                  {t('singleActiveGame.info.previewLinkLabel')}
                </h5>
                <div className="flex items-start justify-between gap-3">
                  <p className="text-grey700 text-sm font-medium overflow-hidden break-words">{gamePreviewLink}</p>

                  <HoverableTooltip tooltipContent={t('singleActiveGame.info.previewLinkIconTooltip')}>
                    {() => (
                      <button onClick={() => handleCopyToClipboard(gamePreviewLink)}>
                        <CopyIcon />
                      </button>
                    )}
                  </HoverableTooltip>
                </div>
              </>
            )}
          </div>

          {isGameFinished && (
            <GameContentDownloadBtn
              isLoading={isLoadingReport}
              icon={<ReportIcon color={colors.black700} />}
              label={t('singleActiveGame.info.downloadReportLabel')}
              onDownloadClick={handleGetGameReport}
            />
          )}

          <GameContentDownloadBtn
            isLoading={isLoadingImages}
            icon={<PhotoIcon width={24} color={colors.black700} />}
            label={t('singleActiveGame.info.downloadImagesLabel')}
            onDownloadClick={handleGetGameImages}
          />

          <GameContentDownloadBtn
            isLoading={isLoadingVideos}
            icon={<VideoCameraIcon width={24} color={colors.black700} />}
            label={t('singleActiveGame.info.downloadVideosLabel')}
            onDownloadClick={handleGetGameVideos}
          />
        </div>
      </BorderCard>

      <EditGameDatesModal
        isOpen={isEditDatesModalOpen}
        setIsOpen={setIsEditDatesModalOpen}
        initStartDate={game.startDate}
        initEndDate={game.endDate}
        onSubmitForm={handleEditDates}
        isLoading={isLoading}
      />

      {!!relatedUsers && !!gameUsers && (
        <EditGameDevicesModal
          initAllowJoinByCode={game.isAvailableToJoin}
          initUsersIds={gameUsers.map(gameUser => gameUser.userGame.user.id)}
          relatedUsers={relatedUsers}
          currentGameUsers={currentGameUsersSimplified}
          isOpen={isEditDevicesModalOpen}
          setIsOpen={setIsEditDevicesModalOpen}
          onSubmitForm={handleEditUsers}
          isLoading={isLoading}
        />
      )}

      <EditGameQuestionDistanceModal
        isOpen={isEditQuestionDistanceModalOpen}
        setIsOpen={setIsEditQuestionDistanceModalOpen}
        initDistance={game.questionUnlockDistance}
        onSubmitForm={handleEditQuestionDistance}
        isLoading={isLoading}
      />
    </>
  );
};

export default SingleGameInfoCard;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/QueryAliases';
import { CallbackDefault } from '../../../types/Types';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';
import { GameTypes } from '../../../types/GlobalTypes';

export interface CreateGameInstanceRequest {
  startDate: string;
  endDate?: string;
  isAvailableToJoin: boolean;
  owner: number;
  templateId: number;
  numberOfPlayers: number;
  userIds?: number[];
}

export interface CreateIndividualPaidGameInstanceRequest {
  templateId: number;
}

const useCreateGameInstance = ({
  onSuccessCb,
  gameType,
}: { onSuccessCb?: CallbackDefault; gameType?: string } = {}) => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  const { showErrorToast, showSuccessToast } = useToast();
  const queryClient = useQueryClient();

  function getUrl(gameType?: string): string {
    if (gameType === GameTypes.INDIVIDUAL_PAID) {
      return api.endpoints.INDIVIDUAL_PAID_GAMES;
    } else {
      return api.endpoints.GAMES;
    }
  }

  const { mutateAsync, isLoading } = useMutation(
    (data: CreateGameInstanceRequest | CreateIndividualPaidGameInstanceRequest) =>
      axiosInstance.post(getUrl(gameType), { ...data }),
    {
      onSuccess: () => {
        !!onSuccessCb && onSuccessCb();
        showSuccessToast(t('successMessages.gameInstanceCreated'));

        queryClient.refetchQueries([QueryAliases.ACTIVE_GAMES]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.createGameInstanceFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { createGameInstance: mutateAsync, isLoading };
};

export default useCreateGameInstance;

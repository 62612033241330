import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from './useToast';

const useSelectFile = () => {
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const selectFile = async ({
    event,
    onSelectFile,
    sizeLimitKb,
    allowedFileTypes,
  }: {
    event: ChangeEvent<HTMLInputElement>;
    onSelectFile: (file: File | null) => void;
    sizeLimitKb: number;
    allowedFileTypes: string[];
  }) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024;

      if (!allowedFileTypes.some(type => type === fileType)) {
        onSelectFile(null);
        showErrorToast(t('errorMessages.fileWrongFormat'));
      } else if (fileSize >= sizeLimitKb) {
        onSelectFile(null);
        showErrorToast(t('errorMessages.fileToBig'));
      } else {
        onSelectFile(file);
      }
    }
  };

  return { selectFile };
};

export default useSelectFile;

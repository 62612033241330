import { TooltipProps } from '@mui/material';
import { useState } from 'react';
import { LightTooltip } from '../../common/tooltips/LightTooltip';

const HoverableTooltip = ({
  tooltipContent,
  children,
  tooltipPlacement = 'top',
}: {
  tooltipContent: React.ReactNode;
  children: (isHovered: boolean) => React.ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const tooltipTitle = <div className="flex items-center gap-2">{tooltipContent}</div>;

  return (
    <LightTooltip title={tooltipTitle} placement={tooltipPlacement} arrow>
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} className="cursor-pointer">
        {children(isHovered)}
      </div>
    </LightTooltip>
  );
};

export default HoverableTooltip;

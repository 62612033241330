import { TypeFromConstObject } from '../Types';

export const UserTypes = {
  GLOBAL_ADMIN: 'global_admin',
  ADMINISTRATION_MANAGER: 'administration_manager',
  PARTNER: 'partner',
  COMPANY_MANAGER: 'company_manager',
  GAMER: 'gamer',
  GUEST: 'guest',
} as const;

export type UserType = TypeFromConstObject<typeof UserTypes>;

import { useSearchParams } from 'react-router-dom';

import { searchParamsData } from '../../static/searchParamsData';
import useDebounce from '../useDebounce';

export const useCommonSearchParams = () => {
  const [searchParams] = useSearchParams();

  const getValueFromSearchParams = (param: string): string => searchParams.get(param) ?? '';

  const searchFilter = getValueFromSearchParams(searchParamsData.searchFilter);
  const pageParam = getValueFromSearchParams(searchParamsData.page);
  const page = !!pageParam && +pageParam > 0 ? +pageParam : 1;

  const tagFilters = !!getValueFromSearchParams(searchParamsData.tagFilter)
    ? getValueFromSearchParams(searchParamsData.tagFilter).split(';')
    : [];

  const cityFilter = getValueFromSearchParams(searchParamsData.cityFilter);
  const ownerFilter = getValueFromSearchParams(searchParamsData.ownerFilter);

  const arcadeFilter = getValueFromSearchParams(searchParamsData.arcadeFilter);

  const gameTypeFilter = getValueFromSearchParams(searchParamsData.gameTypeFilter);

  const { debouncedValue: debouncedSearchValue } = useDebounce({ value: searchFilter, delay: 500 });

  return {
    searchFilter,
    debouncedSearchValue,
    page,
    tagFilters,
    cityFilter,
    ownerFilter,
    arcadeFilter,
    getValueFromSearchParams,
    gameTypeFilter,
  };
};

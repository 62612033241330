import useAsignTemplatesToPartner from '../../../../hooks/api/partner/useAsignTemplatesToPartner';
import { InitialPartnerTemplates } from '../../../../types/ApiPartnerTypes';

const useHandleAddTemplatesToPartner = () => {
  const { asignTemplates, isLoading } = useAsignTemplatesToPartner();

  const handleAddTemplates = async ({
    templates,
    partnerId,
  }: {
    templates: InitialPartnerTemplates[] | undefined;
    partnerId: number;
  }) => {
    const templateIds = templates?.map(template => template.id);

    if (templateIds) {
      const newTemplates = await asignTemplates({ partnerId, templateIds });

      return newTemplates;
    }

    return [];
  };
  return { handleAddTemplates, handleAddTemplatesIsLoading: isLoading };
};

export default useHandleAddTemplatesToPartner;

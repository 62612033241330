import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { InitialPartnerBaseData } from '../../../types/ApiPartnerTypes';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useEditPartner = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useMutation(
    ({ baseData, partnerId }: { baseData: InitialPartnerBaseData; partnerId: number }) =>
      axiosInstance.patch(api.endpoints.SINGLE_USER({ id: partnerId }), baseData),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('partner.toasts.editPartnerError');

        showErrorToast(errorMessage);
      },
    },
  );

  return { editPartner: mutateAsync, isLoading };
};

export default useEditPartner;

import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { searchParamsData } from '../../../../../static/searchParamsData';
import { DropdownOption } from '../../../../../types/Dropdown.types';

const useSetInitialAnswerTeam = ({
  teamsDropdownOptions,
  setSelectedTeam,
}: {
  teamsDropdownOptions: DropdownOption[];
  setSelectedTeam: Dispatch<SetStateAction<DropdownOption | undefined>>;
}) => {
  const [searchParams] = useSearchParams();
  const team = searchParams.get(searchParamsData.team);
  const useEffectHasRun = useRef<boolean>(false);

  const handleSelectTeamFromUrl = useCallback(() => {
    if (!!team) {
      const foundTeam = teamsDropdownOptions.find(option => option.id === team);

      if (!!foundTeam) {
        setSelectedTeam(foundTeam);
        useEffectHasRun.current = true;
      }
    }
  }, [team, teamsDropdownOptions, setSelectedTeam]);

  useEffect(() => {
    if (useEffectHasRun.current) {
      return;
    }

    handleSelectTeamFromUrl();
  }, [handleSelectTeamFromUrl]);
};

export default useSetInitialAnswerTeam;

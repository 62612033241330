import { useNavigate, useSearchParams } from 'react-router-dom';
import MaterialTable from '../../components/dataTable/MaterialTable/MaterialTable';
import useGamers from '../../hooks/api/users/useGamers';
import { routes } from '../../static/routes';
import { searchParamsData } from '../../static/searchParamsData';
import useGamersTableHeaders from './hooks/useGamersTableHeaders';

const pageSize = 20;

const GamersList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pageParam = searchParams.get(searchParamsData.page);
  const page = !!pageParam && +pageParam > 0 ? +pageParam : 1;

  const { gamers, isLoading } = useGamers({ page: page, limit: pageSize });
  const { headers } = useGamersTableHeaders();

  return (
    <div className="pb-36">
      <MaterialTable
        columns={headers}
        rowCount={gamers?.count ?? 0}
        rows={gamers.data ?? []}
        loading={isLoading}
        showPagination
        paginationMode="server"
        paginationModel={{
          page: page - 1,
          pageSize,
        }}
        onPaginationModelChange={e => {
          navigate(routes.allGamers({ page: e.page + 1 }));
        }}
      />
    </div>
  );
};

export default GamersList;

import { CreateSubscriptionRequest } from '../../../types/ApiBundleTypes';
import { InitialPartnerSubscription } from '../../../types/ApiPartnerTypes';
import { getCoordinatesFromString } from '../../../utils/general/getCoordinatesFromString/getCoordinatesFromString';

export const prepareAddSubscriptionRequestData = (subscription: InitialPartnerSubscription, partnerId: number) => {
  const coordinates = getCoordinatesFromString(subscription.coordinates);

  const newSubscription: CreateSubscriptionRequest = {
    validUntil: subscription.validUntil,
    userId: partnerId,
    bundleId: subscription.bundle.id,
    coordinates: {
      latitude: coordinates?.lat as number,
      longitude: coordinates?.lng as number,
    },
  };

  return newSubscription;
};

import { ToastOptions, toast } from 'react-toastify';
import ToastErrorIcon from '../components/icons/ToastErrorIcon';
import ToastSuccessIcon from '../components/icons/ToastSuccessIcon';

const useToast = () => {
  const showSuccessToast = (message: string, options?: ToastOptions) =>
    toast.success(message, {
      icon: <ToastSuccessIcon />,
      ...options,
    });

  const showErrorToast = (message: string, options?: ToastOptions) =>
    toast.error(message, {
      icon: <ToastErrorIcon />,
      ...options,
    });

  return { showSuccessToast, showErrorToast };
};

export default useToast;

import ChangePassword from './ChangePassword';
import UserData from './UserData';

const AccountData = () => {
  return (
    <div className="flex justify-between gap-3 p-8  pb-16 border border-gray-400 rounded-xl">
      <UserData />
      <ChangePassword />
    </div>
  );
};

export default AccountData;

import { Input, InputProps } from './Input';

interface DecoratedInputProps {
  prefix?: React.ReactNode;
  sufix?: React.ReactNode;
  inputProps: InputProps;
}

const DecoratedInput = ({ prefix, sufix, inputProps }: DecoratedInputProps) => {
  return (
    <div className="relative">
      {prefix}
      <Input {...inputProps} />
      {sufix}
    </div>
  );
};

export default DecoratedInput;

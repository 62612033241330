import { ApiBundle, IBundleFormInputs } from '../../types/ApiBundleTypes';
import { prepareBundleLimitsRequest } from './prepareBundleLimitsRequest';

export const prepareAddBundleRequest = (data: IBundleFormInputs) => {
  const limits = data ? prepareBundleLimitsRequest(data) : [];

  const bundle: ApiBundle = { bundleName: data.bundleName, limits };

  return bundle;
};

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiPartner, IPartner } from '../../../types/ApiPartnerTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { UserStatuses } from '../../../types/user/userStatuses';
import { findLocaleByLanguage } from '../../../utils/findLocaleByLanguage/findLocaleByLanguage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import { formatDateString } from './../../../utils/dates/formatDate/formatDateString';

const usePartners = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const axiosInstance = useAxiosInstance();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const locale = findLocaleByLanguage(language);

  const { data, isLoading } = useQuery([QueryAliases.PARTNERS], () => axiosInstance.get(api.endpoints.PARTNERS), {
    refetchOnMount: true,
    enabled,
  });

  const partners: IPartner[] = data?.data?.map((partner: ApiPartner) => {
    const { id, name, email, phone, isActive } = partner;

    const hasSubscription = partner?.subscription;
    const validUntil = partner?.subscription?.validUntil as string;

    const subscription = {
      bundleName: hasSubscription ? partner.subscription?.bundle.bundleName : t('partners.noSubscription'),
      validUntil: hasSubscription ? formatDateString.toOnlyDate(validUntil, locale) : t('partners.noSubscription'),
    };

    let partnerStatus;
    const subscriptionIsValid = new Date(validUntil) > new Date();

    if (!isActive) {
      partnerStatus = UserStatuses.NOT_VERIFIED;
    } else if (!hasSubscription || !subscriptionIsValid) {
      partnerStatus = { value: UserStatuses.INACTIVE.value, status: t(`userStatuses.${UserStatuses.INACTIVE.status}`) };
    } else {
      partnerStatus = { value: UserStatuses.ACTIVE.value, status: t(`userStatuses.${UserStatuses.ACTIVE.status}`) };
    }

    return {
      id,
      name,
      email,
      phone,
      isActive,
      status: partnerStatus,
      bundleName: subscription.bundleName,
      validUntil: subscription.validUntil,
      games: partner.games.length,
    };
  });

  return { partners, isLoading };
};

export default usePartners;

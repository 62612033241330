import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useSendTemplateAds = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const uploadImages = async ({ templateId, ads }: { templateId: string; ads: File[] }) => {
    const formData = new FormData();

    ads.forEach(ad => {
      formData.append('advertisements', ad);
    });

    const response = await axiosInstance.post(api.endpoints.TEMPLATE_ADS({ templateId }), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const { mutateAsync, isLoading } = useMutation(uploadImages, {
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.addTemplateAdsFailed');

      showErrorToast(errorMessage);
    },
  });

  return { sendTemplateAds: mutateAsync, isLoading };
};

export default useSendTemplateAds;

import { useTranslation } from 'react-i18next';
import useFindTranslation from '../../../../../hooks/useFindTranslation';
import { ITemplateStats } from '../../../../../types/TemplateTypes';
import LinearProgressBar from '../../../../charts/linearProgressBar/LinearProgressBar';

interface TemplateStatsModalViewProps {
  templateStatistics: ITemplateStats | undefined;
}

const TemplateStatsModalView = ({ templateStatistics }: TemplateStatsModalViewProps) => {
  const { t } = useTranslation();
  const { findTranslation } = useFindTranslation();

  if (!templateStatistics) {
    return null;
  }

  return (
    <div>
      <h5 className="text-sm uppercase font-bold text-grey500 mb-3">{t('templates.statsModal.gamesCount')}</h5>
      <p className="text-black700 text-sm font-medium">{templateStatistics.gamesFromTemplate}</p>

      <LinearProgressBar
        label={`${t('templates.statsModal.answersCorrect')} / ${t('templates.statsModal.answersIncorrect')}`}
        scored={templateStatistics.correctAnswers}
        total={templateStatistics.answersCount}
        className="mt-8"
      />

      <LinearProgressBar
        label={`${t('templates.statsModal.pointsScoredMax')} / ${t('templates.statsModal.pointsTotal')}`}
        scored={templateStatistics.maxPointsScored}
        total={templateStatistics.maxPointsToGet}
        className="mt-8"
      />

      <h5 className="text-sm uppercase font-bold text-black700 my-8">{t('templates.statsModal.questions.title')}</h5>

      {templateStatistics.questionsStatistics.map(question => (
        <div key={question.id} className="mb-10">
          <h5 className="text-sm uppercase font-bold text-grey500 mb-3">{`${findTranslation(
            question.name,
          )} - ${findTranslation(question.content)}`}</h5>

          <LinearProgressBar
            label={`${t('templates.statsModal.questions.answersCorrect')} / ${t(
              'templates.statsModal.questions.answersTotal',
            )}`}
            scored={question.correctAnswers}
            total={question.answersCount}
            className="mt-4"
          />
          <LinearProgressBar
            label={`${t('templates.statsModal.questions.answersIncorrect')} / ${t(
              'templates.statsModal.questions.answersTotal',
            )}`}
            scored={question.incorrectAnswers}
            total={question.answersCount}
            className="mt-4"
          />
        </div>
      ))}
    </div>
  );
};

export default TemplateStatsModalView;

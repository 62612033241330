import { GridRowId } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import MaterialTable from '../../components/dataTable/MaterialTable/MaterialTable';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useDeleteGame from '../../hooks/api/games/useDeleteGame';
import useGameReport from '../../hooks/api/games/useGameReport';
import useHistoryGames from '../../hooks/api/games/useHistoryGames';
import { elementsIds } from '../../static/elementsIds';
import { QueryAliases } from '../../types/QueryAliases';
import HistoryGamesTableSelectionPanel from './components/HistoryGamesTableSelectionPanel';
import useHistoryGamesTableHeaders from './hooks/useHistoryGamesTableHeaders';

const HistoryGamesView = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { games, isLoading: isLoadingGames } = useHistoryGames();
  const { deleteGame, isLoading: isLoadingDelete } = useDeleteGame({
    onSuccess: () => {
      queryClient.refetchQueries([QueryAliases.HISTORY_GAMES]);
    },
  });
  const { downloadReport } = useGameReport();

  const [selectedGamesIds, setSelectedGamesIds] = useState<number[]>([]);

  const isSingleItemSelected = selectedGamesIds?.length === 1;

  const handleGetReportByGameId = async (id: number) => {
    downloadReport({ gameId: id });
  };

  const handleDeleteSelectedGame = async (ids: GridRowId[]) => {
    ids.forEach(async id => {
      deleteGame(id.toString());
    });
  };

  const gamesToShow = games?.sort((gameA, gameB) => gameB.startDate.getTime() - gameA.startDate.getTime());

  const { headers } = useHistoryGamesTableHeaders({
    onGetReportClick: id => {
      downloadReport({ gameId: id });
    },
  });

  return (
    <TitleWrapper title={t('historyGames.title')}>
      <div id={elementsIds.historyGamesTable} className="flex mb-8 gap-3">
        <MaterialTable
          columns={headers}
          rows={gamesToShow ?? []}
          checkboxSelection
          rowSelectionModel={selectedGamesIds}
          onRowSelectionModelChange={val => {
            setSelectedGamesIds(val as number[]);
          }}
          showPagination
          pageSizeOptions={[20]}
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          loading={isLoadingGames}
        />
      </div>

      {selectedGamesIds.length > 0 && (
        <HistoryGamesTableSelectionPanel
          tableId={elementsIds.historyGamesTable}
          selectedRowsNumber={selectedGamesIds.length}
          hideGetReportBtn={selectedGamesIds.length > 1}
          onGetReportClick={() => {
            if (isSingleItemSelected) {
              handleGetReportByGameId(selectedGamesIds[0]!);
            }
          }}
          onDelete={() => {
            handleDeleteSelectedGame(selectedGamesIds);
          }}
          isLoadingDelete={isLoadingDelete}
        />
      )}
    </TitleWrapper>
  );
};

export default HistoryGamesView;

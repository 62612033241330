import { CallbackDefault } from '../../../../../types/Types';
import XMarkIcon from '../../../../icons/XmarkIcon';

const FilterChip = ({ label, onDelete }: { label: string; onDelete: CallbackDefault }) => {
  return (
    <div className="rounded-md px-3 border-[1.5px] border-red500 flex items-center gap-4 bg-redSelectionBg whitespace-nowrap">
      <p className="text-black700">{label}</p>
      <button onClick={onDelete} className="py-4 px-2">
        <XMarkIcon />
      </button>
    </div>
  );
};

export default FilterChip;

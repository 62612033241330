import { colors } from '../../../styles/variables';

const arrayOfColors = [
  '#7F43E6',
  '#4AC79E',
  '#E65F4A',
  '#78C94E',
  '#E65C91',
  '#5C78E6',
  '#A3E65C',
  '#E6A75C',
  '#5C7BE6',
  '#E64A8F',
  '#4AE6C2',
  '#E6D14A',
  '#5C99E6',
  '#E67B5C',
  '#4A8FE6',
  '#89E65C',
  '#E64F5C',
  '#5C6EE6',
  '#C2E64A',
  '#5C8DE6',
];

export const generateColorById = (id: number): string => {
  const arrayLength = arrayOfColors.length;
  const adjustedIndex = id % arrayLength;
  return arrayOfColors[adjustedIndex] ?? colors.blue700;
};

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/QueryAliases';
import { CallbackDefault } from '../../../types/Types';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

interface EditGameRequest {
  startDate?: string;
  endDate?: string;
  isAvailableToJoin?: boolean;
  users?: number[];
  questionUnlockDistance?: number;
}

const useUpdateGame = ({ onSuccess }: { onSuccess?: CallbackDefault }) => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast, showSuccessToast } = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    ({ id, data }: { id: string; data: EditGameRequest }) =>
      axiosInstance.patch(api.endpoints.SINGLE_GAME(id), { ...data }),
    {
      async onSuccess(res) {
        const id = res.data.id;

        await queryClient.refetchQueries([QueryAliases.SINGLE_GAME(id)]);
        showSuccessToast(t('successMessages.gameUpdated'));

        if (!!onSuccess) {
          onSuccess();
        }
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.editGameFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { updateGame: mutateAsync, isLoading };
};

export default useUpdateGame;

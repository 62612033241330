import { CircularProgress } from '@mui/material';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { ButtonSize } from '../../../types/ButtonSizes';
import { OnClickDefault } from '../../../types/Types';

interface PrimaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: OnClickDefault;
  size?: ButtonSize;
  prefixIcon?: React.ReactNode;
  sufixIcon?: React.ReactNode;
  loading?: boolean;
}

export const PrimaryButton = ({
  children,
  onClick,
  type,
  disabled,
  className,
  prefixIcon,
  sufixIcon,
  size = ButtonSize.Large,
  loading,
}: PrimaryButtonProps) => {
  const getTextStyles = () => {
    switch (size) {
      case ButtonSize.Small:
        return 'text-[.8125rem] font-normal leading-4';
      case ButtonSize.Medium:
        return 'text-sm font-normal leading-[1.0625rem]';
      default:
        return 'text-base font-normal lg:font-semibold leading-[1.125rem]';
    }
  };

  return (
    <button
      type={type ?? 'button'}
      className={twMerge(
        `relative px-3 py-4 border-2 border-red500 rounded-lg text-white900 bg-red500 ${getTextStyles()} hover:bg-red700 hover:border-red700 disabled:bg-black300 disabled:border-black300 active:shadow-activeButton visited:shadow-activeButton w-full`,
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={`flex items-center whitespace-nowrap justify-center gap-2 ${loading ? 'opacity-0' : ''}`}>
        {prefixIcon && prefixIcon}
        {children}
        {sufixIcon && sufixIcon}
      </div>
      {loading && (
        <div className="w-full h-full flex items-center justify-center absolute left-0 top-0">
          <CircularProgress className="p-1" color="inherit" />
        </div>
      )}
    </button>
  );
};

import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiBundle, IBundleFormInputs } from '../../../types/ApiBundleTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useLimitDictionary from '../limitDictionary/useLimitDictionary';

const useBundleById = (bundleId: string | undefined) => {
  const axiosInstance = useAxiosInstance();
  const { convertLimitsForEdit } = useLimitDictionary();

  const { data, isLoading, refetch } = useQuery(
    [QueryAliases.SINGLE_BUNDLE(bundleId ?? '')],
    () => axiosInstance.get(api.endpoints.SINGLE_BUNDLE({ id: bundleId as string })),
    { enabled: !!bundleId },
  );

  const fetchedBundle: ApiBundle | undefined = data?.data;

  const convertedLimits = fetchedBundle ? convertLimitsForEdit(fetchedBundle.limits) : undefined;

  const initialFormBundle: IBundleFormInputs | undefined = convertedLimits &&
    fetchedBundle && {
      bundleId: fetchedBundle.id,
      bundleName: fetchedBundle.bundleName,
      ...convertedLimits,
    };

  return { initialFormBundle, isLoading, refetch };
};

export default useBundleById;

import { ApiAddTemplateRequest, ApiMultilangString, IQuestion } from '../../../../types/ApiTypes';
import { GameTypes, Langs } from '../../../../types/GlobalTypes';
import { findValueByLang } from '../../../../utils/findValueByLang/findValueByLang';
import { getCoordinatesFromString } from '../../../../utils/general/getCoordinatesFromString/getCoordinatesFromString';
import { TemplateFormInputs } from '../types/TemplateFormTypes';

export const prepareTemplateRequestData = ({
  data,
  questions,
  editedNameId,
  editedCoordinatesId,
}: {
  data: TemplateFormInputs;
  questions: IQuestion[];
  editedNameId?: number;
  editedCoordinatesId?: number;
}) => {
  const coordinates = getCoordinatesFromString(data.coordinates);

  const defaultLangs = Object.values(Langs).reduce((acc, lang) => {
    acc[lang] = '';
    return acc;
  }, {} as Record<string, string>);

  const name = Object.values(Langs).reduce(
    (acc: Record<string, string | number>, lang) => {
      acc[lang] =
        findValueByLang({ lang, array: data.name }) ||
        (findValueByLang({ lang: Langs.PL, array: data.name }) as string);
      return acc;
    },
    { ...(!!editedNameId && { id: editedNameId }), ...defaultLangs },
  ) as ApiMultilangString;
  const requestData: ApiAddTemplateRequest = {
    tags:
      data.tags?.map(tag => ({
        tagName: tag.value,
        ...(!tag.isNew && { id: +tag.id }),
      })) ?? [],
    ...(!!name && { name }),
    gameType: data.gameType.code,
    gameCharacter: data.gameCharacter.code,
    ...((data.gameType.code === GameTypes.INDIVIDUAL || data.gameType.code === GameTypes.INDIVIDUAL_PAID) && {
      isActive: data.isActive,
    }),
    city: data.city.trim(),
    averageTime: data.estimatedDuration ?? undefined,
    availableQuestions: questions.map((question, index) => ({
      id: question.id,
      index: index,
      coordinates: {
        latitude: question.coordinates?.lat as number,
        longitude: question.coordinates?.lng as number,
      },
    })),
    coordinates: {
      ...(!!editedCoordinatesId && { id: editedCoordinatesId }),
      latitude: coordinates?.lat as number,
      longitude: coordinates?.lng as number,
    },
  };

  return requestData;
};

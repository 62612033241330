import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../static/api';
import { getFileFromResponse } from '../../../utils/api/getFileFromResponse/getFileFromResponse';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useGameReport = () => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  const { showErrorToast, showSuccessToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async ({ gameId }: { gameId: number }) => {
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(api.endpoints.GAME_REPORT({ gameId }), {
        responseType: 'arraybuffer',
      });

      getFileFromResponse({
        response,
        fileContentType: 'application/pdf',
        fileType: 'pdf',
        fileName: `game_report_${gameId}`,
      });

      showSuccessToast(t('successMessages.reportDownloaded'));
    } catch (error) {
      showErrorToast(t('errorMessages.reportDownloadError'));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    downloadReport: handleDownload,
    isLoading,
  };
};

export default useGameReport;

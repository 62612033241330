import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import { Coordinates } from '../../../types/GlobalTypes';
import { CallbackDefault } from '../../../types/Types';
import MapMarkerGeneralIcon from '../../icons/MapMarkerGeneralIcon';

interface MapMarkerBasicProps {
  coordinates: Coordinates;
  markerColor?: string;
  onClick?: CallbackDefault;
  children?: React.ReactNode;
}

const MapMarkerBasic = ({ coordinates, markerColor, onClick, children }: MapMarkerBasicProps) => {
  return (
    <OverlayViewF position={coordinates} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <div onClick={onClick} className={`relative bottom-[30px] -left-1/2 ${!!onClick ? 'cursor-pointer' : ''}`}>
        <MapMarkerGeneralIcon size={36} color={markerColor} />
        {children}
      </div>
    </OverlayViewF>
  );
};

export default MapMarkerBasic;

import { useState } from 'react';
import usePartners from '../../../../../../hooks/api/users/usePartners';
import useUserDetails from '../../../../../../hooks/api/users/useUserDetails';
import { UserTypes } from '../../../../../../types/user/userTypes';

export function useOwnerFilter() {
  const [ownerFilter, setOwnerFilter] = useState('');
  const { user } = useUserDetails();
  const isGlobalAdmin = user?.role === UserTypes.GLOBAL_ADMIN;
  const { partners } = usePartners({ enabled: isGlobalAdmin });

  const ownersOptions = [
    ...(user ? [{ id: user.id.toString(), email: user.email }] : []),
    ...(partners?.map(partner => ({
      id: partner.id.toString(),
      email: partner.email,
    })) ?? []),
  ];

  const owner = ownersOptions.find(option => option.email === ownerFilter);
  const activeOwnerIds = (owner?.id ? [owner.id] : []) as string[];

  return {
    ownerFilter,
    setOwnerFilter,
    ownersOptions,
    activeOwnerIds,
  };
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Skeleton } from '@mui/material';

import { BreadcrumbsProps } from '../../components/common/breadcrumbs/Breadcrumbs';
import RouteTabSwitcher, {
  TabSwitcherRoute,
} from '../../components/common/tabSwitchers/routeTabSwitcher/RouteTabSwitcher';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useGameReceivedMessages from '../../hooks/api/games/messages/useGameReceivedMessages';
import useGameReviewAnswers from '../../hooks/api/games/useGameReviewAnswers';
import useSingleGame from '../../hooks/api/games/useSingleGame';
import useFindTranslation from '../../hooks/useFindTranslation';
import { routes } from '../../static/routes';
import { ITeamMessage } from '../../types/ApiTypes';
import SingleActiveGameDetailsCard from './components/SingleActiveGameDetailsCard';
import { GameTypes } from '../../types/GlobalTypes';

interface SingleActiveGamePageWrapperProps {
  children: React.ReactNode;
}

const SingleActiveGamePageWrapper = ({ children }: SingleActiveGamePageWrapperProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const gameId = params.id as string;

  const { findTranslation } = useFindTranslation();

  const { numOfAnswersToCheck } = useGameReviewAnswers({ gameId });
  const { messages: received } = useGameReceivedMessages({ gameId });
  const { game } = useSingleGame({
    gameId,
    onErrorCb: () => {
      navigate(routes.activeGames);
    },
  });
  const template = game?.template;

  const countMessagesUnread = (messages: ITeamMessage[] | undefined) => {
    return messages ? messages.filter(msg => !msg.wasRead).length : 0;
  };

  const totalMessagesUnread = countMessagesUnread(received);

  const templateName = !!template?.name ? findTranslation(template?.name.translations) : '';

  const breadcrumbsProps: BreadcrumbsProps = {
    limit: 2,
    extraBreadcrumbs: [{ text: templateName }],
  };

  const individualRoutes = [
    {
      text: t('singleActiveGame.tabs.info'),
      routeTo: routes.singleActiveIndividualGames.info(gameId as string),
    },
    {
      text: t('singleActiveGame.tabs.map'),
      routeTo: routes.singleActiveIndividualGames.map({ id: gameId as string }),
    },
    {
      text: t('singleActiveGame.tabs.questions'),
      routeTo: routes.singleActiveIndividualGames.questions(gameId as string),
    },
    {
      text: t('singleActiveGame.tabs.players'),
      routeTo: routes.singleActiveIndividualGames.players(gameId as string),
    },
    {
      text: t('singleActiveGame.tabs.oneTimeCodes'),
      routeTo: routes.singleActiveIndividualGames.oneTimeCodes(gameId as string),
    },
  ];

  const businessRoutes = [
    {
      text: t('singleActiveGame.tabs.info'),
      routeTo: routes.singleActiveGame.info(gameId as string),
    },
    {
      text: t('singleActiveGame.tabs.map'),
      routeTo: routes.singleActiveGame.map({ id: gameId as string }),
    },
    {
      text: t('singleActiveGame.tabs.questions'),
      routeTo: routes.singleActiveGame.questions(gameId as string),
    },
    {
      text: t('singleActiveGame.tabs.players'),
      routeTo: routes.singleActiveGame.players(gameId as string),
    },
    {
      text: t('singleActiveGame.tabs.messages'),
      routeTo: routes.singleActiveGame.messages({ id: gameId as string }),
      suffixElement:
        totalMessagesUnread > 0 ? (
          <div className="h-5 min-w-[1.25rem] rounded-full bg-red500 flex justify-center items-center">
            <p className="text-white900 text-xs font-semibold">{totalMessagesUnread}</p>
          </div>
        ) : (
          <></>
        ),
    },
    {
      text: t('singleActiveGame.tabs.answers'),
      routeTo: routes.singleActiveGame.answers({ id: gameId as string }),
      suffixElement:
        numOfAnswersToCheck > 0 ? (
          <div className="h-5 min-w-[1.25rem] rounded-full bg-red500 flex justify-center items-center">
            <p className="text-white900 text-xs font-semibold">{numOfAnswersToCheck}</p>
          </div>
        ) : (
          <></>
        ),
    },
  ];

  const tabRoutes: TabSwitcherRoute[] =
    game?.template.gameType === GameTypes.BUSINESS ? businessRoutes : individualRoutes;

  return (
    <TitleWrapper
      title={templateName}
      breadcrumbsProps={breadcrumbsProps}
      sideComponent={
        !!game && !!template ? (
          <SingleActiveGameDetailsCard
            status={game.status}
            gameCharacter={template.gameCharacter}
            city={template.city}
            code={game.code}
          />
        ) : (
          <Skeleton variant="rounded" width={500} height={80} />
        )
      }
    >
      <RouteTabSwitcher routes={tabRoutes} />
      {children}
    </TitleWrapper>
  );
};

export default SingleActiveGamePageWrapper;

import { colors } from '../../../styles/variables';
import { mapStyles } from './mapStyles';

export const defaultMapContainerStyle = {
  width: '100%',
  height: '90vh',
  border: `1.5px solid ${colors.grey500}`,
  borderRadius: '6px',
};

export const defaultMapCenter = {
  lat: 51.77345724658496,
  lng: 19.45656957464774,
};

export const defaultMapZoom = 13;

export function getDefaultMapOptions(): google.maps.MapOptions | undefined {
  if (typeof google === 'undefined') {
    return undefined;
  }

  return {
    styles: mapStyles,
    zoomControl: false,
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.INLINE_END_BLOCK_CENTER,
    },
    mapTypeControl: false,
    fullscreenControl: false,
    clickableIcons: false,
  };
}

export const streetViewOptions = {
  enableCloseButton: true,
  fullscreenControl: false,
  zoomControl: false,
  panControl: false,
};

import { useTranslation } from 'react-i18next';
import { useLogout } from '../../../../hooks/useLogout';
import { routes } from '../../../../static/routes';
import { colors } from '../../../../styles/variables';
import LogoutIcon from '../../../icons/LogoutIcon';
import NavButton from '../NavButton';

const NavButtonLogout = () => {
  const { t } = useTranslation();

  const { handleLogout } = useLogout();

  return (
    <NavButton
      href={routes.login}
      text={t('navigation.logout')}
      icons={{
        default: <LogoutIcon color={colors.grey700} />,
        active: <LogoutIcon color={colors.white900} />,
      }}
      buttonProps={{
        onClick: handleLogout,
      }}
    />
  );
};

export default NavButtonLogout;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormChange } from '../../../../../context/FormChangeProvider';

export function useNavigationGuard(nextLocation: string) {
  const { hasUnsavedChanges, setHasUnsavedChanges } = useFormChange();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  function handleConfirmNavigation() {
    setIsModalOpen(false);
    setHasUnsavedChanges(false);
    navigate(nextLocation);
  }

  function handleNavigation() {
    if (hasUnsavedChanges) {
      setIsModalOpen(true);
    } else {
      navigate(nextLocation);
    }
  }

  return {
    isModalOpen,
    setIsModalOpen,
    handleConfirmNavigation,
    handleNavigation,
    hasUnsavedChanges,
  };
}

import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectionPanelCard from '../../../../../../../components/cards/selectionPanelCard/SelectionPanelCard';
import { PrimaryButton } from '../../../../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../../../../components/common/buttons/SecondaryButton';
import EditQuestionModal from '../../../../../../../components/common/modals/editQuestionModal/EditQuestionModal';
import DragIcon from '../../../../../../../components/icons/DragIcon';
import EditIcon from '../../../../../../../components/icons/EditIcon';
import useFindTranslation from '../../../../../../../hooks/useFindTranslation';
import { colors } from '../../../../../../../styles/variables';
import { IQuestion } from '../../../../../../../types/ApiTypes';
import { Coordinates } from '../../../../../../../types/GlobalTypes';
import { CallbackDefault } from '../../../../../../../types/Types';

interface ActiveGameQuestionsSelectionPanelProps {
  question: IQuestion;
  canDragQuestion: boolean;
  setCanDragQuestion: Dispatch<SetStateAction<boolean>>;
  currentPosition: Coordinates;
  onCancel: CallbackDefault;
  onApply: CallbackDefault;
  onQuestionEditSuccess?: CallbackDefault;
}

const ActiveGameQuestionsSelectionPanel = ({
  question,
  canDragQuestion,
  setCanDragQuestion,
  currentPosition,
  onCancel,
  onApply,
  onQuestionEditSuccess,
}: ActiveGameQuestionsSelectionPanelProps) => {
  const { t } = useTranslation();
  const { findTranslation } = useFindTranslation();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <>
      <SelectionPanelCard className="z-10 absolute bottom-3 left-1/2 -translate-x-1/2">
        {canDragQuestion ? (
          <>
            <div className="pr-6 border-r border-grey400">
              <p className="text-black700 font-bold text-sm whitespace-nowrap">{'Pozycja Lat., Lng.'}</p>
            </div>

            <p className="text-black400 font-semibold text-sm whitespace-nowrap ml-10">{`${currentPosition.lat.toFixed(
              6,
            )}, ${currentPosition.lng.toFixed(6)}`}</p>

            <SecondaryButton
              onClick={e => {
                e.stopPropagation();
                onCancel();
              }}
              className="text-red700 font-semibold text-sm flex items-center gap-1.5 ml-7 whitespace-nowrap"
            >
              {t('singleActiveGame.map.questions.selectionPanel.cancel')}
            </SecondaryButton>

            <PrimaryButton
              onClick={e => {
                e.stopPropagation();
                onApply();
              }}
              className="text-white900 font-semibold text-sm flex items-center gap-1.5 ml-4 whitespace-nowrap"
            >
              {t('singleActiveGame.map.questions.selectionPanel.apply')}
            </PrimaryButton>
          </>
        ) : (
          <>
            <div className="pr-6 border-r border-grey400">
              <p className="text-black700 font-bold text-sm whitespace-nowrap">{findTranslation(question.name)}</p>
            </div>

            <button
              onClick={e => {
                e.stopPropagation();
                setIsEditModalOpen(true);
              }}
              className="text-red700 font-semibold text-sm flex items-center gap-1.5 ml-10 whitespace-nowrap"
            >
              <EditIcon color={colors.red700} width="16" height="16" />
              {t('singleActiveGame.map.questions.selectionPanel.edit')}
            </button>

            <button
              onClick={e => {
                e.stopPropagation();
                setCanDragQuestion(true);
              }}
              className="text-red700 font-semibold text-sm flex items-center gap-1.5 ml-10 whitespace-nowrap"
            >
              <DragIcon color={colors.red700} size={16} />
              {t('singleActiveGame.map.questions.selectionPanel.changePosition')}
            </button>
          </>
        )}
      </SelectionPanelCard>

      <EditQuestionModal
        questionId={question.id}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        onSucceess={onQuestionEditSuccess}
      />
    </>
  );
};

export default ActiveGameQuestionsSelectionPanel;

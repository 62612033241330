interface LinearProgressBarProps {
  label: string;
  scored: number;
  total: number;
  className?: string;
}

const LinearProgressBar = ({ label, scored, total, className }: LinearProgressBarProps) => {
  const percentage = total === 0 ? 0 : (scored / total) * 100;

  return (
    <div className={`${className ?? ''}`}>
      <h3 className="text-black300 text-sm font-medium mb-5">{label}</h3>
      <div className="w-full bg-redButtonBg overflow-hidden h-2 rounded-md">
        <div className="bg-red500 h-full rounded-md" style={{ width: `${percentage}%` }} />
      </div>
      <div className="flex justify-between gap-8 mt-2.5">
        <p className="font-medium text-sm text-red500">{scored}</p>
        <p className="font-medium text-sm text-grey500">{total}</p>
      </div>
    </div>
  );
};

export default LinearProgressBar;

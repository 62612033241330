import { useTranslation } from 'react-i18next';
import { IQuestion } from '../../../../types/ApiTypes';
import { DropdownOption } from '../../../../types/Dropdown.types';
import { Langs, QuestionType } from '../../../../types/GlobalTypes';
import { getCoordinatesStringFromLatLng } from '../../../../utils/general/getCoordinatesStringFromLatLng/getCoordinatesStringFromLatLng';
import { answersDefault, multilangStringDefault } from '../static/QuestionFormDefaults';

const findQuestionTypeDropdownOption = ({ type, options }: { type: QuestionType; options: DropdownOption[] }) =>
  options.find(option => option.id === type);

const useDefaultQuestionValues = () => {
  const { t } = useTranslation();

  const getDefaultQuestionValues = ({
    question,
    questionTypeOptions,
  }: {
    question?: IQuestion;
    questionTypeOptions: DropdownOption[];
  }) => {
    const obj = {
      tags: question?.tags.map(tag => ({
        id: tag.id.toString(),
        value: tag.tagName,
      })),
      name: question?.name ?? multilangStringDefault,
      questionType: !!question
        ? findQuestionTypeDropdownOption({ type: question.questionType.code, options: questionTypeOptions })
        : questionTypeOptions[0],
      content: question?.content ?? multilangStringDefault,
      correctAnswers: question?.correctAnswers ?? answersDefault,
      incorrectAnswers: question?.incorrectAnswers ?? answersDefault,
      points: question?.points ?? undefined,
      multiplePointsOptions: !!question?.pointsOptions
        ? question.pointsOptions.map(option => ({
            id: option.toString(),
            value: option.toString(),
          }))
        : [],
      city: question?.city ?? '',
      coordinates: getCoordinatesStringFromLatLng(question?.coordinates),
      correctAnswerText: question?.correctAnswerText ?? [
        { lang: Langs.PL, value: t('questionForm.answerMessages.defaultCorrectAnswerText') },
        { lang: Langs.EN, value: t('questionForm.answerMessages.defaultCorrectAnswerText') },
        { lang: Langs.UA, value: t('questionForm.answerMessages.defaultCorrectAnswerText') },
      ],
      incorrectAnswerText: question?.incorrectAnswerText ?? [
        { lang: Langs.PL, value: t('questionForm.answerMessages.defaultIncorrectAnswerText') },
        { lang: Langs.EN, value: t('questionForm.answerMessages.defaultIncorrectAnswerText') },
        { lang: Langs.UA, value: t('questionForm.answerMessages.defaultIncorrectAnswerText') },
      ],
    };

    return obj;
  };

  return { getDefaultQuestionValues };
};

export default useDefaultQuestionValues;

import { twMerge } from 'tailwind-merge';

const TableCell = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <p
    className={twMerge(
      `text-black700 text-sm font-medium text-ellipsis py-6 pr-4 break-words overflow-auto ${className}`,
    )}
  >
    {children}
  </p>
);

export default TableCell;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../static/api';
import { routes } from '../../../static/routes';
import { ApiResetPasswordBody } from '../../../types/ApiTypes';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

interface ResetPasswordData extends ApiResetPasswordBody {
  token: string;
}

const useResetPassword = ({ onSuccessMessage }: { onSuccessMessage: string }) => {
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    ({ newPassword, confirmationPassword, token }: ResetPasswordData) =>
      axiosInstance.post(api.endpoints.RESET_PASSWORD({ token }), { newPassword, confirmationPassword }),
    {
      onSuccess: async () => {
        navigate(routes.login);
        showSuccessToast(onSuccessMessage);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.resetPasswordFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { resetPassword: mutate, isLoading };
};

export default useResetPassword;

import LangIcon from '../icons/LangIcon';
import LanguageSwitcher from './components/LanguageSwitcher';
import { Logo } from './components/Logo';

interface NavbarProps {
  buttonsTop: React.ReactNode;
  buttonsBottom: React.JSX.Element;
}

export const Navbar = ({ buttonsTop, buttonsBottom }: NavbarProps) => {
  return (
    <nav className="bg-white900 h-screen pb-12 px-7 z-10 flex flex-col justify-between border-r-2 border-grey200 overflow-y-scroll no-scrollbar">
      <div className="flex flex-col gap-2.5">
        <div className="px-10 pt-[3.75rem] pb-8 cursor-pointer">
          <Logo width={118} />
        </div>

        {buttonsTop}
      </div>

      <div>
        <div className="mb-20 flex flex-col gap-2.5">{buttonsBottom}</div>
        <LanguageSwitcher optionsExpandDirection="top" prefixIcon={<LangIcon />} />
      </div>
    </nav>
  );
};

import AccountData from './components/AccountData';
import UserAvatarImageHandler from './components/UserAvatarImageHandler';

const PersonalDataView = () => {
  return (
    <div className="p-8 bg-white flex flex-col gap-12">
      <UserAvatarImageHandler />
      <AccountData />
    </div>
  );
};

export default PersonalDataView;

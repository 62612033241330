import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiQuestion, IGameAnswer } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { transformQuestion } from '../../../utils/api/dataTransformers/transformQuestion/transformQuestion';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface ApiUserGame {
  id: number;
  teamName: string | null;
  teamImage: string | null;
  firstQuestionId: number | null;
  points: number;
  route: {
    createdAt: string;
    latitude: string;
    longitude: string;
  }[];
}

interface ApiGameAnswer {
  id: number;
  answerValue: string;
  isChecked: boolean;
  isCorrect: boolean | null;
  question: ApiQuestion;
  userGame: ApiUserGame;
  gainedPoints: number | null;
}

const useGameReviewAnswers = ({ gameId }: { gameId: string }) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.GAME_REVIEW_ANSWERS(gameId)],
    () => axiosInstance.get(api.endpoints.GAME_REVIEW_ANSWERS({ gameId })),
    {
      refetchOnMount: true,
    },
  );

  const apiAnswers: ApiGameAnswer[] | undefined = data?.data;

  const iAnswers: IGameAnswer[] | undefined = !!apiAnswers
    ? apiAnswers.map(apiAnswer => {
        const iAnswer: IGameAnswer = {
          id: apiAnswer.id,
          answerValue: apiAnswer.answerValue,
          isChecked: apiAnswer.isChecked,
          isCorrect: apiAnswer.isCorrect,
          question: transformQuestion(apiAnswer.question),
          userGame: {
            id: apiAnswer.userGame.id,
            teamName: apiAnswer.userGame.teamName,
            teamImage: apiAnswer.userGame.teamImage,
            firstQuestionId: apiAnswer.userGame.firstQuestionId,
            points: apiAnswer.userGame.points,
          },
          gainedPoints: apiAnswer.gainedPoints,
        };

        return iAnswer;
      })
    : [];

  const numOfAnswersToCheck = iAnswers.filter(answer => !answer.isChecked).length;

  return {
    gameReviewAnswers: iAnswers,
    numOfAnswersToCheck,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useGameReviewAnswers;

import { useTranslation } from 'react-i18next';
import { ITeamMessageGroup } from '../../../../../types/ApiTypes';
import { CallbackDefault } from '../../../../../types/Types';
import { getTimeDifference } from '../../../../../utils/dates/getRemainingTime/getRemainingTime';
import useMessagePassedTime from '../hooks/useMessagePassedTime';

interface SingleMessageTileProps {
  messageGroup: ITeamMessageGroup;
  onClick: CallbackDefault;
  isSelected: boolean;
  isLastOne?: boolean;
}

const SingleMessageTile = ({ messageGroup, onClick, isSelected, isLastOne }: SingleMessageTileProps) => {
  const { t } = useTranslation();

  const latestMessage = messageGroup.messages[messageGroup.messages.length - 1];
  const isSomeUnreadMessage = messageGroup.messages
    .filter(message => message.isReceived)
    .some(message => !message.wasRead);

  const { days, hours, minutes } = getTimeDifference({
    startDate: latestMessage?.createdAt ?? new Date(),
    endDate: new Date(),
  });

  const isMessageFresh = days < 1 && hours < 1 && minutes < 1;

  const { getTimeInfo } = useMessagePassedTime({ message: latestMessage });

  return (
    <div
      className={`relative p-4 flex justify-between cursor-pointer ${isSelected ? 'bg-redButtonBg' : ''} ${
        isLastOne ? '' : 'border-b border-b-grey300'
      }`}
      onClick={onClick}
    >
      {isSelected && <div className="absolute bottom-0 top-0 left-0 w-1 bg-red500" />}
      <div>
        <p className="text-black300 text-sm font-medium text-ellipsis line-clamp-1">{messageGroup.team.name}</p>
        <p
          className={`mt-1.5 text-sm font-medium text-ellipsis line-clamp-1 ${
            isSomeUnreadMessage ? 'text-black700' : 'text-grey500'
          }`}
        >
          {latestMessage?.message}
        </p>
      </div>

      <div>
        <p className={`font-normal text-xs ${isMessageFresh ? 'text-red700' : 'text-grey700'}`}>
          • {isMessageFresh ? t('singleActiveGame.messages.now') : getTimeInfo()}
        </p>
      </div>
    </div>
  );
};

export default SingleMessageTile;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TypeToConfirmModal from '../../../components/common/modals/TypeToConfirmModal';
import TableSelectionPanel, {
  TableSelectionPanelProps,
} from '../../../components/dataTable/components/TableSelectionPanel';
import ReportIcon from '../../../components/icons/ReportIcon';
import TrashIcon from '../../../components/icons/TrashIcon';
import { colors } from '../../../styles/variables';
import { CallbackDefault } from '../../../types/Types';

interface HistoryGamesTableSelectionPanelProps extends Omit<TableSelectionPanelProps, 'children'> {
  hideGetReportBtn?: boolean;
  onGetReportClick: CallbackDefault;
  onDelete: CallbackDefault;
  isLoadingDelete?: boolean;
}

const HistoryGamesTableSelectionPanel = (props: HistoryGamesTableSelectionPanelProps) => {
  const { hideGetReportBtn, onGetReportClick, onDelete, isLoadingDelete } = props;

  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteGame = () => {
    onDelete();
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <TableSelectionPanel {...props}>
        {!hideGetReportBtn && (
          <button onClick={onGetReportClick} className="text-red700 font-semibold text-sm flex items-center gap-1.5">
            <ReportIcon color={colors.red700} width={16} />
            {t('historyGames.table.downloadReport')}
          </button>
        )}

        <button
          onClick={() => setIsDeleteModalOpen(true)}
          className="text-red700 font-semibold text-sm flex items-center gap-1.5"
        >
          <TrashIcon color={colors.red700} width={16} />
          {t('historyGames.table.selectionPanel.delete')}
        </button>
      </TableSelectionPanel>

      <TypeToConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        textToType={t('historyGames.table.delete.textToType')}
        title={t('historyGames.table.selectionPanel.deleteModal.title')}
        confirmBtnText={t('historyGames.table.selectionPanel.deleteModal.confirmBtnText')}
        confirmBtnAction={handleDeleteGame}
        cancelBtnText={t('historyGames.table.selectionPanel.deleteModal.cancelBtnText')}
        isConfirmBtnDisabled={isLoadingDelete}
        isConfirmBtnLoading={isLoadingDelete}
      />
    </>
  );
};

export default HistoryGamesTableSelectionPanel;

type FormatDateStringTypes = {
  toOnlyDate: (string: string | null | undefined, locale: string) => string;
  toOnlyTime: (string: string | null | undefined, locale: string) => string;
};
export const formatDateString: FormatDateStringTypes = {
  toOnlyDate: (dateString: string | null | undefined, locale: string) =>
    dateString ? new Date(dateString).toLocaleDateString(locale) : '',
  toOnlyTime: (dateString: string | null | undefined, locale: string) =>
    dateString ? new Date(dateString).toLocaleTimeString(locale) : '',
};

import { type Libraries, useJsApiLoader } from '@react-google-maps/api';

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';
const libraries: Libraries = ['drawing'];

export function useLoadMap() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  return isLoaded;
}

import { useGoogleMap } from '@react-google-maps/api';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { colors } from '../../../../../../styles/variables';
import { IQuestion } from '../../../../../../types/ApiTypes';
import { Coordinates } from '../../../../../../types/GlobalTypes';
import { QueryAliases } from '../../../../../../types/QueryAliases';
import { CallbackDefault } from '../../../../../../types/Types';
import CheckIcon from '../../../../../icons/CheckIcon';
import DragIcon from '../../../../../icons/DragIcon';
import PlusIcon from '../../../../../icons/PlusIcon';
import XMarkIcon from '../../../../../icons/XmarkIcon';
import MapMarkerQuestionType from '../../../../../map/components/MapMarkerQuestionType';

interface TemplateFormMapQuestionMarkerProps {
  question: IQuestion;
  mapHeight: number;
  isPreSelected: boolean;
  isSelected: boolean;
  onRemoveFromPreSelectedQuestions: CallbackDefault;
  onAddToPreSelectedQuestions: CallbackDefault;
  onRemoveFromSelectedQuestions: CallbackDefault;
  setIsMapDragDisabled: Dispatch<SetStateAction<boolean>>;
  onUpdateQuestionCoordinates: (newCoordinates: Coordinates) => void;
}

const TemplateFormMapQuestionMarker = ({
  question,
  mapHeight,
  isPreSelected,
  isSelected,
  onRemoveFromPreSelectedQuestions,
  onAddToPreSelectedQuestions,
  onRemoveFromSelectedQuestions,
  setIsMapDragDisabled,
  onUpdateQuestionCoordinates,
}: TemplateFormMapQuestionMarkerProps) => {
  const queryClient = useQueryClient();

  const map = useGoogleMap();

  const handleMouseMove = useCallback(
    (e: google.maps.MapMouseEvent, questionId: string) => {
      const latLng = e.latLng;

      if (!latLng || question.id.toString() !== questionId) {
        return;
      }

      const newCoordinates: Coordinates = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      };

      onUpdateQuestionCoordinates(newCoordinates);
    },
    [question.id, onUpdateQuestionCoordinates],
  );

  const handleMouseDown = useCallback(
    (e: google.maps.MapMouseEvent) => {
      const domEvent = e.domEvent as MouseEvent;
      const targetElement = domEvent.target as HTMLElement;

      const closestWithQuestionId = targetElement.closest('[data-question-id]');
      const questionId = closestWithQuestionId?.getAttribute('data-question-id');

      let mouseMoveListener: google.maps.MapsEventListener | null = null;
      let mouseUpListener: google.maps.MapsEventListener | null = null;

      const handleMouseUp = () => {
        if (mouseMoveListener) {
          mouseMoveListener.remove();
        }
        if (mouseUpListener) {
          mouseUpListener.remove();
        }

        setIsMapDragDisabled(false);
      };

      if (map && !!questionId) {
        setIsMapDragDisabled(true);

        mouseMoveListener = map.addListener('mousemove', (e: google.maps.MapMouseEvent) =>
          handleMouseMove(e, questionId),
        );
        mouseUpListener = map.addListener('mouseup', handleMouseUp);
      }
    },
    [map, handleMouseMove, setIsMapDragDisabled],
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    const mouseDownListener = map.addListener('mousedown', handleMouseDown);

    return () => mouseDownListener.remove();
  }, [map, handleMouseDown]);

  const handleQuestionEditSuccess = () => {
    queryClient.refetchQueries([QueryAliases.QUESTIONS]);
    queryClient.invalidateQueries([QueryAliases.SINGLE_QUESTION(question.id.toString())]);
  };

  return (
    <MapMarkerQuestionType
      key={question.id}
      question={question}
      mapHeight={mapHeight}
      isPreSelected={isPreSelected}
      isSelected={isSelected}
      onQuestionEditSuccess={handleQuestionEditSuccess}
    >
      {({ isExpanded }) => (
        <>
          {isSelected && (
            <div
              className="flex justify-center items-center bg-grey100 rounded-full border border-grey300 w-6 h-6 absolute -bottom-2 -left-1"
              data-question-id={question.id}
            >
              <DragIcon />
            </div>
          )}
          {isSelected && (
            <div className="flex justify-center items-center bg-success rounded-full border border-grey300 w-5 h-5 absolute -bottom-1 -right-1">
              <CheckIcon />
            </div>
          )}
          {isPreSelected && (
            <button
              onClick={onRemoveFromPreSelectedQuestions}
              className="flex justify-center items-center bg-grey100 rounded-full border border-grey300 w-5 h-5 absolute -top-1 -right-1"
            >
              <XMarkIcon size="8" color={colors.black700} />
            </button>
          )}
          {isExpanded && !isPreSelected && (
            <button
              onClick={onAddToPreSelectedQuestions}
              className="flex justify-center items-center bg-grey100 rounded-full border border-grey300 w-5 h-5 absolute -top-1 -right-1"
            >
              <PlusIcon color={colors.black700} />
            </button>
          )}
          {isSelected && (
            <button
              onClick={onRemoveFromSelectedQuestions}
              className="flex justify-center items-center bg-grey100 rounded-full border border-grey300 w-5 h-5 absolute -top-1 -right-1"
            >
              <XMarkIcon size="8" color={colors.black700} />
            </button>
          )}
        </>
      )}
    </MapMarkerQuestionType>
  );
};

export default TemplateFormMapQuestionMarker;

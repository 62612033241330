import { ApiBundleLimit, IBundleLimit, IConvertedLimits } from '../../types/ApiBundleTypes';

export const prepareBundleLimitsRequest = (formLimits: IConvertedLimits) => {
  const limits = Object.values(formLimits).map((limit: IBundleLimit) => {
    if (Array.isArray(limit?.limitValue) && limit?.limitValue?.length === 0) {
      return;
    }

    if (!limit?.limitValue) {
      return;
    }

    let limitValue;

    if (Array.isArray(limit.limitValue)) {
      limitValue = limit.limitValue.map(obj => obj.id);
    } else {
      limitValue = limit.limitValue;
    }

    const limitData = {
      limitDictionary: { id: limit.limitDictionaryId },
      limitValue,
    };

    if (limit.limitId) {
      return { id: limit.limitId, ...limitData };
    }

    return limitData;
  });
  const filteredLimits = limits?.filter(limit => limit);
  return filteredLimits as ApiBundleLimit[];
};

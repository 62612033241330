import { IPointOption, IQuestion } from '../../../types/ApiTypes';

export const mapColorFromPoints = (
  question: IQuestion,
  pointsOptions: IPointOption[] | undefined,
  pointsFallbackColor: string,
) => {
  let markerColor = '';
  if (question.points) {
    markerColor =
      pointsOptions?.find(option => option.points === question.points?.toString())?.color ?? pointsFallbackColor;
  } else if (!question.points && question.pointsOptions?.length === 1) {
    markerColor =
      pointsOptions?.find(option => option.points === question.pointsOptions?.at(0)?.toString())?.color ??
      pointsFallbackColor;
  } else {
    markerColor = pointsFallbackColor;
  }
  return markerColor;
};

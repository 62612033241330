import MaterialTable from '../../../../components/dataTable/MaterialTable/MaterialTable';
import useRelatedUsers from '../../../../hooks/api/users/useRelatedUsers';
import usePlayersTableHeaders from '../hooks/usePlayersTableHeaders';

const PlayersList = () => {
  const { relatedUsers, isLoading } = useRelatedUsers();
  const { headers } = usePlayersTableHeaders();

  return <div>{relatedUsers && <MaterialTable columns={headers} rows={relatedUsers} loading={isLoading} />}</div>;
};

export default PlayersList;

import { useTranslation } from 'react-i18next';
import { ISingleSubscriptionDetails } from '../../../../types/ApiBundleTypes';
import SubscriptionData from './SubscriptionData';

interface UserSubscriptionDataProps {
  subscription: ISingleSubscriptionDetails | undefined;
}

const UserSubscriptionData = ({ subscription }: UserSubscriptionDataProps) => {
  const { t } = useTranslation();

  const gameCharacters = subscription?.gameCharacters ? <SubscriptionData limit={subscription.gameCharacters} /> : null;
  const questionTypes = subscription?.questionTypes ? <SubscriptionData limit={subscription.questionTypes} /> : null;
  const spotRadius = subscription?.spotRadius ? <SubscriptionData limit={subscription.spotRadius} /> : null;
  const gamesPerMonth = subscription?.gamesPerMonth ? <SubscriptionData limit={subscription.gamesPerMonth} /> : null;
  const activeGames = subscription?.activeGames ? <SubscriptionData limit={subscription.activeGames} /> : null;
  const gamersPerGame = subscription?.gamersPerGame ? <SubscriptionData limit={subscription.gamersPerGame} /> : null;
  const validUntil = subscription?.validUntil.value ? <SubscriptionData otherData={subscription.validUntil} /> : null;

  return (
    <div className="flex flex-col gap-6 pb-6">
      {subscription ? (
        <>
          {gameCharacters}
          {questionTypes}
          {spotRadius}
          {gamesPerMonth}
          {activeGames}
          {gamersPerGame}
          {validUntil}
        </>
      ) : (
        <p className="text-center py-20">{t('errorMessages.noData')}</p>
      )}
    </div>
  );
};

export default UserSubscriptionData;

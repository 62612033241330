import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HoverableTooltip from '../../../components/dataTable/components/HoverableTooltip';
import EditIcon from '../../../components/icons/EditIcon';
import { routes } from '../../../static/routes';
import { colors } from '../../../styles/variables';

const EditPartnerButton = ({ id }: { id: number }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <HoverableTooltip tooltipContent={<p>{t('partners.editHoverText')}</p>}>
      {isHovered => (
        <button
          className="p-3"
          onClick={e => {
            e.stopPropagation();
            navigate(routes.editPartner(id));
          }}
        >
          <EditIcon color={isHovered ? colors.red500 : colors.grey700} />
        </button>
      )}
    </HoverableTooltip>
  );
};

export default EditPartnerButton;

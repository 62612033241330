import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { ApiAddTemplateRequest } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useCreateTemplate = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data: ApiAddTemplateRequest) => axiosInstance.post(api.endpoints.TEMPLATES, { ...data }),
    {
      async onSuccess() {
        await queryClient.refetchQueries([QueryAliases.TEMPLATES]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.addTemplateFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { createTemplate: mutateAsync, isLoading };
};

export default useCreateTemplate;

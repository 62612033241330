import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useTags from '../../../../hooks/api/useTags';
import useDebounce from '../../../../hooks/useDebounce';
import AutocompleteMultiDropdown from '../../../common/dropdowns/AutocompleteMultiDropdown';
import FormSectionTitle from '../../../common/formSectionTitle/FormSectionTitle';
import { LabeledComponent } from '../../../common/inputs/LabeledComponent';
import { QuestionFormInputs } from '../types/QuestionFormTypes';

const queryMinLength = 3;

const QuestionFormTags = () => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const { debouncedValue } = useDebounce({ value: searchValue, delay: 300 });

  const { tagsDropdownOptions } = useTags({
    searchValue: debouncedValue,
    queryMinLength,
  });

  const { control } = useFormContext<QuestionFormInputs>();

  return (
    <>
      <FormSectionTitle className="mb-6" title={t('questionForm.tags.title')} />
      <div className="max-w-lg">
        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <LabeledComponent label={t('questionForm.tags.tagsLabel')}>
              <AutocompleteMultiDropdown
                query={searchValue}
                setQuery={setSearchValue}
                allOptions={tagsDropdownOptions ?? []}
                setSelectedOptions={field.onChange}
                selectedOptions={field.value ?? []}
                queryMinLength={queryMinLength}
                placeholder={t('questionForm.tags.tagsPlaceholder')}
                allowAddNewOption
              />
            </LabeledComponent>
          )}
        />
      </div>
    </>
  );
};

export default QuestionFormTags;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { reletedUserConstEmail } from '../../../static/relatedUserConstEmail';
import { QueryAliases } from '../../../types/QueryAliases';
import { UserTypes } from '../../../types/user/userTypes';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';
import useUserDetails from '../users/useUserDetails';

type ApiRegisterRelatedUser = {
  login: string;
  newPassword: string;
  confirmationPassword: string;
};

const useRegisterPlayer = (closeModal: () => void) => {
  const axiosInstance = useAxiosInstance();
  const { showSuccessToast, showErrorToast } = useToast();
  const { user } = useUserDetails();

  const { t } = useTranslation();

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    ({ login, newPassword, confirmationPassword }: ApiRegisterRelatedUser) =>
      axiosInstance.post(api.endpoints.REGISTER_RELATED_USER, {
        email: `${login}${reletedUserConstEmail}`,
        password: newPassword,
        confirmationPassword,
        role: UserTypes.GAMER,
        parentUser: user?.id,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [QueryAliases.RELATED_USERS] });
        showSuccessToast(t('account.players.playerAdded'));
        closeModal();
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.resetPasswordFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { registerPlayer: mutate, isLoading, isSuccess, isError };
};

export default useRegisterPlayer;

import MonthBarChart from '../../../../components/charts/monthBarChart/MonthBarChart';
import BorderCard from '../../../../components/common/cards/BorderCard';
import Dropdown from '../../../../components/common/dropdowns/Dropdown';
import withLoading from '../../../../hoc/withLoading';
import { IMonthStats } from '../../../../types/GameTypes';
import { generateYearsArray } from '../../../../utils/general/generateYearsArray/generateYearsArray';

const yearsOptions = generateYearsArray({ startYear: 2010, endYear: new Date().getFullYear() });

const MonthBarChartWithLoading = withLoading(MonthBarChart);

interface DashboardStatsChartProps {
  title: string;
  currentYear: number;
  onChangeCurrentYear: (newYear: number) => void;
  chartData: IMonthStats[];
  isChartDataLoading: boolean;
  totalInfoLabel: string;
  chartDataTotalCount: number | undefined;
}

const DashboardStatsChart = ({
  title,
  currentYear,
  onChangeCurrentYear,
  chartData,
  isChartDataLoading,
  totalInfoLabel,
  chartDataTotalCount,
}: DashboardStatsChartProps) => {
  return (
    <BorderCard className="w-full">
      <h5 className="text-sm uppercase font-bold text-grey500 mb-3">{title}</h5>

      <div className="w-fit my-8">
        <Dropdown
          options={yearsOptions}
          currentOption={{ id: currentYear.toString(), value: currentYear.toString() }}
          setNewOption={option => onChangeCurrentYear(+option.value)}
          wrapperClassName="outline-1"
        />
      </div>

      <div className="h-[30rem]">
        <MonthBarChartWithLoading chartData={chartData} isLoading={isChartDataLoading} />
      </div>

      <p className="mt-6 text-center text-grey700">
        {chartDataTotalCount !== undefined ? `${totalInfoLabel} ${currentYear}: ${chartDataTotalCount}` : '\u00a0'}
      </p>
    </BorderCard>
  );
};

export default DashboardStatsChart;

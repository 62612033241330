import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../components/navigation/components/Logo';
import useLogin from '../../hooks/api/auth/useLogin';
import useUserDetails from '../../hooks/api/users/useUserDetails';
import { routes } from '../../static/routes';
import { ApiLoginRequest } from '../../types/ApiTypes';
import LoginForm from './components/LoginForm';

const LoginView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { login, isLoading } = useLogin();
  const { refetch: refetchUser } = useUserDetails({ enabled: false });

  const handleLogin = async (data: ApiLoginRequest) => {
    await login(data);
    await refetchUser();

    navigate(routes.home);
  };

  return (
    <div className="px-8 py-8 mx-auto flex flex-col max-w-xl h-screen justify-center">
      <Logo />
      <h1 className="mt-12 text-black700">{t('login.title')}</h1>

      <p className="mt-4 text-black500 font-medium leading-snug">{t('login.description')}</p>
      <LoginForm onFormSubmit={handleLogin} isLoading={isLoading} />
    </div>
  );
};

export default LoginView;

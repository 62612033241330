import styled from '@emotion/styled';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { colors } from '../../../styles/variables';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className, arrow: className }} title={<>{props.title}</>} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.grey700,
    color: colors.white900,
    fontSize: '0.875rem',
    padding: '1.375rem 0.75rem',
    borderRadius: '0.5rem',
    display: 'flex',
    gap: '1rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.grey700,
  },
}));

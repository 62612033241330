import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGamersStatistics from '../../../hooks/api/games/statistics/useGamersStatistics';
import DashboardStatsChart from './common/DashboardStatsChart';

interface DashboardStatsGamersProps {
  ownerFilter: string;
  gameTypeFilter: string;
}

const DashboardStatsGamers = ({ ownerFilter, gameTypeFilter }: DashboardStatsGamersProps) => {
  const { t } = useTranslation();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const { gamersStatistics, isLoading } = useGamersStatistics({
    params: { email: ownerFilter, gameType: gameTypeFilter, year: currentYear },
  });

  const chartData = gamersStatistics?.countByMonth;

  return (
    <DashboardStatsChart
      title={t('dashboard.gamers.title')}
      currentYear={currentYear}
      onChangeCurrentYear={setCurrentYear}
      chartData={chartData ?? []}
      isChartDataLoading={isLoading}
      totalInfoLabel={t('dashboard.gamers.totalInfo')}
      chartDataTotalCount={gamersStatistics?.totalCount}
    />
  );
};

export default DashboardStatsGamers;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { Modal } from '../../../../../components/common/modals/Modal';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import TableCell from '../../../../../components/dataTable/components/TableCell';
import LocationIcon from '../../../../../components/icons/LocationIcon';
import MessageIcon from '../../../../../components/icons/MessageIcon';
import { colors } from '../../../../../styles/variables';
import { IGameUser } from '../../../../../types/ApiTypes';
import { Coordinates } from '../../../../../types/GlobalTypes';
import { UserTypes } from '../../../../../types/user/userTypes';
import SingleActiveGamePlayersTableDeleteBtn from '../components/SingleActiveGamePlayersTableDeleteBtn';

const useActiveGamePlayersHeaders = ({
  onMessageClick,
  onLocationClick,
  onDeleteClick,
  isLoadingDelete,
}: {
  onMessageClick: ({ userId }: { userId: number }) => void;
  onLocationClick: ({ coordinates }: { coordinates: Coordinates }) => void;
  onDeleteClick: ({ userId }: { userId: number }) => void;
  isLoadingDelete?: boolean;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamImage, setTeamImage] = useState<string | null>(null);

  const handleImageOnClick = (teamImage: string | null) => {
    setTeamImage(teamImage);
    setIsModalOpen(true);
  };

  const headers: GridColDef[] = [
    {
      field: 'teamName',
      headerName: t('singleActiveGame.players.table.teamName'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IGameUser>) => {
        return (
          <div className="flex gap-4 items-center">
            {!!params.row.userGame.teamImage && (
              <>
                <img
                  src={params.row.userGame.teamImage}
                  alt="team image"
                  className="w-16 h-16 min-w-[4rem] rounded-full cursor-pointer"
                  onClick={() => handleImageOnClick(params.row.userGame.teamImage)}
                />
                <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} contentClassName="w-[30rem]">
                  {!!teamImage && <img src={teamImage} alt="team image" className="w-100 max-w-[30rem]" />}
                </Modal>
              </>
            )}
            <TableCell>{params.row.userGame.teamName}</TableCell>
          </div>
        );
      },
    },
    {
      field: 'email',
      headerName: t('singleActiveGame.players.table.email'),
      minWidth: 180,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IGameUser>) => {
        const isGuest = params.row.userGame.user.role === UserTypes.GUEST;

        return (
          <TableCell className="overflow-hidden break-words">
            {isGuest ? t('singleActiveGame.players.table.guest') : params.row.userGame.user?.email}
          </TableCell>
        );
      },
    },
    {
      field: 'location',
      headerName: t('singleActiveGame.players.table.location.label'),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGameUser>) => {
        return (
          <div>
            {!!params.row.userGame.currentCoordinates ? (
              <>
                <TableCell className="whitespace-nowrap py-0">
                  <span className="text-black400">{t('singleActiveGame.players.table.location.lat')} </span>
                  {params.row.userGame.currentCoordinates?.lat},
                </TableCell>

                <TableCell className="whitespace-nowrap py-0">
                  <span className="text-black400">{t('singleActiveGame.players.table.location.lng')} </span>
                  {params.row.userGame.currentCoordinates?.lng}
                </TableCell>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      field: 'points',
      headerName: t('singleActiveGame.players.table.points'),
      minWidth: 40,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IGameUser>) => {
        return (
          <TableCell className="whitespace-nowrap">{`${params.row.userGame.points} ${t(
            'singleActiveGame.players.table.points',
          )}`}</TableCell>
        );
      },
    },
    {
      field: 'answersNumber',
      headerName: t('singleActiveGame.players.table.answersNumber'),
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGameUser>) => {
        return <TableCell>{`${params.row.userAnswers}/${params.row.totalAnswers}`}</TableCell>;
      },
    },

    {
      field: 'buttons',
      headerName: '',
      maxWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGameUser>) => {
        const coordinates = params.row.userGame.currentCoordinates;

        return (
          <div className="flex gap-2 justify-end justify-self-end">
            <HoverableTooltip tooltipContent={<p>{t('singleActiveGame.players.table.buttons.message')}</p>}>
              {isHovered => (
                <button
                  className="p-3"
                  onClick={e => {
                    e.preventDefault();
                    onMessageClick({ userId: params.row.userGame.user.id });
                  }}
                >
                  <MessageIcon color={isHovered ? colors.red500 : colors.grey700} width={24} height={24} />
                </button>
              )}
            </HoverableTooltip>

            <HoverableTooltip
              tooltipContent={
                <p>
                  {!!coordinates
                    ? t('singleActiveGame.players.table.buttons.showLocation')
                    : t('singleActiveGame.players.table.buttons.locationUnavailable')}
                </p>
              }
            >
              {isHovered => (
                <button
                  disabled={!coordinates}
                  className="p-3"
                  onClick={e => {
                    e.preventDefault();

                    if (!!coordinates) {
                      onLocationClick({ coordinates });
                    }
                  }}
                >
                  <LocationIcon color={isHovered ? colors.red500 : colors.grey700} width={24} height={24} />
                </button>
              )}
            </HoverableTooltip>

            <SingleActiveGamePlayersTableDeleteBtn
              userId={params.row.userGame.user.id}
              onDelete={onDeleteClick}
              isLoadingDelete={isLoadingDelete}
            />
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useActiveGamePlayersHeaders;

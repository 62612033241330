import { ITeamMessage, ITeamMessageGroup } from '../../../../../types/ApiTypes';

export const getAllMessagesGroups = ({
  sentMessages,
  receivedMessages,
}: {
  sentMessages?: ITeamMessage[];
  receivedMessages?: ITeamMessage[];
}) => {
  if (!sentMessages || !receivedMessages) {
    return [];
  }

  const allMessages = [...sentMessages, ...receivedMessages];

  const groupedMessages = allMessages.reduce((groups: ITeamMessageGroup[], message: ITeamMessage) => {
    const existingGroup = groups.find(group => group.team.id === message.team.id);

    if (!existingGroup) {
      const newGroup: ITeamMessageGroup = {
        team: {
          id: message.team.id,
          email: message.team.email,
          name: message.team.name,
        },
        messages: [message],
      };

      groups.push(newGroup);
    } else {
      existingGroup.messages.push(message);
    }

    return groups;
  }, []);

  groupedMessages.forEach(group => {
    group.messages.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
  });

  return groupedMessages;
};

import { useTranslation } from 'react-i18next';
import { IGameUser } from '../../../../../types/ApiTypes';
import { ALL_TEAMS_ID } from '../SingleActiveGameAnswersView';

const useTeamsDropdownOptions = ({ gameUsers }: { gameUsers: IGameUser[] | undefined }) => {
  const { t } = useTranslation();

  const allTeamsDropdownOption = {
    id: ALL_TEAMS_ID,
    value: t('singleActiveGame.answers.dropdownAllTeams'),
  };

  const gameUsersDropdownOptions = [
    allTeamsDropdownOption,
    ...(gameUsers?.map(gameUser => ({
      id: gameUser.userGame.user.id.toString(),
      value: gameUser.userGame.teamName ?? t('singleActiveGame.answers.noTeamName'),
    })) ?? []),
  ];

  return { allTeamsDropdownOption, gameUsersDropdownOptions };
};

export default useTeamsDropdownOptions;

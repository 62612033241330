import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TypeToConfirmModal from '../../../../../components/common/modals/TypeToConfirmModal';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import TrashIcon from '../../../../../components/icons/TrashIcon';
import useDeleteQuestion from '../../../../../hooks/api/questions/useDeleteQuestion';
import { colors } from '../../../../../styles/variables';

interface QuestionListTableDeleteBtnProps {
  questionId: string;
}

const QuestionListTableDeleteBtn = ({ questionId }: QuestionListTableDeleteBtnProps) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { deleteQuestion, isLoading } = useDeleteQuestion();

  const handleDeleteQuestion = async () => {
    await deleteQuestion(questionId);
  };

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('questions.table.buttons.edit')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <TrashIcon color={isHovered ? colors.red500 : colors.grey700} width={24} height={24} />
          </button>
        )}
      </HoverableTooltip>

      <TypeToConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={t('questions.table.delete.title')}
        textToType={t('questions.table.delete.textToType')}
        confirmBtnText={t('questions.table.delete.confirmBtnText')}
        confirmBtnAction={handleDeleteQuestion}
        cancelBtnText={t('questions.table.delete.cancelBtnText')}
        isConfirmBtnDisabled={isLoading}
        isConfirmBtnLoading={isLoading}
      />
    </>
  );
};

export default QuestionListTableDeleteBtn;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../../components/common/modals/ConfirmModal';
import TrashIcon from '../../../../components/icons/TrashIcon';
import useDeletePlayer from '../../../../hooks/api/players/useDeletePlayer';

interface PlayersListDeleteButtonProps {
  playerId: string;
  playerLogin: string;
}

const PlayersListDeleteButton = ({ playerId, playerLogin }: PlayersListDeleteButtonProps) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { deletePlayer, isLoading } = useDeletePlayer();

  const handleDeletePlayer = async () => {
    await deletePlayer(playerId);
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        className="p-3"
        onClick={e => {
          e.stopPropagation();
          setIsModalOpen(true);
        }}
      >
        <TrashIcon />
      </button>

      <ConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={t('account.players.delete.title')}
        confirmQuestion={`${t('account.players.delete.confirmQuestion')} ${playerLogin}?`}
        confirmBtnText={t('account.players.delete.confirmBtnText')}
        confirmBtnAction={handleDeletePlayer}
        cancelBtnText={t('account.players.delete.cancelBtnText')}
        isConfirmBtnDisabled={isLoading}
        isConfirmBtnLoading={isLoading}
      />
    </>
  );
};

export default PlayersListDeleteButton;

export const breakpoints = {
  smallMobile: '640px',
  mobile: '768px',
  tablet: '1024px',
  desktop: '1280px',
  large: '1536px',
};

export const breakpointsNum = {
  smallMobile: 640,
  mobile: 768,
  tablet: 1024,
  desktop: 1280,
};

export const colors = {
  error: '#E31925',
  errorBg: '#FEF0F1',

  success: '#23AC29',
  successBg: '#E2EFDA',

  grey700: '#797979',
  grey500: '#B4B4B4',
  grey400: '#D2D2D2',
  grey300: '#E1E1E1',

  black700: '#1C1D18',
  black500: '#464646',
  black300: '#7E7E7E',

  blue700: '#22467A',
  blue500: '#3874CB',

  red700: '#931717',
  red500: '#B02B17',
  red400: '#B94E3E',
  redButtonBg: '#FCF3F3',

  orange900: '#E26212',

  white900: '#FFFFFF',
  white700: '#FDFDFD',

  purple900: '#614F96',

  question3Points: '#1739B0',
  question5Points: '#1AB017',
  question10Points: '#B02B17',
  question50Points: '#8817B0',

  gameStatusScheduled: '#1767B0',
};

import { Circle } from '@react-google-maps/api';
import { useMemo } from 'react';
import Map from '../../../components/map/Map';
import useSubscriptionDetails from '../../../hooks/subscriptions/useSubscriptionDetails';
import { colors } from '../../../styles/variables';
import UserSubscriptionData from './components/UserSubscriptionData';

const SubscriptionView = () => {
  const { subscription } = useSubscriptionDetails();

  const mapStyles = { width: '100%', height: `360px` };

  const userRadiusKm = !!subscription?.spotRadius.limitValue ? +subscription?.spotRadius.limitValue * 1000 : undefined;
  const userCenter = subscription?.coordinates;

  const bounds = useMemo(() => {
    if (!(!!userCenter && userRadiusKm)) {
      return;
    }

    const circleBounds = new window.google.maps.Circle({
      center: userCenter,
      radius: userRadiusKm,
    }).getBounds();

    return circleBounds;
  }, [userRadiusKm, userCenter]);

  return (
    <div className="py-6 md:py-11">
      <div className="p-8 border rounded-xl flex gap-3 justify-between">
        <UserSubscriptionData subscription={subscription} />
        <div className="w-full grid max-w-[40%]">
          <Map containerStyle={mapStyles} bounds={bounds} initialZoom={!!subscription ? undefined : 5}>
            <Circle
              center={
                userCenter
                  ? {
                      lat: userCenter.lat,
                      lng: userCenter.lng,
                    }
                  : undefined
              }
              radius={userRadiusKm}
              options={{
                fillColor: colors.red400,
                fillOpacity: 0.3,
                radius: userRadiusKm,
                strokeWeight: 1,
                strokeColor: colors.red700,
                strokeOpacity: 0.8,
              }}
            />
          </Map>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionView;

import { useState } from 'react';

import EditQuestionModal from '../../../../../components/common/modals/editQuestionModal/EditQuestionModal';
import EditIcon from '../../../../../components/icons/EditIcon';
import MapMarkerSnippetCard from '../../../../../components/map/components/MapMarkerSnippetCard';
import useFindTranslation from '../../../../../hooks/useFindTranslation';
import { IQuestion } from '../../../../../types/ApiTypes';

interface QuestionSnippetProps {
  color: string;
  question: IQuestion;
  onQuestionEditSuccess?: (id: number) => void;
  disableQuestionEdit?: boolean;
}

const QuestionSnippet = ({ color, question, onQuestionEditSuccess, disableQuestionEdit }: QuestionSnippetProps) => {
  const { findTranslation } = useFindTranslation();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <>
      <MapMarkerSnippetCard>
        <div className="flex justify-between gap-2">
          <h6 style={{ color }} className="text-[0.8125] font-semibold leading-4">
            {findTranslation(question.name)}
          </h6>
          <h6 style={{ color }} className="text-[0.8125] font-semibold leading-4">
            {question.points && `${question.points}pkt`}
          </h6>
        </div>

        <p className="mt-3.5 text-center text-black500 text-sm font-medium overflow-hidden">
          {findTranslation(question.content!!)}
        </p>

        {!disableQuestionEdit && (
          <button
            onClick={() => {
              if (disableQuestionEdit) {
                return;
              }
              setIsEditModalOpen(true);
            }}
            className="absolute p-2 right-0 bottom-0"
          >
            <EditIcon width="18" height="18" />
          </button>
        )}
      </MapMarkerSnippetCard>

      <EditQuestionModal
        questionId={question.id}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        onSucceess={onQuestionEditSuccess}
      />
    </>
  );
};

export default QuestionSnippet;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { InitialPartnerBaseData } from '../../../types/ApiPartnerTypes';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useAddPartner = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { isLoading, mutateAsync } = useMutation(
    (data: InitialPartnerBaseData) => axiosInstance.post(api.endpoints.REGISTER_PARTNER, data),
    {
      onError(error: AxiosError) {
        const errorMessage = getApiErrorMessage(error) ?? t('partner.toasts.addPartnerError');
        showErrorToast(errorMessage);
      },
    },
  );

  return {
    isLoading,
    addPartner: mutateAsync,
  };
};

export default useAddPartner;

import { ComponentState } from '../../../types/ComponentStates.types';
import { LabeledComponent, LabeledComponentProperties, LabeledComponentProps } from './LabeledComponent';
import Textarea, { TextareaProps } from './Textarea';

interface LabeledProps extends Omit<LabeledComponentProps, 'children'> {
  errorMessage?: string | undefined;
}

interface LabeledStateTextareaProps {
  id: string;
  state: ComponentState;

  labeledProps: LabeledProps;
  textareaProps: TextareaProps;
}

export const LabeledStateTextarea = ({ id, labeledProps, textareaProps, state }: LabeledStateTextareaProps) => {
  const { errorMessage, stateProperties: labeledStateProperties } = labeledProps;

  const myStateProperties: LabeledComponentProperties = {
    error: {
      message: errorMessage,
      color: 'text-error',
    },
    default: {},
    ...labeledStateProperties,
  };

  const labeledComponentProps = {
    id,
    state,
    stateProperties: myStateProperties,
    ...labeledProps,
  };

  return (
    <LabeledComponent {...labeledComponentProps}>
      <Textarea {...textareaProps} />
    </LabeledComponent>
  );
};

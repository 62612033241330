import { Navigate, useParams } from 'react-router-dom';
import { routes } from '../../static/routes';

const NavigateToSingleActiveGame = () => {
  const params = useParams();
  const gameId = params.id;

  return <Navigate to={!!gameId ? routes.singleActiveGame.info(gameId) : routes.home} />;
};

export default NavigateToSingleActiveGame;

import { useTranslation } from 'react-i18next';

import { Outlet } from 'react-router-dom';
import RouteTabSwitcher from '../../components/common/tabSwitchers/routeTabSwitcher/RouteTabSwitcher';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import { routes } from '../../static/routes';

const AccountView = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  const { t } = useTranslation();

  const accountTabRoutes = [
    { text: t('navigation.personal-data'), routeTo: routes.accountPersonalData },
    { text: t('navigation.players'), routeTo: routes.accountPlayers },
  ];

  if (!isAdmin) {
    accountTabRoutes.push({ text: t('navigation.subscriptions'), routeTo: routes.accountSubscriptions });
  }

  return (
    <TitleWrapper title={t('account.title')}>
      <RouteTabSwitcher routes={accountTabRoutes} />
      <Outlet />
    </TitleWrapper>
  );
};

export default AccountView;

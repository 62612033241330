import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiDictionary, DictionaryDropdownOption } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useTemplateGameTypes = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery([QueryAliases.TEMPLATES_GAME_TYPES], () =>
    axiosInstance.get(api.endpoints.TEMPLATES_GAME_TYPES),
  );

  const apiGameTypes: ApiDictionary[] | undefined = data?.data;

  const iGameTypes: DictionaryDropdownOption[] | undefined = !!apiGameTypes
    ? apiGameTypes.map(type => ({
        id: type.id.toString(),
        code: type.code,
        value: t(`gameTypes.${type.code}`),
      }))
    : undefined;

  return { gameTypes: iGameTypes, refetch, isSuccess, isLoading, isError };
};

export default useTemplateGameTypes;

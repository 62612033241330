import { ComponentState } from '../../../types/ComponentStates.types';
import { LabeledComponent, LabeledComponentProperties, LabeledComponentProps } from '../inputs/LabeledComponent';
import MultiDropdown, { MultiDropdownProps } from './MultiDropdown';

interface LabeledProps extends Omit<LabeledComponentProps, 'children'> {
  errorMessage?: string | undefined;
}

interface LabeledStateMultiDropdownProps {
  id: string;
  state: ComponentState;

  labeledProps: LabeledProps;
  dropdownProps: MultiDropdownProps;
}

export const LabeledStateMultiDropdown = ({
  id,
  labeledProps,
  dropdownProps,
  state,
}: LabeledStateMultiDropdownProps) => {
  const { errorMessage, stateProperties: labeledStateProperties } = labeledProps;

  const myStateProperties: LabeledComponentProperties = {
    error: {
      message: errorMessage,
      color: 'text-error',
    },
    default: {},
    ...labeledStateProperties,
  };

  const labeledComponentProps = {
    id,
    state,
    stateProperties: myStateProperties,
    ...labeledProps,
  };

  return (
    <LabeledComponent {...labeledComponentProps}>
      <MultiDropdown {...dropdownProps} />
    </LabeledComponent>
  );
};

import { useTranslation } from 'react-i18next';
import BundleForm from '../../components/forms/bundleForm/BundleForm';
import PlusIcon from '../../components/icons/PlusIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import useAddBundle from '../../hooks/api/bundle/useAddBundle';
import { IBundleFormInputs } from '../../types/ApiBundleTypes';
import { prepareAddBundleRequest } from '../../utils/bundle/prepareAddBundleRequest';
import usePrepareInitialBundle from './hooks/usePreparInitialBundle';

const BundleFormWithLoading = withLoading(BundleForm);

const AddBundleView = () => {
  const { t } = useTranslation();
  const { addBundle } = useAddBundle();
  const { initialFormBundle } = usePrepareInitialBundle();

  const handleSubmitBundleForm = async (data: IBundleFormInputs) => {
    const request = prepareAddBundleRequest(data);

    await addBundle(request);
  };

  return (
    <TitleWrapper title={t('bundle.addBundle.title')}>
      <BundleFormWithLoading
        submitForm={handleSubmitBundleForm}
        submitBtnIcon={<PlusIcon />}
        submitBtnText={t('bundle.addBundle.addBundleButton')}
        initialBundle={initialFormBundle}
        isLoading={!initialFormBundle}
      />
    </TitleWrapper>
  );
};

export default AddBundleView;

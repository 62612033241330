import { colors } from '../../styles/variables';

interface ToastSuccessIconProps {
  color?: string;
}

const ToastSuccessIcon = ({ color }: ToastSuccessIconProps) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6666 7.89052V8.50386C14.6658 9.94147 14.2003 11.3403 13.3395 12.4917C12.4787 13.6432 11.2688 14.4855 9.89016 14.8931C8.51154 15.3007 7.03809 15.2518 5.68957 14.7536C4.34104 14.2554 3.18969 13.3346 2.40723 12.1286C1.62476 10.9225 1.25311 9.4959 1.3477 8.0614C1.44229 6.6269 1.99806 5.26141 2.93211 4.16857C3.86615 3.07574 5.12844 2.31411 6.53071 1.99729C7.93298 1.68046 9.4001 1.82541 10.7133 2.41052M14.6666 3.16671L7.99992 9.84004L5.99992 7.84004"
        stroke={color ?? colors.success}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ToastSuccessIcon;

import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { colors } from '../../../../../styles/variables';
import CheckIcon from '../../../../icons/CheckIcon';
import { Input } from '../../../inputs/Input';
import { DropdownGroup } from '../FilterDropdown';

interface FilterDropdownGroupProps {
  optionGroup: DropdownGroup;
}

const FilterDropdownGroup = ({ optionGroup }: FilterDropdownGroupProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const filteredOptions = optionGroup.options.filter(option =>
    option.value.trim().toLocaleLowerCase().includes(inputValue.trim().toLocaleLowerCase()),
  );

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.stopPropagation();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <div
      className="relative flex justify-between items-center gap-2 p-4 hover:bg-redSelectionBg cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <p>{optionGroup.label}</p>
      <ChevronRightIcon color={colors.black700} width={16} />

      {isHovered && (
        <div className="absolute left-full top-0 rounded-md bg-white900 border-[1.5px] border-grey500 min-w-[16rem] max-h-96 overflow-y-scroll">
          <div className="px-2 py-4">
            <Input className="py-2" value={inputValue} onKeyDown={handleInputKeyDown} onChange={handleInputChange} />
          </div>
          {filteredOptions.map(option => {
            const isActive = optionGroup.activeOptionIds.some(id => id === option.id);

            return (
              <div
                key={option.id}
                className="hover:bg-redSelectionBg p-4 cursor-pointer flex items-center justify-between gap-3"
                onClick={() => {
                  if (isActive) {
                    optionGroup.onSetNewOption(null);
                    return;
                  }
                  optionGroup.onSetNewOption(option);
                }}
              >
                <p className="whitespace-nowrap">{option.value}</p>

                {isActive && <CheckIcon color={colors.red500} width={24} height={24} />}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FilterDropdownGroup;

import withLoading from '../../../../hoc/withLoading';
import useTemplateStatistics from '../../../../hooks/api/templates/useTemplateStatistics';
import useFindTranslation from '../../../../hooks/useFindTranslation';
import { ITranslation } from '../../../../types/ApiTypes';
import XMarkIcon from '../../../icons/XmarkIcon';
import { Modal, ModalProps } from '../Modal';
import TemplateStatsModalView from './components/TemplateStatsModalView';

const TemplateStatsModalViewWithLoading = withLoading(TemplateStatsModalView);

interface TemplateStatsModalProps extends Omit<ModalProps, 'children'> {
  templateId: string;
  templateName: ITranslation[];
}

const TemplateStatsModal = ({ isOpen, setIsOpen, templateId, templateName }: TemplateStatsModalProps) => {
  const { findTranslation } = useFindTranslation();

  const { templateStatistics, isLoading } = useTemplateStatistics({ id: templateId, enabled: isOpen });

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} contentClassName="w-[50rem] min-w-[50rem]">
      <div className="flex flex-col justify-center max-h-fit h-auto">
        <h2 className="p-8 border-b-[1.5px] border-gray-400 text-center text-xl font-medium relative">
          <button className="absolute top-1/2 -translate-y-1/2 left-8 cursor-pointer" onClick={() => setIsOpen(false)}>
            <XMarkIcon size="18" />
          </button>

          {findTranslation(templateName)}
        </h2>
        <div className="px-8 py-8 flex flex-col justify-start">
          <TemplateStatsModalViewWithLoading isLoading={isLoading} templateStatistics={templateStatistics} />
        </div>
      </div>
    </Modal>
  );
};

export default TemplateStatsModal;

import { useTranslation } from 'react-i18next';
import { gameStatusesData } from '../../../static/games/gameStatusesData';
import { GameStatus } from '../../../types/GlobalTypes';

interface GameStatusTextProps {
  status: GameStatus;
  className?: string;
}

const GameStatusText = ({ status, className = '' }: GameStatusTextProps) => {
  const { t } = useTranslation();

  return <p className={`${gameStatusesData[status]?.tailwindText} ${className}`}>{t(`gameStatuses.${status}`)}</p>;
};

export default GameStatusText;

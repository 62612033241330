import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TableCell from '../../../../../components/dataTable/components/TableCell';
import { IGameCode } from '../../../../../types/ApiTypes';
import { colors } from '../../../../../styles/variables';

const useGameCodesHeaders = () => {
  const { t } = useTranslation();
  const translationPrefix = 'singleActiveGame.codes.table.';
  const headers: GridColDef[] = [
    {
      field: 'code',
      headerName: t('singleActiveGame.codes.table.code'),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGameCode>) => {
        const color = params.row.active ? colors.success : colors.red700;
        return (
          <TableCell>
            <div style={{ color: color }}>{params.row.code}</div>
          </TableCell>
        );
      },
    },
    {
      field: 'active',
      headerName: t('singleActiveGame.codes.table.active'),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGameCode>) => {
        const color = params.row.active ? colors.success : colors.red700;
        return (
          <TableCell>
            <div style={{ color: color }}>{t(translationPrefix + params.row.active.toString())}</div>
          </TableCell>
        );
      },
    },
  ];

  return {
    headers,
  };
};

export default useGameCodesHeaders;

import { colors } from '../../styles/variables';

interface CalendarIconProps {
  color?: string;
  height?: number;
  width?: number;
}

const CalendarIcon = ({ color, width, height }: CalendarIconProps) => {
  return (
    <svg
      width={width ?? '17'}
      height={height ?? '16'}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2 6.66536H2.19995M10.8666 1.33203V3.9987M5.53328 1.33203V3.9987M5.39995 14.6654H11C12.1201 14.6654 12.6801 14.6654 13.1079 14.4474C13.4843 14.2556 13.7902 13.9497 13.982 13.5733C14.2 13.1455 14.2 12.5855 14.2 11.4654V5.86536C14.2 4.74526 14.2 4.18521 13.982 3.75738C13.7902 3.38106 13.4843 3.0751 13.1079 2.88335C12.6801 2.66536 12.1201 2.66536 11 2.66536H5.39995C4.27985 2.66536 3.71979 2.66536 3.29197 2.88335C2.91565 3.0751 2.60968 3.38106 2.41794 3.75738C2.19995 4.18521 2.19995 4.74526 2.19995 5.86536V11.4654C2.19995 12.5855 2.19995 13.1455 2.41794 13.5733C2.60968 13.9497 2.91565 14.2556 3.29197 14.4474C3.71979 14.6654 4.27985 14.6654 5.39995 14.6654Z"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;

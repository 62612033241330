import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../static/api';
import { getFileFromResponse } from '../../../utils/api/getFileFromResponse/getFileFromResponse';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useGameImages = () => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  const { showErrorToast, showSuccessToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async ({ gameId }: { gameId: number }) => {
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(api.endpoints.GAME_IMAGES({ gameId }), {
        responseType: 'arraybuffer',
      });

      getFileFromResponse({
        response,
        fileContentType: 'application/zip',
        fileType: 'zip',
        fileName: `game_images_${gameId}`,
      });

      showSuccessToast(t('successMessages.imagesDownloaded'));
    } catch (error) {
      showErrorToast(t('errorMessages.imagesDownloadError'));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    downloadImages: handleDownload,
    isLoading,
  };
};

export default useGameImages;

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { bundleLimitTypes } from '../../../static/bundleLimitTypes';
import { ApiBundleLimit, IConvertedLimits, LimitValuesWithTranslation } from '../../../types/ApiBundleTypes';
import { ApiLimitDictionary, ILimitDictionary } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { convertArrayOfObjectsToObjectByGivenKey } from '../../../utils/convertArrayOfObjectsToObjectByGivenKey/convertArrayOfObjectsToObjectByGivenKey';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useLimitDictionary = () => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();

  const limitsDictionaryTranslations = {
    SPOT_RADIUS: {
      name: 'spotRadius',
      title: t('bundleLimitsDictionary.spotRadius'),
    },
    ACTIVE_GAMES: {
      name: 'activeGames',
      title: t('bundleLimitsDictionary.activeGames'),
    },
    GAME_CHARACTERS: {
      name: 'gameCharacters',
      title: t('bundleLimitsDictionary.gameCharacters'),
    },
    QUESTION_TYPES: {
      name: 'questionTypes',
      title: t('bundleLimitsDictionary.questionTypes'),
    },
    GAMES_PER_MONTH: {
      name: 'gamesPerMonth',
      title: t('bundleLimitsDictionary.gamesPerMonth'),
    },
    GAMERS_PER_GAME: {
      name: 'gamersPerGame',
      title: t('bundleLimitsDictionary.gamersPerGame'),
    },
  };

  const { data } = useQuery([QueryAliases.LIMITS_DICTIONARY], () => axiosInstance.get(api.endpoints.LIMITS_DICTIONARY));

  const limitDictionary = data?.data;

  const limitDictionaryWithTranslations: ILimitDictionary[] =
    limitDictionary &&
    limitDictionary?.map((dictionaryLimit: ApiLimitDictionary) => {
      const currentLimitDictionaryTranslation =
        limitsDictionaryTranslations[dictionaryLimit.limitDictionary as keyof typeof limitsDictionaryTranslations];
      return {
        ...dictionaryLimit,
        name: currentLimitDictionaryTranslation?.name,
        title: currentLimitDictionaryTranslation?.title,
      };
    });

  const gameCharactersTranslations = (limitValues: string[]) =>
    limitValues.map(entry => ({ id: entry, value: t(`gameCharacters.${entry}`) }));
  const questionTypeTranslations = (limitValues: string[]) =>
    limitValues.map(entry => ({ id: entry, value: t(`questionTypes.${entry}`) }));

  const translateLimitValues = (bundleLimitValue: string[], value: string) => {
    if (value === bundleLimitTypes.GAME_CHARACTERS) {
      return gameCharactersTranslations(bundleLimitValue) as LimitValuesWithTranslation[];
    }
    if (value === bundleLimitTypes.QUESTION_TYPES) {
      return questionTypeTranslations(bundleLimitValue) as LimitValuesWithTranslation[];
    }
    return;
  };

  const formatLimits = (bundleLimits: ApiBundleLimit[], prepareForEditing = false) => {
    const formatedLimits = limitDictionaryWithTranslations?.map((dictionaryLimit: ILimitDictionary) => {
      const { name, title } = dictionaryLimit;
      const bundleLimit = bundleLimits?.find(
        bundleLimit => bundleLimit?.limitDictionary?.limitDictionary === dictionaryLimit.limitDictionary,
      );

      let processedData = undefined;

      if (bundleLimit) {
        const valuesAreInArray = Array.isArray(bundleLimit.limitValue);

        let thisLimitValues;
        if (valuesAreInArray) {
          thisLimitValues =
            Array.isArray(bundleLimit.limitValue) && bundleLimit.limitValue.length !== 0
              ? translateLimitValues(bundleLimit.limitValue as string[], dictionaryLimit.limitDictionary)
              : undefined;
        } else {
          thisLimitValues = bundleLimit.limitValue;
        }

        processedData = {
          limitId: bundleLimit.id,
          limitDictionaryId: bundleLimit.limitDictionary.id,
          limitValue: thisLimitValues,
        };
      } else {
        processedData = {
          limitDictionaryId: dictionaryLimit.id,
          limitValue: prepareForEditing ? undefined : t('bundle.noLimit'),
        };
      }
      if (prepareForEditing) {
        return { name, ...processedData };
      }
      return { name, title, ...processedData };
    });

    return formatedLimits;
  };

  const convertLimitsForView = (bundleLimits: ApiBundleLimit[]) => {
    const formated = formatLimits(bundleLimits);

    const converted: IConvertedLimits = convertArrayOfObjectsToObjectByGivenKey(formated, 'name');

    return converted;
  };

  const convertLimitsForEdit = (bundleLimits: ApiBundleLimit[]) => {
    const formated = formatLimits(bundleLimits, true);

    const converted: IConvertedLimits = convertArrayOfObjectsToObjectByGivenKey(formated, 'name');

    return converted;
  };

  return { convertLimitsForView, convertLimitsForEdit, limitDictionaryWithTranslations };
};

export default useLimitDictionary;

import { isLandscape } from '../utils/isLandscape';
import { isSmallHeight } from '../utils/isSamllDeviceHeight';
import { DEAFAULT_CARD_HEIGHT, OFFSSET_HEIGHT, TOOLTIP_CARD_HEIGHT_OFFSET } from './consts';

export const calculateCardHeight = (mapHeight?: number) => {
  if (isLandscape() && isSmallHeight()) {
    return (mapHeight ?? DEAFAULT_CARD_HEIGHT) - OFFSSET_HEIGHT;
  }
  return (mapHeight ?? DEAFAULT_CARD_HEIGHT) - TOOLTIP_CARD_HEIGHT_OFFSET;
};

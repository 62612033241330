import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiQuestion, IQuestion } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { CallbackDefault } from '../../../types/Types';
import { transformQuestion } from '../../../utils/api/dataTransformers/transformQuestion/transformQuestion';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface ApiResponse {
  question: ApiQuestion;
  relatedQuestion: ApiQuestion | null;
}

interface useSingleQuestionProps {
  questionId: string;
  onErrorCb?: CallbackDefault;
  enabled?: boolean;
}

const useSingleQuestion = ({ questionId, onErrorCb, enabled = true }: useSingleQuestionProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.SINGLE_QUESTION(questionId)],
    () => axiosInstance.get(api.endpoints.SINGLE_QUESTION({ id: questionId })),
    {
      enabled,
      cacheTime: 1,
      onError: () => {
        if (!!onErrorCb) {
          onErrorCb();
        }
      },
    },
  );

  const apiQuestionResponse: ApiResponse | undefined = data?.data;

  const iQuestionResponse: { question: IQuestion; relatedQuestion: IQuestion | null } | undefined =
    !!apiQuestionResponse
      ? {
          question: transformQuestion(apiQuestionResponse.question),
          relatedQuestion: !!apiQuestionResponse.relatedQuestion
            ? transformQuestion(apiQuestionResponse.relatedQuestion)
            : null,
        }
      : undefined;

  return { questionResponse: iQuestionResponse, refetch, isSuccess, isLoading, isError };
};

export default useSingleQuestion;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

export interface UpdatedSubscription {
  validUntil: Date;
  bundle: { id: string };
  coordinates: {
    latitude: number;
    longitude: number;
  };
}
export interface UpdateSubscriptionProps {
  subscription: UpdatedSubscription;
  subscriptionId: number;
}

const useUpdateSubscription = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { isLoading, mutateAsync } = useMutation(
    ({ subscription, subscriptionId }: UpdateSubscriptionProps) =>
      axiosInstance.put(api.endpoints.UPDATE_SUBSCRIPTION({ subscriptionId }), subscription),
    {
      onError(error: AxiosError) {
        const errorMessage = getApiErrorMessage(error) ?? t('partner.toasts.addPartnerError');
        showErrorToast(errorMessage);
      },
    },
  );

  return {
    isLoading,
    updateSubscription: mutateAsync,
  };
};

export default useUpdateSubscription;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCreatedGamesStatistics from '../../../hooks/api/games/statistics/useCreatedGamesStatistics';
import DashboardStatsChart from './common/DashboardStatsChart';

interface DashboardStatsCreatedGamesProps {
  ownerFilter: string;
  gameTypeFilter: string;
}

const DashboardStatsCreatedGames = ({ ownerFilter, gameTypeFilter }: DashboardStatsCreatedGamesProps) => {
  const { t } = useTranslation();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const { createdGamesStatistics, isLoading } = useCreatedGamesStatistics({
    params: { email: ownerFilter, gameType: gameTypeFilter, year: currentYear },
  });

  const chartData = createdGamesStatistics?.countByMonth;

  return (
    <DashboardStatsChart
      title={t('dashboard.createdGames.title')}
      currentYear={currentYear}
      onChangeCurrentYear={setCurrentYear}
      chartData={chartData ?? []}
      isChartDataLoading={isLoading}
      totalInfoLabel={t('dashboard.createdGames.totalInfo')}
      chartDataTotalCount={createdGamesStatistics?.totalCount}
    />
  );
};

export default DashboardStatsCreatedGames;

import useVerifyPartner from '../../../hooks/api/users/useVerifyPartner';

const useHandleVerifyPartner = () => {
  const { verifyPartner, isLoading } = useVerifyPartner();

  const handleVerifyPartner = async (partnerId: number) => {
    const { data } = await verifyPartner(partnerId);

    const isPartnerActive = data?.isActive;

    return { isPartnerActive };
  };

  return { handleVerifyPartner, handleVerifyPartnerIsLoading: isLoading };
};

export default useHandleVerifyPartner;

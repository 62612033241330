import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Dropdown from '../../../../components/common/dropdowns/Dropdown';
import useGameCheckedAnswers from '../../../../hooks/api/games/useGameCheckedAnswers';
import useGameReviewAnswers from '../../../../hooks/api/games/useGameReviewAnswers';
import useGameUsers from '../../../../hooks/api/games/useGameUsers';
import { IGameAnswer, IQuestion } from '../../../../types/ApiTypes';
import { DropdownOption } from '../../../../types/Dropdown.types';
import { TypeFromConstObject } from '../../../../types/Types';
import SingleActiveGamePageWrapper from '../../SingleActiveGamePageWrapper';
import CheckedAnswers from './components/checkedAnswers/CheckedAnswers';
import ReviewAnswers from './components/reviewAnswers/ReviewAnswers';
import useSetInitialAnswerTeam from './hooks/useSetInitialAnswerTeam';
import useTeamsDropdownOptions from './hooks/useTeamsDropdownOptions';
import { filterAnswerGroupsByTeamName } from './utils/filterAnswerGroupsByTeamName';
import { groupAnswersByQuestion } from './utils/groupAnswersByQuestion';

export const AnswersOptions = {
  UNCHECKED: 'unchecked',
  CHECKED: 'checked',
} as const;

export type AnswersOption = TypeFromConstObject<typeof AnswersOptions>;

export const ALL_TEAMS_ID = 'all';

export interface IAnswersGroup {
  question: IQuestion;
  answers: IGameAnswer[];
}

const SingleActiveGameAnswersView = () => {
  const { t } = useTranslation();
  const params = useParams();
  const gameId = params.id as string;

  const answersOptions: DropdownOption[] = [
    {
      id: AnswersOptions.UNCHECKED,
      value: t('singleActiveGame.answers.answersToReview'),
    },
    {
      id: AnswersOptions.CHECKED,
      value: t('singleActiveGame.answers.answersChecked'),
    },
  ];

  const [selectedAnswersOption, setSelectedAnswersOption] = useState(answersOptions[0]);

  const showAnswersToReview = selectedAnswersOption?.id === AnswersOptions.UNCHECKED;
  const showAnswersChecked = selectedAnswersOption?.id === AnswersOptions.CHECKED;

  const { gameReviewAnswers, isLoading: isLoadingReviewAnswers } = useGameReviewAnswers({
    gameId,
  });
  const { gameCheckedAnswers, isLoading: isLoadingCheckedAnswers } = useGameCheckedAnswers({
    gameId,
    enabled: showAnswersChecked,
  });
  const { gameUsers } = useGameUsers({ gameId });

  const { allTeamsDropdownOption, gameUsersDropdownOptions } = useTeamsDropdownOptions({ gameUsers });
  const [selectedGameUser, setSelectedGameUser] = useState<DropdownOption | undefined>(allTeamsDropdownOption);

  useSetInitialAnswerTeam({ teamsDropdownOptions: gameUsersDropdownOptions, setSelectedTeam: setSelectedGameUser });

  const reviewAnswersGroupedByQuestion = useMemo(() => groupAnswersByQuestion(gameReviewAnswers), [gameReviewAnswers]);
  const checkedAnswersGroupedByQuestion = useMemo(
    () => groupAnswersByQuestion(gameCheckedAnswers),
    [gameCheckedAnswers],
  );

  const reviewAnswersToShow =
    selectedGameUser?.id !== ALL_TEAMS_ID && !!selectedGameUser?.value
      ? filterAnswerGroupsByTeamName({
          answerGroups: reviewAnswersGroupedByQuestion,
          teamName: selectedGameUser?.value,
        })
      : reviewAnswersGroupedByQuestion;

  const checkedAnswersToShow =
    selectedGameUser?.id !== ALL_TEAMS_ID && !!selectedGameUser?.value
      ? filterAnswerGroupsByTeamName({
          answerGroups: checkedAnswersGroupedByQuestion,
          teamName: selectedGameUser?.value,
        })
      : checkedAnswersGroupedByQuestion;

  return (
    <SingleActiveGamePageWrapper>
      <div className="flex justify-between mt-8 pr-8">
        <Dropdown
          options={answersOptions}
          currentOption={selectedAnswersOption}
          setNewOption={setSelectedAnswersOption}
          wrapperClassName="w-fit"
        />
        <Dropdown
          options={gameUsersDropdownOptions}
          currentOption={selectedGameUser}
          setNewOption={setSelectedGameUser}
          wrapperClassName="w-fit"
        />
      </div>

      <div className="py-8">
        {showAnswersToReview && <ReviewAnswers answers={reviewAnswersToShow} isLoading={isLoadingReviewAnswers} />}
        {showAnswersChecked && <CheckedAnswers answers={checkedAnswersToShow} isLoading={isLoadingCheckedAnswers} />}
      </div>
    </SingleActiveGamePageWrapper>
  );
};

export default SingleActiveGameAnswersView;

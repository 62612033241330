import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/common/buttons/SecondaryButton';
import FormSectionTitle from '../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../components/common/inputs/LabeledStateInput';
import { Modal, ModalProps } from '../../../components/common/modals/Modal';
import XMarkIcon from '../../../components/icons/XmarkIcon';
import { questionDistanceSchema } from '../../../static/validation/schemas/questionDistanceSchema';
import getInputState from '../../../utils/getInputState/getInputState';

interface EditGameQuestionDistanceModalInputs {
  distance: number;
}

type EditGameQuestionDistanceModalKeys = keyof EditGameQuestionDistanceModalInputs;

interface EditGameQuestionDistanceModalProops extends Omit<ModalProps, 'children'> {
  initDistance: number;
  onSubmitForm: (distance: number) => Promise<void>;
  isLoading: boolean;
}

const EditGameQuestionDistanceModal = ({
  isOpen,
  setIsOpen,
  initDistance,
  onSubmitForm,
  isLoading,
}: EditGameQuestionDistanceModalProops) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<EditGameQuestionDistanceModalInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(questionDistanceSchema),
    defaultValues: {
      distance: initDistance,
    },
  });

  const onSubmit: SubmitHandler<EditGameQuestionDistanceModalInputs> = async data => {
    onSubmitForm(data.distance);
  };

  const getErrorMessage = (field: EditGameQuestionDistanceModalKeys) => get(errors, field);

  const onCloseModal = () => {
    reset();
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onCloseModal}>
      <div>
        <div className="p-6 relative border-b border-grey300">
          <h2 className="text-center text-[1.375rem] font-medium">
            {t('singleActiveGame.info.editDistanceModal.title')}
          </h2>
          <div
            onClick={() => {
              onCloseModal();
              setIsOpen(false);
            }}
            className="w-8 h-8 min-w-8 absolute top-6 right-6 lg:left-6 flex justify-center items-center cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6">
          <FormSectionTitle className="mb-6" title={t('singleActiveGame.info.editDistanceModal.distanceDescription')} />
          <LabeledStateInput
            id="distance"
            state={getInputState(getErrorMessage('distance'))}
            labeledProps={{
              wrapperClassName: 'w-full',
              label: t('singleActiveGame.info.editDistanceModal.distanceLabel'),
              errorMessage: t(`errorMessages.${getErrorMessage('distance')?.message}`),
            }}
            inputProps={{
              placeholder: t('singleActiveGame.info.editDistanceModal.distancePlaceholder'),
              register: {
                ...register(`distance`),
              },
            }}
          />

          <div className="flex justify-end mt-12 gap-3">
            <SecondaryButton
              type="button"
              onClick={() => {
                onCloseModal();
                setIsOpen(false);
              }}
              className="w-fit"
            >
              {t('singleActiveGame.info.editDistanceModal.cancelBtn')}
            </SecondaryButton>
            <PrimaryButton type="submit" className="w-fit" disabled={isLoading} loading={isLoading}>
              {t('singleActiveGame.info.editDistanceModal.submitBtn')}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditGameQuestionDistanceModal;

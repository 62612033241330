import dayjs from 'dayjs';
import * as yup from 'yup';
import { Langs } from '../../types/GlobalTypes';
import {
  emailRegex,
  loginRegex,
  min1NumberRegex,
  min1SpecialCharRegex,
  min1UppercaseRegex,
  phoneRegex,
  stringCoordinatesRegex,
} from '../regex';

export const requiredArray = yup.array().min(1, 'required');

export const requiredString = yup.string().required('required');

export const number = yup
  .number()
  .transform(value => (isNaN(value) || value === null || value === undefined ? null : value));

export const requiredNumber = number.required('required');

export const requiredDate = yup.date().required('required');

export const endDateBasedOnStartDate = requiredDate.when('startHour', {
  is: (startHour: Date) => startHour != null,
  then: endHourSchema =>
    endHourSchema.test('start-game-end-hour', 'endTimeToEarly', function (value) {
      const { startHour } = this.parent;

      return dayjs(value).diff(dayjs(startHour), 'minute') > 0;
    }),
});

export const notLessThanZeroNumber = yup
  .number()
  .min(0, 'min0')
  .transform(value => (isNaN(value) || value === null || value === undefined ? null : value))
  .required('required');

export const requiredPositiveNumber = yup
  .number()
  .positive('positiveNumber')
  .transform(value => (isNaN(value) || value === null || value === undefined ? null : value))
  .required('required');

export const requiredBoolean = yup.boolean().required('required');

export const requiredObject = yup.object().required('required');

export const login = yup.string().matches(loginRegex, 'loginInvalid').required('required').min(3, 'atLeast3Char');
export const email = yup.string().email('email').required('required').matches(emailRegex, 'email');

export const password = yup
  .string()
  .required('required')
  .min(8, 'atLeast8Char')
  .matches(min1UppercaseRegex, 'min1Uppercase')
  .matches(min1NumberRegex, 'min1Number')
  .matches(min1SpecialCharRegex, 'min1SpecialChar');

export const repeatPassword = yup
  .string()
  .required('required')
  .test('passwordsDontMatch', 'passwordsDontMatch', function (value) {
    const { newPassword } = this.parent;

    return value === newPassword;
  });

export const phoneNumber = yup.string().matches(phoneRegex, 'phoneNumberIsNotValid');
export const phoneNumberRequired = phoneNumber.required('required');

export const dropdownOption = yup.object().shape({
  id: yup.string().required('required'),
  value: yup.string().required('required'),
  color: yup.string(),
});

export const dictionaryDropdownOption = yup.object().shape({
  id: yup.string().required('required'),
  value: yup.string().required('required'),
  code: yup.string().required('required'),
});

export const conditionallyRequiredNumber = (isRequired: boolean) => {
  if (isRequired) {
    return requiredNumber;
  }

  return number;
};

export const conditionallyRequiredArray = (isRequired: boolean) => {
  if (isRequired) {
    return requiredArray;
  }

  return yup.array();
};

export const multilangValue = yup.string().when('lang', (langs: string[]) => {
  const lang = langs[0];

  if (lang === Langs.PL) {
    return yup.string().required('required');
  } else {
    return yup.string();
  }
});

export const multilangString = yup.object({
  lang: yup.string().required('required'),
  value: multilangValue,
});

export const answerMultilangString = yup.object({
  lang: requiredString,
  value: multilangValue,
  answerId: requiredString,
});

export const coordinatesString = yup
  .string()
  .matches(stringCoordinatesRegex, 'invalidCoordinates')
  .required('required');

export const loginWithoutAtSign = yup.string().matches(/([^@])/);

export const limitWithLimitValueString = yup.object({ limitValue: yup.string() }).required('required');
export const limitWithLimitValueArray = yup
  .object({
    limitValue: yup.array(dropdownOption),
  })
  .required('required');

import axios, { AxiosInstance } from 'axios';
import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import useToast from '../hooks/useToast';
import i18n, { defaultLang } from '../i18n';
import { api } from '../static/api';
import { routes } from '../static/routes';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from '../utils/cookieHelpers/cookieHelpers';

export type AxiosContextType = {
  axiosInstance: AxiosInstance;
};

const AxiosContext = createContext<AxiosContextType>({} as AxiosContextType);

let isRefreshing = false;
let refreshQueue: (() => void)[] = [];

const AxiosContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { handleLogout } = useLogout();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();

  const onRefreshError = () => {
    handleLogout();
    navigate(routes.login);
    showErrorToast(t('errorMessages.loggedOut'));
  };

  const addToQueue = (callback: () => void) => {
    refreshQueue.push(callback);

    if (!isRefreshing) {
      refreshAccessToken();
    }
  };

  const processQueue = () => {
    isRefreshing = false;

    refreshQueue.forEach(callback => callback());

    refreshQueue = [];
  };

  const refreshAccessToken = async () => {
    isRefreshing = true;

    try {
      const response = await axiosInstance.get(api.endpoints.REFRESH, {
        headers: {
          refresh: getRefreshToken(),
        },
      });

      const newAccessToken = response.data.accessToken;
      const newRefreshToken = response.data.refreshToken;

      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);

      processQueue();
    } catch (error) {
      onRefreshError();
    }
  };

  const axiosInstance = axios.create({
    baseURL: api.BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosInstance.interceptors.request.use(config => {
    const accessToken = getAccessToken();
    const currentLang = i18n.resolvedLanguage;

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    config.headers['Accept-Language'] = currentLang ?? defaultLang.id;

    return config;
  });

  axiosInstance.interceptors.response.use(
    res => res,
    async err => {
      const originalConfig = err.config;

      const refreshToken = getRefreshToken();

      const shouldRefresh =
        !!refreshToken &&
        originalConfig.url !== api.endpoints.REFRESH &&
        originalConfig.url !== api.endpoints.LOGIN &&
        err.response;

      if (shouldRefresh) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          return new Promise(resolve => {
            addToQueue(() => resolve(axiosInstance(originalConfig)));
          });
        }
      }

      return Promise.reject(err);
    },
  );

  const AxiosContextValue = {
    axiosInstance,
  };

  return <AxiosContext.Provider value={AxiosContextValue}>{children}</AxiosContext.Provider>;
};

export { AxiosContext, AxiosContextProvider };

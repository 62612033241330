import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LinkButon } from '../../../components/common/buttons/LinkButton';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import { LabeledStateInput } from '../../../components/common/inputs/LabeledStateInput';
import ArrowRight from '../../../components/icons/ArrowRight';
import { routes } from '../../../static/routes';
import { loginValidationSchema } from '../../../static/validation/schemas/loginValidationSchema';
import { colors } from '../../../styles/variables';
import { ApiLoginRequest } from '../../../types/ApiTypes';
import { ButtonSize } from '../../../types/ButtonSizes';
import getInputState from '../../../utils/getInputState/getInputState';

type IFormInput = ApiLoginRequest;

interface LoginFormProps {
  onFormSubmit: (data: ApiLoginRequest) => Promise<void>;
  isLoading: boolean;
}

const LoginForm = ({ onFormSubmit, isLoading }: LoginFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({ password, email }) => {
    await onFormSubmit({ password, email });

    reset();
  };

  const getErrorMessage = (field: keyof IFormInput) => get(errors, field);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 w-full">
        <LabeledStateInput
          id="login-email"
          state={getInputState(getErrorMessage('email'))}
          labeledProps={{
            wrapperClassName: 'mb-8',
            label: t('login.emailLabel'),
            errorMessage: t(`errorMessages.${getErrorMessage('email')?.message}`),
          }}
          inputProps={{
            placeholder: t('login.emailPlaceholder') as string,
            register: {
              ...register('email'),
            },
          }}
        />

        <LabeledStateInput
          id="login-password"
          state={getInputState(getErrorMessage('password'))}
          labeledProps={{
            wrapperClassName: 'mb-8',
            label: t('login.passwordLabel'),
            errorMessage: t(`errorMessages.${getErrorMessage('password')?.message}`),
          }}
          inputProps={{
            type: 'password',
            register: {
              ...register('password'),
            },
          }}
        />

        <PrimaryButton type="submit" disabled={isLoading} loading={isLoading}>
          {t('login.loginButton')}
        </PrimaryButton>
      </form>

      <LinkButon
        onClick={() => {
          navigate(routes.requestResetPassword);
        }}
        className="mt-11 mb-16 lg:mb-0"
        size={ButtonSize.Small}
        suffixIcon={<ArrowRight color={colors.black700} small />}
      >
        {t('login.forgotPasswordButton')}
      </LinkButon>
    </>
  );
};

export default LoginForm;

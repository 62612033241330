import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BreadcrumbsProps } from '../../components/common/breadcrumbs/Breadcrumbs';
import BundleForm from '../../components/forms/bundleForm/BundleForm';
import EditIcon from '../../components/icons/EditIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import useBundleById from '../../hooks/api/bundle/useBundleById';
import { colors } from '../../styles/variables';
import { IBundleFormInputs } from '../../types/ApiBundleTypes';
import useHandleEditBundle from './hooks/useHandleEditBundle';

const BundleFormWithLoading = withLoading(BundleForm);

const EditBundleView = () => {
  const { t } = useTranslation();
  const params = useParams();
  const bundleId = params.bundleId;

  const { initialFormBundle } = useBundleById(bundleId);
  const { handleEditBundle } = useHandleEditBundle();

  const handleSubmitBundleForm = async (data: IBundleFormInputs) => {
    handleEditBundle(data);
  };

  const breadcrumbsProps: BreadcrumbsProps = {
    limit: 3,
    extraBreadcrumbs: [{ text: initialFormBundle ? initialFormBundle?.bundleName : '' }],
  };

  return (
    <TitleWrapper title={t('bundle.editBundle.title')} breadcrumbsProps={breadcrumbsProps}>
      <BundleFormWithLoading
        submitForm={handleSubmitBundleForm}
        submitBtnIcon={<EditIcon color={colors.white900} />}
        submitBtnText={t('bundle.editBundle.editBundleButton')}
        initialBundle={initialFormBundle}
        isLoading={!initialFormBundle}
      />
    </TitleWrapper>
  );
};

export default EditBundleView;

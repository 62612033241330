import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { routes } from '../../../static/routes';
import { ApiBundle } from '../../../types/ApiBundleTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

type EditBundleData = {
  id: number;
  bundle: ApiBundle;
};
const useEditBundle = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast, showSuccessToast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync, isLoading, isSuccess } = useMutation(
    ({ id, bundle }: EditBundleData) => axiosInstance.patch(api.endpoints.SINGLE_BUNDLE({ id }), bundle),
    {
      async onSuccess() {
        await queryClient.refetchQueries([QueryAliases.ALL_BUNDLES]);
        await queryClient.invalidateQueries([QueryAliases.SINGLE_BUNDLE]);
        const successMessage = t('bundle.toasts.editBundleSuccess');
        showSuccessToast(successMessage);
        navigate(routes.bundles);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('bundle.toasts.editBundleError');

        showErrorToast(errorMessage);
      },
    },
  );

  return { editBundle: mutateAsync, isLoading, editBundleSuccess: isSuccess };
};

export default useEditBundle;

import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiGameQuestion, IGameQuestion } from '../../../../types/ApiTypes';
import { QueryAliases } from '../../../../types/QueryAliases';
import { transformGameQuestion } from '../../../../utils/api/dataTransformers/transformGameQuestion/transformGameQuestion';
import useAxiosInstance from '../../../axios/useAxiosInstance';

const useSpectatorGameQuestions = ({ gameId, token, enabled }: { gameId: string; token: string; enabled: boolean }) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError, isFetching } = useQuery(
    [QueryAliases.SPECTATOR.GAME_QUESTIONS(gameId)],
    () =>
      axiosInstance.get(api.endpoints.SPECTATOR.GAME_QUESTIONS({ gameId }), {
        params: { token },
      }),
    {
      enabled,
    },
  );

  const apiQuestions: ApiGameQuestion[] | undefined = data?.data;

  const iQuestions: IGameQuestion[] = !!apiQuestions
    ? apiQuestions.map(question => transformGameQuestion(question))
    : [];

  return {
    gameQuestions: iQuestions,
    refetch,
    isSuccess,
    isLoading,
    isError,
    isFetching,
  };
};

export default useSpectatorGameQuestions;

import { colors } from '../../styles/variables';

interface MapMarkerGeneralIconProps {
  color?: string;
  size?: number;
}

const MapMarkerGeneralIcon = ({ color, size }: MapMarkerGeneralIconProps) => {
  return (
    <svg
      width={size ?? '36'}
      height={size ?? '36'}
      viewBox={`0 0 ${size ?? '36'} ${size ?? '36'}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.93 12.675C29.355 5.745 23.31 2.625 18 2.625C18 2.625 18 2.625 17.985 2.625C12.69 2.625 6.63002 5.73 5.05502 12.66C3.30002 20.4 8.04002 26.955 12.33 31.08C13.92 32.61 15.96 33.375 18 33.375C20.04 33.375 22.08 32.61 23.655 31.08C27.945 26.955 32.685 20.415 30.93 12.675ZM18 20.19C15.39 20.19 13.275 18.075 13.275 15.465C13.275 12.855 15.39 10.74 18 10.74C20.61 10.74 22.725 12.855 22.725 15.465C22.725 18.075 20.61 20.19 18 20.19Z"
        fill={color ?? colors.black700}
      />
    </svg>
  );
};

export default MapMarkerGeneralIcon;

interface SectionWrapperProps {
  children: React.ReactNode;
}

const SectionWrapper = ({ children }: SectionWrapperProps) => {
  return (
    <div className="w-full pt-5 lg:pt-10 px-4 lg:px-8 xl:px-14 flex flex-col justify-center h-full">{children}</div>
  );
};

export default SectionWrapper;

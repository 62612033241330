import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { CreateSubscriptionRequest } from '../../../types/ApiBundleTypes';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useCreateSubscription = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { isLoading, mutateAsync, isError } = useMutation(
    ({ newSubscription }: { newSubscription: CreateSubscriptionRequest }) =>
      axiosInstance.post(api.endpoints.CREATE_SUBSCRIPTION, newSubscription),
    {
      onError(error: AxiosError) {
        const errorMessage = getApiErrorMessage(error) ?? t('partner.toasts.addPartnerError');
        showErrorToast(errorMessage);
      },
    },
  );

  return {
    isLoading,
    createSubscription: mutateAsync,
    createSubscriptionError: isError,
  };
};

export default useCreateSubscription;

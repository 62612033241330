import { TypeFromConstObject } from './Types';

export const Langs = {
  PL: 'pl',
  EN: 'en',
  UA: 'ua',
  DE: 'de',
  ES: 'es',
  FR: 'fr',
  IT: 'it',
  CS: 'cs',
  SK: 'sk',
  LT: 'lt',
  DA: 'da',
  FI: 'fi',
  NO: 'no',
  PT: 'pt',
  HU: 'hu',
} as const;

export type LangType = TypeFromConstObject<typeof Langs>;

export const QuestionTypes = {
  CLOSED: 'closed',
  OPEN_SHORT_TEXT: 'open_short_text',
  OPEN_LONG_TEXT: 'open_long_text',
  IMAGE: 'image',
  VIDEO: 'video',
  ANIMATOR: 'animator',
};

export type QuestionType = TypeFromConstObject<typeof QuestionTypes>;

export interface Coordinates {
  id?: number;
  lat: number;
  lng: number;
}

export interface RectangleAreaCoordinates {
  ne: Coordinates;
  sw: Coordinates;
  se: Coordinates;
  nw: Coordinates;
}

export interface MapViewport {
  northeast: { lat: number; lng: number };
  southwest: { lat: number; lng: number };
}

export interface MultilangString {
  lang: string;
  value?: string;
}

export interface IAnswer extends MultilangString {
  answerId: string;
}

export const GameTypes = {
  INDIVIDUAL: 'individual',
  BUSINESS: 'business',
  INDIVIDUAL_PAID: 'individual_paid',
};

export type GameType = TypeFromConstObject<typeof GameTypes>;

export const GameCharacters = {
  STANDARD: 'standard',
  LOOPED: 'looped',
  TRACED: 'traced',
};

export type GameCharacter = TypeFromConstObject<typeof GameCharacters>;

export const GameStatuses = {
  ONGOING: 'ongoing',
  FINISHIED: 'finished',
  SCHEDULED: 'scheduled',
};

export type GameStatus = TypeFromConstObject<typeof GameStatuses>;

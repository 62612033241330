import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/QueryAliases';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeletePlayer = () => {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useMutation(
    (id: string) => axiosInstance.delete(api.endpoints.DELETE_RELETED_USER({ id })),
    {
      async onSuccess() {
        await queryClient.refetchQueries([QueryAliases.RELATED_USERS]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.deleteReletedUserFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { deletePlayer: mutateAsync, isLoading };
};

export default useDeletePlayer;

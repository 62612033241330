import { ApiAnswer, ApiQuestion, IQuestion } from '../../../../types/ApiTypes';
import { IAnswer } from '../../../../types/GlobalTypes';

export const transformQuestion = (question: ApiQuestion) => {
  const getAnswers = ({ apiAnswers, correct }: { apiAnswers?: ApiAnswer[]; correct: boolean }) => {
    const answers: IAnswer[] = [];

    apiAnswers
      ?.filter(ans => ans.isCorrect === correct)
      ?.forEach(answer => {
        if (!answer.content) {
          return;
        }

        const answerId = answer.id;

        answer.content.translations.forEach(translation => {
          answers.push({ answerId, lang: translation.lang, value: translation.value });
        });
      });

    return answers;
  };

  const iQuestion: IQuestion = {
    id: question.id,
    questionType: question.questionType,
    points: question.points,
    pointsOptions: question.pointsOptions,
    city: question.city,
    name: question.name.translations,
    content: question.content?.translations,
    correctAnswerText: question.correctAnswerText?.translations,
    incorrectAnswerText: question.incorrectAnswerText?.translations,
    coordinates: !!question.coordinates
      ? { lat: +question.coordinates.latitude, lng: +question.coordinates.longitude }
      : undefined,
    correctAnswers: !!question.answers ? getAnswers({ apiAnswers: question.answers, correct: true }) : [],
    incorrectAnswers: !!question.answers ? getAnswers({ apiAnswers: question.answers, correct: false }) : [],
    tags: question.tags,
    image: question.image,
    correctAnswerImage: question.correctAnswerImage,
    incorrectAnswerImage: question.incorrectAnswerImage,
    icon: question.icon,
    owner: question.owner,
    isArcade: !question.coordinates,
  };

  return iQuestion;
};

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import Textarea from '../../../../../components/common/inputs/Textarea';
import useSendMessage from '../../../../../hooks/api/games/messages/useSendMessage';
import { ITeamMessageGroup } from '../../../../../types/ApiTypes';
import { ButtonSize } from '../../../../../types/ButtonSizes';
import { GameStatus, GameStatuses } from '../../../../../types/GlobalTypes';
import { getTimeDifference } from '../../../../../utils/dates/getRemainingTime/getRemainingTime';
import useMessagePassedTime from '../hooks/useMessagePassedTime';

interface SingleMessageDetailsProps {
  gameId: string;
  messageGroup: ITeamMessageGroup;
  gameStatus?: GameStatus;
}

const SingleMessageDetails = ({ gameId, gameStatus, messageGroup }: SingleMessageDetailsProps) => {
  const { t } = useTranslation();

  const latestMessage = messageGroup.messages[messageGroup.messages.length - 1];

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  };

  const [newMessageValue, setNewMessageValue] = useState('');

  const { getTimeInfo } = useMessagePassedTime({ message: latestMessage });
  const { sendMessage, isLoading } = useSendMessage({ gameId, onSuccess: () => setNewMessageValue('') });

  const handleSendMessage = ({ receiverId }: { receiverId: number }) => {
    sendMessage({ users: [receiverId], message: newMessageValue });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageGroup.messages.length]);

  const { days, hours, minutes } = getTimeDifference({
    startDate: latestMessage?.createdAt ?? new Date(),
    endDate: new Date(),
  });

  const isMessageFresh = days < 1 && hours < 1 && minutes < 1;

  return (
    <div className="p-5 ">
      <div className="flex gap-2.5 items-center justify-start">
        <p className="text-black300 text-sm font-medium">{messageGroup.team.name}</p>
        <p className="text-black300 text-sm font-normal">
          • {isMessageFresh ? t('singleActiveGame.messages.now') : getTimeInfo()}
        </p>
      </div>

      <div ref={scrollContainerRef} className="max-h-60 mt-3 overflow-y-scroll">
        {messageGroup.messages.map(message => (
          <div key={message.id} className={`py-2.5 my-2.5 ${message.isReceived ? 'pl-0 pr-12' : 'pl-12 pr-0'}`}>
            <p className={`text-sm ${message.isReceived ? 'text-black700' : 'text-red700'}`}>{message.message}</p>
          </div>
        ))}
      </div>

      {gameStatus === GameStatuses.FINISHIED ? (
        <p className="text-grey700 my-6">{t('singleActiveGame.messages.gameFinishedInfo')}</p>
      ) : (
        <Textarea
          className="resize-none mt-2"
          placeholder={t('singleActiveGame.messages.textPlaceholder')}
          value={newMessageValue}
          onChange={e => setNewMessageValue(e.target.value)}
        />
      )}

      <div className="mt-2 flex justify-end">
        <PrimaryButton
          disabled={!newMessageValue.trim() || isLoading}
          loading={isLoading}
          onClick={() => handleSendMessage({ receiverId: messageGroup.team.id })}
          className="w-fit"
          size={ButtonSize.Small}
        >
          {t('singleActiveGame.messages.send')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SingleMessageDetails;

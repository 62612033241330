interface FormSectionTitleProps {
  number?: number;
  title: string;
  className?: string;
}

const FormSectionTitle = ({ number, title, className }: FormSectionTitleProps) => {
  return (
    <div className={`flex gap-2 ${className}`}>
      {!!number && (
        <div className="w-6 h-6 bg-blue500 flex justify-center items-center text-white900 rounded-full">{number}</div>
      )}
      <p className="font-bold">{title}</p>
    </div>
  );
};

export default FormSectionTitle;

import { useMutation } from 'react-query';
import { api } from '../../../../static/api';
import useAxiosInstance from '../../../axios/useAxiosInstance';

const useMarkMessageRead = () => {
  const axiosInstance = useAxiosInstance();

  const { mutateAsync, isLoading } = useMutation(({ messageId }: { messageId: number }) =>
    axiosInstance.patch(api.endpoints.MARK_MESSAGE_READ({ messageId })),
  );

  return { markMessageRead: mutateAsync, isLoading };
};

export default useMarkMessageRead;

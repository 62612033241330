import { useEffect } from 'react';
import { useFormChange } from '../FormChangeProvider';

export function useTrackUnsavedChange(isDirty: boolean) {
  const { setHasUnsavedChanges } = useFormChange();

  useEffect(() => {
    setHasUnsavedChanges(isDirty);

    return () => {
      setHasUnsavedChanges(false);
    };
  }, [isDirty, setHasUnsavedChanges]);
}

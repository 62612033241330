import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TypeToConfirmModal from '../../../../../components/common/modals/TypeToConfirmModal';
import TableSelectionPanel, {
  TableSelectionPanelProps,
} from '../../../../../components/dataTable/components/TableSelectionPanel';
import CopyIcon from '../../../../../components/icons/CopyIcon';
import TrashIcon from '../../../../../components/icons/TrashIcon';
import { colors } from '../../../../../styles/variables';
import { CallbackDefault } from '../../../../../types/Types';

interface QuestionsListTableSelectionPanelProps extends Omit<TableSelectionPanelProps, 'children'> {
  hideDuplicate?: boolean;
  onDuplicate: CallbackDefault;
  onDelete: CallbackDefault;
  isLoadingDelete: boolean;
}

const QuestionsListTableSelectionPanel = (props: QuestionsListTableSelectionPanelProps) => {
  const { hideDuplicate, onDuplicate, onDelete, isLoadingDelete } = props;
  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteQuestion = () => onDelete();

  return (
    <>
      <TableSelectionPanel {...props}>
        {!hideDuplicate && (
          <button onClick={onDuplicate} className="text-red700 font-semibold text-sm flex items-center gap-1.5">
            <CopyIcon color={colors.red700} width={16} />
            {t('questions.table.selectionPanel.duplicate')}
          </button>
        )}

        <button
          onClick={() => setIsDeleteModalOpen(true)}
          className="text-red700 font-semibold text-sm flex items-center gap-1.5"
        >
          <TrashIcon color={colors.red700} width={16} />
          {t('questions.table.selectionPanel.delete')}
        </button>
      </TableSelectionPanel>

      <TypeToConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        title={t('questions.table.selectionPanel.deleteModal.title')}
        textToType={t('questions.table.selectionPanel.deleteModal.textToType')}
        confirmBtnText={t('questions.table.selectionPanel.deleteModal.confirmBtnText')}
        confirmBtnAction={handleDeleteQuestion}
        cancelBtnText={t('questions.table.selectionPanel.deleteModal.cancelBtnText')}
        isConfirmBtnDisabled={isLoadingDelete}
        isConfirmBtnLoading={isLoadingDelete}
      />
    </>
  );
};

export default QuestionsListTableSelectionPanel;

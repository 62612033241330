export const getGameDate = ({ dateInput, hourInput }: { dateInput: Date; hourInput: Date }) => {
  const year = dateInput.getFullYear();
  const month = dateInput.getMonth();
  const day = dateInput.getDate();

  const startHour = hourInput.getHours();
  const startMinute = hourInput.getMinutes();

  const date = new Date(year, month, day, startHour, startMinute);

  return date;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gm-svpc {
    border-radius: 0.375rem !important;
    border-width: 1px !important;
    border-style: solid !important;
    --tw-border-opacity: 1 !important;
    border-color: rgb(225 225 225 / var(--tw-border-opacity)) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity)) !important;
    padding: 0.5rem !important;
    --tw-shadow: 0 0 #0000 !important;
    --tw-shadow-colored: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}
.gm-svpc:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(240 240 240 / var(--tw-bg-opacity)) !important
}
`, "",{"version":3,"sources":["webpack://./src/components/map/map.css"],"names":[],"mappings":"AACE;IAAA,kCAAwG;IAAxG,4BAAwG;IAAxG,8BAAwG;IAAxG,iCAAwG;IAAxG,oEAAwG;IAAxG,6BAAwG;IAAxG,oEAAwG;IAAxG,0BAAwG;IAAxG,iCAAwG;IAAxG,yCAAwG;IAAxG;AAAwG;AAAxG;IAAA,6BAAwG;IAAxG;AAAwG","sourcesContent":[".gm-svpc {\r\n  @apply !p-2 !bg-grey100 !rounded-md !border !border-solid !border-grey300 !shadow-none hover:!bg-grey200;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

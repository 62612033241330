import { colors } from '../../styles/variables';

const DragIcon = ({ color, size }: { color?: string; size?: number }) => {
  return (
    <svg width={size ?? 27} height={size ?? 27} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 3.95406V23.0459" stroke={color ?? colors.black700} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16.6821 7.13623L13.5001 3.95425L10.3181 7.13623"
        stroke={color ?? colors.black700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3181 19.8638L13.5001 23.0458L16.6821 19.8638"
        stroke={color ?? colors.black700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.9541 13.5H23.046" stroke={color ?? colors.black700} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.13608 16.682L3.9541 13.5L7.13608 10.318"
        stroke={color ?? colors.black700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8641 10.318L23.0461 13.5L19.8641 16.682"
        stroke={color ?? colors.black700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DragIcon;

import { colors } from '../../styles/variables';

interface InfoIconProps {
  color?: string;
}

const InfoIcon = ({ color }: InfoIconProps) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4 15V11M11.4 7H11.41M21.4 11C21.4 16.5228 16.9228 21 11.4 21C5.87715 21 1.39999 16.5228 1.39999 11C1.39999 5.47715 5.87715 1 11.4 1C16.9228 1 21.4 5.47715 21.4 11Z"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoIcon;

import { useQuery } from 'react-query';

import { api } from '../../../static/api';
import { ApiFeaturesResponse, ApiQuestion, IQuestion } from '../../../types/ApiTypes';
import { LangType, QuestionType } from '../../../types/GlobalTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { transformQuestion } from '../../../utils/api/dataTransformers/transformQuestion/transformQuestion';
import { getEndpointQueryFilters } from '../../../utils/api/getQueryFilters/getEndpointQueryFilters';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useQuestionsProps {
  lang?: LangType;
  search?: string;
  limit?: number;
  page?: number;
  filters?: { questionType: QuestionType; city: string; tags: string[]; owner?: string; isArcade: string };
}

const useQuestions = ({ lang, search, limit, page, filters }: useQuestionsProps = {}) => {
  const axiosInstance = useAxiosInstance();

  const filtersParams = !filters ? '' : getEndpointQueryFilters(filters);

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [
      QueryAliases.QUESTIONS,
      lang ?? '',
      search ?? '',
      limit ?? '',
      page ?? '',
      filters?.questionType ?? '',
      filters?.tags ?? '',
      filters?.city ?? '',
      filters?.owner ?? '',
      filters?.isArcade ?? '',
    ],
    () =>
      axiosInstance.get(`${api.endpoints.QUESTIONS}${filtersParams}`, {
        params: {
          lang,
          search,
          limit,
          page,
        },
      }),
  );

  const apiQuestions: ApiFeaturesResponse<ApiQuestion[]> | undefined = data?.data;

  const iQuestions: ApiFeaturesResponse<IQuestion[]> | undefined = !!apiQuestions
    ? {
        count: apiQuestions.count,
        data: apiQuestions.data.map(question => transformQuestion(question)),
      }
    : undefined;

  return { questions: iQuestions, refetch, isSuccess, isLoading, isError };
};

export default useQuestions;

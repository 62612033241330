import { useTranslation } from 'react-i18next';

import BorderCard from '../../../components/common/cards/BorderCard';
import useAllGamesStatistics from '../../../hooks/api/games/statistics/useAllGamesStatistics';

interface DashboardStatsSummaryProps {
  ownerFilter: string;
  gameTypeFilter: string;
}

const DashboardStatsSummary = ({ ownerFilter, gameTypeFilter }: DashboardStatsSummaryProps) => {
  const { t } = useTranslation();

  const { gamesStatistics } = useAllGamesStatistics({
    params: {
      email: ownerFilter,
      gameType: gameTypeFilter,
    },
  });

  return (
    <BorderCard className="">
      <h5 className="text-sm uppercase font-bold text-grey500 mb-3">{t('dashboard.summary.totalSteps')}</h5>
      <p className="text-black700 text-sm font-medium">{gamesStatistics?.totalSteps ?? '\u00a0'}</p>

      <h5 className="mt-6 text-sm uppercase font-bold text-grey500 mb-3">{t('dashboard.summary.totalDistanceKm')}</h5>
      <p className="text-black700 text-sm font-medium">{gamesStatistics?.totalDistanceInKm.toFixed(2) ?? '\u00a0'}</p>

      <h5 className="mt-6 text-sm uppercase font-bold text-grey500 mb-3">{t('dashboard.summary.totalGameTime')}</h5>
      <p className="text-black700 text-sm font-medium">{gamesStatistics?.totalGameTimeInMinutes ?? '\u00a0'}</p>

      <h5 className="mt-6 text-sm uppercase font-bold text-grey500 mb-3">{t('dashboard.summary.usersCount')}</h5>
      <p className="text-black700 text-sm font-medium">{gamesStatistics?.relatedUsersCount ?? '\u00a0'}</p>

      <h5 className="mt-6 text-sm uppercase font-bold text-grey500 mb-3">
        {t('dashboard.summary.averageUsersInGame')}
      </h5>
      <p className="text-black700 text-sm font-medium">
        {gamesStatistics?.averageUserGamesCount?.toFixed(2) ?? '\u00a0'}
      </p>
    </BorderCard>
  );
};

export default DashboardStatsSummary;

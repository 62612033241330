import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { CallbackDefault } from '../../../types/Types';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeleteTemplateQuestion = ({ onSuccess }: { onSuccess?: CallbackDefault } = {}) => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useMutation(
    ({ templateId, questionId }: { templateId: number; questionId: number }) =>
      axiosInstance.delete(api.endpoints.TEMPLATE_QUESTION({ templateId, questionId })),
    {
      onSuccess: () => {
        if (!!onSuccess) {
          onSuccess();
        }
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.deleteQuestionFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { deleteTemplateQuestion: mutateAsync, isLoading };
};

export default useDeleteTemplateQuestion;

import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { IGooglePlacesPrediction } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface ApiPrediction {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

interface useMapAutocompleteProps {
  input: string;
  onlyCities?: boolean;
}

const useMapAutocomplete = ({ input, onlyCities }: useMapAutocompleteProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.GOOGLE_PLACES, input],
    () =>
      axiosInstance.get(
        api.endpoints.GOOGLE_AUTOCOMPLETE(input),

        {
          params: {
            ...(onlyCities && { types: '(cities)' }),
          },
        },
      ),
    { enabled: input.length >= 3 },
  );

  const response: { predictions: ApiPrediction[] } | undefined = data?.data;

  const iPredictions: IGooglePlacesPrediction[] = !!response
    ? response.predictions.map(prediction => ({
        description: prediction.description,
        placeId: prediction.place_id,
        structuredFormatting: {
          mainText: prediction.structured_formatting.main_text,
          secondaryText: prediction.structured_formatting.secondary_text,
        },
      }))
    : [];

  return { predictions: iPredictions, refetch, isSuccess, isLoading, isError };
};

export default useMapAutocomplete;

export const generateURLQueryString = (params: Record<string, string | number | boolean | string[]>) => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        const joinedValues = value.map(val => encodeURIComponent(val)).join(';');
        queryParams.append(key, joinedValues);
      } else {
        queryParams.append(key, value.toString());
      }
    }
  });

  const queryString = queryParams.toString();

  return queryString;
};

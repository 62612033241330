interface LangTabButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean;
  text: string;
}

const LangTabButton = ({ isActive, text, ...props }: LangTabButtonProps) => {
  return (
    <button
      className={`whitespace-nowrap uppercase relative text-center font-medium text-sm p-4 cursor-pointer rounded-none grow max-w-[550px] ${
        isActive ? 'text-red500 bg-transparent' : 'text-grey700 '
      }`}
      {...props}
    >
      {isActive && (
        <div className="absolute block w-full h-[3px] bg-red500 bottom-0 left-0 rounded-tl-[100px] rounded-tr-[100px]" />
      )}
      {text}
    </button>
  );
};

export default LangTabButton;

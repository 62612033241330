import { colors } from '../../styles/variables';

interface ArrowLeftProps {
  color?: string;
  size?: number;
}

const ArrowLeft = ({ color, size }: ArrowLeftProps) => {
  return (
    <svg width={size ?? 24} height={size ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 12H4M4 12L10 18M4 12L10 6"
        stroke={color ?? colors.black700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;

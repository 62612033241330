import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiFeaturesResponse, ApiGamer, IGamer } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useGamersProps {
  limit: number;
  page: number;
}
const useGamers = ({ limit, page }: useGamersProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, isLoading } = useQuery(
    [QueryAliases.ALL_GAMERS, limit ?? '', page ?? ''],
    () => axiosInstance.get(api.endpoints.ALL_GAMERS, { params: { limit, page } }),
    {
      refetchOnMount: true,
    },
  );

  const apiGamers: ApiFeaturesResponse<ApiGamer[]> | undefined = data?.data;

  const allGamersData: IGamer[] | undefined = apiGamers?.data?.map((gamer: ApiGamer) => ({
    ...gamer,
    userGames: gamer.userGames.length,
  }));

  const gamers = {
    count: apiGamers?.count,
    data: allGamersData,
  };

  return { gamers, isLoading };
};

export default useGamers;

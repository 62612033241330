import { useTranslation } from 'react-i18next';
import SelectionPanelCard from '../../cards/selectionPanelCard/SelectionPanelCard';

export interface TableSelectionPanelProps {
  tableId: string;
  children: React.ReactNode;
  selectedRowsNumber: number;
}

const TableSelectionPanel = ({ tableId, children, selectedRowsNumber }: TableSelectionPanelProps) => {
  const { t } = useTranslation();

  const table = document.getElementById(tableId);

  const tableWidth = table?.offsetWidth;

  return (
    <SelectionPanelCard
      className="fixed bottom-11 z-10"
      customStyle={{
        width: tableWidth,
      }}
    >
      <div className="pr-6 border-r border-grey400">
        <p className="text-black400 font-medium text-sm">
          <span className="text-black700 font-bold">{`(${selectedRowsNumber}) `}</span>
          {t('table.positionChosen')}
        </p>
      </div>

      {children}
    </SelectionPanelCard>
  );
};

export default TableSelectionPanel;

import { toString } from 'lodash';
import { useTranslation } from 'react-i18next';

import useTemplates from '../../../../hooks/api/templates/useTemplates';
import { getLangCode } from '../../../../utils/getLangCode/getLangCode';

const useTemplatesDropdownOptions = () => {
  const { templates } = useTemplates();
  const {
    i18n: { language },
  } = useTranslation();

  const langToSearch = getLangCode(language);

  const templatesDropdownOptions = templates?.data.map(template => ({
    id: toString(template.id),
    value: template.name.translations.find(name => name.lang === langToSearch)?.value as string,
  }));

  return { templatesDropdownOptions };
};

export default useTemplatesDropdownOptions;

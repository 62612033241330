import { ApiAllTemplatesItem, IAllTemplatesItem } from '../../../../types/ApiTypes';

export const transformAllTemplatesItem = (apiTemplate: ApiAllTemplatesItem) => {
  const iTemplate: IAllTemplatesItem = {
    id: apiTemplate.id,
    name: {
      id: apiTemplate.name.id,
      translations: apiTemplate.name.translations.map(tr => ({
        lang: tr.lang,
        value: tr.value,
      })),
    },
    city: apiTemplate.city,
    gameCharacter: apiTemplate.gameCharacter?.code,
    gameType: apiTemplate.gameType?.code,
    averageTime: apiTemplate.averageTime,
    coordinates: !!apiTemplate.coordinates
      ? {
          lat: +apiTemplate.coordinates.latitude,
          lng: +apiTemplate.coordinates.longitude,
          id: apiTemplate.coordinates.id,
        }
      : undefined,
    questionToTemplate: apiTemplate.questionToTemplate.map(item => ({
      id: item.id,
      index: item.index,
      coordinates: {
        lat: +item.coordinates.latitude,
        lng: +item.coordinates.longitude,
      },
    })),
    tags: apiTemplate.tags,
    isActive: apiTemplate.isActive,
    advertisements: apiTemplate.advertisements,
    owner: apiTemplate.owner,
  };

  return iTemplate;
};

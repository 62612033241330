import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../../components/common/buttons/IconButton';
import TypeToConfirmModal from '../../../components/common/modals/TypeToConfirmModal';
import TemplateStatsModal from '../../../components/common/modals/templateStatsModal/TemplateStatsModal';
import CopyIcon from '../../../components/icons/CopyIcon';
import PlayIcon from '../../../components/icons/PlayIcon';
import TrashIcon from '../../../components/icons/TrashIcon';
import useDeleteTemplate from '../../../hooks/api/templates/useDeleteTemplate';
import { routes } from '../../../static/routes';
import { colors } from '../../../styles/variables';
import { ITranslation } from '../../../types/ApiTypes';
import { GameType, GameTypes } from '../../../types/GlobalTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import TemplatesListTableStartGameModal from '../../templates/components/templatesViewList/components/templatesListTableStartGameModal/TemplatesListTableStartGameModal';

interface EditTemplateActionBoxProps {
  templateId: string;
  templateName: ITranslation[];
  gameType: GameType;
}

const EditTemplateActionBox = ({ templateId, templateName, gameType }: EditTemplateActionBoxProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isStartGameModalOpen, setIsStartGameModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);

  const shouldShowAdditionalActions = gameType === GameTypes.BUSINESS || gameType === GameTypes.INDIVIDUAL_PAID;

  const { deleteTemplate, isLoading: isLoadingDelete } = useDeleteTemplate({
    onSuccess: () => {
      navigate(routes.templates());
      queryClient.refetchQueries([QueryAliases.TEMPLATES]);
    },
  });

  const handleDeleteTemplate = async () => {
    deleteTemplate(templateId);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <div className="flex gap-6">
        {shouldShowAdditionalActions && (
          <>
            <IconButton
              onClick={() => setIsStatsModalOpen(true)}
              icon={<ChartBarSquareIcon color={colors.grey700} width={24} />}
              className="border border-grey400"
            />
            <IconButton
              onClick={() => setIsStartGameModalOpen(true)}
              icon={<PlayIcon color={colors.grey700} />}
              className="border border-grey400"
            />
          </>
        )}
        <IconButton
          onClick={() => navigate(routes.copyTemplate(templateId))}
          icon={<CopyIcon color={colors.grey700} />}
          className="border border-grey400"
        />
        <IconButton
          onClick={() => setIsDeleteModalOpen(true)}
          icon={<TrashIcon color={colors.grey700} />}
          className="border border-grey400"
        />
      </div>

      <TemplatesListTableStartGameModal
        templateId={templateId}
        isOpen={isStartGameModalOpen}
        setIsOpen={setIsStartGameModalOpen}
        gameType={gameType}
      />

      <TypeToConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        textToType={t('templates.table.delete.textToType')}
        title={t('templates.table.delete.title')}
        confirmBtnText={t('templates.table.delete.confirmBtnText')}
        confirmBtnAction={handleDeleteTemplate}
        cancelBtnText={t('templates.table.delete.cancelBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        isConfirmBtnDisabled={isLoadingDelete}
        isConfirmBtnLoading={isLoadingDelete}
      />

      <TemplateStatsModal
        isOpen={isStatsModalOpen}
        setIsOpen={setIsStatsModalOpen}
        templateId={templateId}
        templateName={templateName}
      />
    </>
  );
};

export default EditTemplateActionBox;

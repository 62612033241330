import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiGameCode, IGameCode } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';
import { transformGameCode } from '../../../utils/api/dataTransformers/transformGameCode/transformGameCode';

const useGameQuestions = ({ gameId }: { gameId: string }) => {
  const axiosInstance = useAxiosInstance();
  const { data, refetch, isSuccess, isLoading, isError, isFetching } = useQuery([QueryAliases.GAME_CODES(gameId)], () =>
    axiosInstance.get(api.endpoints.GAME_CODES({ gameId })),
  );
  const apiCodes: ApiGameCode[] | undefined = data?.data;
  const iCodes: IGameCode[] | undefined = !!apiCodes ? apiCodes.map(code => transformGameCode(code)) : [];

  return {
    gameCodes: iCodes,
    refetch,
    isSuccess,
    isLoading,
    isError,
    isFetching,
  };
};

export default useGameQuestions;

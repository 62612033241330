import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiAllTemplatesItem, ApiFeaturesResponse, IAllTemplatesItem } from '../../../types/ApiTypes';
import { LangType } from '../../../types/GlobalTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { transformAllTemplatesItem } from '../../../utils/api/dataTransformers/transformTemplate/transformAllTemplatesItem';
import { getEndpointQueryFilters } from '../../../utils/api/getQueryFilters/getEndpointQueryFilters';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useTemplatesProps {
  lang?: LangType;
  search?: string;
  limit?: number;
  page?: number;
  filters?: { gameCharacter: string; city: string; tags: string[]; owner: string; gameType: string };
}

const useTemplates = ({ lang, search, limit, page, filters }: useTemplatesProps = {}) => {
  const axiosInstance = useAxiosInstance();

  const filtersParams = !filters ? '' : getEndpointQueryFilters(filters);

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [
      QueryAliases.TEMPLATES,
      lang ?? '',
      search ?? '',
      limit ?? '',
      page ?? '',
      filters?.gameCharacter ?? '',
      filters?.tags ?? '',
      filters?.city ?? '',
      filters?.owner ?? '',
      filters?.gameType ?? '',
    ],
    () =>
      axiosInstance.get(`${api.endpoints.TEMPLATES}${filtersParams}`, {
        params: {
          lang,
          search,
          limit,
          page,
        },
      }),
  );

  const apiTemplates: ApiFeaturesResponse<ApiAllTemplatesItem[]> | undefined = data?.data;

  const iTemplates: ApiFeaturesResponse<IAllTemplatesItem[]> | undefined = !!apiTemplates
    ? {
        count: apiTemplates.count,
        data: apiTemplates.data.map(template => transformAllTemplatesItem(template)),
      }
    : undefined;

  return { templates: iTemplates, refetch, isSuccess, isLoading, isError };
};

export default useTemplates;

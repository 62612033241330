import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { colors } from '../../../../styles/variables';
import { LangType, Langs, QuestionType, QuestionTypes } from '../../../../types/GlobalTypes';
import getInputState from '../../../../utils/getInputState/getInputState';
import XMarkButton from '../../../common/buttons/XMarkButton';
import FormSectionTitle from '../../../common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../common/inputs/LabeledStateInput';
import PlusIcon from '../../../icons/PlusIcon';
import { QuestionFormInputs, QuestionFormInputsKeys } from '../types/QuestionFormTypes';

const getBlankAnswerGroup = () => {
  const id = uuidv4();

  const newAnswers = [
    { lang: Langs.PL, value: '', answerId: id },
    { lang: Langs.EN, value: '', answerId: id },
    { lang: Langs.UA, value: '', answerId: id },
  ];

  return newAnswers;
};

interface QuestionFormAnswersProps {
  activeLang: LangType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage: (field: QuestionFormInputsKeys) => any;
  questionType: QuestionType;
}

const QuestionFormAnswers = ({ activeLang, getErrorMessage, questionType }: QuestionFormAnswersProps) => {
  const { t } = useTranslation();

  const { register, control } = useFormContext<QuestionFormInputs>();

  const {
    fields: correctAnswersFields,
    append: appendCorrectAnswer,
    remove: removeCorrectAnswer,
  } = useFieldArray({
    control,
    name: 'correctAnswers',
  });

  const {
    fields: wrongAnswersFields,
    append: appendWrongAnswer,
    remove: removeWrongAnswer,
  } = useFieldArray({
    control,
    name: 'incorrectAnswers',
  });

  const isCorrectAnswerVisible =
    questionType === QuestionTypes.CLOSED ||
    questionType === QuestionTypes.OPEN_SHORT_TEXT ||
    questionType === QuestionTypes.ANIMATOR;

  const isIncorrectAnswerVisible = questionType === QuestionTypes.CLOSED;

  const deleteCorrectAnswerGroup = (answerId: string) => {
    const answersToDelete = correctAnswersFields.filter(ans => ans.answerId === answerId);

    answersToDelete.forEach(ans => {
      const indexToDelete = correctAnswersFields.findIndex(item => item.answerId === ans.answerId);
      removeCorrectAnswer(indexToDelete);
    });
  };

  const deleteWrongAnswerGroup = (answerId: string) => {
    const answersToDelete = wrongAnswersFields.filter(ans => ans.answerId === answerId);

    answersToDelete.forEach(ans => {
      const indexToDelete = wrongAnswersFields.findIndex(item => item.answerId === ans.answerId);
      removeWrongAnswer(indexToDelete);
    });
  };

  return (
    <>
      {(isCorrectAnswerVisible || isIncorrectAnswerVisible) && (
        <FormSectionTitle className="mb-6" title={t('questionForm.answers.title')} />
      )}

      {isCorrectAnswerVisible && (
        <div>
          {correctAnswersFields.map((answer, index) => {
            if (answer.lang !== activeLang) {
              return;
            }

            const isFirst = answer.answerId === correctAnswersFields[0]?.answerId;

            return (
              <div key={answer.id} className="relative pb-7 max-w-lg flex items-center gap-2 justify-between">
                <LabeledStateInput
                  id={`correct-answer-${answer.id}`}
                  state={getInputState(getErrorMessage('correctAnswers')?.[index])}
                  labeledProps={{
                    wrapperClassName: 'max-w-xl grow',
                    label: t('questionForm.answers.correctAnswerLabel'),
                    errorMessage: t(`errorMessages.${getErrorMessage('correctAnswers')?.[index]?.value?.message}`),
                  }}
                  inputProps={{
                    register: {
                      ...register(`correctAnswers.${index}.value`),
                    },
                  }}
                />

                {!isFirst && (
                  <XMarkButton
                    onClick={() => deleteCorrectAnswerGroup(answer.answerId)}
                    className="absolute -right-12 top-5"
                  />
                )}
              </div>
            );
          })}

          {!!getErrorMessage('correctAnswers')?.message && (
            <p className="text-error text-xs">{t(`errorMessages.${getErrorMessage('correctAnswers')?.message}`)}</p>
          )}

          <button
            className="border-b border-grey200 flex px-2 py-6 text-black700 font-medium text-base gap-4"
            type="button"
            onClick={() => {
              const newAnswers = getBlankAnswerGroup();

              appendCorrectAnswer(newAnswers);
            }}
          >
            <PlusIcon color={colors.black500} />
            {t('questionForm.answers.addCorrectAnswerBtn')}
          </button>
        </div>
      )}

      {isIncorrectAnswerVisible && (
        <div className="mt-6">
          {wrongAnswersFields.map((answer, index) => {
            if (answer.lang !== activeLang) {
              return;
            }

            return (
              <div key={answer.id} className="relative pb-7 max-w-lg flex items-center gap-2 justify-between">
                <LabeledStateInput
                  id={`wrong-answer-${answer.id}`}
                  state={getInputState(getErrorMessage('incorrectAnswers')?.[index])}
                  labeledProps={{
                    wrapperClassName: 'max-w-xl grow',
                    label: t('questionForm.answers.wrongAnswerLabel'),
                    errorMessage: t(`errorMessages.${getErrorMessage('incorrectAnswers')?.[index]?.value?.message}`),
                  }}
                  inputProps={{
                    register: {
                      ...register(`incorrectAnswers.${index}.value`),
                    },
                  }}
                />

                <XMarkButton
                  onClick={() => deleteWrongAnswerGroup(answer.answerId)}
                  className="absolute -right-12 top-5"
                />
              </div>
            );
          })}

          {!!getErrorMessage('incorrectAnswers')?.message && (
            <p className="text-error text-xs">{t(`errorMessages.${getErrorMessage('incorrectAnswers')?.message}`)}</p>
          )}

          <button
            className="border-b border-grey200 flex px-2 py-6 text-black700 font-medium text-base gap-4"
            type="button"
            onClick={() => {
              const newAnswers = getBlankAnswerGroup();

              appendWrongAnswer(newAnswers);
            }}
          >
            <PlusIcon color={colors.black500} />
            {t('questionForm.answers.addWrongAnswerBtn')}
          </button>
        </div>
      )}
    </>
  );
};

export default QuestionFormAnswers;

import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiBundle, ISingleBundle } from '../../../types/ApiBundleTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useLimitDictionary from '../limitDictionary/useLimitDictionary';

const useAllBundles = () => {
  const axiosInstance = useAxiosInstance();
  const { convertLimitsForView } = useLimitDictionary();

  const { data, isLoading } = useQuery([QueryAliases.ALL_BUNDLES], () => axiosInstance.get(api.endpoints.BUNDLES));

  const formatedBundles: ISingleBundle[] = data?.data.map((bundle: ApiBundle) => {
    const formatedLimits = convertLimitsForView(bundle.limits);
    return { id: bundle.id, bundleName: bundle.bundleName, ...formatedLimits };
  });

  return { bundles: formatedBundles, isLoading };
};

export default useAllBundles;

import { colors } from '../../../styles/variables';
import { SuccessIcon } from '../../icons/SuccessIcon';
import XMarkIcon from '../../icons/XmarkIcon';

interface PasswordCriteriaItemProps {
  isSuccess: boolean;
  text: string;
}

const PasswordCriteriaItem = ({ isSuccess, text }: PasswordCriteriaItemProps) => {
  return (
    <div className="flex gap-2 items-center">
      {isSuccess ? (
        <SuccessIcon />
      ) : (
        <div className="px-1">
          <XMarkIcon color={colors.grey700} size="8" />
        </div>
      )}
      <p className={`text-xs font-normal leading-normal ${isSuccess ? 'text-success' : 'text-grey700'}`}>{text}</p>
    </div>
  );
};

export default PasswordCriteriaItem;

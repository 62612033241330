import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BreadcrumbsProps } from '../../components/common/breadcrumbs/Breadcrumbs';
import PartnerForm from '../../components/forms/partnerForm/PartnerForm';
import useBundlesDropdownOptions from '../../components/forms/partnerForm/hooks/useBundlesDropdownOptions';
import usePrepareInitialPartner from '../../components/forms/partnerForm/hooks/usePrepareInitialPartner';
import useTemplatesDropdownOptions from '../../components/forms/partnerForm/hooks/useTemplatesDropdownOptions';
import { PartnerFormSubmitProps } from '../../components/forms/partnerForm/types/partnerFormTypes';
import EditIcon from '../../components/icons/EditIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import usePartnerById from '../../hooks/api/partner/usePartnerById';
import { colors } from '../../styles/variables';
import useHandleEditPartner from './hooks/useHandleEditPartner';

const PartnerFormWithLoadingState = withLoading(PartnerForm);

const EditPartnerView = () => {
  const { t } = useTranslation();
  const params = useParams();
  const partnerId = params.partnerId as string;

  const { partner } = usePartnerById({ partnerId });
  const { templatesDropdownOptions } = useTemplatesDropdownOptions();
  const { bundleDropdownOptions } = useBundlesDropdownOptions();
  const { prepareInitialPartner } = usePrepareInitialPartner();

  const initialPartner = partner && prepareInitialPartner(partner);

  const { initEditingPartnerData, editingPartnerInProgress } = useHandleEditPartner();

  const handleSubmitPatnerForm = async ({
    partner,
    baseDataHasChanged,
    subscriptionHasChanged,
    templatesHasChanged,
  }: PartnerFormSubmitProps) => {
    initEditingPartnerData({ partner, baseDataHasChanged, subscriptionHasChanged, templatesHasChanged });
  };

  const breadcrumbsProps: BreadcrumbsProps = {
    limit: 2,
    extraBreadcrumbs: [{ text: initialPartner ? initialPartner?.baseData.name : '' }],
  };

  const allDataReady = !!initialPartner && !!bundleDropdownOptions && !!templatesDropdownOptions;

  return (
    <TitleWrapper title={t('partner.editPartner.title')} breadcrumbsProps={breadcrumbsProps}>
      <PartnerFormWithLoadingState
        submitBtnIcon={<EditIcon color={colors.white900} />}
        submitBtnText={t('partner.editPartner.editPartnerButtonText')}
        submitForm={handleSubmitPatnerForm}
        initialPartner={initialPartner}
        submitIsInProgess={editingPartnerInProgress}
        templatesDropdownOptions={templatesDropdownOptions || []}
        bundleDropdownOptions={bundleDropdownOptions || []}
        isLoading={!allDataReady}
      />
    </TitleWrapper>
  );
};

export default EditPartnerView;

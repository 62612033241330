import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

import FilterChip from '../../../components/common/dropdowns/FilterDropdown/components/FilterChip';
import FilterDropdown from '../../../components/common/dropdowns/FilterDropdown/FilterDropdown';
import FilterIcon from '../../../components/icons/FilterIcon';
import { colors } from '../../../styles/variables';
import { Tag } from '../../../types/ApiTypes';
import { DropdownOption } from '../../../types/Dropdown.types';

interface QuestionsViewFiltersProps {
  isGlobalAdmin: boolean;
  tags: Tag[];
  tagFilters: string[];
  setTagFilters: (newTags: string[]) => void;
  questionTypesOptions: {
    id: string;
    value: string;
  }[];
  questionTypeFilter: string;
  setQuestionTypeFilter: (newValue: string) => void;
  citiesOptions: string[];
  cityFilter: string;
  setCityFilter: (newValue: string) => void;
  ownersOptions: {
    id: string;
    email: string;
  }[];
  ownerFilter: string;
  setOwnerFilter: (newValue: string) => void;
  isArcadeOptions: {
    id: string;
    value: string;
  }[];
  arcadeFilter: string;
  setArcadeFilter: (newValue: string) => void;
}

const QuestionsViewFilters = ({
  isGlobalAdmin,
  tags,
  tagFilters,
  setTagFilters,
  questionTypesOptions,
  questionTypeFilter,
  setQuestionTypeFilter,
  citiesOptions,
  cityFilter,
  setCityFilter,
  ownersOptions,
  ownerFilter,
  setOwnerFilter,
  arcadeFilter,
  setArcadeFilter,
  isArcadeOptions,
}: QuestionsViewFiltersProps) => {
  const { t } = useTranslation();

  const handleChangeTagFilters = (option: DropdownOption | null) => {
    if (!option?.value) {
      return;
    }

    const isExisting = tagFilters.some(tagItem => tagItem === option.value);

    if (isExisting) {
      const remainingTagString = tagFilters.filter(item => item !== option.value);
      setTagFilters(remainingTagString);
    } else {
      const newTagString = [...tagFilters, option.value];
      setTagFilters(newTagString);
    }
  };

  const handleDeleteTagFilter = (tagToRemove: string) => {
    const remainingTagString = tagFilters.filter(item => item !== tagToRemove);
    setTagFilters(remainingTagString);
  };

  return (
    <div className="flex gap-4">
      <FilterDropdown
        renderButton={({ open }) => (
          <>
            <FilterIcon />
            <p className="text-black500 whitespace-nowrap px-1.5">{t('questions.table.filters.title')}</p>
            {open ? (
              <ChevronUpIcon color={colors.black700} className="w-8 h-4" />
            ) : (
              <ChevronDownIcon color={colors.black700} className="w-8 h-4" />
            )}
          </>
        )}
        optionGroups={[
          {
            id: uuidv4(),
            label: t('questions.table.filters.tag'),
            options: tags.map(tag => ({
              id: tag.tagName,
              value: tag.tagName,
            })),
            onSetNewOption: handleChangeTagFilters,
            activeOptionIds: [...tagFilters],
          },
          {
            id: uuidv4(),
            label: t('questions.table.filters.questionType'),
            options: questionTypesOptions,
            onSetNewOption: option => setQuestionTypeFilter(option?.id ?? ''),
            activeOptionIds: [questionTypeFilter],
          },
          {
            id: uuidv4(),
            label: t('questions.table.filters.city'),
            options: citiesOptions.map(city => ({
              id: city,
              value: city,
            })),
            onSetNewOption: option => setCityFilter(option?.value ?? ''),
            activeOptionIds: [cityFilter],
          },
          {
            id: uuidv4(),
            label: t('questions.table.filters.localization'),
            options: isArcadeOptions,
            onSetNewOption: () => setArcadeFilter('isArcade' ?? ''),
            activeOptionIds: [arcadeFilter],
          },
          ...(isGlobalAdmin
            ? [
                {
                  id: uuidv4(),
                  label: t('questions.table.filters.owner'),
                  options:
                    ownersOptions?.map(owner => ({
                      id: owner.id,
                      value: owner.email,
                    })) ?? [],
                  onSetNewOption: (option: DropdownOption | null) => setOwnerFilter(option?.value ?? ''),
                  activeOptionIds: !!ownersOptions.find(option => option.email === ownerFilter)?.id
                    ? [ownersOptions.find(option => option.email === ownerFilter)!.id]
                    : [],
                },
              ]
            : []),
        ]}
      />

      {!!questionTypeFilter && (
        <FilterChip label={t(`questionTypes.${questionTypeFilter}`)} onDelete={() => setQuestionTypeFilter('')} />
      )}
      {tagFilters.length > 0 &&
        tagFilters.map(singleTag => (
          <FilterChip key={singleTag} label={singleTag} onDelete={() => handleDeleteTagFilter(singleTag)} />
        ))}
      {!!cityFilter && <FilterChip label={cityFilter} onDelete={() => setCityFilter('')} />}
      {!!ownerFilter && <FilterChip label={ownerFilter} onDelete={() => setOwnerFilter('')} />}
    </div>
  );
};

export default QuestionsViewFilters;

import { useEffect, useMemo, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

export function useSearchBar(value: string) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const handlers = useMemo(
    () => ({
      open: () => setIsOpen(true),
      close: () => {
        if (!value) {
          setIsOpen(false);
        }
      },
    }),
    [value],
  );

  useOnClickOutside(containerRef, handlers.close);

  useEffect(() => {
    if (isOpen && containerRef.current) {
      containerRef.current.querySelector('input')?.focus();
    }
  }, [isOpen]);

  return [containerRef, isOpen, handlers] as const;
}

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import { MultiDropdown } from '../../../../../components/common/dropdowns/MultiDropdown';
import Textarea from '../../../../../components/common/inputs/Textarea';
import useSendMessage from '../../../../../hooks/api/games/messages/useSendMessage';
import useGameUsers from '../../../../../hooks/api/games/useGameUsers';
import { ButtonSize } from '../../../../../types/ButtonSizes';
import { DropdownOption } from '../../../../../types/Dropdown.types';
import { GameStatus, GameStatuses } from '../../../../../types/GlobalTypes';
import useSetInitialMessageReceiver from '../hooks/useSetInitialMessageReceiver';

interface NewMessageProps {
  gameId: string;
  gameStatus?: GameStatus;
}

const NewMessage = ({ gameId, gameStatus }: NewMessageProps) => {
  const { t } = useTranslation();

  const { gameUsers: users } = useGameUsers({ gameId });
  const usersDropdownOptions =
    users?.map(user => {
      const option: DropdownOption = {
        id: user.userGame.user.id.toString(),
        value: `${user.userGame.teamName ?? t('singleActiveGame.messages.noTeamName')} ${
          !!user.userGame.user.email ? `(${user.userGame.user.email?.split('@')[0]})` : ''
        }`,
      };
      return option;
    }) ?? [];

  const [newMessageValue, setNewMessageValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<DropdownOption[]>([]);

  useSetInitialMessageReceiver({ usersDropdownOptions, setSelectedUsers });

  const { sendMessage, isLoading } = useSendMessage({
    gameId,
    onSuccess: () => {
      setNewMessageValue('');
      setSelectedUsers([]);
    },
  });

  const handleSendMessage = ({ receiverIds }: { receiverIds: number[] }) => {
    sendMessage({ users: receiverIds, message: newMessageValue });
  };

  return (
    <div className="p-5 ">
      <div className="flex gap-2.5 items-center justify-start mb-6">
        <p className="text-black300 text-sm font-medium">{t('singleActiveGame.messages.newMessage')}</p>
      </div>

      {gameStatus === GameStatuses.FINISHIED ? (
        <p className="text-grey700 my-6">{t('singleActiveGame.messages.gameFinishedInfo')}</p>
      ) : (
        <>
          <MultiDropdown
            options={usersDropdownOptions}
            currentOptions={selectedUsers}
            setNewOptions={setSelectedUsers}
            placeholder={t('singleActiveGame.messages.choosePlayers')}
          />
          <Textarea
            className="resize-none mt-6 min-h-[11rem]"
            placeholder={t('singleActiveGame.messages.textPlaceholder')}
            value={newMessageValue}
            onChange={e => setNewMessageValue(e.target.value)}
          />
        </>
      )}

      <div className="mt-2 flex justify-end">
        <PrimaryButton
          disabled={!newMessageValue.trim() || selectedUsers.length === 0 || isLoading}
          loading={isLoading}
          onClick={() => handleSendMessage({ receiverIds: selectedUsers.map(user => +user.id) })}
          className="w-fit"
          size={ButtonSize.Small}
        >
          {t('singleActiveGame.messages.send')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default NewMessage;

import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { colors } from '../../../styles/variables';
import { IPointOption } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface ApiPointOption {
  id: number;
  points: string;
  color: string;
}

const useQuestionPointsOptions = () => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery([QueryAliases.QUESTION_POINTS_OPTIONS], () =>
    axiosInstance.get(api.endpoints.QUESTION_POINTS_OPTIONS),
  );

  const apiPointOptions: ApiPointOption[] | undefined = data?.data;

  const iPointsData: IPointOption[] | undefined = !!apiPointOptions
    ? apiPointOptions.map(option => ({
        id: option.id,
        points: option.points,
        color: option.color,
      }))
    : undefined;

  const iPointOptions: IPointOption[] | undefined = !!iPointsData
    ? iPointsData.filter(option => option.points !== '0')
    : undefined;

  const fallbackColor = iPointsData?.find(option => option.points === '0')?.color ?? colors.black700;

  return {
    pointsData: iPointsData,
    pointsOptions: iPointOptions,
    pointsFallbackColor: fallbackColor,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useQuestionPointsOptions;

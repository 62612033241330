import MapMarkerQuestionType from '../../../components/map/components/MapMarkerQuestionType';
import { ITemplateDetails } from '../../../types/ApiTypes';

interface GamePreviewQuestionsProps {
  templateQuestions: ITemplateDetails['questionToTemplate'];
  mapHeight: number;
}

const GamePreviewQuestions = ({ templateQuestions, mapHeight }: GamePreviewQuestionsProps) => {
  return (
    <>
      {templateQuestions.map(templateQuestion => (
        <MapMarkerQuestionType
          key={templateQuestion.id}
          mapHeight={mapHeight}
          question={{ ...templateQuestion.question, coordinates: templateQuestion.coordinates }}
          disableQuestionEdit
        />
      ))}
    </>
  );
};

export default GamePreviewQuestions;

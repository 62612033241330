/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LangType } from '../../../../types/GlobalTypes';
import getInputState from '../../../../utils/getInputState/getInputState';
import FormSectionTitle from '../../../common/formSectionTitle/FormSectionTitle';
import SingleImagePicker from '../../../common/imagePickers/singleImagePicker/SingleImagePicker';
import { LabeledStateInput } from '../../../common/inputs/LabeledStateInput';
import { QuestionFormInputs, QuestionFormInputsKeys } from '../types/QuestionFormTypes';

interface QuestionFormAnswerMessagesProps {
  activeLang: LangType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage: (field: QuestionFormInputsKeys) => any;
  oldCorrectAnswerImageUrl?: string | null;
  newCorrectAnswerImage: File | null;
  setNewCorrectAnswerImage: Dispatch<SetStateAction<File | null>>;
  oldIncorrectAnswerImageUrl?: string | null;
  newIncorrectAnswerImage: File | null;
  setNewIncorrectAnswerImage: Dispatch<SetStateAction<File | null>>;
}

const QuestionFormAnswerMessages = ({
  activeLang,
  getErrorMessage,
  oldCorrectAnswerImageUrl,
  newCorrectAnswerImage,
  setNewCorrectAnswerImage,
  oldIncorrectAnswerImageUrl,
  newIncorrectAnswerImage,
  setNewIncorrectAnswerImage,
}: QuestionFormAnswerMessagesProps) => {
  const { t } = useTranslation();

  const { register, control } = useFormContext<QuestionFormInputs>();

  const { fields: correctAnswerFields } = useFieldArray({
    name: 'correctAnswerText',
    control,
  });

  const { fields: incorrectAnswerFields } = useFieldArray({
    name: 'incorrectAnswerText',
    control,
  });

  return (
    <>
      <FormSectionTitle className="mb-6" title={t('questionForm.answerMessages.title')} />

      {correctAnswerFields.map((field, index) => {
        if (field.lang !== activeLang) {
          return;
        }
        return (
          <div key={field.id} className="pb-7 max-w-lg">
            <LabeledStateInput
              id={`correct-answer-${field.id}`}
              state={getInputState(getErrorMessage('correctAnswerText')?.[index])}
              labeledProps={{
                wrapperClassName: 'max-w-xl',
                label: t('questionForm.answerMessages.correctAnswerMessageLabel'),
                errorMessage: t(`errorMessages.${getErrorMessage('correctAnswerText')?.[index]?.value?.message}`),
              }}
              inputProps={{
                register: {
                  ...register(`correctAnswerText.${index}.value`),
                },
              }}
            />
          </div>
        );
      })}

      <div className="pb-7 max-w-lg">
        <SingleImagePicker
          title={t('questionForm.answerMessages.correntAnswerImageTitle')}
          subtitle={t('questionForm.answerMessages.correntAnswerImageSubitle')}
          oldImageUrl={oldCorrectAnswerImageUrl}
          selectedFile={newCorrectAnswerImage}
          setSelectedFile={setNewCorrectAnswerImage}
        />
      </div>

      {incorrectAnswerFields.map((field, index) => {
        if (field.lang !== activeLang) {
          return;
        }
        return (
          <div key={field.id} className="pb-7 max-w-lg">
            <LabeledStateInput
              id={`wrong-answer-${field.id}`}
              state={getInputState(getErrorMessage('incorrectAnswerText')?.[index])}
              labeledProps={{
                wrapperClassName: 'max-w-xl',
                label: t('questionForm.answerMessages.wrongAnswerMessageLabel'),
                errorMessage: t(`errorMessages.${getErrorMessage('incorrectAnswerText')?.[index]?.value?.message}`),
              }}
              inputProps={{
                register: {
                  ...register(`incorrectAnswerText.${index}.value`),
                },
              }}
            />
          </div>
        );
      })}

      <div className="pb-7 max-w-lg">
        <SingleImagePicker
          title={t('questionForm.answerMessages.incorrentAnswerImageTitle')}
          subtitle={t('questionForm.answerMessages.incorrentAnswerImageSubitle')}
          oldImageUrl={oldIncorrectAnswerImageUrl}
          selectedFile={newIncorrectAnswerImage}
          setSelectedFile={setNewIncorrectAnswerImage}
        />
      </div>
    </>
  );
};

export default QuestionFormAnswerMessages;

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, UseFormSetError, UseFormUnregister, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';
import { LabeledStateInput } from '../../../../components/common/inputs/LabeledStateInput';
import PasswordCriteriaItem from '../../../../components/forms/passwordCriteriaItem/PasswordCriteriaItem';
import { min1NumberRegex, min1SpecialCharRegex, min1UppercaseRegex } from '../../../../static/regex';
import { registerPlayerValidationSchema } from '../../../../static/validation/schemas/registerPlayerValidationSchema';
import { ApiRegisterPlayer } from '../../../../types/ApiTypes';
import getInputState from '../../../../utils/getInputState/getInputState';

type RegisterRelatedUserFormProps = {
  onFormSubmit: (
    data: ApiRegisterPlayer,
    setError: UseFormSetError<ApiRegisterPlayer>,
    unregister: UseFormUnregister<ApiRegisterPlayer>,
  ) => void;
  isLoading: boolean;
  onCancel?: () => void;
};

const RegisterRelatedUserForm = ({ onFormSubmit, isLoading, onCancel }: RegisterRelatedUserFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    getFieldState,
    setError,
    unregister,
    formState,
    formState: { errors, isValid },
  } = useForm<ApiRegisterPlayer>({
    mode: 'onChange',
    resolver: yupResolver(registerPlayerValidationSchema),
  });

  const onSubmit: SubmitHandler<ApiRegisterPlayer> = data => {
    onFormSubmit(data, setError, unregister);
  };

  const passwordCurrentValue = watch('newPassword');
  const loginCurrentValue = watch('login');
  const disableSubmit = isLoading || !isValid;

  const getErrorMessage = (field: keyof ApiRegisterPlayer) => {
    return get(errors, field);
  };

  const loginValidation = getFieldState('login', formState);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-8 w-full">
      <div className="relative">
        <LabeledStateInput
          id="register-player-login"
          state={getInputState(getErrorMessage('login'))}
          labeledProps={{
            wrapperClassName: 'mb-8',
            label: t('account.players.loginInputLabel'),
            errorMessage: t(`errorMessages.${getErrorMessage('login')?.message}`),
          }}
          inputProps={{
            placeholder: `${t('account.players.loginPlaceholder')}`,
            className: 'pr-[41%]',
            type: 'text',
            register: {
              ...register('login'),
            },
          }}
        />
        <div className="absolute left-0 right-0 top-1/2 -translate-y-1/2 max-w-full pointer-events-none">
          <p className="h-0 opacity-0 min-w-[5rem] max-w-[60%] pl-4 mr-1 w-fit text-sm inline-block">
            {loginCurrentValue}
          </p>
          <span>{t('account.players.loginEmailConstant')}</span>
        </div>
      </div>

      <LabeledStateInput
        id="register-player-password"
        state={getInputState(getErrorMessage('newPassword'))}
        labeledProps={{
          wrapperClassName: 'mb-8',
          label: t('resetPassword.newPasswordLabel'),
          errorMessage: t(`errorMessages.${getErrorMessage('newPassword')?.message}`),
        }}
        inputProps={{
          type: 'password',
          register: {
            ...register('newPassword'),
          },
        }}
      />

      <LabeledStateInput
        id="register-player-confirmation-password"
        state={getInputState(getErrorMessage('confirmationPassword'))}
        labeledProps={{
          wrapperClassName: 'mb-8',
          label: t('resetPassword.confirmationPasswordLabel'),
          errorMessage: t(`errorMessages.${getErrorMessage('confirmationPassword')?.message}`),
        }}
        inputProps={{
          type: 'password',
          register: {
            ...register('confirmationPassword'),
          },
        }}
      />

      <div className="mb-10">
        <PasswordCriteriaItem
          isSuccess={!loginValidation.invalid && loginValidation.isDirty}
          text={t('account.players.validLoginCriteriaText')}
        />
        <PasswordCriteriaItem
          isSuccess={min1UppercaseRegex.test(passwordCurrentValue)}
          text={t('errorMessages.min1Uppercase')}
        />
        <PasswordCriteriaItem
          isSuccess={min1NumberRegex.test(passwordCurrentValue)}
          text={t('errorMessages.min1Number')}
        />
        <PasswordCriteriaItem
          isSuccess={min1SpecialCharRegex.test(passwordCurrentValue)}
          text={t('errorMessages.min1SpecialChar')}
        />
      </div>
      <div className="flex justify-center gap-10 items-center">
        <SecondaryButton onClick={onCancel} className="min-w-[10rem]">
          {t('resetPassword.cancelBtn')}
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={disableSubmit} loading={isLoading}>
          {t('account.players.addButton')}
        </PrimaryButton>
      </div>
    </form>
  );
};

export default RegisterRelatedUserForm;

import useEditPartner from '../../../hooks/api/partner/useEditPartner';
import { InitialPartnerBaseData } from './../../../types/ApiPartnerTypes';

const useHandleEditPartnerBaseData = () => {
  const { editPartner, isLoading } = useEditPartner();

  const handleEditPartnerBaseData = async ({
    baseData,
    partnerId,
  }: {
    baseData: InitialPartnerBaseData;
    partnerId: number | undefined;
  }) => {
    if (partnerId) {
      const { data } = await editPartner({ baseData, partnerId });

      const responsePartnerId: number | undefined = data?.id;
      return responsePartnerId;
    }

    return;
  };

  return { handleEditPartnerBaseData, handleEditPartnerBaseDataIsLoading: isLoading };
};

export default useHandleEditPartnerBaseData;

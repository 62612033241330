import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { CallbackDefault } from '../../../types/Types';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useAddGamePartnerLogo = ({ onSuccess }: { onSuccess?: CallbackDefault } = {}) => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const uploadImage = async ({ gameId, partnerLogo }: { gameId: string; partnerLogo: File }) => {
    const formData = new FormData();

    formData.append('partnerLogo', partnerLogo);

    const response = await axiosInstance.post(api.endpoints.GAME_PARTNER_LOGO({ gameId }), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const { mutateAsync, isLoading } = useMutation(uploadImage, {
    onSuccess: () => {
      if (!!onSuccess) {
        onSuccess();
      }
    },
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.addPartnerLogoFailed');
      showErrorToast(errorMessage);
    },
  });

  return { addGamePartnerLogo: mutateAsync, isLoading };
};

export default useAddGamePartnerLogo;

import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../../../../../../../components/dataTable/MaterialTable/MaterialTable';
import TableCell from '../../../../../../../components/dataTable/components/TableCell';
import useQuestionPointsOptions from '../../../../../../../hooks/api/questions/useQuestionPointsOptions';
import { colors } from '../../../../../../../styles/variables';
import { IGameAnswer } from '../../../../../../../types/ApiTypes';
import { QuestionTypes } from '../../../../../../../types/GlobalTypes';
import { isQuestionTextAnswer } from '../../../../../../../utils/questions/isQuestionTextAnswer/isQuestionTextAnswer';
import { IAnswersGroup } from '../../../SingleActiveGameAnswersView';
import AnswerQuestionLabel from '../../answerQuestionLabel/AnswerQuestionLabel';
import ImageAnswer from '../../imageAnswer/ImageAnswer';
import VideoAnswer from '../../videoAnswer/VideoAnswer';
import SingleCheckedAnswerActionButtons from './components/SingleCheckedAnswerActionButtons';

interface QuestionCheckedAnswersGroupProps {
  answersGroup: IAnswersGroup;
}
const QuestionCheckedAnswersGroup = ({ answersGroup }: QuestionCheckedAnswersGroupProps) => {
  const { t } = useTranslation();

  const { pointsOptions, pointsFallbackColor } = useQuestionPointsOptions();

  const pointsColor =
    pointsOptions?.find(option => option.points === answersGroup.question.points?.toString())?.color ??
    pointsFallbackColor;

  return (
    <div className="mb-10">
      <AnswerQuestionLabel question={answersGroup.question} />

      <MaterialTable
        disableRowSelectionOnClick
        columns={[
          {
            field: 'teamName',
            headerName: t('singleActiveGame.answers.table.teamName'),
            minWidth: 120,
            flex: 1,
            sortable: true,
            renderCell: (params: GridRenderCellParams<IGameAnswer>) => {
              return <TableCell>{params.row.userGame.teamName}</TableCell>;
            },
          },
          {
            field: 'answer',
            headerName: t('singleActiveGame.answers.table.answer'),
            minWidth: 120,
            flex: 1,
            sortable: true,
            renderCell: (params: GridRenderCellParams<IGameAnswer>) => {
              const questionType = params.row.question.questionType.code;
              const isCorrect = params.row.isCorrect;

              if (isQuestionTextAnswer({ questionType })) {
                return (
                  <TableCell className={isCorrect ? 'text-success' : 'text-error'}>{params.row.answerValue}</TableCell>
                );
              } else if (questionType === QuestionTypes.IMAGE) {
                return (
                  <ImageAnswer
                    src={params.row.answerValue}
                    fullscreenTitle={t('singleActiveGame.answers.imageFulscreenTitle')}
                  />
                );
              } else if (questionType === QuestionTypes.VIDEO) {
                return <VideoAnswer src={params.row.answerValue} />;
              }
              return <></>;
            },
          },
          {
            field: 'points',
            headerName: t('singleActiveGame.answers.table.points'),
            minWidth: 120,
            maxWidth: 250,
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams<IGameAnswer>) => {
              const isCorrect = params.row.isCorrect;

              return (
                <div
                  className="rounded-full border flex justify-center items-center py-2 px-2.5"
                  style={{ borderColor: isCorrect ? pointsColor : colors.grey700 }}
                >
                  <p className="text-sm font-medium" style={{ color: isCorrect ? pointsColor : colors.grey700 }}>
                    {isCorrect ? params.row?.gainedPoints : '0'}
                  </p>
                </div>
              );
            },
          },
          {
            field: 'buttons',
            headerName: '',
            minWidth: 120,
            maxWidth: 250,
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams<IGameAnswer>) => {
              const isCorrect = params.row.isCorrect;

              return isCorrect ? <></> : <SingleCheckedAnswerActionButtons answerId={params.row.id} />;
            },
          },
        ]}
        rows={answersGroup.answers}
      />
    </div>
  );
};

export default QuestionCheckedAnswersGroup;

import { ComponentState } from '../../../../types/ComponentStates.types';
import { LabeledComponent, LabeledComponentProperties, LabeledComponentProps } from '../../inputs/LabeledComponent';
import MapAutocompleteDropdown, { MapAutocompleteDropdownProps } from './MapAutocompleteDropdown';

interface LabeledProps extends Omit<LabeledComponentProps, 'children'> {
  errorMessage?: string | undefined;
}

interface LabeledStateMapAutocompleteDropdownProps {
  id: string;
  state: ComponentState;

  labeledProps: LabeledProps;
  dropdownProps: MapAutocompleteDropdownProps;
}

export const LabeledStateMapAutocompleteDropdown = ({
  id,
  labeledProps,
  dropdownProps,
  state,
}: LabeledStateMapAutocompleteDropdownProps) => {
  const { errorMessage, stateProperties: labeledStateProperties } = labeledProps;

  const myStateProperties: LabeledComponentProperties = {
    error: {
      message: errorMessage,
      color: 'text-error',
    },
    default: {},
    ...labeledStateProperties,
  };

  const labeledComponentProps = {
    id,
    state,
    stateProperties: myStateProperties,
    ...labeledProps,
  };

  return (
    <LabeledComponent {...labeledComponentProps}>
      <MapAutocompleteDropdown {...dropdownProps} />
    </LabeledComponent>
  );
};

import { TypeFromConstObject } from '../Types';

// TODO change the shape of UserStatuses when third status will be added
export const UserStatuses = {
  ACTIVE: { value: 'ACTIVE', status: 'active' },
  INACTIVE: { value: 'INACTIVE', status: 'inActive' },
  NOT_VERIFIED: { value: 'NOT_VERIFIED', status: '' },
};

export type UserStatuses = TypeFromConstObject<typeof UserStatuses>;

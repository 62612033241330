import { useTranslation } from 'react-i18next';
import { Logo } from '../../components/navigation/components/Logo';
import useRequestResetPassword from '../../hooks/api/auth/useRequestResetPassword';
import RequestResetPasswordForm from './components/RequestResetPasswordForm';

const RequestResetPasswordView = () => {
  const { t } = useTranslation();

  const { requestResetPassword, isLoading, isSuccess } = useRequestResetPassword();

  const handleRequestResetPassword = async ({ email }: { email: string }) => {
    await requestResetPassword({ email });
  };

  return (
    <div className="px-8 py-8 mx-auto flex flex-col max-w-xl h-screen justify-center">
      <Logo />
      <h1 className="mt-12 text-black700">{t('requestResetPassword.title')}</h1>

      <p className="mt-4 text-black500 font-medium leading-snug">
        {isSuccess ? t('requestResetPassword.requestSentInfo') : t('requestResetPassword.description')}
      </p>

      {!isSuccess && <RequestResetPasswordForm onFormSubmit={handleRequestResetPassword} isLoading={isLoading} />}
    </div>
  );
};

export default RequestResetPasswordView;

import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

import { colors } from '../../../styles/variables';
import { DropdownOption } from '../../../types/Dropdown.types';
import XMarkIcon from '../../icons/XmarkIcon';

const filterSelectedOptions = ({
  options,
  selectedOptions,
}: {
  options: DropdownOption[];
  selectedOptions: DropdownOption[];
}) =>
  options?.filter(
    option =>
      !selectedOptions.some(selectedOption => selectedOption.id === option.id || selectedOption.value === option.value),
  );

const filterQueryMatchingOptions = ({ query, options }: { query: string; options: DropdownOption[] }) =>
  options?.filter(option =>
    option?.value?.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
  );

export interface AutocompleteMultiDropdownProps {
  query: string;
  setQuery: (newVal: string) => void;
  queryMinLength?: number;
  allOptions: DropdownOption[];
  selectedOptions: DropdownOption[];
  setSelectedOptions: (options: DropdownOption[]) => void;
  placeholder?: string;
  allowAddNewOption?: boolean;
}

const AutocompleteMultiDropdown = ({
  query,
  setQuery,
  queryMinLength = 0,
  allOptions,
  selectedOptions,
  setSelectedOptions,
  placeholder,
  allowAddNewOption,
}: AutocompleteMultiDropdownProps) => {
  const { t } = useTranslation();

  const optionsWithoutSelectedOptions = filterSelectedOptions({ options: allOptions, selectedOptions });
  const optionsToShow = filterQueryMatchingOptions({ query, options: optionsWithoutSelectedOptions });

  const queryAlreadyExists =
    allOptions?.some(selectedOption => selectedOption?.value?.toLowerCase().trim() === query?.toLowerCase().trim()) ||
    selectedOptions?.some(
      selectedOption => selectedOption?.value?.toLowerCase().trim() === query?.toLowerCase().trim(),
    );

  const handleRemoveOption = (id: string) => {
    const newOptions = selectedOptions?.filter(option => option.id !== id);
    setSelectedOptions(newOptions);
  };

  const handleChangeOptions = (options: DropdownOption[]) => {
    setSelectedOptions(options);
    setQuery('');
  };

  const canAddNewOption = allowAddNewOption && !queryAlreadyExists && query.length > 0;

  return (
    <div
      className={`relative  rounded-md text-sm text-black500 font-medium w-full placeholder:text-grey500 outline outline-1.5 outline-grey500`}
    >
      <Combobox value={selectedOptions} onChange={handleChangeOptions} multiple>
        <div className="flex pl-4 pr-12 py-2.5 w-full">
          <div
            className={`relative cursor-default flex shrink flex-wrap overflow-x-scroll no-scrollbar ${
              selectedOptions?.length !== 0 && 'mr-1.5'
            }`}
          >
            {selectedOptions.map(option => (
              <div
                key={option.id}
                className="w-fit bg-grey200 text-black500 text-sm mt-1 rounded-md flex items-center gap-3 px-4 py-2 mr-1.5"
              >
                <p className="whitespace-nowrap text-xs leading-5">{option.value}</p>
                <button onClick={() => handleRemoveOption(option.id)}>
                  <XMarkIcon />
                </button>
              </div>
            ))}
          </div>
          <Combobox.Input
            className="border-none outline-0 rounded-md text-sm leading-5 text-black500 pr-4 py-2 w-auto min-w-[9rem] grow"
            value={query}
            onChange={event => setQuery(event.target.value)}
            placeholder={placeholder}
          />

          <Combobox.Button className="absolute inset-y-0 right-2 flex items-center p-3">
            {({ open }) => (
              <>
                {open ? (
                  <ChevronUpIcon color={colors.black700} className="w-8 h-4" />
                ) : (
                  <ChevronDownIcon color={colors.black700} className="w-8 h-4" />
                )}
              </>
            )}
          </Combobox.Button>
        </div>
        {query.length >= queryMinLength && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {canAddNewOption && (
                <Combobox.Option
                  className={`relative cursor-pointer select-none p-4 hover:bg-redSelectionBg `}
                  value={{ id: uuidv4(), value: query, isNew: true }}
                  onClick={() => setQuery('')}
                >
                  {t('autocompleteMultiDropdown.addNew')}: <span className="text-red500 font-medium">{query}</span>
                </Combobox.Option>
              )}
              {optionsToShow?.map(option => {
                return (
                  <Combobox.Option
                    key={option.id}
                    className={`relative cursor-pointer select-none p-4 hover:bg-redSelectionBg `}
                    value={option}
                  >
                    {() => (
                      <>
                        <span
                          className={`block truncate text-black500 font-medium text-sm ${
                            selectedOptions?.some(currentOption => currentOption.id === option.id) && 'pl-6'
                          }`}
                        >
                          {option.value}
                        </span>
                        {selectedOptions?.some(currentOption => currentOption.id === option.id) ? (
                          <span className={`absolute inset-y-0 left-0 flex items-center pl-3 `}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                );
              })}
              {optionsToShow?.length === 0 && (
                <div className={`p-4 text-black500 font-medium text-sm`}>{t('autocompleteMultiDropdown.noData')}</div>
              )}
            </Combobox.Options>
          </Transition>
        )}
      </Combobox>
    </div>
  );
};

export default AutocompleteMultiDropdown;

import { colors } from '../../styles/variables';

interface ToastErrorIconProps {
  color?: string;
}

const ToastErrorIcon = ({ color }: ToastErrorIconProps) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99992 6.50004L5.99992 10.5M5.99992 6.50004L9.99992 10.5M14.6666 8.50004C14.6666 12.1819 11.6818 15.1667 7.99992 15.1667C4.31802 15.1667 1.33325 12.1819 1.33325 8.50004C1.33325 4.81814 4.31802 1.83337 7.99992 1.83337C11.6818 1.83337 14.6666 4.81814 14.6666 8.50004Z"
        stroke={color ?? colors.error}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ToastErrorIcon;

import MapTeamsPaths from '../../../components/mapTeamsPaths/MapTeamsPaths';
import { IUserGame } from '../../../types/ApiTypes';

interface GamePreviewTeamsProps {
  teams: IUserGame[];
}

const GamePreviewTeams = ({ teams }: GamePreviewTeamsProps) => {
  return <MapTeamsPaths teams={teams} />;
};

export default GamePreviewTeams;

import { ApiTemplateDetails, ITemplateDetails } from '../../../../types/ApiTypes';
import { transformQuestion } from '../transformQuestion/transformQuestion';

export const transformSingleTemplate = (template: ApiTemplateDetails) => {
  const iTemplate: ITemplateDetails = {
    id: template.id,
    name: {
      id: template.name.id,
      translations: template.name.translations,
    },
    city: template.city,
    gameCharacter: template.gameCharacter.code,
    gameType: template.gameType.code,
    averageTime: template.averageTime,
    coordinates: !!template.coordinates
      ? { lat: +template.coordinates.latitude, lng: +template.coordinates.longitude, id: template.coordinates.id }
      : undefined,
    questionToTemplate: template.questionToTemplate.map(item => ({
      id: item.id,
      index: item.index,
      coordinates: {
        lat: +item.coordinates.latitude,
        lng: +item.coordinates.longitude,
      },
      question: transformQuestion(item.question),
    })),
    tags: template.tags,
    isActive: template.isActive,
    advertisements: template.advertisements,
    owner: template.owner,
  };

  return iTemplate;
};

import { useState } from 'react';
import { Modal } from '../../../../../../components/common/modals/Modal';
import FullScreenOpenIcon from '../../../../../../components/icons/FullScreenOpenIcon';
import XMarkIcon from '../../../../../../components/icons/XmarkIcon';
import { colors } from '../../../../../../styles/variables';

const ImageAnswer = ({ fullscreenTitle, src }: { fullscreenTitle: string; src: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="relative border border-grey300 rounded-md w-20 h-20">
        <img src={src} alt="answer image" className=" overflow-hidden w-full h-full object-cover rounded-md" />

        <button
          onClick={() => setIsModalOpen(true)}
          className="absolute bottom-1 right-1 z-10 bg-white900 bg-opacity-90 rounded-full p-1"
        >
          <FullScreenOpenIcon color={colors.grey700} width={16} height={16} />
        </button>
      </div>

      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <div>
          <div className="p-6 relative border-b border-grey300">
            <h2 className="text-center text-[1.375rem] font-medium">{fullscreenTitle}</h2>
            <div
              onClick={() => setIsModalOpen(false)}
              className="w-8 h-8 min-w-8 absolute top-6 right-6 lg:left-6 flex justify-center items-center cursor-pointer"
            >
              <XMarkIcon />
            </div>
          </div>

          <div className="p-6 pt-16 pb-24">
            <img src={src} alt="answer image" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageAnswer;

import { colors } from '../../styles/variables';

interface CopyIconProps {
  color?: string;
  height?: number;
  width?: number;
}

const CopyIcon = ({ color, height, width }: CopyIconProps) => {
  return (
    <svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00006 8V5.2C8.00006 4.0799 8.00006 3.51984 8.21805 3.09202C8.40979 2.71569 8.71576 2.40973 9.09208 2.21799C9.5199 2 10.08 2 11.2001 2H18.8001C19.9202 2 20.4802 2 20.908 2.21799C21.2844 2.40973 21.5903 2.71569 21.7821 3.09202C22.0001 3.51984 22.0001 4.0799 22.0001 5.2V12.8C22.0001 13.9201 22.0001 14.4802 21.7821 14.908C21.5903 15.2843 21.2844 15.5903 20.908 15.782C20.4802 16 19.9202 16 18.8001 16H16.0001M5.20006 22H12.8001C13.9202 22 14.4802 22 14.908 21.782C15.2844 21.5903 15.5903 21.2843 15.7821 20.908C16.0001 20.4802 16.0001 19.9201 16.0001 18.8V11.2C16.0001 10.0799 16.0001 9.51984 15.7821 9.09202C15.5903 8.71569 15.2844 8.40973 14.908 8.21799C14.4802 8 13.9202 8 12.8001 8H5.20006C4.07996 8 3.5199 8 3.09208 8.21799C2.71576 8.40973 2.40979 8.71569 2.21805 9.09202C2.00006 9.51984 2.00006 10.0799 2.00006 11.2V18.8C2.00006 19.9201 2.00006 20.4802 2.21805 20.908C2.40979 21.2843 2.71576 21.5903 3.09208 21.782C3.5199 22 4.07996 22 5.20006 22Z"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;

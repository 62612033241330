import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import PlusIcon from '../../../../components/icons/PlusIcon';
import AddPlayerModal from './AddPlayerModal';

const AddPlayerButton = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex mb-12 justify-end items-end gap-3 pt-5">
      <PrimaryButton
        onClick={() => setIsModalOpen(true)}
        className="w-fit self-end"
        prefixIcon={<PlusIcon size={16} />}
      >
        {t('account.players.addButton')}
      </PrimaryButton>
      <AddPlayerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </div>
  );
};

export default AddPlayerButton;

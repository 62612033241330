import { useRef } from 'react';
import { useQueryClient } from 'react-query';
import Map from '../../../../components/map/Map';
import MapMarkerQuestionType from '../../../../components/map/components/MapMarkerQuestionType';
import MapQuestionsLegend from '../../../../components/mapQuestionsLegend/MapQuestionsLegend';
import { IQuestion } from '../../../../types/ApiTypes';
import { QueryAliases } from '../../../../types/QueryAliases';

interface QuestionsViewMapProps {
  questions: IQuestion[];
}

const QuestionsViewMap = ({ questions }: QuestionsViewMapProps) => {
  const queryClient = useQueryClient();

  const mapHeight = 1000;
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const mapStyles = { width: '100%', height: `${mapHeight - 20}px` };

  const handleQuestionEditSuccess = (id: number) => {
    queryClient.refetchQueries([QueryAliases.QUESTIONS]);
    queryClient.invalidateQueries([QueryAliases.SINGLE_QUESTION(id.toString())]);
  };

  return (
    <div ref={mapContainerRef}>
      <Map containerStyle={mapStyles} center={questions?.[0]?.coordinates}>
        {questions?.map(question => {
          return (
            <MapMarkerQuestionType
              key={question.id}
              question={question}
              mapHeight={mapHeight}
              onQuestionEditSuccess={handleQuestionEditSuccess}
            />
          );
        })}
        <div className="z-10 absolute bottom-3 left-2 flex flex-col gap-1.5">
          <MapQuestionsLegend />
        </div>
      </Map>
    </div>
  );
};

export default QuestionsViewMap;

import { colors } from '../../../styles/variables';

const GameScheduledIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 6.66536H2M10.6667 1.33203V3.9987M5.33333 1.33203V3.9987M6 10.6654L7.33333 11.9987L10.3333 8.9987M5.2 14.6654H10.8C11.9201 14.6654 12.4802 14.6654 12.908 14.4474C13.2843 14.2556 13.5903 13.9497 13.782 13.5733C14 13.1455 14 12.5855 14 11.4654V5.86536C14 4.74526 14 4.18521 13.782 3.75738C13.5903 3.38106 13.2843 3.0751 12.908 2.88335C12.4802 2.66536 11.9201 2.66536 10.8 2.66536H5.2C4.0799 2.66536 3.51984 2.66536 3.09202 2.88335C2.71569 3.0751 2.40973 3.38106 2.21799 3.75738C2 4.18521 2 4.74526 2 5.86536V11.4654C2 12.5855 2 13.1455 2.21799 13.5733C2.40973 13.9497 2.71569 14.2556 3.09202 14.4474C3.51984 14.6654 4.0799 14.6654 5.2 14.6654Z"
        stroke={color ?? colors.blue500}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GameScheduledIcon;

import { QuestionFormInputs } from '../../../components/forms/questionForm/types/QuestionFormTypes';
import { checkIfCorrectAnswersRequired } from '../../../components/forms/questionForm/utils/checkIfCorrectAnswersRequired';
import { checkIfWrongAnswersRequired } from '../../../components/forms/questionForm/utils/checkIfWrongAnswersRequired';
import { ApiAddQuestionRequest } from '../../../types/ApiTypes';
import { Langs, MultilangString, QuestionTypes } from '../../../types/GlobalTypes';
import { findValueByLang } from '../../findValueByLang/findValueByLang';
import { getCoordinatesFromString } from '../../general/getCoordinatesFromString/getCoordinatesFromString';
import { prepareQuestionAnswers } from '../prepareQuestionAnswers/prepareQuestionAnswers';

export const prepareQuestionRequestData = (inputData: QuestionFormInputs) => {
  const questionType = inputData.questionType;
  const shouldAddCorrectAnswers = checkIfCorrectAnswersRequired(questionType.id);
  const shouldAddWrongAnswers = checkIfWrongAnswersRequired(questionType.id);
  const coordinates = getCoordinatesFromString(inputData.coordinates ?? '');
  const isAnimatorQuestion = questionType.id === QuestionTypes.ANIMATOR;

  const defaultLangs = Object.values(Langs).reduce((acc, lang) => {
    acc[lang] = '';
    return acc;
  }, {} as Record<string, string>);

  const name = Object.values(Langs).reduce(
    (acc, lang) => {
      acc[lang] =
        findValueByLang({ lang, array: inputData.name }) ||
        (findValueByLang({ lang: Langs.PL, array: inputData.name }) as string);
      return acc;
    },
    { ...defaultLangs },
  );

  const content = Object.values(Langs).reduce(
    (acc, lang) => {
      acc[lang] =
        findValueByLang({ lang, array: inputData.content }) ||
        (findValueByLang({ lang: Langs.PL, array: inputData.content }) as string);
      return acc;
    },
    { ...defaultLangs },
  );

  const correctAnswerText = inputData.correctAnswerText
    ? Object.values(Langs).reduce(
        (acc, lang) => {
          acc[lang] =
            findValueByLang({ lang, array: inputData.correctAnswerText as MultilangString[] }) ||
            (findValueByLang({ lang: Langs.PL, array: inputData.correctAnswerText as MultilangString[] }) as string);
          return acc;
        },
        { ...defaultLangs },
      )
    : undefined;

  const incorrectAnswerText = inputData.incorrectAnswerText
    ? Object.values(Langs).reduce(
        (acc, lang) => {
          acc[lang] =
            findValueByLang({ lang, array: inputData.incorrectAnswerText as MultilangString[] }) ||
            (findValueByLang({ lang: Langs.PL, array: inputData.incorrectAnswerText as MultilangString[] }) as string);
          return acc;
        },
        { ...defaultLangs },
      )
    : undefined;

  const data: ApiAddQuestionRequest = {
    tags:
      inputData.tags?.map(tag => ({
        tagName: tag.value,
        ...(!tag.isNew && { id: +tag.id }),
      })) ?? [],
    name,
    questionType: inputData.questionType.id,
    content,
    ...(isAnimatorQuestion
      ? {
          pointsOptions: inputData.multiplePointsOptions?.map(option => +option.value),
          points: null,
        }
      : {
          pointsOptions: null,
          points: inputData.points,
        }),
    city: inputData.city?.trim(),
    ...(coordinates
      ? {
          coordinates: {
            latitude: coordinates.lat as number,
            longitude: coordinates.lng as number,
          },
        }
      : {
          coordinates: null,
        }),
    answers: prepareQuestionAnswers({
      correctAnswers: shouldAddCorrectAnswers && !!inputData.correctAnswers ? inputData.correctAnswers : [],
      incorrectAnswers: shouldAddWrongAnswers && !!inputData.incorrectAnswers ? inputData.incorrectAnswers : [],
    }),
    ...(!!correctAnswerText && { correctAnswerText }),
    ...(!!incorrectAnswerText && { incorrectAnswerText }),
  };

  return data;
};

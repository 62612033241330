import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiDictionary, DictionaryDropdownOption } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useTemplateGameCharacters = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery([QueryAliases.TEMPLATES_GAME_CHARACTERS], () =>
    axiosInstance.get(api.endpoints.TEMPLATES_GAME_CHARACTERS),
  );

  const apiGameCharacters: ApiDictionary[] | undefined = data?.data;

  const iGameCharacters: DictionaryDropdownOption[] | undefined = !!apiGameCharacters
    ? apiGameCharacters.map(character => ({
        id: character.id.toString(),
        code: character.code,
        value: t(`gameCharacters.${character.code}`),
      }))
    : undefined;

  return { gameCharacters: iGameCharacters, refetch, isSuccess, isLoading, isError };
};

export default useTemplateGameCharacters;

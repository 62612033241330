import { colors } from '../../styles/variables';

interface SuccessIconProps {
  size?: string;
}

export const SuccessIcon = ({ size }: SuccessIconProps) => {
  return (
    <svg width={size ?? '16'} height={size ?? '16'} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3332 4.5L5.99984 11.8333L2.6665 8.5"
        stroke={colors.success}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { FormState, UseFormGetFieldState } from 'react-hook-form';
import { InitialPartnerFormValues } from '../../../../types/ApiPartnerTypes';

const useGetPartnerFormFieldStates = (
  getFieldState: UseFormGetFieldState<InitialPartnerFormValues>,
  formState: FormState<InitialPartnerFormValues>,
) => {
  const baseDataInputs = getFieldState('baseData', formState);
  const baseDataHasChanged = baseDataInputs.isDirty;

  const subscriptionInputs = getFieldState('subscription', formState);
  const subscriptionHasChanged = subscriptionInputs.isDirty;

  const templatesInput = getFieldState('templates', formState);
  const templatesHasChanged = templatesInput.isDirty;

  return { baseDataHasChanged, subscriptionHasChanged, templatesHasChanged };
};

export default useGetPartnerFormFieldStates;

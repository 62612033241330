import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import MaterialTable from '../../../../components/dataTable/MaterialTable/MaterialTable';
import PlusIcon from '../../../../components/icons/PlusIcon';
import useGameQuestions from '../../../../hooks/api/games/useGameQuestions';
import useSingleGame from '../../../../hooks/api/games/useSingleGame';
import { routes } from '../../../../static/routes';
import type { Coordinates } from '../../../../types/GlobalTypes';
import SingleActiveGamePageWrapper from '../../SingleActiveGamePageWrapper';
import useActiveGameQuestionsHeaders from './hooks/useActiveGameQuestionsHeaders';
import { LOCATION_ZOOM_LEVEL } from './core/consts';

const SingleActiveGameQuestionsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const gameId = params.id as string;
  const { gameQuestions, isLoading } = useGameQuestions({ gameId });
  const { game } = useSingleGame({ gameId });

  const { headers } = useActiveGameQuestionsHeaders({
    templateId: game?.template.id as number,
    gameId: game?.id as number,
    onLocationClick: ({ coordinates }: { coordinates: Coordinates }) => {
      navigate(
        routes.singleActiveGame.map({
          id: gameId,
          center: `${coordinates.lat},${coordinates.lng}`,
          showQuestions: true,
          zoom: LOCATION_ZOOM_LEVEL,
        }),
      );
    },
  });

  return (
    <SingleActiveGamePageWrapper>
      <div className="mt-6">
        <MaterialTable
          columns={headers}
          rows={gameQuestions ?? []}
          loading={isLoading}
          getRowId={row => row.id}
          showPagination
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
        />

        {!!game && (
          <div className="flex justify-end mt-5">
            <PrimaryButton
              onClick={() => {
                navigate(routes.editTemplate(game.template.id.toString()));
              }}
              className="w-fit"
              prefixIcon={<PlusIcon />}
            >
              {t('singleActiveGame.questions.addQuestionBtn')}
            </PrimaryButton>
          </div>
        )}
      </div>
    </SingleActiveGamePageWrapper>
  );
};

export default SingleActiveGameQuestionsView;

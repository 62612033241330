import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TypeToConfirmModal from '../../../components/common/modals/TypeToConfirmModal';
import HoverableTooltip from '../../../components/dataTable/components/HoverableTooltip';
import TrashIcon from '../../../components/icons/TrashIcon';
import useDeleteUser from '../../../hooks/api/users/useDeleteUser';
import { queryClient } from '../../../queryClient';
import { colors } from '../../../styles/variables';
import { QueryAliases } from '../../../types/QueryAliases';

interface DeletePartnerButtonProps {
  id: number;
  partnerName: string;
}

const DeletePartnerButton = ({ id, partnerName }: DeletePartnerButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { deleteUser, isLoading } = useDeleteUser();

  const handleDeletePartner = async () => {
    const res = await deleteUser(id);

    if (res.data.deleted) {
      await queryClient.refetchQueries([QueryAliases.PARTNERS]);
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('partners.deletePartner.hoverText')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <TrashIcon color={isHovered ? colors.red500 : colors.grey700} />
          </button>
        )}
      </HoverableTooltip>

      <TypeToConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={t('partners.deletePartner.modalTitle')}
        textToType={partnerName}
        confirmBtnText={t('partners.deletePartner.confirmButtonText')}
        confirmBtnAction={handleDeletePartner}
        cancelBtnText={t('partners.deletePartner.cancelButtonText')}
        isConfirmBtnDisabled={isLoading}
        isConfirmBtnLoading={isLoading}
      />
    </>
  );
};

export default DeletePartnerButton;

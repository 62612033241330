import { DictionaryDropdownOption, ITemplateDetails } from '../../../../types/ApiTypes';
import { GameCharacter, GameType } from '../../../../types/GlobalTypes';
import { getCoordinatesStringFromLatLng } from '../../../../utils/general/getCoordinatesStringFromLatLng/getCoordinatesStringFromLatLng';
import { multilangStringDefault } from '../../questionForm/static/QuestionFormDefaults';

const findGameCharacterOption = (gameCharacter: GameCharacter, options: DictionaryDropdownOption[]) =>
  options.find(option => option.code === gameCharacter);

const findGameTypeOption = (gameType: GameType, options: DictionaryDropdownOption[]) =>
  options.find(option => option.code === gameType);

export const getDefaultTemplateValues = ({
  template,
  gameTypesOptions,
  gameCharactersOptions,
}: {
  template?: ITemplateDetails;
  gameTypesOptions: DictionaryDropdownOption[];
  gameCharactersOptions: DictionaryDropdownOption[];
}) => {
  const obj = {
    tags: template?.tags.map(tag => ({
      id: tag.id.toString(),
      value: tag.tagName,
    })),
    name: template?.name.translations ?? multilangStringDefault,
    city: template?.city ?? '',
    coordinates: getCoordinatesStringFromLatLng(template?.coordinates),
    estimatedDuration: template?.averageTime,
    gameCharacter: !!template?.gameCharacter
      ? findGameCharacterOption(template?.gameCharacter, gameCharactersOptions) ?? undefined
      : undefined,
    gameType: !!template?.gameType ? findGameTypeOption(template?.gameType, gameTypesOptions) ?? undefined : undefined,
    isActive: template?.isActive ?? undefined,
  };

  return obj;
};

import { useParams } from 'react-router-dom';
import useSingleGameStatistics from '../../../../hooks/api/games/statistics/useSingleGameStatistics';
import useGameUsers from '../../../../hooks/api/games/useGameUsers';
import useSingleGame from '../../../../hooks/api/games/useSingleGame';
import useRelatedUsers from '../../../../hooks/api/users/useRelatedUsers';
import SingleActiveGamePageWrapper from '../../SingleActiveGamePageWrapper';
import SingleGameInfoCard from './components/SingleGameInfoCard';
import SingleGameStatisticsCard from './components/SingleGameStatisticsCard';

const SingleActiveGameView = () => {
  const params = useParams();
  const gameId = params.id as string;

  const { game } = useSingleGame({ gameId });
  const { gameUsers } = useGameUsers({ gameId: gameId.toString() });
  const { relatedUsers } = useRelatedUsers();
  const { gameStatistics } = useSingleGameStatistics({ gameId });

  return (
    <SingleActiveGamePageWrapper>
      <div className="p-8 gap-5 mt-6">
        {!!game && <SingleGameInfoCard game={game} gameUsers={gameUsers} relatedUsers={relatedUsers} />}
        {!!gameStatistics && <SingleGameStatisticsCard gameStatistics={gameStatistics} />}
      </div>
    </SingleActiveGamePageWrapper>
  );
};

export default SingleActiveGameView;

import AddPlayerButton from './components/AddPlayerButton';
import PlayersList from './components/PlayersList';

const PlayersView = () => {
  return (
    <div className="pt-6">
      <PlayersList />
      <AddPlayerButton />
    </div>
  );
};

export default PlayersView;

import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/common/buttons/PrimaryButton';
import useVerifyPartner from '../../../hooks/api/users/useVerifyPartner';
import { queryClient } from '../../../queryClient';
import { ButtonSize } from '../../../types/ButtonSizes';
import { QueryAliases } from '../../../types/QueryAliases';

const VerifyPartnerButton = ({ partnerId }: { partnerId: number }) => {
  const { verifyPartner, isLoading } = useVerifyPartner();
  const { t } = useTranslation();

  const handleVerification = async () => {
    await verifyPartner(partnerId);
    await queryClient.refetchQueries([QueryAliases.PARTNERS]);
  };

  return (
    <PrimaryButton
      size={ButtonSize.Small}
      onClick={() => handleVerification()}
      disabled={isLoading}
      loading={isLoading}
    >
      {t('partners.verifyButton')}
    </PrimaryButton>
  );
};

export default VerifyPartnerButton;

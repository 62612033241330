import { useTranslation } from 'react-i18next';
import useLimitDictionary from '../../../../hooks/api/limitDictionary/useLimitDictionary';
import { ApiSingleSubscriptionDetails, ISingleSubscriptionDetails } from '../../../../types/ApiBundleTypes';
import { formatDateString } from '../../../../utils/dates/formatDate/formatDateString';

export const useFormatSubscription = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { convertLimitsForView } = useLimitDictionary();

  const formatSubscription = (subscription: ApiSingleSubscriptionDetails) => {
    const formatedLimits = convertLimitsForView(subscription.bundle.limits);

    const formatedSubscription: ISingleSubscriptionDetails = {
      validUntil: {
        title: t('account.subscriptions.validUntil'),
        value: subscription?.validUntil
          ? formatDateString.toOnlyDate(subscription.validUntil, language)
          : t('bundle.noLimit'),
      },
      coordinates: !!subscription.coordinates
        ? { lat: +subscription.coordinates?.latitude, lng: +subscription.coordinates.longitude }
        : null,
      ...formatedLimits,
    };

    return formatedSubscription;
  };

  return { formatSubscription };
};

export default useFormatSubscription;

import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { colors } from '../../../styles/variables';
import { IMonthStats } from '../../../types/GameTypes';
import ChartTooltip from '../tooltip/ChartTooltip';

interface MonthBarChartProps {
  chartData: IMonthStats[];
}

const MonthBarChart = ({ chartData }: MonthBarChartProps) => {
  const { t } = useTranslation();

  return (
    <ResponsiveContainer width="100%">
      <BarChart
        data={chartData}
        margin={{
          left: -40,
        }}
      >
        <CartesianGrid strokeDasharray="4 4" vertical={false} />
        <XAxis
          dataKey="month"
          tickFormatter={month => t(`months.${month}`)}
          fontSize={14}
          tickLine={false}
          stroke={colors.grey700}
          axisLine={{ stroke: colors.grey700 }}
        />
        <YAxis tickLine={false} fontSize={14} stroke={colors.grey700} axisLine={{ stroke: colors.grey700 }} />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={({ active, payload, label }) => (
            <ChartTooltip isActive={!!active && !!payload && !!payload.length} title={t(`months.${label}`)}>
              {payload?.map((item, index) => (
                <p key={index} className="font-medium text-red700">
                  {item.payload.count}
                </p>
              ))}
            </ChartTooltip>
          )}
        />
        <Bar dataKey="count" fill={colors.red700} barSize={24} radius={[6, 6, 0, 0]} cursor="pointer">
          {chartData?.map((_, index) => (
            <Cell key={`cell-${index}`} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthBarChart;

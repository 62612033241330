import { Dialog, Transition } from '@headlessui/react';
import { CircularProgress } from '@mui/material';
import { Fragment } from 'react';

const LoadingOverlay = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Transition appear show={isLoading} as={Fragment}>
      <Dialog onClose={() => null} as="div" className="relative z-30">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black700 bg-opacity-50" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full max-w-xs h-80 transition-all rounded-3xl shadow-xl bg-white grid place-items-center`}
              >
                <CircularProgress />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoadingOverlay;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useQuestionPointsOptions from '../../hooks/api/questions/useQuestionPointsOptions';
import useQuestionTypesOptions from '../../hooks/api/questions/useQuestionTypesOptions';
import { colors } from '../../styles/variables';
import IconButton from '../common/buttons/IconButton';
import InfoIcon from '../icons/InfoIcon';
import QuestionIconOpenShortText from '../icons/questions/QuestionIconOpenShortText';
import MapMarkerQuestionTypeWrapper from '../map/components/MapMarkerQuestionTypeWrapper';
import MapTooltipCard from '../map/components/MapTooltipCard';

const MapQuestionsLegend = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { typesOptions } = useQuestionTypesOptions();
  const { pointsData } = useQuestionPointsOptions();

  return isOpen ? (
    <MapTooltipCard
      className="absolute bottom-0 left-0"
      header={
        <h2 className="text-black500 text-[1.375rem] font-medium leading-6">{t('questions.map.legend.title')}</h2>
      }
      onCloseClick={() => setIsOpen(false)}
    >
      <div>
        <h5 className="text-grey700 text-sm font-bold uppercase">{t('questions.map.legend.questionTypes')}</h5>
        <div className="mt-3.5 grid grid-cols-2 gap-x-10 gap-y-3.5">
          {typesOptions?.map(option => (
            <div key={option.id} className="flex items-center gap-2.5">
              <MapMarkerQuestionTypeWrapper color={colors.grey300}>
                <img src={option.icon} alt={option.code} />
                <QuestionIconOpenShortText color={colors.black700} />
              </MapMarkerQuestionTypeWrapper>
              <p className="text-black700 text-xs font-normal">{t(`questionTypes.${option.code}`)}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-6">
        <h5 className="text-grey700 text-sm font-bold uppercase">{t('questions.map.legend.points')}</h5>

        <div className="flex items-center gap-10 mt-3">
          {pointsData?.map(pointsOption => (
            <div key={pointsOption.id} className="flex gap-2 items-center">
              <div className="w-6 min-w-[1.5rem] h-6 rounded-full" style={{ backgroundColor: pointsOption.color }} />
              <p className="text-[0.8125rem] font-semibold whitespace-nowrap" style={{ color: pointsOption.color }}>
                {`${pointsOption.points === '0' ? 'X' : pointsOption.points} pkt`}
              </p>
            </div>
          ))}
        </div>
      </div>
    </MapTooltipCard>
  ) : (
    <IconButton
      icon={<InfoIcon />}
      className="bg-opacity-100 bg-grey100 border-grey300"
      onClick={() => setIsOpen(true)}
    />
  );
};

export default MapQuestionsLegend;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { ApiLoginRequest } from '../../../types/ApiTypes';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import { setAccessToken, setRefreshToken } from '../../../utils/cookieHelpers/cookieHelpers';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useLogin = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const { mutateAsync, isLoading } = useMutation(
    (data: ApiLoginRequest) => axiosInstance.post(api.endpoints.LOGIN, { ...data }),
    {
      onSuccess: async response => {
        setAccessToken(response.data.accessToken);
        setRefreshToken(response.data.refreshToken);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.loginFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { login: mutateAsync, isLoading };
};

export default useLogin;

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TableCell from '../../../components/dataTable/components/TableCell';
import { IPartner } from '../../../types/ApiPartnerTypes';
import DeletePartnerButton from '../components/DeletePartnerButton';
import EditPartnerButton from '../components/EditPartnerButton';
import PartnerStatusTableCell from '../components/PartnerStatusTableCell';

const usePartnersTableHeaders = () => {
  const { t } = useTranslation();
  const headers: GridColDef[] = [
    {
      field: 'name',
      headerName: t('partners.tableHeaders.name'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IPartner>) => {
        return <TableCell>{params.row.name}</TableCell>;
      },
    },
    {
      field: 'email',
      headerName: t('partners.tableHeaders.email'),
      minWidth: 180,
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IPartner>) => {
        return <TableCell>{params.row.email}</TableCell>;
      },
    },
    {
      field: 'status',
      headerName: t('partners.tableHeaders.status'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IPartner>) => {
        return <PartnerStatusTableCell partnerId={params.row.id} status={params.row.status} />;
      },
    },
    {
      field: 'games',
      headerName: t('partners.tableHeaders.gamesCreated'),
      maxWidth: 160,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IPartner>) => {
        return <TableCell>{params.row.games}</TableCell>;
      },
    },
    {
      field: 'validUntil',
      headerName: t('partners.tableHeaders.validUntil'),
      minWidth: 100,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IPartner>) => {
        return <TableCell>{params.row.validUntil}</TableCell>;
      },
    },
    {
      field: 'subscription',
      headerName: t('partners.tableHeaders.subscription'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IPartner>) => {
        return <TableCell>{params.row.bundleName}</TableCell>;
      },
    },
    {
      field: 'buttons',
      headerName: '',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IPartner>) => {
        const partnerIsVerified = params.row.isActive;
        return (
          <div className="flex w-full justify-end items-center gap-4">
            {partnerIsVerified && <EditPartnerButton id={params.row.id} />}
            <DeletePartnerButton id={params.row.id} partnerName={params.row.name} />
          </div>
        );
      },
    },
  ];
  return { headers };
};

export default usePartnersTableHeaders;

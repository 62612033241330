import { useQuery } from 'react-query';
import { api } from '../../static/api';
import { Tag } from '../../types/ApiTypes';
import { DropdownOption } from '../../types/Dropdown.types';
import { QueryAliases } from '../../types/QueryAliases';
import useAxiosInstance from '../axios/useAxiosInstance';

const useTags = ({ searchValue, queryMinLength = 3 }: { searchValue: string; queryMinLength?: number }) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.TAGS(searchValue)],
    () => axiosInstance.get(api.endpoints.TAGS(searchValue)),
    {
      refetchOnMount: true,
      enabled: searchValue.length >= queryMinLength,
    },
  );

  const tags: Tag[] | undefined = data?.data;

  const tagsDropdownOptions = tags?.map(tag => {
    const tagOption: DropdownOption = {
      id: tag.id.toString(),
      value: tag.tagName,
    };

    return tagOption;
  });

  return { tags, tagsDropdownOptions, refetch, isSuccess, isLoading, isError };
};

export default useTags;

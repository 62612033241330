import { useQuery } from 'react-query';

import { api } from '../../../static/api';
import { ApiUserDetails } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useUserProps {
  enabled?: boolean;
}

const useUserDetails = ({ enabled = true }: useUserProps = {}) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.USER_DETAILS],
    () => axiosInstance.get(api.endpoints.USER_DETAILS),
    {
      enabled,
      retry: 1,
    },
  );

  const user: ApiUserDetails | undefined = data?.data;

  return { user, refetch, isSuccess, isLoading, isError };
};

export default useUserDetails;

import { colors } from '../../styles/variables';

const ActiveGameIcon = ({ color, height, width }: { color?: string; height?: number; width?: number }) => {
  return (
    <svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 8V13"
        stroke={color ?? colors.grey700}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25"
        stroke={color ?? colors.grey700}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 2H15"
        stroke={color ?? colors.grey700}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9 18.5017V17.3417C14.9 15.9117 15.92 15.3217 17.16 16.0417L18.16 16.6217L19.16 17.2017C20.4 17.9217 20.4 19.0917 19.16 19.8117L18.16 20.3917L17.16 20.9717C15.92 21.6917 14.9 21.1017 14.9 19.6717V18.5017Z"
        stroke={color ?? colors.grey700}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActiveGameIcon;

import { CSSProperties } from 'react';

interface SelectionPanelCardProps {
  children: React.ReactNode;
  className?: string;
  customStyle?: CSSProperties;
}

const SelectionPanelCard = ({ children, className, customStyle }: SelectionPanelCardProps) => {
  return (
    <div
      className={`border border-grey400 bg-redButtonBg rounded-md flex justify-between items-center py-5 px-10 ${className}`}
      style={customStyle}
    >
      {children}
    </div>
  );
};

export default SelectionPanelCard;

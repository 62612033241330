import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CallbackDefault } from '../../../types/Types';
import XMarkIcon from '../../icons/XmarkIcon';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { SecondaryButton } from '../buttons/SecondaryButton';
import { Input } from '../inputs/Input';
import { Modal, ModalProps } from './Modal';

interface TypeToConfirmModalProps extends Omit<ModalProps, 'children'> {
  title: string;
  confirmBtnText: string;
  confirmBtnAction: CallbackDefault;
  confirmBtnIcon?: React.ReactNode;
  cancelBtnText: string;
  isConfirmBtnDisabled?: boolean;
  isConfirmBtnLoading?: boolean;
  textToType: string;
}

const TypeToConfirmModal = ({
  isOpen,
  setIsOpen,
  title,
  confirmBtnText,
  confirmBtnAction,
  confirmBtnIcon,
  cancelBtnText,
  isConfirmBtnDisabled,
  isConfirmBtnLoading,
  textToType,
}: TypeToConfirmModalProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');

  const isEnabled = !isConfirmBtnDisabled && textToType === value;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div>
        <div className="p-6 relative border-b border-grey300">
          <h2 className="text-center text-[1.375rem] font-medium">{title}</h2>
          <div
            onClick={() => setIsOpen(false)}
            className="w-8 h-8 min-w-8 absolute top-6 right-6 lg:left-6 flex justify-center items-center cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>

        <div className="p-6 lg:pt-16 lg:pb-24 mx-auto max-w-md">
          <h3 className="font-bold text-base text-black900 mb-3">
            {t('modal.typeToConfirm.confirm1')}
            <span className="text-red500">{` ${textToType} `}</span>
            {t('modal.typeToConfirm.confirm2')}
          </h3>
          <Input value={value} onChange={e => setValue(e.target.value)} placeholder={textToType} />

          <div className="flex gap-6 mt-12 ">
            <SecondaryButton onClick={() => setIsOpen(false)}>{cancelBtnText}</SecondaryButton>
            <PrimaryButton onClick={confirmBtnAction} disabled={!isEnabled} loading={isConfirmBtnLoading}>
              {confirmBtnIcon && confirmBtnIcon}
              {confirmBtnText}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TypeToConfirmModal;

import * as yup from 'yup';
import { checkIfCorrectAnswersRequired } from '../../../components/forms/questionForm/utils/checkIfCorrectAnswersRequired';
import { checkIfWrongAnswersRequired } from '../../../components/forms/questionForm/utils/checkIfWrongAnswersRequired';
import { QuestionType, QuestionTypes } from '../../../types/GlobalTypes';
import * as Schema from '../schemaFields';

const baseSchema = {
  tags: yup.array(Schema.dropdownOption),
  name: yup.array(Schema.multilangString).required('required'),
  questionType: Schema.dropdownOption.required('required'),
  content: yup.array(Schema.multilangString).required('required'),
  city: yup.string(),
};

const locationSchema = {
  coordinates: Schema.coordinatesString,
};

const correctAnswersSchema = {
  correctAnswers: yup.array(Schema.answerMultilangString).min(1, 'correctAnswersRequired'),
};

const incorrectAnswersSchema = {
  incorrectAnswers: yup.array(Schema.answerMultilangString),
};

const answerMessagesSchema = {
  correctAnswerText: yup.array(Schema.multilangString).required('required'),
  incorrectAnswerText: yup.array(Schema.multilangString).required('required'),
};

export const questionValidationSchema = ({
  isLocation,
  questionType,
  areAnswerMessagesRequired,
}: {
  isLocation: boolean;
  questionType: QuestionType;
  areAnswerMessagesRequired: boolean;
}) => {
  let schemaObj = isLocation ? { ...baseSchema, ...locationSchema } : baseSchema;

  const areCorrectAnswersRequired = checkIfCorrectAnswersRequired(questionType);
  const areWrongAnswersRequired = checkIfWrongAnswersRequired(questionType);
  const isAnimatorQuestion = questionType === QuestionTypes.ANIMATOR;

  if (isAnimatorQuestion) {
    schemaObj = {
      ...schemaObj,
      ...{ multiplePointsOptions: yup.array(Schema.dropdownOption).min(1, 'required') },
    };
  } else {
    schemaObj = { ...schemaObj, ...{ points: Schema.requiredPositiveNumber } };
  }

  if (areCorrectAnswersRequired) {
    schemaObj = { ...schemaObj, ...correctAnswersSchema };
  }

  if (areWrongAnswersRequired) {
    schemaObj = { ...schemaObj, ...incorrectAnswersSchema };
  }

  if (areAnswerMessagesRequired) {
    schemaObj = { ...schemaObj, ...answerMessagesSchema };
  }

  const schema = yup.object(schemaObj);

  return schema;
};

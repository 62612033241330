import MaterialTable from '../../../components/dataTable/MaterialTable/MaterialTable';
import usePartners from '../../../hooks/api/users/usePartners';
import usePartnersTableHeaders from '../hooks/usePartnersTableHeaders';

const PartnersList = () => {
  const { headers } = usePartnersTableHeaders();
  const { partners, isLoading } = usePartners();

  return (
    <>
      <div className="pb-36">
        <MaterialTable columns={headers} rowCount={partners?.length} rows={partners ?? []} loading={isLoading} />
      </div>
    </>
  );
};

export default PartnersList;

import { useLocation } from 'react-router-dom';
import { paths } from '../../../static/routes';
import { useMemo } from 'react';

const useIsEditOrAddTemplatePath = () => {
  const location = useLocation();

  const isEditOrAddTemplatePath = useMemo(() => {
    const isEditTemplatePath = location.pathname.includes(paths.editTemplate.split('/').at(-1) ?? '');
    const isAddTemplatePath = location.pathname.includes(paths.addTemplate);

    return isEditTemplatePath || isAddTemplatePath;
  }, [location.pathname]);

  return isEditOrAddTemplatePath;
};

export default useIsEditOrAddTemplatePath;

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import QuestionForm from '../../components/forms/questionForm/QuestionForm';
import { OnSubmitFormProps } from '../../components/forms/questionForm/types/QuestionFormTypes';
import PlusIcon from '../../components/icons/PlusIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useAddQuestionImages from '../../hooks/api/questions/useAddQuestionImages';
import useCreateQuestion from '../../hooks/api/questions/useCreateQuestion';
import { routes } from '../../static/routes';
import { prepareQuestionRequestData } from '../../utils/questions/prepareQuestionRequestData/prepareQuestionRequestData';

const AddQuestionView = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const prevPath = location.state?.prevPath;

  const { createQuestion, isLoading: isLoadingCreateQuestion } = useCreateQuestion();
  const { addQuestionImages, isLoading: isLoadingAddImages } = useAddQuestionImages();

  const navigate = useNavigate();

  const handleSubmitQuestion = async ({
    formData,
    mainImage,
    correctAnswerImage,
    incorrectAnswerImage,
    markerIcon,
    goToRelatedQuestion,
    isDirtyAnyImage,
  }: OnSubmitFormProps) => {
    const requestData = prepareQuestionRequestData(formData);

    const res = await createQuestion(requestData);
    const questionId = res.data.id;

    if (isDirtyAnyImage && !!questionId) {
      addQuestionImages({
        questionId: questionId as string,
        mainImage,
        correctAnswerImage,
        incorrectAnswerImage,
        markerIcon,
      });
    }

    let redirectRoute = routes.questions();

    if (goToRelatedQuestion) {
      redirectRoute = routes.addRelatedQuestion({ parentQuestionId: questionId });
    } else if (!!prevPath && typeof prevPath === 'string') {
      redirectRoute = prevPath;
    }

    navigate(redirectRoute);
  };

  return (
    <TitleWrapper title={t('addQuestion.title')}>
      <div className="pb-20">
        <QuestionForm
          isLoadingSubmit={isLoadingCreateQuestion || isLoadingAddImages}
          onSubmitForm={handleSubmitQuestion}
          submitBtnIcon={<PlusIcon />}
          submitBtnText={t('addQuestion.submitBtn')}
        />
      </div>
    </TitleWrapper>
  );
};

export default AddQuestionView;

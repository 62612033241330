import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../../static/api';
import { QueryAliases } from '../../../../types/QueryAliases';
import { CallbackDefault } from '../../../../types/Types';
import { getApiErrorMessage } from '../../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../../axios/useAxiosInstance';
import useToast from '../../../useToast';

interface MessageRequest {
  users: number[];
  message: string;
}

interface useSendMessageProps {
  gameId: string;
  onError?: CallbackDefault;
  onSuccess?: CallbackDefault;
}

const useSendMessage = ({ gameId, onError, onSuccess }: useSendMessageProps) => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    ({ users, message }: MessageRequest) =>
      axiosInstance.post(api.endpoints.GAME_MESSAGE_CREATE({ gameId }), { users, message }),
    {
      async onSuccess() {
        queryClient.refetchQueries({
          queryKey: [QueryAliases.GAME_SENT_MESSAGES(gameId)],
        });

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.sendMessageFailed');

        showErrorToast(errorMessage);

        if (onError) {
          onError();
        }
      },
    },
  );

  return { sendMessage: mutateAsync, isLoading };
};

export default useSendMessage;

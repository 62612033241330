import { useRef, useState } from 'react';
import { Tag } from '../../../../../types/ApiTypes';
import { generateColorById } from '../../../../../utils/colors/generateColorById/generateColorById';

const QuestionsListTableTags = ({ tags }: { tags: Tag[] }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDragging(true);
    if (!!containerRef.current) {
      setStartX(e.pageX - containerRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDragging) {
      return;
    }
    const newScrollLeft = startX - e.pageX;

    if (!!containerRef.current) {
      containerRef.current.scrollLeft = newScrollLeft;
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  return (
    <div className="max-w-full relative pr-4">
      <div
        ref={containerRef}
        className="relative flex gap-1.5 overflow-x-scroll no-scrollbar cursor-pointer "
        onClick={e => {
          e.stopPropagation();
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {tags.map(tag => {
          const color = generateColorById(tag.id);

          return (
            <div
              key={tag.id}
              className="px-4 py-2 rounded-md border bg-opacity-60"
              style={{ borderColor: color, backgroundColor: `${color}50` }}
            >
              <p className="text-xs text-black500 whitespace-nowrap noselect">{tag.tagName}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionsListTableTags;

import { type Dispatch, type SetStateAction, useRef, useState } from 'react';
import useSelectFile from '../../../../hooks/useSelectFile';
import AddImgIcon from '../../../icons/AddImgIcon';
import EditIcon from '../../../icons/EditIcon';
import TrashIcon from '../../../icons/TrashIcon';
import IconButton from '../../buttons/IconButton';
import ImagePickerCard from '../ImagePickerCard';

interface SingleImagePickerProps {
  title: string;
  subtitle?: string;
  oldImageUrl?: string | null;
  selectedFile: File | null;
  setSelectedFile: Dispatch<SetStateAction<File | null>>;
  onDeletedImage?: Dispatch<SetStateAction<boolean>>;
}

const SingleImagePicker = ({
  title,
  subtitle,
  oldImageUrl: initialOldImageUrl,
  selectedFile,
  setSelectedFile,
  onDeletedImage,
}: SingleImagePickerProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [oldImageUrl, setOldImageUrl] = useState<string | null>(initialOldImageUrl || null);

  const { selectFile } = useSelectFile();

  const handleClickChangeImg = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function handleDeleteFile() {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedFile(null);
    }
  }

  function handleDeleteImageMutation() {
    setSelectedFile(null);
    setOldImageUrl(null);
    onDeletedImage?.(true);
  }

  const getImg = () => {
    const imgSrc = selectedFile ? URL.createObjectURL(selectedFile) : oldImageUrl;
    const handleDelete = selectedFile ? handleDeleteFile : handleDeleteImageMutation;

    if (imgSrc) {
      return (
        <>
          <img src={imgSrc} alt="avatar" />
          <IconButton
            icon={<EditIcon width="16" />}
            onClick={handleClickChangeImg}
            className="absolute top-1.5 right-9 bg-white900 bg-opacity-90 w-6 min-w-[1.5rem] max-w-[1.5rem] h-6"
          />
          <IconButton
            icon={<TrashIcon width={16} />}
            onClick={handleDelete}
            className="absolute top-1.5 right-1.5 bg-white900 bg-opacity-90 w-6 min-w-[1.5rem] max-w-[1.5rem] h-6"
          />
        </>
      );
    }
    return (
      <button type="button" onClick={handleClickChangeImg} className="w-full h-full flex items-center justify-center ">
        <div className="bg-white900 border border-grey700 rounded-full p-3">
          <AddImgIcon />
        </div>
      </button>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <h5 className="text-xs text-black700 leading-tight">{title}</h5>

      <ImagePickerCard>{getImg()}</ImagePickerCard>

      <p className="text-black500 text-xs">{subtitle}</p>

      <input
        ref={fileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={async event => {
          await selectFile({
            event,
            onSelectFile: setSelectedFile,
            sizeLimitKb: 2048,
            allowedFileTypes: ['image/jpeg', 'image/png'],
          });
        }}
        className="hidden"
      />
    </div>
  );
};

export default SingleImagePicker;

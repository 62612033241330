const AnswersIcon = ({ color, width = 17, height = 16 }: { color: string; width?: number; height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6803 5.83464V8.7513C13.6803 11.668 12.5137 12.8346 9.59701 12.8346H6.09701C3.18034 12.8346 2.01367 11.668 2.01367 8.7513V5.2513C2.01367 2.33464 3.18034 1.16797 6.09701 1.16797H9.01367"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6803 5.83464H11.347C9.59701 5.83464 9.01367 5.2513 9.01367 3.5013V1.16797L13.6803 5.83464Z"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.93066 7.58203H8.43066"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.93066 9.91797H7.264" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AnswersIcon;

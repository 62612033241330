export const getEndpointQueryFilters = (filters: Record<string, string | number | null | string[] | boolean>) => {
  const filtersArray = Object.entries(filters);
  const appliedFiltersArray = filtersArray.filter(([, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return !!value;
  });

  if (appliedFiltersArray.length === 0) {
    return '';
  }

  let filterString = '?filters=';

  appliedFiltersArray.forEach((filter, index) => {
    const [key, value] = filter;
    const isLast = index === appliedFiltersArray.length - 1;

    if (Array.isArray(value)) {
      filterString += `${key}=${value.join(`;${key}=`)}`;
    } else {
      filterString += `${key}=${value}`;
    }

    filterString += !isLast ? ';' : '';
  });

  return filterString;
};

import { CallbackDefault } from '../../../types/Types';
import XMarkIcon from '../../icons/XmarkIcon';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { SecondaryButton } from '../buttons/SecondaryButton';
import { Modal, ModalProps } from './Modal';

interface ConfirmModalProps extends Omit<ModalProps, 'children'> {
  title: string;
  confirmQuestion: string;
  confirmBtnText: string;
  confirmBtnAction: CallbackDefault;
  confirmBtnIcon?: React.ReactNode;
  cancelBtnText: string;
  isConfirmBtnDisabled?: boolean;
  isConfirmBtnLoading?: boolean;
}

const ConfirmModal = ({
  isOpen,
  setIsOpen,
  title,
  confirmQuestion,
  confirmBtnText,
  confirmBtnAction,
  confirmBtnIcon,
  cancelBtnText,
  isConfirmBtnDisabled,
  isConfirmBtnLoading,
}: ConfirmModalProps) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div>
        <div className="p-6 relative border-b border-grey300">
          <h2 className="text-center text-[1.375rem] font-medium">{title}</h2>
          <div
            onClick={() => setIsOpen(false)}
            className="w-8 h-8 min-w-8 absolute top-6 right-6 lg:left-6 flex justify-center items-center cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>

        <div className="p-6 lg:pt-16 lg:pb-24">
          <h2 className="text-center text-[1.375rem] font-medium">{confirmQuestion}</h2>

          <div className="flex gap-6 mt-12 max-w-sm mx-auto">
            <SecondaryButton onClick={() => setIsOpen(false)}>{cancelBtnText}</SecondaryButton>
            <PrimaryButton onClick={confirmBtnAction} disabled={isConfirmBtnDisabled} loading={isConfirmBtnLoading}>
              {confirmBtnIcon && confirmBtnIcon}
              {confirmBtnText}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

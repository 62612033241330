import { Combobox, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { colors } from '../../../../styles/variables';
import { IGooglePlacesPrediction } from '../../../../types/ApiTypes';

export interface MapAutocompleteDropdownProps {
  value: string;
  setValue: (newValue: string) => void;
  predictions: IGooglePlacesPrediction[];
  placeholder?: string;
  onSelectPrediction: (prediction: IGooglePlacesPrediction) => void;
  disabled?: boolean;
  selectedPrediction: IGooglePlacesPrediction | null;
  onlyMainTextOnSetValue?: boolean;
}

const MapAutocompleteDropdown = ({
  value,
  setValue,
  predictions,
  placeholder,
  onSelectPrediction,
  disabled,
  selectedPrediction,
  onlyMainTextOnSetValue,
}: MapAutocompleteDropdownProps) => {
  return (
    <div
      className={`relative  rounded-md text-sm text-black500 font-medium w-full placeholder:text-grey500 ${
        disabled ? 'outline outline-1.5 outline-grey300 text-grey500' : 'outline outline-1.5 outline-grey500'
      }`}
    >
      <Combobox
        disabled={disabled}
        value={value}
        onChange={val => {
          const selectedPrediction = predictions.find(prediction => {
            return prediction.description === val;
          });
          if (!selectedPrediction) {
            return;
          }

          onSelectPrediction(selectedPrediction);

          const valToSet = onlyMainTextOnSetValue
            ? selectedPrediction.structuredFormatting.mainText
            : selectedPrediction.description;

          setValue(valToSet);
        }}
      >
        <div className="flex pl-4 pr-12 py-2.5 w-full">
          <Combobox.Input
            className="border-none outline-0 rounded-md text-sm leading-5 text-black500 pr-4 py-2 w-auto min-w-[9rem] grow"
            value={value}
            onChange={event => {
              const value = event.target.value;
              setValue(value);
            }}
            placeholder={placeholder}
          />

          <Combobox.Button className="absolute inset-y-0 right-2 flex items-center p-3">
            {({ open }) => (
              <>
                {open ? (
                  <ChevronUpIcon color={colors.black700} className="w-8 h-4" />
                ) : (
                  <ChevronDownIcon color={colors.black700} className="w-8 h-4" />
                )}
              </>
            )}
          </Combobox.Button>
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => {
            if (!!selectedPrediction) {
              const valToSet = onlyMainTextOnSetValue
                ? selectedPrediction.structuredFormatting.mainText
                : selectedPrediction.description;

              setValue(valToSet);
            } else {
              setValue('');
            }
          }}
        >
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {predictions?.map(prediction => {
              return (
                <Combobox.Option
                  key={prediction.placeId}
                  className={`relative cursor-pointer select-none p-4 ui-active:bg-redSelectionBg `}
                  value={prediction.description}
                  onClick={() => {
                    onSelectPrediction(prediction);
                  }}
                >
                  {prediction.description}
                </Combobox.Option>
              );
            })}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
};

export default MapAutocompleteDropdown;

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SelectionPanelCard from '../../../../../../../components/cards/selectionPanelCard/SelectionPanelCard';
import AnswersIcon from '../../../../../../../components/icons/AnswersIcon';
import MessageIcon from '../../../../../../../components/icons/MessageIcon';
import { routes } from '../../../../../../../static/routes';
import { colors } from '../../../../../../../styles/variables';
import { IUserGame } from '../../../../../../../types/ApiTypes';

interface ActiveMapTeamsSelectionPanelProps {
  selectedTeam: IUserGame;
}

const ActiveMapTeamsSelectionPanel = ({ selectedTeam }: ActiveMapTeamsSelectionPanelProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const gameId = params.id as string;
  const navigate = useNavigate();

  const handleMessageToTeam = () =>
    navigate(routes.singleActiveGame.messages({ id: gameId, messageTo: selectedTeam.user.id }));

  return (
    <SelectionPanelCard className="z-10 absolute bottom-3 left-1/2 -translate-x-1/2">
      <div className="pr-6 border-r border-grey400">
        <p className="text-black700 font-bold text-sm whitespace-nowrap">
          {selectedTeam.teamName ?? selectedTeam.user.email}
        </p>
      </div>

      <button
        onClick={handleMessageToTeam}
        className="text-red700 font-semibold text-sm flex items-center gap-1.5 ml-10 whitespace-nowrap"
      >
        <MessageIcon color={colors.red700} />
        {t('singleActiveGame.map.teams.selectionPanel.writeMessage')}
      </button>

      <button
        onClick={() => {
          navigate(routes.singleActiveGame.answers({ id: gameId, team: selectedTeam.user.id.toString() }));
        }}
        className="text-red700 font-semibold text-sm flex items-center gap-1.5 ml-10 whitespace-nowrap"
      >
        <AnswersIcon color={colors.red700} />
        {t('singleActiveGame.map.teams.selectionPanel.seeAnswers')}
      </button>
    </SelectionPanelCard>
  );
};

export default ActiveMapTeamsSelectionPanel;

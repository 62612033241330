import RouteTabSwitcherButton from './RouteTabSwitcherButton';

export type TabSwitcherRoute = { text: string; routeTo: string; suffixElement?: React.ReactNode };

export interface TabSwicherProps {
  routes: TabSwitcherRoute[];
}

const RouteTabSwitcher = ({ routes }: TabSwicherProps) => {
  return (
    <nav
      className={`flex gap-2.5 lg:justify-between lg:flex-nowrap px-4 overflow-x-scroll no-scrollbar lg:overflow-auto lg:border-b lg:border-grey200`}
    >
      {routes.map(route => (
        <RouteTabSwitcherButton key={route.routeTo} route={route} />
      ))}
    </nav>
  );
};

export default RouteTabSwitcher;

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ResetPasswordForm from '../../components/forms/resetPasswordForm/ResetPasswordForm';
import { Logo } from '../../components/navigation/components/Logo';
import useResetPassword from '../../hooks/api/auth/useResetPassword';
import { routes } from '../../static/routes';
import { searchParamsData } from '../../static/searchParamsData';
import { ApiResetPasswordBody } from '../../types/ApiTypes';

const CreatePasswordView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get(searchParamsData.token);

  const { resetPassword, isLoading } = useResetPassword({ onSuccessMessage: t('createPassword.successMessage') });

  if (!token) {
    navigate(routes.home);
  }

  const handleCreatePassword = ({ newPassword, confirmationPassword }: ApiResetPasswordBody) => {
    resetPassword({ newPassword, confirmationPassword, token: token! });
  };

  return (
    <div className="px-8 py-8 mx-auto flex flex-col max-w-xl h-screen justify-center">
      <Logo />
      <h1 className="mt-12 text-black700">{t('createPassword.title')}</h1>
      <p className="mt-4 text-black500 font-medium leading-snug">{t('createPassword.description')}</p>
      <ResetPasswordForm
        onFormSubmit={handleCreatePassword}
        isLoading={isLoading}
        passwordLabel={t('createPassword.newPasswordLabel')}
        confirmationPasswordLabel={t('createPassword.confirmationPasswordLabel')}
        submitBtnText={t('createPassword.submitBtn')}
      />
    </div>
  );
};

export default CreatePasswordView;

import { IBundleLimit, LimitValuesWithTranslation } from '../../../../types/ApiBundleTypes';
import SubscriptionDataContent from './SubscriptionDataContent';
import SubscriptionDataRow from './SubscriptionDataRow';
import SubscriptionDataTitle from './SubscriptionDataTitle';

const SubscriptionData = ({
  limit,
  otherData,
}: {
  limit?: IBundleLimit;
  otherData?: { title: string; value: string };
}) => {
  if (otherData) {
    return (
      <SubscriptionDataRow>
        <SubscriptionDataTitle>{otherData.title}</SubscriptionDataTitle>
        <SubscriptionDataContent>{otherData?.value}</SubscriptionDataContent>
      </SubscriptionDataRow>
    );
  }

  const valuesAreInArray = Array.isArray(limit?.limitValue);
  const limitValueArray = valuesAreInArray ? (limit?.limitValue as LimitValuesWithTranslation[]) : [];

  const limitValue = Array.isArray(limit?.limitValue) ? (
    limitValueArray?.map((value: LimitValuesWithTranslation) => (
      <SubscriptionDataContent key={value.id}>{value.value}</SubscriptionDataContent>
    ))
  ) : (
    <SubscriptionDataContent>{limit?.limitValue}</SubscriptionDataContent>
  );

  return (
    <SubscriptionDataRow key={limit?.value}>
      <SubscriptionDataTitle>{limit?.title}</SubscriptionDataTitle>
      {limitValue}
    </SubscriptionDataRow>
  );
};

export default SubscriptionData;

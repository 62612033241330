import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TableCell from '../../../../components/dataTable/components/TableCell';
import PlayersListDeleteButton from '../components/PlayersListDeleteButton';

const usePlayersTableHeaders = () => {
  const { t } = useTranslation();

  const headers = [
    {
      field: 'login',
      headerName: t('account.players.tableHeaders.login'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <TableCell>{params.row.login}</TableCell>;
      },
    },
    {
      field: 'createdAt',
      headerName: t('account.players.tableHeaders.addedDate'),
      minWidth: 120,
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <TableCell className="py-0">{params.row.createdAt.date}</TableCell>
            <TableCell className="text-gray-400 py-0 pt-2">{params.row.createdAt.time}</TableCell>
          </div>
        );
      },
    },
    {
      field: 'buttons',
      headerName: '',
      maxWidth: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <PlayersListDeleteButton playerId={params.row.id} playerLogin={params.row.email} />;
      },
    },
  ];

  return { headers };
};

export default usePlayersTableHeaders;

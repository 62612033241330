import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BreadcrumbsProps } from '../../components/common/breadcrumbs/Breadcrumbs';
import IconButton from '../../components/common/buttons/IconButton';
import ConfirmModal from '../../components/common/modals/ConfirmModal';
import QuestionForm from '../../components/forms/questionForm/QuestionForm';
import { QuestionFormInputs } from '../../components/forms/questionForm/types/QuestionFormTypes';
import SaveIcon from '../../components/icons/SaveIcon';
import TrashIcon from '../../components/icons/TrashIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import useAddQuestionImages from '../../hooks/api/questions/useAddQuestionImages';
import useDeleteQuestion from '../../hooks/api/questions/useDeleteQuestion';
import useSingleQuestion from '../../hooks/api/questions/useSingleQuestion';
import useUpdateRelatedQuestion from '../../hooks/api/questions/useUpdateRelatedQuestion';
import useToast from '../../hooks/useToast';
import { routes } from '../../static/routes';
import { colors } from '../../styles/variables';
import { Langs } from '../../types/GlobalTypes';
import { findValueByLang } from '../../utils/findValueByLang/findValueByLang';
import { prepareQuestionRequestData } from '../../utils/questions/prepareQuestionRequestData/prepareQuestionRequestData';

const QuestionFormWithLoading = withLoading(QuestionForm);

const EditRelatedQuestionView = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToast();
  const params = useParams();
  const parentQuestionId = params.parentQuestionId as string;
  const editedQuestionId = params.editedQuestionId as string;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { questionResponse: editedRelatedQuestionResponse, isLoading: isLoadingEditedRelatedQuestion } =
    useSingleQuestion({
      questionId: editedQuestionId,
      onErrorCb: () => {
        showErrorToast(t('errorMessages.somethingWentWrong'));
        navigate(routes.questions());
      },
    });
  const {
    questionResponse: parentQuestionResponse,
    isLoading: isLoadingParentQuestion,
    refetch: refetchParentQuestion,
  } = useSingleQuestion({
    questionId: parentQuestionId,
    onErrorCb: () => {
      showErrorToast(t('errorMessages.somethingWentWrong'));
      navigate(routes.questions());
    },
  });

  const { updateRelatedQuestion, isLoading: isLoadingSubmit } = useUpdateRelatedQuestion();
  const { addQuestionImages, isLoading: isLoadingAddImages } = useAddQuestionImages();
  const { deleteQuestion, isLoading: isLoadingDelete } = useDeleteQuestion({
    onSuccess: () => {
      refetchParentQuestion();
      showSuccessToast(t('editRelatedQuestion.deleteSuccessMessage'));
      navigate(routes.editQuestion(parentQuestionId));
    },
  });

  const handleSubmit = async ({
    formData,
    mainImage,
    correctAnswerImage,
    incorrectAnswerImage,
  }: {
    formData: QuestionFormInputs;
    mainImage: File | null;
    correctAnswerImage: File | null;
    incorrectAnswerImage: File | null;
  }) => {
    const relatedQuestionData = prepareQuestionRequestData(formData);

    const parentCoordinates = !!parentQuestionResponse?.question.coordinates
      ? {
          latitude: parentQuestionResponse?.question.coordinates?.lat,
          longitude: parentQuestionResponse?.question.coordinates?.lng,
        }
      : undefined;

    await updateRelatedQuestion({
      parentQuestionId,
      data: {
        relatedQuestion: { ...relatedQuestionData, coordinates: parentCoordinates },
      },
    });

    if (!!mainImage || !!correctAnswerImage || !!incorrectAnswerImage) {
      addQuestionImages({
        questionId: editedQuestionId as string,
        mainImage,
        correctAnswerImage,
        incorrectAnswerImage,
      });
    }

    navigate(routes.editQuestion(parentQuestionId));
  };

  const handleDeleteQuestion = () => {
    deleteQuestion(editedQuestionId);
  };

  const questionName = findValueByLang({
    lang: i18n.resolvedLanguage ?? Langs.PL,
    array: parentQuestionResponse?.question?.name ?? [],
  });

  const breadcrumbsProps: BreadcrumbsProps = {
    limit: 2,
    extraBreadcrumbs: [{ text: questionName ?? '' }, { text: t('editRelatedQuestion.title') }],
  };

  return (
    <>
      <TitleWrapper
        title={t('editRelatedQuestion.title')}
        breadcrumbsProps={breadcrumbsProps}
        sideComponent={
          <IconButton
            onClick={() => setIsDeleteModalOpen(true)}
            className="border border-red500"
            icon={<TrashIcon color={colors.red500} width={16} height={16} />}
          />
        }
      >
        <QuestionFormWithLoading
          isLoading={isLoadingEditedRelatedQuestion || isLoadingParentQuestion}
          isLoadingSubmit={isLoadingSubmit || isLoadingAddImages}
          onSubmitForm={handleSubmit}
          submitBtnIcon={<SaveIcon />}
          submitBtnText={t('editRelatedQuestion.submitBtn')}
          initialQuestion={editedRelatedQuestionResponse?.question}
          isRelatedQuestionForm
        />
      </TitleWrapper>

      <ConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        title={t('editRelatedQuestion.deleteModal.title')}
        confirmQuestion={t('editRelatedQuestion.deleteModal.confirmQuestion')}
        confirmBtnText={t('editRelatedQuestion.deleteModal.confirmBtnText')}
        confirmBtnAction={() => {
          setIsDeleteModalOpen(false);
          handleDeleteQuestion();
        }}
        cancelBtnText={t('editRelatedQuestion.deleteModal.cancelBtnText')}
        isConfirmBtnDisabled={isLoadingDelete}
        isConfirmBtnLoading={isLoadingDelete}
      />
    </>
  );
};

export default EditRelatedQuestionView;

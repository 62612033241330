import dayjs from 'dayjs';
import { GameStatuses } from '../../../types/GlobalTypes';

export const getGameStatusByDate = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
  const currentDate = new Date();

  if (dayjs(currentDate).isBefore(dayjs(startDate))) {
    return GameStatuses.SCHEDULED;
  } else if (dayjs(currentDate).isAfter(dayjs(endDate))) {
    return GameStatuses.FINISHIED;
  } else {
    return GameStatuses.ONGOING;
  }
};

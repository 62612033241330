import { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useMapAutocomplete from '../../../../../hooks/api/map/useMapAutocomplete';
import useMapPlaceDetails from '../../../../../hooks/api/map/useMapPlaceDetails';
import useDebounce from '../../../../../hooks/useDebounce';
import { IGooglePlacesPrediction } from '../../../../../types/ApiTypes';
import { DropdownOption } from '../../../../../types/Dropdown.types';
import { GameTypes, LangType } from '../../../../../types/GlobalTypes';
import { getCoordinatesStringFromLatLng } from '../../../../../utils/general/getCoordinatesStringFromLatLng/getCoordinatesStringFromLatLng';
import getInputState from '../../../../../utils/getInputState/getInputState';
import { LabeledStateDropdown } from '../../../../common/dropdowns/LabeledStateDropdown';
import { LabeledStateMapAutocompleteDropdown } from '../../../../common/dropdowns/mapAutocomplete/LabeledStateMapAutocompleteDropdown';
import MapAutocompleteDropdown from '../../../../common/dropdowns/mapAutocomplete/MapAutocompleteDropdown';
import { LabeledStateInput } from '../../../../common/inputs/LabeledStateInput';
import Switch from '../../../../common/switch/Switch';
import { TemplateFormInputs, TemplateFormInputsKeys } from '../../types/TemplateFormTypes';

interface TemplateFormGeneralProps {
  activeLang: LangType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage: (field: TemplateFormInputsKeys) => any;
  gameCharactersOptions: DropdownOption[];
  gameTypesOptions: DropdownOption[];
  isGameTypeDisabled?: boolean;
  isGameCharacterDisabled?: boolean;
  initialCity: string;
}

const TemplateFormGeneral = ({
  activeLang,
  getErrorMessage,
  gameCharactersOptions,
  gameTypesOptions,
  isGameTypeDisabled,
  isGameCharacterDisabled,
  initialCity,
}: TemplateFormGeneralProps) => {
  const { t } = useTranslation();

  const { register, control, watch, setValue } = useFormContext<TemplateFormInputs>();

  const [cityInput, setCityInput] = useState(initialCity);
  const { debouncedValue: debouncedCityInput } = useDebounce({ value: cityInput, delay: 700 });
  const [selectedCityPrediction, setSelectedCityPrediction] = useState<IGooglePlacesPrediction | null>(null);
  const { predictions: cityPredictions } = useMapAutocomplete({ input: debouncedCityInput, onlyCities: true });

  const [locationInput, setLocationInput] = useState('');
  const { debouncedValue: debouncedLocationInput } = useDebounce({ value: locationInput, delay: 700 });
  const [selectedLocationPrediction, setSelectedLocationPrediction] = useState<IGooglePlacesPrediction | null>(null);
  const { predictions: locationPredictions } = useMapAutocomplete({ input: debouncedLocationInput });

  useMapPlaceDetails({
    placeId: selectedLocationPrediction?.placeId ?? '',
    onSuccess: data => {
      const coordinatesString = getCoordinatesStringFromLatLng(data.location);
      setValue('coordinates', coordinatesString);
    },
  });

  const { fields: nameFields } = useFieldArray({
    name: 'name',
    control,
  });

  const currentGameType = watch('gameType');

  return (
    <div>
      {nameFields.map((field, index) => {
        if (field.lang !== activeLang) {
          return;
        }
        return (
          <div key={field.id} className="pb-7">
            <LabeledStateInput
              id={`template-name-${field.id}`}
              state={getInputState(getErrorMessage('name')?.[index])}
              labeledProps={{
                wrapperClassName: 'w-full',
                label: t('templateForm.general.nameLabel'),
                errorMessage: t(`errorMessages.${getErrorMessage('name')?.[index]?.value?.message}`),
              }}
              inputProps={{
                placeholder: t('templateForm.general.namePlaceholder'),
                register: {
                  ...register(`name.${index}.value`),
                },
                autoComplete: 'off',
              }}
            />
          </div>
        );
      })}

      <div className="pb-7">
        <Controller
          name="gameCharacter"
          control={control}
          render={({ field }) => (
            <LabeledStateDropdown
              id="template-game-character"
              state={getInputState(getErrorMessage('gameCharacter'))}
              labeledProps={{
                label: t('templateForm.general.gameCharacterLabel'),
                wrapperClassName: 'w-full',
                errorMessage: t(`errorMessages.${getErrorMessage('gameCharacter')?.value?.message}`),
              }}
              dropdownProps={{
                disabled: isGameCharacterDisabled,
                placeholder: t('templateForm.general.gameCharacterPlaceholder'),
                options: gameCharactersOptions,
                currentOption: field.value,
                setNewOption: val => {
                  field.onChange(val);
                },
              }}
            />
          )}
        />
      </div>

      <div className="pb-7">
        <Controller
          name="gameType"
          control={control}
          render={({ field }) => (
            <LabeledStateDropdown
              id="template-game-type"
              state={getInputState(getErrorMessage('gameType'))}
              labeledProps={{
                label: t('templateForm.general.gameTypeLabel'),
                wrapperClassName: 'w-full',
                errorMessage: t(`errorMessages.${getErrorMessage('gameType')?.value?.message}`),
              }}
              dropdownProps={{
                disabled: isGameTypeDisabled,
                placeholder: t('templateForm.general.gameTypePlaceholder'),
                options: gameTypesOptions,
                currentOption: field.value,
                setNewOption: val => {
                  field.onChange(val);
                },
              }}
            />
          )}
        />
      </div>

      {currentGameType?.code === GameTypes.INDIVIDUAL && (
        <Controller
          name="isActive"
          control={control}
          render={({ field }) => (
            <div className="pb-7 flex items-center gap-3">
              <Switch isChecked={!!field.value} onChange={() => field.onChange(!field.value)} />
              <p className="font-bold">{t('templateForm.general.isActive')}</p>
            </div>
          )}
        />
      )}

      <div className="pb-7">
        <Controller
          name="city"
          control={control}
          render={({ field }) => {
            return (
              <LabeledStateMapAutocompleteDropdown
                id="template-city"
                state={getInputState(getErrorMessage('city'))}
                labeledProps={{
                  label: t('templateForm.general.cityLabel'),
                  errorMessage: t(`errorMessages.${getErrorMessage('city')?.message}`),
                }}
                dropdownProps={{
                  placeholder: t('templateForm.general.cityPlaceholder'),
                  value: cityInput,
                  setValue: newValue => {
                    setCityInput(newValue);
                  },
                  predictions: cityPredictions,
                  onSelectPrediction: prediction => {
                    setSelectedCityPrediction(prediction);
                    field.onChange(prediction.structuredFormatting.mainText);
                  },
                  selectedPrediction: selectedCityPrediction,
                  onlyMainTextOnSetValue: true,
                }}
              />
            );
          }}
        />
      </div>

      <div className="pb-7">
        <MapAutocompleteDropdown
          value={locationInput}
          setValue={newValue => {
            setLocationInput(newValue);
          }}
          predictions={locationPredictions}
          onSelectPrediction={prediction => {
            setSelectedLocationPrediction(prediction);
          }}
          placeholder={t('templateForm.general.locationAutocompletePlaceholder')}
          selectedPrediction={selectedLocationPrediction}
        />
      </div>

      <div className="pb-7">
        <LabeledStateInput
          id="template-coordinates"
          state={getInputState(getErrorMessage('coordinates'))}
          labeledProps={{
            wrapperClassName: 'w-full',
            label: t('templateForm.general.coordinatesLabel'),
            errorMessage: t(`errorMessages.${getErrorMessage('coordinates')?.message}`),
          }}
          inputProps={{
            placeholder: t('templateForm.general.coordinatesPlacholder'),
            register: {
              ...register('coordinates'),
            },
            autoComplete: 'off',
          }}
        />
      </div>

      <div className="pb-7">
        <LabeledStateInput
          id="template-estimatedDuration"
          state={getInputState(getErrorMessage('estimatedDuration'))}
          labeledProps={{
            wrapperClassName: 'w-full',
            label: t('templateForm.general.estimatedDurationLabel'),
            errorMessage: t(`errorMessages.${getErrorMessage('estimatedDuration')?.message}`),
          }}
          inputProps={{
            type: 'number',
            placeholder: t('templateForm.general.estimatedDurationPlacholder'),
            register: {
              ...register('estimatedDuration'),
            },
            onWheel: (e: React.WheelEvent) => {
              const targetElement = e.target as HTMLElement;
              targetElement.blur();
            },
            autoComplete: 'off',
          }}
        />
      </div>
    </div>
  );
};

export default TemplateFormGeneral;

const NewMessageIcon = ({ color, width = 32, height = 32 }: { color: string; width?: number; height?: number }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3332 2.66797H10.6665C5.33317 2.66797 2.6665 5.33464 2.6665 10.668V28.0013C2.6665 28.7346 3.2665 29.3346 3.99984 29.3346H21.3332C26.6665 29.3346 29.3332 26.668 29.3332 21.3346V10.668C29.3332 5.33464 26.6665 2.66797 21.3332 2.66797Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2132 10.4535L10.2932 17.3735C10.0266 17.6402 9.77323 18.1602 9.71989 18.5335L9.34656 21.1735C9.21323 22.1335 9.87989 22.8002 10.8399 22.6669L13.4799 22.2935C13.8532 22.2402 14.3732 21.9869 14.6399 21.7202L21.5599 14.8002C22.7466 13.6135 23.3199 12.2269 21.5599 10.4669C19.7999 8.69353 18.4132 9.25354 17.2132 10.4535Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2266 11.4414C16.8132 13.5347 18.4532 15.1881 20.5599 15.7747"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewMessageIcon;

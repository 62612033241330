import { useTranslation } from 'react-i18next';

import useBundlesDropdownOptions from '../../components/forms/partnerForm/hooks/useBundlesDropdownOptions';
import usePrepareInitialPartner from '../../components/forms/partnerForm/hooks/usePrepareInitialPartner';
import useTemplatesDropdownOptions from '../../components/forms/partnerForm/hooks/useTemplatesDropdownOptions';
import PartnerForm from '../../components/forms/partnerForm/PartnerForm';
import { PartnerFormSubmitProps } from '../../components/forms/partnerForm/types/partnerFormTypes';
import PlusIcon from '../../components/icons/PlusIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import useHandleAddPartner from './hooks/useHandleAddPartner';

const PartnerFormWithLoadingState = withLoading(PartnerForm);

const AddPartnerView = () => {
  const { t } = useTranslation();
  const { templatesDropdownOptions } = useTemplatesDropdownOptions();
  const { bundleDropdownOptions } = useBundlesDropdownOptions();
  const { prepareInitialPartner } = usePrepareInitialPartner();

  const initialPartner = prepareInitialPartner();
  const { initAddingPartnerData, addingPartnerInProgress } = useHandleAddPartner();

  const handleSubmitPatnerForm = async ({
    partner,
    baseDataHasChanged,
    subscriptionHasChanged,
    templatesHasChanged,
  }: PartnerFormSubmitProps) => {
    await initAddingPartnerData({ partner, baseDataHasChanged, subscriptionHasChanged, templatesHasChanged });
  };

  const allDataReady = !!initialPartner && !!bundleDropdownOptions && !!templatesDropdownOptions;

  return (
    <TitleWrapper title={t('partner.addPartner.title')}>
      <PartnerFormWithLoadingState
        submitBtnIcon={<PlusIcon />}
        submitBtnText={t('partner.addPartner.addPartnerButtonText')}
        submitForm={handleSubmitPatnerForm}
        initialPartner={initialPartner}
        submitIsInProgess={addingPartnerInProgress}
        isLoading={!allDataReady}
        templatesDropdownOptions={templatesDropdownOptions || []}
        bundleDropdownOptions={bundleDropdownOptions || []}
      />
    </TitleWrapper>
  );
};

export default AddPartnerView;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { ApiAddTemplateRequest } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useUpdateTemplate = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    ({ id, data }: { id: string; data: ApiAddTemplateRequest }) =>
      axiosInstance.patch(api.endpoints.SINGLE_TEMPLATE({ id }), { ...data }),
    {
      async onSuccess() {
        await queryClient.refetchQueries([QueryAliases.TEMPLATES]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.editTemplateFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { updateTemplate: mutateAsync, isLoading };
};

export default useUpdateTemplate;

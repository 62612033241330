import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useQuestionPointsOptions from '../../../../hooks/api/questions/useQuestionPointsOptions';
import { QuestionType, QuestionTypes } from '../../../../types/GlobalTypes';
import getInputState from '../../../../utils/getInputState/getInputState';
import { LabeledStateAutocompleteDropdown } from '../../../common/dropdowns/LabeledStateAutocompleteDropdown';
import { LabeledStateAutocompleteMultiDropdown } from '../../../common/dropdowns/LabeledStateAutocompleteMultiDropdown';
import FormSectionTitle from '../../../common/formSectionTitle/FormSectionTitle';
import { QuestionFormInputs, QuestionFormInputsKeys } from '../types/QuestionFormTypes';

interface QuestionFormPointsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage: (field: QuestionFormInputsKeys) => any;
  questionType: QuestionType;
}

const QuestionFormPoints = ({ getErrorMessage, questionType }: QuestionFormPointsProps) => {
  const { t } = useTranslation();

  const { pointsOptions } = useQuestionPointsOptions();

  const { control } = useFormContext<QuestionFormInputs>();

  const [pointOptionTextValue, setPointOptionTextValue] = useState('');

  const defaultPointsOptions =
    pointsOptions?.map(option => ({
      id: option.id.toString(),
      value: option.points,
    })) ?? [];

  const isAnimatorQuestion = questionType === QuestionTypes.ANIMATOR;

  return (
    <>
      <FormSectionTitle className="mb-6 mt-10" title={t('questionForm.points.title')} />

      <div className="pb-7 max-w-lg">
        {isAnimatorQuestion && (
          <Controller
            name="multiplePointsOptions"
            control={control}
            render={({ field }) => (
              <LabeledStateAutocompleteMultiDropdown
                id="question-multiple-points-options"
                state={getInputState(getErrorMessage('multiplePointsOptions'))}
                labeledProps={{
                  label: t('questionForm.points.multiplePointsOptionsLabel'),
                  wrapperClassName: 'w-full',
                  errorMessage: t(`errorMessages.${getErrorMessage('multiplePointsOptions')?.message}`),
                }}
                dropdownProps={{
                  placeholder: t('questionForm.points.multiplePointsOptionsPlaceholder'),
                  query: pointOptionTextValue,
                  setQuery: setPointOptionTextValue,
                  allOptions: defaultPointsOptions ?? [],
                  setSelectedOptions: newVal => {
                    field.onChange(newVal);
                  },
                  selectedOptions: field.value ?? [],
                  allowAddNewOption: true,
                }}
              />
            )}
          />
        )}

        {!isAnimatorQuestion && (
          <Controller
            name="points"
            control={control}
            render={({ field }) => (
              <LabeledStateAutocompleteDropdown
                id="question-points"
                state={getInputState(getErrorMessage('points'))}
                labeledProps={{
                  label: t('questionForm.points.pointsLabel'),
                  wrapperClassName: 'w-full',
                  errorMessage: t(`errorMessages.${getErrorMessage('points')?.message}`),
                }}
                dropdownProps={{
                  placeholder: t('questionForm.points.pointsPlaceholder'),
                  allOptions: defaultPointsOptions,
                  autocompleteValue: field.value?.toString() ?? '',
                  setAutocompleteValue: (newVal: string) => {
                    if (newVal === '') {
                      field.onChange(null);
                      return;
                    }

                    const isNumber = /^\d+(\.\d+)?$/.test(newVal);

                    if (!isNumber) {
                      return;
                    }

                    field.onChange(+newVal);
                  },
                }}
              />
            )}
          />
        )}
      </div>
    </>
  );
};

export default QuestionFormPoints;

import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useToast from '../../../../hooks/useToast';
import { routes } from '../../../../static/routes';
import { QueryAliases } from '../../../../types/QueryAliases';

const useToastSuccessAndNavigateOut = () => {
  const { showSuccessToast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const toastSuccessAndNavigateOut = async ({
    messageType,
    partnerId,
  }: {
    messageType?: string;
    partnerId: number;
  }) => {
    await queryClient.refetchQueries([QueryAliases.PARTNERS]);
    queryClient.invalidateQueries([QueryAliases.GET_PARTNER(partnerId.toString())]);
    const successMessage = t(`partner.toasts.${messageType ?? 'addPartnerSuccess'}`);
    showSuccessToast(successMessage);
    navigate(routes.partners);
  };

  return { toastSuccessAndNavigateOut };
};

export default useToastSuccessAndNavigateOut;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/QueryAliases';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useRejectAnswer = ({ gameId }: { gameId: string }) => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess } = useMutation(
    ({ answerId }: { answerId: number }) =>
      axiosInstance.post(api.endpoints.REJECT_ANSWER({ answerId: answerId.toString() })),
    {
      onSuccess: () => {
        queryClient.refetchQueries([QueryAliases.GAME_REVIEW_ANSWERS(gameId)]);
        queryClient.refetchQueries([QueryAliases.GAME_CHECKED_ANSWERS(gameId)]);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.rejectAnswerFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { rejectAnswer: mutateAsync, isLoading, isSuccess };
};

export default useRejectAnswer;

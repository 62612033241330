import { colors } from '../../../styles/variables';

const GameFinishedIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9190_8819)">
        <path
          d="M14.6667 7.38918V8.00251C14.6658 9.44013 14.2003 10.839 13.3395 11.9904C12.4788 13.1418 11.2688 13.9842 9.89022 14.3918C8.5116 14.7994 7.03815 14.7504 5.68963 14.2522C4.3411 13.754 3.18975 12.8332 2.40729 11.6272C1.62482 10.4212 1.25317 8.99456 1.34776 7.56006C1.44235 6.12556 1.99812 4.76006 2.93217 3.66723C3.86621 2.57439 5.1285 1.81277 6.53077 1.49594C7.93304 1.17912 9.40016 1.32407 10.7133 1.90918M14.6666 2.66536L7.99998 9.3387L5.99998 7.3387"
          stroke={color ?? colors.error}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9190_8819">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GameFinishedIcon;

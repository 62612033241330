import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import TypeToConfirmModal from '../../../components/common/modals/TypeToConfirmModal';
import HoverableTooltip from '../../../components/dataTable/components/HoverableTooltip';
import TrashIcon from '../../../components/icons/TrashIcon';
import useDeleteGame from '../../../hooks/api/games/useDeleteGame';
import { colors } from '../../../styles/variables';
import { QueryAliases } from '../../../types/QueryAliases';

const HistoryGameDeleteBtn = ({ gameId }: { gameId: string }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { deleteGame, isLoading } = useDeleteGame({
    onSuccess: () => {
      queryClient.refetchQueries([QueryAliases.HISTORY_GAMES]);
    },
  });

  const handleDeleteGame = async () => {
    await deleteGame(gameId);
    setIsModalOpen(false);
  };

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('historyGames.table.delete.label')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <TrashIcon color={isHovered ? colors.red500 : colors.grey700} width={24} height={24} />
          </button>
        )}
      </HoverableTooltip>

      <TypeToConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        textToType={t('historyGames.table.delete.textToType')}
        title={t('historyGames.table.delete.title')}
        confirmBtnText={t('historyGames.table.delete.confirmBtnText')}
        confirmBtnAction={handleDeleteGame}
        cancelBtnText={t('historyGames.table.delete.cancelBtnText')}
        isConfirmBtnDisabled={isLoading}
        isConfirmBtnLoading={isLoading}
      />
    </>
  );
};

export default HistoryGameDeleteBtn;

import { ApiAnswerRequest } from '../../../types/ApiTypes';
import { IAnswer, Langs } from '../../../types/GlobalTypes';
import { findValueByLang } from '../../findValueByLang/findValueByLang';
import { groupAnswersById } from './groupAnswersById';

export const prepareQuestionAnswers = ({
  correctAnswers,
  incorrectAnswers,
}: {
  correctAnswers: IAnswer[];
  incorrectAnswers: IAnswer[];
}) => {
  const answers: ApiAnswerRequest[] = [];

  groupAnswersById(correctAnswers).forEach(item => {
    answers.push({
      isCorrect: true,
      content: {
        pl: findValueByLang({ lang: Langs.PL, array: item.answers }) as string,
        en:
          findValueByLang({ lang: Langs.EN, array: item.answers }) ||
          (findValueByLang({ lang: Langs.PL, array: item.answers }) as string),
        ua:
          findValueByLang({ lang: Langs.UA, array: item.answers }) ||
          (findValueByLang({ lang: Langs.PL, array: item.answers }) as string),
      },
    });
  });

  groupAnswersById(incorrectAnswers).forEach(item => {
    answers.push({
      isCorrect: false,
      content: {
        pl: findValueByLang({ lang: Langs.PL, array: item.answers }) as string,
        en:
          findValueByLang({ lang: Langs.EN, array: item.answers }) ||
          (findValueByLang({ lang: Langs.PL, array: item.answers }) as string),
        ua:
          findValueByLang({ lang: Langs.UA, array: item.answers }) ||
          (findValueByLang({ lang: Langs.PL, array: item.answers }) as string),
      },
    });
  });

  return answers;
};

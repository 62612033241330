import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CallbackDefault } from '../../../../types/Types';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import ConfirmModal from '../../../common/modals/ConfirmModal';

const QuestionFormRelatedQuestionButton = ({
  onSaveCurrentForm,
  isLoading,
  text,
}: {
  onSaveCurrentForm: CallbackDefault;
  isLoading: boolean;
  text: string;
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <PrimaryButton onClick={() => setIsModalOpen(true)} className="w-fit">
        {text}
      </PrimaryButton>
      <ConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={t('questionForm.relatedQuestion.modal.title')}
        confirmQuestion={t('questionForm.relatedQuestion.modal.confirmQuestion')}
        confirmBtnText={t('questionForm.relatedQuestion.modal.confirmBtnText')}
        confirmBtnAction={() => {
          setIsModalOpen(false);
          onSaveCurrentForm();
        }}
        cancelBtnText={t('questionForm.relatedQuestion.modal.cancelBtnText')}
        isConfirmBtnDisabled={isLoading}
        isConfirmBtnLoading={isLoading}
      />
    </>
  );
};

export default QuestionFormRelatedQuestionButton;

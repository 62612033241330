import { colors } from '../../../styles/variables';
import { CallbackDefault } from '../../../types/Types';
import MinusIcon from '../../icons/MinusIcon';
import PlusIcon from '../../icons/PlusIcon';

interface MapZoomButtonsProps {
  onClickPlus: CallbackDefault;
  onClickMinus: CallbackDefault;
}

const MapZoomButtons = ({ onClickPlus, onClickMinus }: MapZoomButtonsProps) => {
  return (
    <div className="flex flex-col gap-2">
      <button
        data-testid="zoom-plus-btn"
        onClick={onClickPlus}
        className="p-2 flex justify-center items-center bg-grey100 rounded-md border border-grey300"
      >
        <PlusIcon color={colors.black700} />
      </button>
      <button
        data-testid="zoom-minus-btn"
        onClick={onClickMinus}
        className="p-2 flex justify-center items-center bg-grey100 rounded-md border border-grey300"
      >
        <MinusIcon />
      </button>
    </div>
  );
};

export default MapZoomButtons;

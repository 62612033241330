import { useTranslation } from 'react-i18next';

import MapMarkerQuestionTypeWrapper from '../../../../../../components/map/components/MapMarkerQuestionTypeWrapper';
import useQuestionPointsOptions from '../../../../../../hooks/api/questions/useQuestionPointsOptions';
import useFindTranslation from '../../../../../../hooks/useFindTranslation';
import { IQuestion } from '../../../../../../types/ApiTypes';

const AnswerQuestionLabel = ({ question }: { question: IQuestion }) => {
  const { t } = useTranslation();
  const { findTranslation } = useFindTranslation();

  const { pointsOptions, pointsFallbackColor } = useQuestionPointsOptions();

  const pointsColor =
    pointsOptions?.find(option => option.points === question.points?.toString())?.color ?? pointsFallbackColor;

  const getPointsInfo = () => {
    if (!!question.points) {
      return question.points;
    } else if (!!question.pointsOptions) {
      return question.pointsOptions.map((option, index) => {
        if (index === question.pointsOptions!.length - 1) {
          return option;
        }

        return ` ${option}`;
      });
    } else {
      return '';
    }
  };

  return (
    <div className="flex gap-2.5 mb-6">
      <MapMarkerQuestionTypeWrapper color={pointsColor}>
        <img
          src={question.icon ?? question.questionType.icon}
          alt={question.questionType.code}
          className="!max-w-[1.5rem] max-h-6"
        />
      </MapMarkerQuestionTypeWrapper>
      <div>
        <div className="flex items-center gap-2">
          <p className="text-grey700 text-sm font-semibold">{`${t(
            'singleActiveGame.answers.question',
          )} ${findTranslation(question.name)}`}</p>
          <p className="text-sm font-semibold" style={{ color: pointsColor }}>{`${getPointsInfo()} ${t(
            'singleActiveGame.answers.points',
          )}`}</p>
        </div>
        <p className="text-black500 text-sm font-medium mt-1.5">{findTranslation(question.content!!)}</p>
      </div>
    </div>
  );
};

export default AnswerQuestionLabel;

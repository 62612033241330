import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCreatedTemplatesStatistics from '../../../hooks/api/games/statistics/useCreatedTemplatesStatistics';
import DashboardStatsChart from './common/DashboardStatsChart';

interface DashboardStatsTemplatesProps {
  ownerFilter: string;
  gameTypeFilter: string;
}

const DashboardStatsTemplates = ({ ownerFilter, gameTypeFilter }: DashboardStatsTemplatesProps) => {
  const { t } = useTranslation();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const { createdTemplatesStatistics, isLoading } = useCreatedTemplatesStatistics({
    params: { email: ownerFilter, gameType: gameTypeFilter, year: currentYear },
  });

  const chartData = createdTemplatesStatistics?.countByMonth;

  return (
    <DashboardStatsChart
      title={t('dashboard.templates.title')}
      currentYear={currentYear}
      onChangeCurrentYear={setCurrentYear}
      chartData={chartData ?? []}
      isChartDataLoading={isLoading}
      totalInfoLabel={t('dashboard.templates.totalInfo')}
      chartDataTotalCount={createdTemplatesStatistics?.totalCount}
    />
  );
};

export default DashboardStatsTemplates;

import { getMapTypeOptions } from './core/getMapTypeOptions';
import { useMapType } from './hooks/useMapType';

type MapTypeSelectProps = {
  mapRef: React.RefObject<google.maps.Map | null>;
};

export default function MapTypeSelect({ mapRef }: MapTypeSelectProps) {
  const { mapTypeId, handleMapTypeChange } = useMapType(mapRef);

  return (
    <div className="absolute z-10 bg-white900 px-2 py-2 rounded-md top-4 focus-visible:outline-none left-2 text-sm">
      <select id="mapType" className="outline-none " value={mapTypeId} onChange={handleMapTypeChange}>
        {getMapTypeOptions().map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

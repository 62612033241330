import { type MutableRefObject, useEffect } from 'react';

export function useFitBounds(
  mapRef: MutableRefObject<google.maps.Map | null>,
  bounds: google.maps.LatLngBounds | null | undefined,
) {
  useEffect(() => {
    if (mapRef.current && !!bounds) {
      const curretZoom = mapRef.current.getZoom();
      mapRef.current.fitBounds(bounds);
      if (!curretZoom) {
        return;
      }
      mapRef.current.setZoom(curretZoom);
    }
  }, [mapRef, bounds]);
}

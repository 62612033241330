import useEditBundle from '../../../hooks/api/bundle/useEditBundle';
import { IBundleFormInputs } from '../../../types/ApiBundleTypes';
import { prepareEditBundleRequest } from '../../../utils/bundle/prepareEditBundleRequest';

const useHandleEditBundle = () => {
  const { editBundle } = useEditBundle();

  const handleEditBundle = async (data: IBundleFormInputs) => {
    const request = prepareEditBundleRequest(data);
    await editBundle(request);
  };
  return { handleEditBundle };
};

export default useHandleEditBundle;

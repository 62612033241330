import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import NewMessageIcon from '../../../../components/icons/NewMessageIcon';
import useGameReceivedMessages from '../../../../hooks/api/games/messages/useGameReceivedMessages';
import useGameSentMessages from '../../../../hooks/api/games/messages/useGameSentMessages';
import useMarkMessageRead from '../../../../hooks/api/games/messages/useMarkMessageRead';
import useSingleGame from '../../../../hooks/api/games/useSingleGame';
import { colors } from '../../../../styles/variables';
import { ITeamMessageGroup } from '../../../../types/ApiTypes';
import SingleActiveGamePageWrapper from '../../SingleActiveGamePageWrapper';
import NewMessage from './components/NewMessage';
import SingleMessageDetails from './components/SingleMessageDetails';
import SingleMessageTile from './components/SingleMessageTile';
import { getAllMessagesGroups } from './utils/getAllMessagesGroups';

const SingleActiveGameMessagesView = () => {
  const { t } = useTranslation();
  const params = useParams();
  const gameId = params.id as string;

  const { game } = useSingleGame({ gameId });
  const { messages: receivedMessages } = useGameReceivedMessages({ gameId });
  const { messages: sentMessages } = useGameSentMessages({ gameId });
  const { markMessageRead } = useMarkMessageRead();

  const allMessagesGroups = useMemo(
    () =>
      getAllMessagesGroups({ sentMessages, receivedMessages }).sort((a, b) => {
        const aLastMessageDate = a.messages.at(-1)?.createdAt;
        const bLastMessageDate = b.messages.at(-1)?.createdAt;
        if (aLastMessageDate && bLastMessageDate) {
          return +new Date(bLastMessageDate) - +new Date(aLastMessageDate);
        } else if (aLastMessageDate) {
          return 1;
        } else if (bLastMessageDate) {
          return -1;
        } else {
          return 0;
        }
      }),
    [sentMessages, receivedMessages],
  );

  const [selectedMessageGroupId, setSelectedMessageGroupId] = useState<number | null>(null);
  const selectedMessageGroup = useMemo(
    () => allMessagesGroups.find(group => group.team.id === selectedMessageGroupId),
    [selectedMessageGroupId, allMessagesGroups],
  );

  const handleWriteNewMessage = () => {
    setSelectedMessageGroupId(null);
  };

  const handleSelectMessageGroup = (messageGroup: ITeamMessageGroup) => {
    setSelectedMessageGroupId(messageGroup.team.id);

    const unreadMessages = messageGroup.messages.filter(message => !message.wasRead && message.isReceived);

    if (unreadMessages.length !== 0) {
      unreadMessages.forEach(unreadMessage => {
        markMessageRead({ messageId: unreadMessage.id });
      });
    }
  };

  return (
    <SingleActiveGamePageWrapper>
      <div className="flex gap-5 mt-6 justify-between">
        <div className="w-full rounded-md border border-grey300">
          <div className="p-4 flex justify-between items-center">
            <h5 className="text-grey500 uppercase font-bold text-sm">
              {t('singleActiveGame.messages.allMessagesHeader')}
            </h5>
            <button onClick={handleWriteNewMessage}>
              <NewMessageIcon color={colors.grey500} />
            </button>
          </div>
          <div className="border-t border-t-grey300">
            {allMessagesGroups.map(messageGroup => {
              console.log(allMessagesGroups);
              return (
                <SingleMessageTile
                  key={messageGroup.team.id}
                  messageGroup={messageGroup}
                  onClick={() => handleSelectMessageGroup(messageGroup)}
                  isSelected={selectedMessageGroup?.team.id === messageGroup.team.id}
                />
              );
            })}
          </div>
        </div>
        <div className="w-full rounded-md border border-grey300">
          {!!selectedMessageGroup ? (
            <SingleMessageDetails messageGroup={selectedMessageGroup} gameId={gameId} gameStatus={game?.status} />
          ) : (
            <NewMessage gameId={gameId} gameStatus={game?.status} />
          )}
        </div>
      </div>
    </SingleActiveGamePageWrapper>
  );
};

export default SingleActiveGameMessagesView;

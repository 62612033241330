import { useCallback } from 'react';
import i18n, { defaultLang } from '../i18n';
import type { ITranslation } from '../types/ApiTypes';
import { Langs } from '../types/GlobalTypes';

function useFindTranslation() {
  const currentLang = i18n.resolvedLanguage ?? Langs.PL;

  const findTranslation = useCallback(
    (translations: ITranslation[]): string => {
      if (!translations || translations.length === 0) {
        return '';
      }

      const translation = translations.find(tr => tr.lang === currentLang);

      if (translation) {
        return translation.value;
      }

      const defaultTranslation = translations.find(tr => tr.lang === defaultLang.id);
      return defaultTranslation?.value ?? '';
    },
    [currentLang],
  );

  return { findTranslation };
}

export default useFindTranslation;

import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../common/modals/ConfirmModal';
import { useNavigationGuard } from './hooks/useNavigationGuard';

type UnsavedChangesLinkProps = {
  to: string;
} & ComponentProps<'button'>;

export function UnsavedChangesLink({ children, to: nextLocation, ...props }: UnsavedChangesLinkProps) {
  const { t } = useTranslation();
  const { isModalOpen, setIsModalOpen, handleConfirmNavigation, handleNavigation, hasUnsavedChanges } =
    useNavigationGuard(nextLocation);

  return (
    <>
      {isModalOpen && hasUnsavedChanges && (
        <ConfirmModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          title={t('navigation.modal.confirm.title')}
          confirmQuestion={t('navigation.modal.confirm.confirmQuestion')}
          confirmBtnText={t('navigation.modal.confirm.confirmBtnText')}
          confirmBtnAction={handleConfirmNavigation}
          cancelBtnText={t('navigation.modal.confirm.cancelBtnText')}
        />
      )}
      <button type="button" onClick={handleNavigation} {...props}>
        {children}
      </button>
    </>
  );
}

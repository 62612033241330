import * as yup from 'yup';
import * as Schema from '../schemaFields';

export const partnerValidationSchema = yup.object({
  baseData: yup
    .object({
      name: Schema.requiredString,
      email: Schema.email,
      phone: Schema.phoneNumberRequired,
    })
    .required('required'),
  subscription: yup
    .object({
      bundle: yup.object({ id: Schema.requiredString, value: Schema.requiredString }),
      coordinates: Schema.requiredString,
      validUntil: Schema.requiredDate,
    })
    .required('required'),
});

import useUpdateSubscription from '../../../hooks/api/subscription/useUpdateSubscription';
import { InitialPartnerSubscription } from '../../../types/ApiPartnerTypes';
import { prepareEditSubscriptionRequest } from '../utils/prepareEditSubscriptionRequest';

const useHandleEditSubscription = () => {
  const { updateSubscription, isLoading } = useUpdateSubscription();

  const handleEditSubscription = async ({ subscription }: { subscription: InitialPartnerSubscription }) => {
    const subscriptionRequestData = prepareEditSubscriptionRequest(subscription);

    const { data } = await updateSubscription({
      subscription: subscriptionRequestData,
      subscriptionId: subscription.id as number,
    });

    const subscriptionId: number | undefined = data.id;
    return subscriptionId;
  };

  return { handleEditSubscription, handleEditSubscriptionIsLoading: isLoading };
};

export default useHandleEditSubscription;

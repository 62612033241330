import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import TypeToConfirmModal from '../../../../../components/common/modals/TypeToConfirmModal';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import TrashIcon from '../../../../../components/icons/TrashIcon';
import useDeleteTemplate from '../../../../../hooks/api/templates/useDeleteTemplate';
import { colors } from '../../../../../styles/variables';
import { QueryAliases } from '../../../../../types/QueryAliases';

interface TemplatesListTableDeleteBtnProps {
  templateId: string;
}

const TemplatesListTableDeleteBtn = ({ templateId }: TemplatesListTableDeleteBtnProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { deleteTemplate, isLoading: isLoadingDelete } = useDeleteTemplate({
    onSuccess: () => {
      queryClient.refetchQueries([QueryAliases.TEMPLATES]);
    },
  });

  const handleDeleteTemplate = async () => {
    deleteTemplate(templateId);
    setIsModalOpen(false);
  };

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('templates.table.buttons.delete')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <TrashIcon color={isHovered ? colors.red500 : colors.grey700} />
          </button>
        )}
      </HoverableTooltip>

      <TypeToConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        textToType={t('templates.table.delete.textToType')}
        title={t('templates.table.delete.title')}
        confirmBtnText={t('templates.table.delete.confirmBtnText')}
        confirmBtnAction={handleDeleteTemplate}
        cancelBtnText={t('templates.table.delete.cancelBtnText')}
        confirmBtnIcon={<TrashIcon color={colors.white900} />}
        isConfirmBtnDisabled={isLoadingDelete}
        isConfirmBtnLoading={isLoadingDelete}
      />
    </>
  );
};

export default TemplatesListTableDeleteBtn;

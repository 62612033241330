import { colors } from '../../styles/variables';

interface UserIconProps {
  color?: string;
  small?: boolean;
}

const UserIcon = ({ color, small = false }: UserIconProps) => {
  return (
    <svg
      width={small ? '24' : '32'}
      height={small ? '24' : '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 26.6667C7.11439 23.3634 11.3427 21.3333 16 21.3333C20.6573 21.3333 24.8856 23.3634 28 26.6667M22 10C22 13.3137 19.3137 16 16 16C12.6863 16 10 13.3137 10 10C10 6.68629 12.6863 4 16 4C19.3137 4 22 6.68629 22 10Z"
        stroke={color ?? colors.black700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;

import { useState } from 'react';
import MapTeamsPaths from '../../../../../../components/mapTeamsPaths/MapTeamsPaths';
import { IUserGame } from '../../../../../../types/ApiTypes';
import ActiveMapTeamsSelectionPanel from './components/ActiveMapTeamsSelectionPanel';

interface ActiveGameTeamsProps {
  teams: IUserGame[];
}

const ActiveGameTeams = ({ teams }: ActiveGameTeamsProps) => {
  const [selectedTeam, setSelectedTeam] = useState<IUserGame | null>(null);

  return (
    <>
      <MapTeamsPaths teams={teams} selectedTeam={selectedTeam} setSelectedTeam={setSelectedTeam} />

      {!!selectedTeam && <ActiveMapTeamsSelectionPanel selectedTeam={selectedTeam} />}
    </>
  );
};

export default ActiveGameTeams;

import { AxiosResponse } from 'axios';

export const getFileFromResponse = ({
  response,
  fileContentType,
  fileType,
  fileName,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: AxiosResponse<any, any>;
  fileContentType: string;
  fileType: string;
  fileName: string;
}) => {
  const blob = new Blob([response.data], { type: fileContentType });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');

  a.href = url;
  a.download = `${fileName}.${fileType}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

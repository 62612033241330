import useAddPartner from '../../../hooks/api/partner/useAddPartner';
import { InitialPartnerBaseData } from '../../../types/ApiPartnerTypes';

const useHandleAddPartnerBaseData = () => {
  const { addPartner, isLoading } = useAddPartner();

  const handleAddPartnerBaseData = async ({ baseData }: { baseData: InitialPartnerBaseData }) => {
    const { data } = await addPartner(baseData);

    const partnerId: number | undefined = data?.id;

    return partnerId;
  };

  return { handleAddPartnerBaseData, handleAddPartnerIsLoading: isLoading };
};

export default useHandleAddPartnerBaseData;

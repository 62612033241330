import MaterialTable from '../../../components/dataTable/MaterialTable/MaterialTable';
import useAllBundles from '../../../hooks/api/bundle/useAllBundles';
import useBundlesTableHeaders from '../hooks/useBundlesTableHeaders';

const BundlesList = () => {
  const { bundles, isLoading } = useAllBundles();
  const { headers } = useBundlesTableHeaders();

  return (
    <div className="pb-36">
      <MaterialTable columns={headers} rowCount={bundles?.length} rows={bundles ?? []} loading={isLoading} />
    </div>
  );
};

export default BundlesList;

import { OverlayView, OverlayViewF, PolylineF } from '@react-google-maps/api';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { colors } from '../../styles/variables';
import { IUserGame } from '../../types/ApiTypes';
import CheckIcon from '../icons/CheckIcon';
import XMarkIcon from '../icons/XmarkIcon';
import MapMarkerBasic from '../map/components/MapMarkerBasic';

interface MapTeamsPathsProps {
  teams: IUserGame[];
  selectedTeam?: IUserGame | null;
  setSelectedTeam?: Dispatch<SetStateAction<IUserGame | null>>;
}

const MapTeamsPaths = ({ teams, selectedTeam, setSelectedTeam }: MapTeamsPathsProps) => {
  const handleTeamMarkerClick = (team: IUserGame) => {
    if (!setSelectedTeam) {
      return;
    }

    setSelectedTeam(prevTeam => {
      if (prevTeam?.id === team.id) {
        return null;
      } else {
        return team;
      }
    });
  };

  const handleResetSelectedTeam = () => {
    if (!setSelectedTeam) {
      return;
    }

    setSelectedTeam(null);
  };

  return (
    <>
      {teams.map(team => {
        const firstPoint = team.allCoordinates[0];
        const lastPoint = team.allCoordinates[team.allCoordinates.length - 1];

        return (
          <Fragment key={team.id}>
            <PolylineF
              key={team.id}
              path={team.allCoordinates}
              options={{
                strokeColor: team.color,
                strokeWeight: 3,
                strokeOpacity: 0.8,
              }}
            />
            {!!firstPoint && (
              <OverlayViewF position={firstPoint} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                <div
                  className={`w-2 h-2 rounded-lg -translate-x-1/2 -translate-y-1/2`}
                  style={{ backgroundColor: team.color }}
                />
              </OverlayViewF>
            )}
            {!!lastPoint && (
              <MapMarkerBasic
                markerColor={team.color}
                coordinates={lastPoint}
                onClick={() => handleTeamMarkerClick(team)}
              >
                <div
                  className="absolute -top-8 right-1/2 translate-x-1/2 p-2 rounded-full"
                  style={{ backgroundColor: team.color }}
                >
                  <p className="text-white900 text-xs font-normal leading-4 whitespace-nowrap">
                    {team.teamName ?? team.user.email}
                  </p>
                </div>
                {selectedTeam?.id === team.id && (
                  <>
                    <button
                      onClick={e => {
                        e.stopPropagation();
                        handleResetSelectedTeam();
                      }}
                      className="flex justify-center items-center bg-grey100 rounded-full border border-grey300 w-5 h-5 absolute -top-1 -right-1"
                    >
                      <XMarkIcon size="8" color={colors.black700} />
                    </button>
                    <div className="flex justify-center items-center bg-success rounded-full border border-grey300 w-5 h-5 absolute -bottom-2 -right-1">
                      <CheckIcon />
                    </div>
                  </>
                )}
              </MapMarkerBasic>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default MapTeamsPaths;

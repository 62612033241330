import { CircularProgress } from '@mui/material';
import { ComponentType, FC } from 'react';

interface WithLoadingProps {
  isLoading: boolean;
}

const withLoading = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithLoading: FC<P & WithLoadingProps> = (props: WithLoadingProps) => {
    const { isLoading, ...rest } = props;

    if (isLoading) {
      return (
        <div className="w-full flex justify-center items-center h-full">
          <CircularProgress />
        </div>
      );
    }

    return <WrappedComponent {...(rest as P)} />;
  };

  return WithLoading;
};

export default withLoading;

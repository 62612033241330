import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CallbackDefault } from '../../../types/Types';
import ArrowLeft from '../../icons/ArrowLeft';
import ConfirmModal from '../modals/ConfirmModal';
import IconButton from './IconButton';

interface BackButtonProps {
  className?: string;
  onClick?: CallbackDefault;
}

const BackButton = ({ className, onClick }: BackButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    if (!!onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        icon={<ArrowLeft />}
        className={`border border-grey400 bg-grey100 ${className}`}
      />
      <ConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={t('navigation.modal.confirm.title')}
        confirmQuestion={t('navigation.modal.confirm.confirmQuestion')}
        confirmBtnText={t('navigation.modal.confirm.confirmBtnText')}
        confirmBtnAction={() => {
          setIsModalOpen(false);
          handleClick();
        }}
        cancelBtnText={t('navigation.modal.confirm.cancelBtnText')}
      />
    </>
  );
};

export default BackButton;

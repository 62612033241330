import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TypeToConfirmModal from '../../../components/common/modals/TypeToConfirmModal';
import HoverableTooltip from '../../../components/dataTable/components/HoverableTooltip';
import TrashIcon from '../../../components/icons/TrashIcon';
import useDeleteBundle from '../../../hooks/api/bundle/useDeleteBundle';
import { colors } from '../../../styles/variables';

interface DeleteBundleButtonProps {
  id: number;
  bundleName: string;
}

const DeleteBundleButton = ({ id, bundleName }: DeleteBundleButtonProps) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { deleteBundle, isSuccess, isLoading } = useDeleteBundle();

  const handleDeletePlayer = async () => {
    await deleteBundle(id);
    isSuccess && setIsModalOpen(false);
  };

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('bundle.tableDeleteLabel')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <TrashIcon color={isHovered ? colors.red500 : colors.grey700} />
          </button>
        )}
      </HoverableTooltip>

      <TypeToConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={t('bundle.deleteBundle.modalTitle')}
        textToType={bundleName}
        confirmBtnText={t('bundle.deleteBundle.confirmButtonText')}
        confirmBtnAction={handleDeletePlayer}
        cancelBtnText={t('bundle.deleteBundle.cancelButtonText')}
        isConfirmBtnDisabled={isLoading}
        isConfirmBtnLoading={isLoading}
      />
    </>
  );
};

export default DeleteBundleButton;

import { DrawingManagerF } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import { colors } from '../../../styles/variables';
import { Coordinates, RectangleAreaCoordinates } from '../../../types/GlobalTypes';
import { filterItemsWithinArea } from '../../../utils/map/filterItemsWithinArea/filterItemsWithinArea';
import { getGoogleMapAreaCoordinates } from '../../../utils/map/getGoogleMapAreaCoordinates/getGoogleMapAreaCoordinates';
import AreaIcon from '../../icons/AreaIcon';
import DragIcon from '../../icons/DragIcon';

export interface MapSelectableItem {
  id: number;
  coordinates?: Coordinates;
}

interface MapDrawingManagerProps {
  allItems: MapSelectableItem[];
  onSelectItems: (items: MapSelectableItem[]) => void;
}

const MapDrawingManager = ({ allItems, onSelectItems }: MapDrawingManagerProps) => {
  const [drawingManager, setDrawingManager] = useState<google.maps.drawing.DrawingManager | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const handleSelectItems = useCallback(
    (areaCoordinates: RectangleAreaCoordinates) => {
      const areaItems = filterItemsWithinArea({ items: allItems, areaCoordinates });

      onSelectItems(areaItems);
    },
    [allItems, onSelectItems],
  );

  const handleOverlayComplete = useCallback(
    (e: google.maps.drawing.OverlayCompleteEvent) => {
      if (e.type === google.maps.drawing.OverlayType.RECTANGLE) {
        const newRectangle = e.overlay as google.maps.Rectangle;
        const newRectangleBounds = newRectangle.getBounds();

        if (newRectangleBounds) {
          const areaCoordinates = getGoogleMapAreaCoordinates(newRectangleBounds);
          handleSelectItems(areaCoordinates);
        }

        const hideRectangle = () => {
          newRectangle.setMap(null);
        };

        hideRectangle();
      }

      setIsDrawing(false);
      drawingManager?.setDrawingMode(null);
    },
    [drawingManager, handleSelectItems],
  );

  const handleStartDrawingMode = () => {
    setIsDrawing(true);
    drawingManager?.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);
  };

  const handleEndDrawingMode = () => {
    setIsDrawing(false);
    drawingManager?.setDrawingMode(null);
  };

  useEffect(() => {
    if (drawingManager) {
      google.maps.event.addListener(drawingManager, 'overlaycomplete', handleOverlayComplete);
    }
  }, [drawingManager, handleOverlayComplete]);

  const handleDrawingManagerLoad = useCallback((drawingManager: google.maps.drawing.DrawingManager) => {
    drawingManager.setOptions({
      drawingControl: false,
      rectangleOptions: {
        strokeColor: colors.red700,
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: 'transparent',
        fillOpacity: 0.35,
      },
    });
    setDrawingManager(drawingManager);
  }, []);

  return (
    <>
      <DrawingManagerF onLoad={handleDrawingManagerLoad} />

      {drawingManager && (
        <div className="z-10 absolute top-3 left-1/2 -translate-x-1/2 bg-white900 flex rounded-lg">
          <button
            className={`flex items-center justify-center py-2 px-4 bg-g rounded-lg ${
              isDrawing ? 'bg-white900' : 'bg-red700'
            }`}
            onClick={handleEndDrawingMode}
          >
            <DragIcon color={isDrawing ? colors.red700 : colors.white900} />
          </button>
          <button
            className={`flex items-center justify-center py-2 px-4 bg-g rounded-lg ${
              isDrawing ? 'bg-red700' : 'bg-white900'
            }`}
            onClick={handleStartDrawingMode}
          >
            <AreaIcon color={isDrawing ? colors.white900 : colors.red500} />
          </button>
        </div>
      )}
    </>
  );
};

export default MapDrawingManager;

import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { muiDatepickerStyles } from '../../../styles/muiStyles';

export interface TimepickerProps {
  date: Date | null | undefined;
  setDate: (date: Date | null | undefined) => void;
}
const Timepicker = ({ date, setDate }: TimepickerProps) => {
  const { t } = useTranslation();

  return (
    <MobileTimePicker
      value={!!date ? dayjs(date) : null}
      onChange={newDate => {
        setDate(!!newDate ? newDate.toDate() : null);
      }}
      localeText={{
        toolbarTitle: t('timepicker.toolbarTitle'),
        okButtonLabel: t('timepicker.okBtnLabel'),
        cancelButtonLabel: t('timepicker.cancelBtnLabel'),
      }}
      className="w-full"
      sx={{ ...muiDatepickerStyles }}
    />
  );
};

export default Timepicker;

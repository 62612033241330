import { type MouseEvent, useState } from 'react';
import type { IQuestion } from '../../../../../../../types/ApiTypes';
import { swapArrayItemsPosition } from '../../../../../../../utils/general/swapArrayItemsPosition/swapArrayItemsPosition';

function useSelectedQuestions(
  selectedQuestions: IQuestion[],
  setSelectedQuestions: React.Dispatch<React.SetStateAction<IQuestion[]>>,
) {
  const [selectedToRemoveQuestions, setSelectedToRemoveQuestions] = useState<IQuestion[]>([]);
  const [firstSelectedQuestion, setFirstSelectedQuestion] = useState<IQuestion | null>(null);
  const [lastSelectedQuestion, setLastSelectedQuestion] = useState<IQuestion | null>(null);

  const toggleSelectedToRemoveQuestions = (event: MouseEvent, newQuestion: IQuestion) => {
    if (event.shiftKey) {
      if (lastSelectedQuestion) {
        setLastSelectedQuestion(null);
        setFirstSelectedQuestion(null);
        return;
      }
      if (!firstSelectedQuestion) {
        setFirstSelectedQuestion(newQuestion);
      } else {
        const questionsToSelect = selectedQuestions.filter(question => {
          return firstSelectedQuestion.id > newQuestion.id
            ? question.id < firstSelectedQuestion.id && question.id > newQuestion.id
            : question.id > firstSelectedQuestion.id && question.id < newQuestion.id;
        });

        for (const questionBetween of questionsToSelect) {
          toggleSelectedToRemoveQuestions({ ...event, shiftKey: false }, questionBetween);
        }
      }
    }
    setSelectedToRemoveQuestions(prevQuestions => {
      const foundQuestion = prevQuestions.find(question => question.id === newQuestion.id);

      if (foundQuestion) {
        return prevQuestions.filter(question => question.id !== newQuestion.id);
      }
      return [...prevQuestions, newQuestion];
    });
  };

  const handleRemoveFromGame = (onRemoveFromGame: (questions: IQuestion[]) => void) => {
    onRemoveFromGame(selectedToRemoveQuestions);
    setSelectedToRemoveQuestions([]);
  };

  const moveQuestion = (oldIndex: number, newIndex: number) => {
    setSelectedQuestions(prevQuestions => {
      const newSortedQuestions = [...prevQuestions];
      swapArrayItemsPosition(newSortedQuestions, oldIndex, newIndex);

      return newSortedQuestions;
    });
  };

  const handleMoveUp = ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => {
    e.stopPropagation();
    moveQuestion(currentIndex, currentIndex - 1);
  };

  const handleMoveDown = ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => {
    e.stopPropagation();
    moveQuestion(currentIndex, currentIndex + 1);
  };

  return {
    selectedToRemoveQuestions,
    toggleSelectedToRemoveQuestions,
    handleRemoveFromGame,
    handleMoveUp,
    handleMoveDown,
  };
}

export default useSelectedQuestions;

import { useNavigate } from 'react-router-dom';

import { useCommonSearchParams } from '../../../hooks/params/useCommonSearchParams';
import { QuestionsViewFilter, routes } from '../../../static/routes';
import { searchParamsData } from '../../../static/searchParamsData';

export const useQuestionsViewParams = () => {
  const navigate = useNavigate();

  const {
    searchFilter,
    debouncedSearchValue,
    page,
    tagFilters,
    cityFilter,
    ownerFilter,
    arcadeFilter,
    getValueFromSearchParams,
  } = useCommonSearchParams();

  const questionTypeFilter = getValueFromSearchParams(searchParamsData.questionTypeFilter);

  const navigateWithFilters = (filters: Record<string, string | number | string[]>): void => {
    navigate(routes.questions(filters));
  };

  const handleChange = (filter: QuestionsViewFilter, newVal: string | number): void => {
    const filters: Record<string, string | number | string[]> = {
      ...(!!searchFilter && { search: searchFilter }),
      ...(!!page && { page }),
      ...(!!tagFilters && { tag: tagFilters }),
      ...(!!questionTypeFilter && { questionType: questionTypeFilter }),
      ...(!!cityFilter && { city: cityFilter }),
      ...(!!ownerFilter && { owner: ownerFilter }),
      ...(!!arcadeFilter && { isArcade: arcadeFilter }),
      [filter]: newVal,
    };
    navigateWithFilters(filters);
  };

  return {
    searchFilter,
    debouncedSearchValue,
    handleChangeSearchFilter: (newVal: string) => handleChange('search', newVal),

    page,
    handleChangePage: (newVal: number) => handleChange('page', newVal),

    tagFilters,
    handleChangeTagFilters: (newTags: string[]) => handleChange('tag', newTags.join(';')),

    questionTypeFilter,
    handleChangeQuestionTypeFilter: (newVal: string) => handleChange('questionType', newVal),

    cityFilter,
    handleChangeCityFilter: (newVal: string) => handleChange('city', newVal),

    ownerFilter,
    handleChangeOwnerFilter: (newVal: string) => handleChange('owner', newVal),

    arcadeFilter,
    handleChangeArcadeFilter: (newVal: string) => handleChange('isArcade', newVal),
  };
};

import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { muiDatepickerStyles } from '../../../styles/muiStyles';
import CalendarIcon from '../../icons/CalendarIcon';

export interface DatepickerProps {
  date: Date | null | undefined;
  setDate: (date: Date | null | undefined) => void;
  minDate?: Date;
}

const Datepicker = ({ date, setDate, minDate }: DatepickerProps) => {
  return (
    <DatePicker
      format="DD/MM/YYYY"
      value={!!date ? dayjs(date) : null}
      onChange={date => {
        setDate(!!date ? date.toDate() : null);
      }}
      className="text-sm text-black500 font-medium w-full placeholder:text-grey500 placeholder:text-sm"
      slots={{
        openPickerIcon: props => <CalendarIcon {...props} width={24} height={24} />,
      }}
      sx={muiDatepickerStyles}
      minDate={!!minDate ? dayjs(minDate) : undefined}
    />
  );
};

export default Datepicker;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '../../components/common/buttons/PrimaryButton';
import SearchBar from '../../components/common/searchBar/SearchBar';
import PlusIcon from '../../components/icons/PlusIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useTemplateGameCharacters from '../../hooks/api/templates/useTemplateGameCharacters';
import useTemplates from '../../hooks/api/templates/useTemplates';
import useTemplatesCities from '../../hooks/api/templates/useTemplatesCities';
import usePartners from '../../hooks/api/users/usePartners';
import useUserDetails from '../../hooks/api/users/useUserDetails';
import useTags from '../../hooks/api/useTags';
import { routes } from '../../static/routes';
import { UserTypes } from '../../types/user/userTypes';
import TemplatesViewFilters from './components/TemplatesViewFilters';
import TemplatesViewList from './components/templatesViewList/TemplatesViewList';
import TemplatesViewMap from './components/templatesViewMap/TemplatesViewMap';
import { useTemplatesViewParams } from './hooks/useTemplatesViewParams';
import useTemplateGameTypes from '../../hooks/api/templates/useTemplateGameTypes';

const PAGE_SIZE = 100;

const TemplatesView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useUserDetails();
  const isGlobalAdmin = user?.role === UserTypes.GLOBAL_ADMIN;

  const { partners } = usePartners({ enabled: isGlobalAdmin });
  const { tags } = useTags({ searchValue: '', queryMinLength: 0 });
  const { gameCharacters } = useTemplateGameCharacters();
  const { templatesCities } = useTemplatesCities();
  const { gameTypes } = useTemplateGameTypes();

  const {
    searchFilter,
    debouncedSearchValue,
    handleChangeSearchFilter,
    page,
    handleChangePage,
    tagFilters,
    handleChangeTagFilters,
    gameCharacterFilter,
    handleChangeGameCharacterFilter,
    cityFilter,
    handleChangeCityFilter,
    ownerFilter,
    handleChangeOwnerFilter,
    gameTypeFilter,
    handleChangeGameTypeFilter,
  } = useTemplatesViewParams();

  const { templates, isLoading } = useTemplates({
    search: debouncedSearchValue,
    limit: PAGE_SIZE,
    page: page,
    filters: {
      gameCharacter: gameCharacterFilter,
      tags: tagFilters,
      city: cityFilter,
      owner: ownerFilter,
      gameType: gameTypeFilter,
    },
  });

  const [selectedTemplateIds, setSelectedTemplateIds] = useState<number[]>([]);

  const ownersOptions = [
    ...(!!user ? [{ id: user.id.toString(), email: user.email }] : []),
    ...(partners?.map(partner => ({
      id: partner.id.toString(),
      email: partner.email,
    })) ?? []),
  ];

  return (
    <TitleWrapper
      title={t('templates.title')}
      sideComponent={<SearchBar value={searchFilter} setValue={handleChangeSearchFilter} />}
    >
      <div className="flex mb-8 justify-between items-end gap-3">
        <TemplatesViewFilters
          isGlobalAdmin={isGlobalAdmin}
          tags={tags ?? []}
          tagFilters={tagFilters}
          setTagFilters={newTags => handleChangeTagFilters(newTags)}
          gameCharactersOptions={gameCharacters ?? []}
          gameCharacterFilter={gameCharacterFilter}
          setGameCharacterFilter={newValue => handleChangeGameCharacterFilter(newValue)}
          citiesOptions={templatesCities ?? []}
          cityFilter={cityFilter}
          setCityFilter={newValue => handleChangeCityFilter(newValue)}
          ownersOptions={ownersOptions}
          ownerFilter={ownerFilter}
          setOwnerFilter={newValue => handleChangeOwnerFilter(newValue)}
          gameTypeFilter={gameTypeFilter}
          setGameTypeFilter={newValue => handleChangeGameTypeFilter(newValue)}
          gameTypes={gameTypes ?? []}
        />

        <PrimaryButton
          onClick={() => navigate(routes.addTemplate)}
          className="w-fit self-end"
          prefixIcon={<PlusIcon size={16} />}
        >
          {t('templates.addTemplateBtn')}
        </PrimaryButton>
      </div>

      <TemplatesViewList
        templates={templates?.data ?? []}
        isLoading={isLoading}
        pageSize={PAGE_SIZE}
        page={page}
        onPageChange={newValue => handleChangePage(newValue)}
        rowCount={templates?.count ?? 0}
        selectedTemplateIds={selectedTemplateIds}
        setSelectedTemplateIds={setSelectedTemplateIds}
      />
      <div className="mt-8 mb-36">
        <TemplatesViewMap
          templates={templates?.data ?? []}
          selectedTemplateIds={selectedTemplateIds}
          setSelectedTemplateIds={setSelectedTemplateIds}
        />
      </div>
    </TitleWrapper>
  );
};

export default TemplatesView;

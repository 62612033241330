import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import CustomToastContainer from './components/toastContainer/CustomToastContainer';
import { AxiosContextProvider } from './context/AxiosContextProvider';
import { FormChangeProvider } from './context/FormChangeProvider/FormChangeProvider';
import { queryClient } from './queryClient';
import AppRoutes from './routes/AppRoutes';
import { theme } from './styles/theme';

const App: FC = () => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.resolvedLanguage;

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLocale}>
          <AxiosContextProvider>
            <ThemeProvider theme={theme}>
              <FormChangeProvider>
                <AppRoutes />
                <CustomToastContainer />
                {/*
                    ReactQueryDevtools is useful for debugging react-query state.
                    Uncomment the line below to enable it during development.
                    <ReactQueryDevtools initialIsOpen={false} />
                  */}
              </FormChangeProvider>
            </ThemeProvider>
          </AxiosContextProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;

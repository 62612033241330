import { useTranslation } from 'react-i18next';
import { DropdownOption } from '../types/Dropdown.types';
import useTemplateGameCharacters from './api/templates/useTemplateGameCharacters';

const useGameCharactersDropdownOptions = () => {
  const { t } = useTranslation();
  const { gameCharacters } = useTemplateGameCharacters();

  const dropdownOptions: DropdownOption[] | undefined = gameCharacters?.map(option => ({
    id: option.code,
    value: t(`gameCharacters.${option.code}`),
  }));

  return { gameCharactersDropdownOptions: dropdownOptions };
};

export default useGameCharactersDropdownOptions;

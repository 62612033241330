import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeleteTemplateAd = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useMutation(
    (id: number) => axiosInstance.delete(api.endpoints.SINGLE_AD({ id })),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.deleteTemplateAdFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { deleteTemplateAd: mutateAsync, isLoading };
};

export default useDeleteTemplateAd;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { CallbackDefault } from '../../../types/Types';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeleteTemplate = ({ onSuccess }: { onSuccess?: CallbackDefault } = {}) => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast, showSuccessToast } = useToast();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useMutation(
    (id: string) => axiosInstance.delete(api.endpoints.SINGLE_TEMPLATE({ id })),
    {
      async onSuccess() {
        showSuccessToast(t('templates.table.deleteSuccessMessage'));

        if (!!onSuccess) {
          onSuccess();
        }
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('templates.table.deleteErrorMessage');

        showErrorToast(errorMessage);
      },
    },
  );

  return { deleteTemplate: mutateAsync, isLoading };
};

export default useDeleteTemplate;

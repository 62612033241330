import { useTranslation } from 'react-i18next';
import useUserDetails from '../../../../hooks/api/users/useUserDetails';

const UserData = () => {
  const { user } = useUserDetails();
  const { t } = useTranslation();

  const phoneDataElement = user?.phone ? (
    <div>
      <p className="text-base uppercase font-bold pb-3">{t('account.personalData.phoneNumber')}</p>
      <p className="text-sm font-medium text-black400">{user.phone}</p>
    </div>
  ) : null;

  const emailDataElement = user?.email ? (
    <div>
      <p className="text-base uppercase font-bold pb-3">{t('account.personalData.emailAddress')}</p>
      <p className="text-sm font-medium text-black400">{user.email}</p>
    </div>
  ) : null;

  const nameElement = user?.name ? (
    <div>
      <p className="text-base uppercase font-bold pb-3">{t('account.personalData.name')}</p>
      <p className="text-sm font-medium text-black400">{user.name}</p>
    </div>
  ) : null;

  return (
    <div className="flex flex-col gap-5">
      {nameElement}
      {emailDataElement}
      {phoneDataElement}
    </div>
  );
};

export default UserData;

import { useTranslation } from 'react-i18next';
import { ITeamMessage } from '../../../../../types/ApiTypes';
import { getTimeDifference } from '../../../../../utils/dates/getRemainingTime/getRemainingTime';

const useMessagePassedTime = ({ message }: { message?: ITeamMessage }) => {
  const { t } = useTranslation();

  const { days, hours, minutes } = getTimeDifference({
    startDate: message?.createdAt ?? new Date(),
    endDate: new Date(),
  });

  const getTimeInfo = () => {
    if (!!days) {
      return `${days} ${days === 1 ? t('common.days.single') : t('common.days.multiple')} ${t('common.ago')}`;
    } else if (!!hours) {
      return `${hours} ${t('common.hourShort')} ${t('common.ago')}`;
    } else {
      return `${minutes} ${t('common.minutesShort')} ${t('common.ago')}`;
    }
  };

  return { getTimeInfo };
};

export default useMessagePassedTime;

import { colors } from '../../styles/variables';
import { GameStatus, GameStatuses } from '../../types/GlobalTypes';

type GameStatusesPropertiesData = {
  color?: string;
  tailwindText?: React.ReactNode;
};

export type GameStatusesProperties = Record<GameStatus, GameStatusesPropertiesData>;

export const gameStatusesData: GameStatusesProperties = {
  [GameStatuses.ONGOING]: {
    color: colors.success,
    tailwindText: 'text-success',
  },
  [GameStatuses.FINISHIED]: {
    color: colors.error,
    tailwindText: 'text-error',
  },
  [GameStatuses.SCHEDULED]: {
    color: colors.gameStatusScheduled,
    tailwindText: 'text-gameStatusScheduled',
  },
};

import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiGame } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { transformGame } from '../../../utils/api/dataTransformers/transformGame/transformGame';
import useAxiosInstance from '../../axios/useAxiosInstance';

const useActiveGames = () => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.ACTIVE_GAMES],
    () => axiosInstance.get(api.endpoints.ACTIVE_GAMES),
    {
      cacheTime: 1,
    },
  );

  const apiGames: ApiGame[] | undefined = data?.data;

  const games = !!apiGames ? apiGames.map(game => transformGame(game)) : undefined;

  return {
    games,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useActiveGames;

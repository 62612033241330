import { useTranslation } from 'react-i18next';
import UserIcon from '../../../../components/icons/UserIcon';
import { SelectedFileType } from './UserAvatarImageHandler';

const UserAvatarImageElement = ({
  selectedFile,
  currentImage,
}: {
  selectedFile: SelectedFileType;
  currentImage: string | undefined;
}) => {
  const { t } = useTranslation();

  const selectedImage = selectedFile?.size ? URL.createObjectURL(selectedFile) : currentImage ?? '';

  return (
    <div>
      {!!selectedImage ? (
        <img src={selectedImage} alt="avatar" className="w-20 h-20 object-contain" />
      ) : (
        <div className="flex items-center gap-6">
          <div className="w-20 h-20 rounded-full bg-gray-100 grid place-items-center">
            <UserIcon />
          </div>
          <div>
            <p className="text-sm font-medium">{t('account.personalData.fileNotPicked')}</p>
            <p className="text-xs">{t('account.personalData.fileSizeAndType')}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAvatarImageElement;

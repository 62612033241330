import { Navigate, Outlet } from 'react-router-dom';
import { routes } from '../static/routes';
import { getAccessToken } from '../utils/cookieHelpers/cookieHelpers';

export const ProtectedRoute = () => {
  if (!getAccessToken()) {
    return <Navigate to={routes.login} replace />;
  }
  return <Outlet />;
};

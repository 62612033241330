import { useState } from 'react';

export function useMapType(mapRef: React.RefObject<google.maps.Map | null>) {
  const [mapTypeId, setMapTypeId] = useState<google.maps.MapTypeId | 'roadmap'>('roadmap');

  function handleMapTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const newMapTypeId = event.target.value as google.maps.MapTypeId;
    setMapTypeId(newMapTypeId);
    if (mapRef.current) {
      mapRef.current.setMapTypeId(newMapTypeId);
    }
  }

  return {
    mapTypeId,
    handleMapTypeChange,
  };
}

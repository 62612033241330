import { ApiBundle, IBundleFormInputs } from '../../types/ApiBundleTypes';
import { prepareBundleLimitsRequest } from './prepareBundleLimitsRequest';

export const prepareEditBundleRequest = (data: IBundleFormInputs) => {
  const limits = data ? prepareBundleLimitsRequest(data) : [];

  const bundle: ApiBundle = { bundleName: data.bundleName, limits };
  const request = { id: data.bundleId as number, bundle };

  return request;
};

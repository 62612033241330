import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface ApiCity {
  city: string;
}

const useQuestionsCities = () => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.QUESTIONS_CITIES],
    () => axiosInstance.get(`${api.endpoints.QUESTIONS_CITIES}`),
    {
      refetchOnMount: true,
    },
  );

  const apiQuestionsCities: ApiCity[] | undefined = data?.data;

  const iQuestionsCities: string[] | undefined = !!apiQuestionsCities
    ? apiQuestionsCities.map(city => city.city)
    : undefined;

  return { questionsCities: iQuestionsCities, refetch, isSuccess, isLoading, isError };
};

export default useQuestionsCities;

import { Dispatch, SetStateAction } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useQuestionTypeDropdownOptions from '../../../../hooks/useQuestionTypeDropdownOptions';
import { LangType } from '../../../../types/GlobalTypes';
import getInputState from '../../../../utils/getInputState/getInputState';
import { LabeledStateDropdown } from '../../../common/dropdowns/LabeledStateDropdown';
import FormSectionTitle from '../../../common/formSectionTitle/FormSectionTitle';
import SingleImagePicker from '../../../common/imagePickers/singleImagePicker/SingleImagePicker';
import { LabeledStateInput } from '../../../common/inputs/LabeledStateInput';
import { LabeledStateTextarea } from '../../../common/inputs/LabeledStateTextarea';
import { QuestionFormInputs, QuestionFormInputsKeys } from '../types/QuestionFormTypes';

interface QuestionFormGeneralProps {
  activeLang: LangType;
  oldMainImageUrl?: string | null;
  newMainImage: File | null;
  setNewMainImage: Dispatch<SetStateAction<File | null>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage: (field: QuestionFormInputsKeys) => any;
  setQuestionType: Dispatch<SetStateAction<string>>;
  isQuestionTypeDisabled?: boolean;
}

const QuestionFormGeneral = ({
  activeLang,
  oldMainImageUrl,
  newMainImage,
  setNewMainImage,
  getErrorMessage,
  setQuestionType,
  isQuestionTypeDisabled,
}: QuestionFormGeneralProps) => {
  const { t } = useTranslation();
  const { questionTypeDropdownOptions } = useQuestionTypeDropdownOptions();

  const { register, control } = useFormContext<QuestionFormInputs>();

  const { fields: nameFields } = useFieldArray({
    name: 'name',
    control,
  });

  const { fields: contentFields } = useFieldArray({
    name: 'content',
    control,
  });

  return (
    <>
      <FormSectionTitle className="mb-6 mt-10" title={t('questionForm.general.title')} />

      {nameFields.map((field, index) => {
        if (field.lang !== activeLang) {
          return;
        }
        return (
          <div key={field.id} className="pb-7 max-w-lg">
            <LabeledStateInput
              id={`name-${field.id}`}
              state={getInputState(getErrorMessage('name')?.[index])}
              labeledProps={{
                wrapperClassName: 'max-w-xl',
                label: t('questionForm.general.nameLabel'),
                errorMessage: t(`errorMessages.${getErrorMessage('name')?.[index]?.value?.message}`),
              }}
              inputProps={{
                placeholder: t('questionForm.general.namePlaceholder'),
                register: {
                  ...register(`name.${index}.value`),
                },
              }}
            />
          </div>
        );
      })}

      <div className="pb-7 max-w-lg">
        <Controller
          name="questionType"
          control={control}
          render={({ field }) => (
            <LabeledStateDropdown
              id="question-type"
              state={getInputState(getErrorMessage('questionType'))}
              labeledProps={{
                label: t('questionForm.general.questionTypeLabel'),
                wrapperClassName: 'w-full',
                errorMessage: t(`errorMessages.${getErrorMessage('questionType')?.value?.message}`),
              }}
              dropdownProps={{
                disabled: isQuestionTypeDisabled,
                placeholder: t('questionForm.general.questionTypePlaceholder'),
                options: questionTypeDropdownOptions,
                currentOption: field.value,
                setNewOption: val => {
                  field.onChange(val);
                  setQuestionType(val.id);
                },
              }}
            />
          )}
        />
      </div>

      {contentFields.map((field, index) => {
        if (field.lang !== activeLang) {
          return;
        }
        return (
          <div key={field.id} className="pb-7 max-w-lg">
            <LabeledStateTextarea
              id={`content-${field.id}`}
              state={getInputState(getErrorMessage('content')?.[index])}
              labeledProps={{
                wrapperClassName: 'max-w-xl',
                label: t('questionForm.general.contentLabel'),
                errorMessage: t(`errorMessages.${getErrorMessage('content')?.[index]?.value?.message}`),
              }}
              textareaProps={{
                placeholder: t('questionForm.general.contentPlaceholder'),
                register: {
                  ...register(`content.${index}.value`),
                },
              }}
            />
          </div>
        );
      })}

      <div className="pb-7 max-w-lg">
        <SingleImagePicker
          title={t('questionForm.general.image.title')}
          subtitle={t('questionForm.general.image.subtitle')}
          oldImageUrl={oldMainImageUrl}
          selectedFile={newMainImage}
          setSelectedFile={setNewMainImage}
        />
      </div>
    </>
  );
};

export default QuestionFormGeneral;

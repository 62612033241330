import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { IQuestionTypeOption } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface ApiQuestionTypeOption {
  id: number;
  code: string;
  icon: string;
}

const useQuestionTypesOptions = () => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery([QueryAliases.QUESTION_TYPES_OPTIONS], () =>
    axiosInstance.get(api.endpoints.QUESTION_TYPES_OPTIONS),
  );

  const apiTypesOptions: ApiQuestionTypeOption[] | undefined = data?.data;

  const iTypesOptions: IQuestionTypeOption[] | undefined = !!apiTypesOptions
    ? apiTypesOptions.map(option => ({
        id: option.id,
        code: option.code,
        icon: option.icon,
      }))
    : undefined;

  return {
    typesOptions: iTypesOptions,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useQuestionTypesOptions;

import { Dispatch, SetStateAction, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { GridRowId } from '@mui/x-data-grid';

import MaterialTable from '../../../../components/dataTable/MaterialTable/MaterialTable';
import useDeleteTemplate from '../../../../hooks/api/templates/useDeleteTemplate';
import { elementsIds } from '../../../../static/elementsIds';
import { routes } from '../../../../static/routes';
import { IAllTemplatesItem } from '../../../../types/ApiTypes';
import { GameTypes } from '../../../../types/GlobalTypes';
import { QueryAliases } from '../../../../types/QueryAliases';
import TemplatesListTableSelectionPanel from './components/TemplatesListTableSelectionPanel';
import TemplatesListTableStartGameModal from './components/templatesListTableStartGameModal/TemplatesListTableStartGameModal';
import useTemplatesTableHeaders from './hooks/useTemplatesTableHeaders';

interface TemplatesViewListProps {
  templates: IAllTemplatesItem[];
  isLoading: boolean;
  pageSize: number;
  page: number;
  onPageChange: (newVal: number) => void;
  rowCount: number;
  selectedTemplateIds: number[];
  setSelectedTemplateIds: Dispatch<SetStateAction<number[]>>;
}

const TemplatesViewList = ({
  templates,
  isLoading,
  pageSize,
  page,
  onPageChange,
  rowCount,
  selectedTemplateIds,
  setSelectedTemplateIds,
}: TemplatesViewListProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isStartGameModalOpen, setIsStartGameModalOpen] = useState(false);

  const isSingleItemSelected = selectedTemplateIds?.length === 1;

  const { deleteTemplate, isLoading: isLoadingDelete } = useDeleteTemplate({
    onSuccess: () => {
      queryClient.refetchQueries([QueryAliases.TEMPLATES]);
    },
  });

  const { headers } = useTemplatesTableHeaders();

  const handleStartGame = () => {
    setIsStartGameModalOpen(true);
  };

  const handleDuplicate = (id: GridRowId) => {
    navigate(routes.copyTemplate(id.toString()));
  };

  const handleDelete = (ids: GridRowId[]) => {
    ids.forEach(id => deleteTemplate(id.toString()));
  };

  const findTemplateById = (id: number) => templates.find(template => template.id === id);

  function shouldHideGame(): boolean {
    const gameType = findTemplateById(selectedTemplateIds[0] as number)?.gameType;
    const isStartable = gameType === GameTypes.BUSINESS || gameType === GameTypes.INDIVIDUAL_PAID;
    return !isSingleItemSelected || !isStartable;
  }

  return (
    <>
      <div>
        <div id={elementsIds.templatesTable}>
          <MaterialTable
            columns={headers}
            rowCount={rowCount}
            rows={templates}
            checkboxSelection
            onRowSelectionModelChange={val => {
              setSelectedTemplateIds(val as number[]);
            }}
            loading={isLoading}
            showPagination
            paginationMode="server"
            paginationModel={{
              page: page - 1,
              pageSize,
            }}
            onPaginationModelChange={e => {
              onPageChange(e.page + 1);
            }}
            disableRowSelectionOnClick
          />
        </div>
      </div>

      {selectedTemplateIds?.length > 0 && (
        <TemplatesListTableSelectionPanel
          tableId={elementsIds.templatesTable}
          selectedRowsNumber={selectedTemplateIds.length}
          onStartGame={() => {
            if (isSingleItemSelected) {
              handleStartGame();
            }
          }}
          onDuplicate={() => {
            if (isSingleItemSelected) {
              handleDuplicate(selectedTemplateIds[0]!);
            }
          }}
          onDelete={() => handleDelete(selectedTemplateIds)}
          hideStartGame={shouldHideGame()}
          hideDuplicate={!isSingleItemSelected}
          isLoadingDelete={isLoadingDelete}
        />
      )}

      {isSingleItemSelected && (
        <TemplatesListTableStartGameModal
          templateId={selectedTemplateIds[0]!.toString()}
          gameType={findTemplateById(selectedTemplateIds[0] as number)!!.gameType}
          isOpen={isStartGameModalOpen}
          setIsOpen={setIsStartGameModalOpen}
        />
      )}
    </>
  );
};

export default TemplatesViewList;

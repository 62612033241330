import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { CircularProgress, Skeleton } from '@mui/material';

import { HorizontalDivider } from '../../components/common/dividers/HorizontalDivider';
import Switch from '../../components/common/switch/Switch';
import Map from '../../components/map/Map';
import MapTeamsRanking from '../../components/mapTeamsRanking/MapTeamsRanking';
import LanguageSwitcher from '../../components/navigation/components/LanguageSwitcher';
import useSpectatorGameQuestions from '../../hooks/api/games/spectator/useSpectatorGameQuestions';
import useSpectatorGameUsers from '../../hooks/api/games/spectator/useSpectatorGameUsers';
import useSpectatorSingleGame from '../../hooks/api/games/spectator/useSpectatorSingleGame';
import useFindTranslation from '../../hooks/useFindTranslation';
import { searchParamsData } from '../../static/searchParamsData';
import { GameStatuses } from '../../types/GlobalTypes';
import GamePreviewQuestions from './components/GamePreviewQuestions';
import GamePreviewTeams from './components/GamePreviewTeams';
import GamePreviewTimer from './components/GamePreviewTimer';

const GamePreview = () => {
  const { t } = useTranslation();
  const params = useParams();
  const gameId = params.gameId as string;
  const [searchParams] = useSearchParams();
  const token = searchParams.get(searchParamsData.spectatorToken);
  const { findTranslation } = useFindTranslation();

  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapHeight, setMapHeight] = useState(438);
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const isFetchEnabled = !!token;
  const [areQuestionsVisible, setAreQuestionsVisible] = useState(true);
  const mapStyles = { width: '100%', height: `${mapHeight}px`, borderRadius: 0, border: 'none' };

  const { game, isLoading: isLoadingGame } = useSpectatorSingleGame({
    gameId,
    token: token as string,
    enabled: isFetchEnabled,
  });
  const { gameUsers } = useSpectatorGameUsers({
    gameId,
    token: token as string,
    enabled: isFetchEnabled,
  });
  const teams = useMemo(() => gameUsers?.map(user => user.userGame) ?? [], [gameUsers]);

  const { gameQuestions, isFetching: isFetchingGameQuestions } = useSpectatorGameQuestions({
    gameId,
    token: token as string,
    enabled: isFetchEnabled,
  });

  useEffect(() => {
    if (headerContainerRef.current) {
      const distance = window.innerHeight - headerContainerRef.current.clientHeight;

      setMapHeight(distance);
    }
  }, []);

  return (
    <div>
      <div ref={headerContainerRef}>
        <div className="grid grid-cols-3 px-5 pb-3 pt-6 gap-4">
          <div className="flex items-center justify-center col-start-2">
            {isLoadingGame ? (
              <Skeleton height={45} width={180} />
            ) : (
              <h4 className="text-center">{findTranslation(game?.template.name.translations ?? [])}</h4>
            )}
          </div>
          <LanguageSwitcher className="p-0 place-self-end" />
        </div>
        <HorizontalDivider className="overflow-hidden" />
      </div>

      <Map
        center={game?.template.coordinates}
        containerStyle={mapStyles}
        onMapLoad={map => {
          mapRef.current = map;
        }}
      >
        <div className="absolute right-2 top-5 flex justify-between items-center gap-2.5">
          {isFetchingGameQuestions && <CircularProgress size={25} />}
          <Switch isChecked={areQuestionsVisible} onChange={() => setAreQuestionsVisible(prev => !prev)} />
          <p className="font-bold">{t('gamePreview.questionsSwitchLabel')}</p>
        </div>

        {game?.status === GameStatuses.ONGOING && (
          <div className="absolute left-2 top-4">
            <GamePreviewTimer endDate={game.endDate} />
          </div>
        )}

        <div className="z-10 absolute bottom-3 left-2 flex flex-col gap-1.5">
          <div className="relative">
            <MapTeamsRanking teams={teams} mapHeight={mapHeight} />
          </div>
        </div>

        <GamePreviewTeams teams={teams} />
        {areQuestionsVisible && <GamePreviewQuestions templateQuestions={gameQuestions} mapHeight={mapHeight} />}
      </Map>
    </div>
  );
};

export default GamePreview;

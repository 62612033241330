import { colors } from '../../styles/variables';

const GameIcon = ({ color, width, height }: { color?: string; width?: number; height?: number }) => {
  return (
    <svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.67125 12.4414L6.74833 15.3643"
        stroke={color ?? colors.grey700}
        strokeWidth="1.15"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.77708 12.4688L9.7 15.3917"
        stroke={color ?? colors.grey700}
        strokeWidth="1.15"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4662 13.916H13.4737"
        stroke={color ?? colors.grey700}
        strokeWidth="1.15"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2421 13.916H17.2495"
        stroke={color ?? colors.grey700}
        strokeWidth="1.15"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3542 15.8097V15.7949"
        stroke={color ?? colors.grey700}
        strokeWidth="1.15"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3542 12.0343V12.0195"
        stroke={color ?? colors.grey700}
        strokeWidth="1.15"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.125 21.5833H14.875C19.6667 21.5833 21.5833 19.6667 21.5833 14.875V12.9583C21.5833 8.16667 19.6667 6.25 14.875 6.25H9.125C4.33333 6.25 2.41666 8.16667 2.41666 12.9583V14.875C2.41666 19.6667 4.33333 21.5833 9.125 21.5833Z"
        stroke={color ?? colors.grey700}
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9679 2.41602L12.9583 3.38393C12.9487 3.91102 12.5271 4.33268 12 4.33268H11.9712C11.4442 4.33268 11.0225 4.76393 11.0225 5.29102C11.0225 5.8181 11.4537 6.24935 11.9808 6.24935H12.9392"
        stroke={color ?? colors.grey700}
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GameIcon;

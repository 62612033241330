import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SelectedFileType } from '../../../pages/account/personalData/components/UserAvatarImageHandler';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/QueryAliases';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useAddUserAvatar = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast, showSuccessToast } = useToast();

  const uploadImage = async ({ userId, file }: { userId: number | undefined; file: SelectedFileType }) => {
    const formData = new FormData();

    if (file && userId) {
      formData.append('avatar', file);

      const response = await axiosInstance.post(api.endpoints.ADD_USER_AVATAR, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    }
    return;
  };

  const { mutateAsync, isLoading } = useMutation(uploadImage, {
    async onSuccess() {
      await queryClient.refetchQueries([QueryAliases.USER_DETAILS]);
      const successMessage = t('userMessages.updateSuccess');
      showSuccessToast(successMessage);
    },
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err) ?? t('userMessages.updateError');

      showErrorToast(errorMessage);
    },
  });

  return { addAvatarImage: mutateAsync, isLoading };
};

export default useAddUserAvatar;

import { UpdatedSubscription } from '../../../hooks/api/subscription/useUpdateSubscription';
import { InitialPartnerSubscription } from '../../../types/ApiPartnerTypes';
import { getCoordinatesFromString } from '../../../utils/general/getCoordinatesFromString/getCoordinatesFromString';

export const prepareEditSubscriptionRequest = (subscription: InitialPartnerSubscription) => {
  const coordinates = getCoordinatesFromString(subscription?.coordinates);

  const updateSubscription: UpdatedSubscription = {
    validUntil: subscription.validUntil,
    bundle: { id: subscription.bundle.id },
    coordinates: {
      latitude: coordinates?.lat as number,
      longitude: coordinates?.lng as number,
    },
  };

  return updateSubscription;
};

import { UseFormRegisterReturn } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  className?: string;
  register?: UseFormRegisterReturn;
  testId?: string;
}

const Textarea = ({ id, className, register, testId, ...props }: TextareaProps) => {
  return (
    <textarea
      id={id}
      className={twMerge(
        `outline outline-1.5 outline-grey500  rounded-md px-4 py-[1.1875rem] text-sm text-black500 disabled:outline-grey300 disabled:text-grey500 font-medium w-full placeholder:text-grey500 focus:outline-2 focus:outline-red500`,
        className,
      )}
      data-testid={testId}
      {...props}
      {...register}
    />
  );
};

export default Textarea;

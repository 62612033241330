import { colors } from '../../styles/variables';

const SubscriptionsIcon = ({ color, width, height }: { color?: string; width?: number; height?: number }) => {
  return (
    <svg width={width ?? 32} height={height ?? 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.6666 9.33317V9.0665C26.6666 6.82629 26.6666 5.70619 26.2306 4.85054C25.8471 4.09789 25.2352 3.48597 24.4825 3.10248C23.6269 2.6665 22.5068 2.6665 20.2666 2.6665H11.7333C9.49304 2.6665 8.37294 2.6665 7.51729 3.10248C6.76464 3.48597 6.15272 4.09789 5.76923 4.85054C5.33325 5.70619 5.33325 6.82629 5.33325 9.0665V22.9332C5.33325 25.1734 5.33325 26.2935 5.76923 27.1491C6.15272 27.9018 6.76464 28.5137 7.51729 28.8972C8.37294 29.3332 9.49304 29.3332 11.7333 29.3332H16.6666M16.6666 14.6665H10.6666M15.3333 19.9998H10.6666M21.3333 9.33317H10.6666M23.9999 23.9998V16.6665C23.9999 15.5619 24.8953 14.6665 25.9999 14.6665C27.1045 14.6665 27.9999 15.5619 27.9999 16.6665V23.9998C27.9999 26.209 26.2091 27.9998 23.9999 27.9998C21.7908 27.9998 19.9999 26.209 19.9999 23.9998V18.6665"
        stroke={color ?? colors.grey700}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SubscriptionsIcon;

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiGame, IGame } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { CallbackDefault } from '../../../types/Types';
import { transformGame } from '../../../utils/api/dataTransformers/transformGame/transformGame';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

interface useSingleGameProps {
  gameId: string;
  onErrorCb?: CallbackDefault;
}

const useSingleGame = ({ gameId, onErrorCb }: useSingleGameProps) => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.SINGLE_GAME(gameId)],
    () => axiosInstance.get(api.endpoints.SINGLE_GAME(gameId)),
    {
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.somethingWentWrong');

        showErrorToast(errorMessage);

        if (!!onErrorCb) {
          onErrorCb();
        }
      },
    },
  );

  const apiGame: ApiGame | undefined = data?.data;

  const game: IGame | undefined = !!apiGame ? transformGame(apiGame) : undefined;

  return {
    game,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useSingleGame;

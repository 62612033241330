import { useParams } from 'react-router-dom';
import SingleActiveGamePageWrapper from '../../SingleActiveGamePageWrapper';
import useGameCodes from '../../../../hooks/api/games/useGameCodes';
import useCreateNewCodes from '../../../../hooks/api/games/useCreateNewCodes';
import MaterialTable from '../../../../components/dataTable/MaterialTable/MaterialTable';
import useGameCodesHeaders from './hooks/useGameCodesHeaders';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { IGameCode } from '../../../../types/ApiTypes';
import useToast from '../../../../hooks/useToast';

const SingleActiveGameCodesView = () => {
  const params = useParams();
  const gameId = params.id as string;
  const { t } = useTranslation();
  const { showSuccessToast, showErrorToast } = useToast();

  const { gameCodes, isLoading: isLoadingGameCodes, refetch: refetchGameCodes } = useGameCodes({ gameId });
  const { headers } = useGameCodesHeaders();
  const [filter, setFilter] = useState('all');
  const [modalOpen, setModalOpen] = useState(false);
  const [newCodes, setNewCodes] = useState<IGameCode[]>([]);
  const { createGameCodes, isLoading: isLoadingCreateNewCodes } = useCreateNewCodes({
    onSuccess: (newCodes: IGameCode[]) => {
      setNewCodes(newCodes);
      setModalOpen(true);
      refetchGameCodes();
    },
  });

  const filterRows = (rows: IGameCode[]) => {
    if (filter === 'active') {
      return rows.filter(row => row.active);
    } else if (filter === 'inactive') {
      return rows.filter(row => !row.active);
    } else {
      return rows;
    }
  };

  const filteredGameCodes = filterRows(gameCodes ?? []);
  const activeCount = gameCodes?.filter(row => row.active).length || 0;
  const inactiveCount = gameCodes?.filter(row => !row.active).length || 0;
  const totalCount = gameCodes?.length || 0;

  const copyActiveCodesToClipboard = () => {
    const activeCodes =
      gameCodes
        ?.filter(row => row.active)
        .map(row => row.code)
        .join(',') || '';
    navigator.clipboard
      .writeText(activeCodes)
      .then(() => {
        showSuccessToast(t('singleActiveGame.codes.copiedToClipboard'));
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const copyNewCodesToClipboard = () => {
    const codes = newCodes.map(row => row.code).join(',');
    navigator.clipboard
      .writeText(codes)
      .then(() => {
        showSuccessToast(t('singleActiveGame.codes.newCodesCopiedToClipboard'));
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleCreateNewCodes = async () => {
    try {
      await createGameCodes({ gameId });
    } catch (err) {
      console.error('Failed to create new codes: ', err);
      showErrorToast(t('singleActiveGame.codes.failedToCreateNewCodes'));
    }
  };

  return (
    <SingleActiveGamePageWrapper>
      <div className="mt-6">
        <div className="mt-9">
          <FormControl component="fieldset">
            <FormLabel component="legend">{t('singleActiveGame.codes.filterCodes')}</FormLabel>
            <RadioGroup row aria-label="filter" name="filter" value={filter} onChange={e => setFilter(e.target.value)}>
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={`${t('singleActiveGame.codes.showAll')} (${totalCount})`}
              />
              <FormControlLabel
                value="active"
                control={<Radio />}
                label={`${t('singleActiveGame.codes.showActive')} (${activeCount})`}
              />
              <FormControlLabel
                value="inactive"
                control={<Radio />}
                label={`${t('singleActiveGame.codes.showInactive')} (${inactiveCount})`}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="mt-9">
          <Button variant="contained" onClick={copyActiveCodesToClipboard} disabled={isLoadingCreateNewCodes}>
            {t('singleActiveGame.codes.copyActiveCodes')}
          </Button>
        </div>
        <div className="mt-9">
          <Button
            variant="contained"
            onClick={handleCreateNewCodes}
            disabled={isLoadingCreateNewCodes || isLoadingGameCodes}
          >
            {t('singleActiveGame.codes.createNewCodes')}
          </Button>
        </div>
        <div className="mt-9">
          <MaterialTable
            columns={headers}
            rows={filteredGameCodes}
            loading={isLoadingGameCodes}
            getRowId={row => row.code}
            showPagination
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } },
            }}
          />
        </div>
      </div>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>{t('singleActiveGame.codes.newCodes')}</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setModalOpen(false)}>
              {t('singleActiveGame.codes.close')}
            </Button>
            <Button variant="contained" onClick={copyNewCodesToClipboard}>
              {t('singleActiveGame.codes.copyNewCodes')}
            </Button>
          </DialogActions>
          <MaterialTable
            columns={headers}
            rows={newCodes}
            loading={false}
            getRowId={row => row.code}
            showPagination={false}
          />
        </DialogContent>
      </Dialog>
    </SingleActiveGamePageWrapper>
  );
};

export default SingleActiveGameCodesView;

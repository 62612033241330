import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BorderCard from '../../../../../../components/common/cards/BorderCard';
import { IAnswersGroup } from '../../SingleActiveGameAnswersView';
import QuestionReviewAnswersGroup from './questionReviewAnswersGroup/QuestionReviewAnswersGroup';

interface ReviewAnswersProps {
  answers: IAnswersGroup[];
  isLoading: boolean;
}

const ReviewAnswers = ({ answers, isLoading }: ReviewAnswersProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (answers.length === 0) {
    return <h3 className="text-black700 text-base font-bold">{t('singleActiveGame.answers.noAnswersToShow')}</h3>;
  }

  return (
    <BorderCard className="mb-10">
      <div className="flex items-center gap-1.5 mb-6">
        <h3 className="text-black700 text-base font-bold">{t('singleActiveGame.answers.answersToReview')}</h3>
        <div className="h-5 min-w-[1.25rem] rounded-full bg-red500 flex justify-center items-center">
          <p className="text-white900 text-xs font-semibold">{answers.length}</p>
        </div>
      </div>

      {answers.map(answersGroup => {
        return <QuestionReviewAnswersGroup key={answersGroup.question.id} answersGroup={answersGroup} />;
      })}
    </BorderCard>
  );
};

export default ReviewAnswers;

import useHandleAddTemplatesToPartner from '../../../components/forms/partnerForm/hooks/useHandleAddTemplatesToPartner';
import useToastSuccessAndNavigateOut from '../../../components/forms/partnerForm/hooks/useToastSuccessAndNavigateOut';
import { PartnerFormSubmitProps } from '../../../components/forms/partnerForm/types/partnerFormTypes';
import useHandleAddPartnerBaseData from './useHandleAddPartnerBaseData';
import useHandleCreateSubscription from './useHandleCreateSubscription';
import useHandleVerifyPartner from './useHandleVerifyPartner';

const useHandleAddPartner = () => {
  const { toastSuccessAndNavigateOut } = useToastSuccessAndNavigateOut();
  const { handleAddPartnerBaseData, handleAddPartnerIsLoading } = useHandleAddPartnerBaseData();
  const { handleVerifyPartner, handleVerifyPartnerIsLoading } = useHandleVerifyPartner();
  const { handleCreateSubscription, handleCreateSubscriptionIsLoading } = useHandleCreateSubscription();
  const { handleAddTemplates, handleAddTemplatesIsLoading } = useHandleAddTemplatesToPartner();

  const isLoading =
    handleAddPartnerIsLoading ||
    handleVerifyPartnerIsLoading ||
    handleCreateSubscriptionIsLoading ||
    handleAddTemplatesIsLoading;

  const initAddingPartnerData = async ({
    partner,
    subscriptionHasChanged,
    templatesHasChanged,
  }: PartnerFormSubmitProps) => {
    const partnerId = await handleAddPartnerBaseData({ baseData: partner.baseData });

    if (!partnerId) {
      return;
    }

    const { isPartnerActive } = await handleVerifyPartner(partnerId);

    if (!isPartnerActive) {
      toastSuccessAndNavigateOut({ messageType: 'addPartnerSuccessNotVerified', partnerId });
      return;
    }

    if (!subscriptionHasChanged) {
      toastSuccessAndNavigateOut({ messageType: 'addPartnerSuccessWithoutSubscription', partnerId });
      return;
    }

    let subscriptionId = undefined;

    if (partner?.subscription) {
      subscriptionId = await handleCreateSubscription({ subscription: partner?.subscription, partnerId });
    }

    if (!subscriptionId) {
      return;
    }

    if (!templatesHasChanged) {
      toastSuccessAndNavigateOut({ partnerId });
      return;
    }

    const templatesIds = await handleAddTemplates({ templates: partner.templates, partnerId });

    if (templatesIds) {
      toastSuccessAndNavigateOut({ partnerId });
    }

    return;
  };

  return { initAddingPartnerData, addingPartnerInProgress: isLoading };
};

export default useHandleAddPartner;

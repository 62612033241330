import { useMemo, useState } from 'react';
import { defaultMapZoom } from '../static/mapDefaults';

function useMapZoom({ initial }: { initial?: number }) {
  const [zoom, setZoom] = useState(initial ?? defaultMapZoom);

  const handler = useMemo(
    () => ({
      zoomIn: () => setZoom(z => z + 1),
      zoomOut: () => setZoom(z => z - 1),
      updateZoom: (newZoom: number) => setZoom(newZoom),
    }),
    [],
  );

  return [zoom, handler] as const;
}

export default useMapZoom;

import { useTranslation } from 'react-i18next';

function NoSelectedQuestionsMessage() {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex justify-center items-center">
      <p className="font-medium text-grey500 max-w-xs text-center">
        {t('templateForm.questions.noSelectedQuestionsMessage')}
      </p>
    </div>
  );
}

export default NoSelectedQuestionsMessage;

import { colors } from '../../styles/variables';

const FullScreenCloseIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 4.5V10.5H19.5"
        stroke={color ?? colors.black700}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 19.5V13.5H4.5"
        stroke={color ?? colors.black700}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 10.5L21 3"
        stroke={color ?? colors.black700}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 21L10.5 13.5"
        stroke={color ?? colors.black700}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FullScreenCloseIcon;

export const getInitHour = (initDate: Date) => {
  const today = new Date();

  const hour = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    initDate.getHours(),
    initDate.getMinutes(),
    initDate.getSeconds(),
  );
  return hour;
};

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TableCell from '../../../components/dataTable/components/TableCell';
import { IGamer } from '../../../types/ApiTypes';
import { formatDateString } from '../../../utils/dates/formatDate/formatDateString';
import { findLocaleByLanguage } from '../../../utils/findLocaleByLanguage/findLocaleByLanguage';
import DeleteGamerButton from '../components/DeleteGamerButton';

const useGamersTableHeaders = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const locale = findLocaleByLanguage(language);

  const headers: GridColDef[] = [
    {
      field: 'email',
      headerName: t('gamers.tableHeaders.email'),
      minWidth: 180,
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IGamer>) => {
        return <TableCell>{params.row.email}</TableCell>;
      },
    },
    {
      field: 'userGames',
      headerName: t('gamers.tableHeaders.userGames'),
      minWidth: 160,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IGamer>) => {
        return <TableCell>{params.row.userGames}</TableCell>;
      },
    },
    {
      field: 'createdAt',
      headerName: t('gamers.tableHeaders.createdAt'),
      minWidth: 100,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IGamer>) => {
        const date = formatDateString.toOnlyDate(params.row.createdAt, locale);
        return <TableCell>{date}</TableCell>;
      },
    },
    {
      field: 'buttons',
      headerName: '',
      minWidth: 80,
      maxWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGamer>) => {
        return <DeleteGamerButton gamerName={params.row.email} id={params.row.id} />;
      },
    },
  ];

  return { headers };
};

export default useGamersTableHeaders;

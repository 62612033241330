import useLimitDictionary from '../../../hooks/api/limitDictionary/useLimitDictionary';
import { IConvertedLimits } from '../../../types/ApiBundleTypes';
import { ILimitDictionary } from '../../../types/ApiTypes';
import { convertArrayOfObjectsToObjectByGivenKey } from '../../../utils/convertArrayOfObjectsToObjectByGivenKey/convertArrayOfObjectsToObjectByGivenKey';

const usePrepareInitialBundle = () => {
  const { limitDictionaryWithTranslations } = useLimitDictionary();
  const formated =
    !!limitDictionaryWithTranslations &&
    limitDictionaryWithTranslations?.map((limitDictionary: ILimitDictionary) => ({
      limitDictionaryId: limitDictionary.id,
      limitValue: undefined,
      name: limitDictionary.name,
    }));

  const converted: IConvertedLimits = formated && convertArrayOfObjectsToObjectByGivenKey(formated, 'name');
  const initialFormBundle = converted && {
    bundleName: '',
    ...converted,
  };

  return { initialFormBundle };
};

export default usePrepareInitialBundle;

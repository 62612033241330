import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { routes } from '../../../static/routes';
import { ApiBundle } from '../../../types/ApiBundleTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useAddBundle = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast, showSuccessToast } = useToast();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { isLoading, mutateAsync } = useMutation((data: ApiBundle) => axiosInstance.post(api.endpoints.BUNDLES, data), {
    async onSuccess() {
      await queryClient.refetchQueries([QueryAliases.ALL_BUNDLES]);
      const successMessage = t('bundle.toasts.addBundleSuccess');
      showSuccessToast(successMessage);
      navigate(routes.bundles);
    },
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err) ?? t('bundle.toasts.addBundleError');

      showErrorToast(errorMessage);
    },
  });

  return { isLoading, addBundle: mutateAsync };
};

export default useAddBundle;

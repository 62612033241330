import { IAnswer, MultilangString } from '../../../types/GlobalTypes';

interface GroupedAnswer {
  id: string;
  answers: MultilangString[];
}

export const groupAnswersById = (answers: IAnswer[]): GroupedAnswer[] => {
  const groupedAnswers: { [id: string]: MultilangString[] } = {};

  answers.forEach(answer => {
    const { answerId, lang, value } = answer;

    if (!groupedAnswers[answerId]) {
      groupedAnswers[answerId] = [];
    }

    if (!value) {
      return;
    }

    groupedAnswers[answerId]!.push({ lang, value });
  });

  return Object.entries(groupedAnswers).map(([id, answers]) => ({
    id,
    answers,
  }));
};

import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';
import SaveIcon from '../../../../components/icons/SaveIcon';
import useAddUserAvatar from '../../../../hooks/api/users/useAddUserAvatar';
import useUserDetails from '../../../../hooks/api/users/useUserDetails';
import useSelectFile from '../../../../hooks/useSelectFile';
import UserAvatarImageElement from './UserAvatarImageElement';
import { useTrackUnsavedChange } from '../../../../context/FormChangeProvider';

export type SelectedFileType = File | null;

const UserAvatarImageHandler = () => {
  const { user } = useUserDetails();
  const { t } = useTranslation();
  const { addAvatarImage, isLoading } = useAddUserAvatar();
  const { selectFile } = useSelectFile();
  const [selectedFile, setSelectedFile] = useState<SelectedFileType>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useTrackUnsavedChange(!!selectedFile);

  const handleClickChangeImg = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSaveImage = () => {
    addAvatarImage({ file: selectedFile, userId: user?.id });
  };

  return (
    <div className="flex justify-between items-center px-8 py-4 border border-gray-400 rounded-xl">
      <UserAvatarImageElement selectedFile={selectedFile} currentImage={user?.avatar} />
      <div className="flex gap-6 items-center">
        <SecondaryButton onClick={handleClickChangeImg}>{t('account.personalData.addImage')}</SecondaryButton>
        <PrimaryButton prefixIcon={<SaveIcon />} onClick={handleSaveImage} disabled={isLoading} loading={isLoading}>
          {t('account.personalData.saveChanges')}
        </PrimaryButton>
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={async event => {
            await selectFile({
              event,
              onSelectFile: setSelectedFile,
              sizeLimitKb: 800,
              allowedFileTypes: ['image/jpeg', 'image/png'],
            });
          }}
          className="hidden"
        />
      </div>
    </div>
  );
};

export default UserAvatarImageHandler;

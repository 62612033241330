import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ISingleGameStatistics } from '../../../../types/ApiTypes';
import { QueryAliases } from '../../../../types/QueryAliases';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface ApiGameStatistics {
  activeGamers: number;
  aquiredPoints: number;
  correctAnswers: number;
  incorrectAnswers: number;
  maxPoints: number;
  totalAnswers: number;
  totalGamers: number;
  totalQuestions: number;
}

const useSingleGameStatistics = ({ gameId }: { gameId: string }) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.SINGLE_GAME_STATISTICS(gameId)],
    () => axiosInstance.get(api.endpoints.SINGLE_GAME_STATISTICS({ gameId })),
    { refetchOnMount: true },
  );

  const apiGameStatistics: ApiGameStatistics | undefined = data?.data;

  const iGameStatistics: ISingleGameStatistics | undefined = !!apiGameStatistics
    ? {
        activeGamers: apiGameStatistics.activeGamers,
        aquiredPoints: apiGameStatistics.aquiredPoints,
        correctAnswers: apiGameStatistics.correctAnswers,
        incorrectAnswers: apiGameStatistics.incorrectAnswers,
        maxPoints: apiGameStatistics.maxPoints,
        totalAnswers: apiGameStatistics.totalAnswers,
        totalGamers: apiGameStatistics.totalGamers,
        totalQuestions: apiGameStatistics.totalQuestions,
      }
    : undefined;

  return {
    gameStatistics: iGameStatistics,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useSingleGameStatistics;

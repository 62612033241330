import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BreadcrumbsProps } from '../../components/common/breadcrumbs/Breadcrumbs';
import useTemplateQuestionsFilters from '../../components/forms/templateForm/hooks/useTemplateQuestionsFilters';
import TemplateForm from '../../components/forms/templateForm/TemplateForm';
import { TemplateFormOnSubmit } from '../../components/forms/templateForm/types/TemplateFormTypes';
import { prepareTemplateRequestData } from '../../components/forms/templateForm/utils/prepareTemplateRequestData';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import useQuestions from '../../hooks/api/questions/useQuestions';
import useDeleteTemplateAd from '../../hooks/api/templates/useDeleteTemplateAd';
import useSendTemplateAds from '../../hooks/api/templates/useSendTemplateAds';
import useSingleTemplate from '../../hooks/api/templates/useSingleTemplate';
import useTemplateGameCharacters from '../../hooks/api/templates/useTemplateGameCharacters';
import useTemplateGameTypes from '../../hooks/api/templates/useTemplateGameTypes';
import useUpdateTemplate from '../../hooks/api/templates/useUpdateTemplate';
import useToast from '../../hooks/useToast';
import { routes } from '../../static/routes';
import { Langs } from '../../types/GlobalTypes';
import { findValueByLang } from '../../utils/findValueByLang/findValueByLang';
import EditTemplateActionBox from './components/EditTemplateActionBox';

const TemplateFormWithLoading = withLoading(TemplateForm);

const EditTemplateView = () => {
  const { t, i18n } = useTranslation();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const templateId = params.templateId as string;

  const { template, isLoading: isLoadingTemplate } = useSingleTemplate({
    templateId,
    onErrorCb: () => {
      showErrorToast(t('errorMessages.somethingWentWrong'));
      navigate(routes.templates());
    },
  });

  const {
    searchValue,
    setSearchValue,
    debouncedSearchValue,
    questionTypeFilter,
    setQuestionTypeFilter,
    tagFilters,
    setTagFilters,
    cityFilter,
    setCityFilter,
    arcadeFilter,
    setArcadeFilter,
  } = useTemplateQuestionsFilters();

  const { questions } = useQuestions({
    search: debouncedSearchValue,
    filters: {
      tags: tagFilters,
      questionType: questionTypeFilter,
      city: cityFilter,
      isArcade: arcadeFilter,
    },
  });
  const { gameTypes } = useTemplateGameTypes();
  const { gameCharacters } = useTemplateGameCharacters();

  const { updateTemplate, isLoading: isLoadingSubmit } = useUpdateTemplate();
  const { sendTemplateAds } = useSendTemplateAds();
  const { deleteTemplateAd } = useDeleteTemplateAd();

  const handleEditTemplate = async ({ data, questions, newAds, deletedAdIds }: TemplateFormOnSubmit) => {
    const requestData = {
      ...prepareTemplateRequestData({
        data,
        questions,
        editedNameId: template?.name.id,
        editedCoordinatesId: template?.coordinates?.id,
      }),
      gameType: template?.gameType as string,
      gameCharacter: template?.gameCharacter as string,
    };

    await updateTemplate({ id: templateId, data: requestData });

    if (!!templateId && newAds.length !== 0) {
      sendTemplateAds({ templateId, ads: newAds });
    }

    deletedAdIds.forEach(async id => {
      await deleteTemplateAd(id);
    });

    navigate(routes.templates());
  };

  const templateName = findValueByLang({
    lang: i18n.resolvedLanguage ?? Langs.PL,
    array: template?.name.translations ?? [],
  });

  const breadcrumbsProps: BreadcrumbsProps = {
    limit: 2,
    extraBreadcrumbs: [{ text: templateName ?? '' }],
  };

  return (
    <TitleWrapper
      title={templateName}
      breadcrumbsProps={breadcrumbsProps}
      sideComponent={
        !!template ? (
          <EditTemplateActionBox
            templateId={template.id.toString()}
            templateName={template.name.translations}
            gameType={template.gameType}
          />
        ) : null
      }
    >
      <TemplateFormWithLoading
        isLoading={isLoadingTemplate}
        onSubmitForm={handleEditTemplate}
        isLoadingSubmit={isLoadingSubmit}
        submitBtnText={t('editTemplate.submitBtn')}
        allQuestions={questions?.data ?? []}
        gameCharactersOptions={gameCharacters ?? []}
        gameTypesOptions={gameTypes ?? []}
        initialTemplate={template}
        isEditing
        questionFiltersProps={{
          searchValue,
          setSearchValue,
          questionType: questionTypeFilter,
          setQuestionType: setQuestionTypeFilter,
          tags: tagFilters,
          setTags: setTagFilters,
          city: cityFilter,
          setCity: setCityFilter,
          isArcade: arcadeFilter,
          setIsArcade: setArcadeFilter,
        }}
      />
    </TitleWrapper>
  );
};

export default EditTemplateView;

export const QueryAliases = {
  USER_DETAILS: 'user-details',
  ALL_USERS: 'all-users',
  QUESTIONS: 'questions',
  SINGLE_QUESTION: (id: string) => `single-question-${id}`,
  QUESTION_POINTS_OPTIONS: 'question-points-options',
  QUESTION_TYPES_OPTIONS: 'question-types-options',
  QUESTIONS_CITIES: 'questions-cities',
  GEOCODE: ({ lat, lng }: { lat: number; lng: number }) => `geocode-${lat}-${lng}`,
  GOOGLE_PLACES: 'google-places',
  GOOGLE_PLACE_DETAILS: 'google-place-details',
  TEMPLATES: 'templates',
  SINGLE_TEMPLATE: (id: string) => `single-template-${id}`,
  TEMPLATES_GAME_TYPES: 'templates-game-types',
  TEMPLATES_CITIES: 'templates-cities',
  TEMPLATES_GAME_CHARACTERS: 'templates-game-characters',
  TEMPLATE_STATISTICS: (id: string) => `template-statistics-${id}`,
  TAGS: (tag: string) => `tags-${tag}`,
  RELATED_USERS: 'related-users',
  USER_SUBSCRIPTION: (id: number) => `user-${id}-subscription`,
  ACTIVE_GAMES: 'active-games',
  PARTNERS: 'partners',
  ALL_BUNDLES: 'all-bundles',
  ADD_BUNDLE: 'add-bundle',
  SINGLE_BUNDLE: (bundleId: string) => `single-bundle-${bundleId}`,
  LIMITS_DICTIONARY: 'limit-dictionary',
  GET_PARTNER: (id: string) => `users/${id}`,
  HISTORY_GAMES: 'history-games',
  SINGLE_GAME: (id: string) => `game-${id}`,
  GAME_RECEIVED_MESSAGES: (id: string) => `game-received-messages-${id}`,
  GAME_SENT_MESSAGES: (id: string) => `game-sent-messages-${id}`,
  GAME_USERS: (gameId: string) => `games-users-${gameId}`,
  GAME_QUESTIONS: (gameId: string) => `game-questions-${gameId}`,
  GAME_CODES: (gameId: string) => `game-codes-${gameId}`,
  GAME_REVIEW_ANSWERS: (gameId: string) => `game-answers-${gameId}`,
  GAME_CHECKED_ANSWERS: (gameId: string) => `game-checked-answers-${gameId}`,
  GAME_STATISTICS: (gameId: string) => `game-statistics-${gameId}`,
  ALL_GAMERS: 'all-gamers',
  SINGLE_GAME_STATISTICS: (gameId: string) => `game-statistics-${gameId}`,
  ALL_GAME_STATISTICS: {
    SUMMARY: 'all-game-statistics-summary',
    CREATED_GAMES: 'all-game-statistics-created-games',
    GAMERS: 'all-game-statistics-gamers',
    CREATED_QUESTIONS: 'all-game-statistics-created-questions',
    CREATED_TEMPLATES: 'all-game-statistics-created-templates',
  },
  SPECTATOR: {
    SINGLE_GAME: (gameId: string) => `spectator-game-${gameId}`,
    GAME_USERS: (gameId: string) => `spectator-games-users-${gameId}`,
    GAME_QUESTIONS: (gameId: string) => `spectator-game-questions-${gameId}`,
  },
} as const;

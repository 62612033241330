import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PrimaryButton } from '../../components/common/buttons/PrimaryButton';
import SearchBar from '../../components/common/searchBar/SearchBar';
import PlusIcon from '../../components/icons/PlusIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useQuestions from '../../hooks/api/questions/useQuestions';
import useQuestionsCities from '../../hooks/api/questions/useQuestionsCities';
import usePartners from '../../hooks/api/users/usePartners';
import useUserDetails from '../../hooks/api/users/useUserDetails';
import useTags from '../../hooks/api/useTags';
import useQuestionTypeDropdownOptions from '../../hooks/useQuestionTypeDropdownOptions';
import { routes } from '../../static/routes';
import { UserTypes } from '../../types/user/userTypes';
import QuestionsViewFilters from './components/QuestionsViewFilters';
import QuestionsViewList from './components/QuestionsViewList/QuestionsViewList';
import QuestionsViewMap from './components/QuestionsViewMap/QuestionsViewMap';
import { useQuestionsViewParams } from './useQuestionsViewParams/useQuestionsViewParams';

const PAGE_SIZE = 100;

const QuestionsView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    searchFilter,
    debouncedSearchValue,
    handleChangeSearchFilter,
    page,
    handleChangePage,
    tagFilters,
    handleChangeTagFilters,
    questionTypeFilter,
    handleChangeQuestionTypeFilter,
    cityFilter,
    handleChangeCityFilter,
    ownerFilter,
    handleChangeOwnerFilter,
    arcadeFilter,
    handleChangeArcadeFilter,
  } = useQuestionsViewParams();

  const { user } = useUserDetails();
  const isGlobalAdmin = user?.role === UserTypes.GLOBAL_ADMIN;

  const { partners } = usePartners({ enabled: isGlobalAdmin });
  const { tags } = useTags({ searchValue: '', queryMinLength: 0 });
  const { questionTypeDropdownOptions } = useQuestionTypeDropdownOptions();
  const { questionsCities } = useQuestionsCities();

  const { questions, isLoading } = useQuestions({
    search: debouncedSearchValue,
    limit: PAGE_SIZE,
    page: page,
    filters: {
      tags: tagFilters,
      questionType: questionTypeFilter,
      city: cityFilter,
      owner: ownerFilter,
      isArcade: arcadeFilter,
    },
  });

  const ownersOptions = [
    ...(!!user ? [{ id: user.id.toString(), email: user.email }] : []),
    ...(partners?.map(partner => ({
      id: partner.id.toString(),
      email: partner.email,
    })) ?? []),
  ];

  const isArcadeOptions = [{ id: 'isArcade', value: t('questions.table.filters.arcade') }];

  const handleGoToAddQuestion = () => {
    const currentPath = `${location.pathname}${location.search}`;

    navigate(routes.addQuestion, { state: { prevPath: currentPath } });
  };

  return (
    <TitleWrapper
      title={t('questions.title')}
      sideComponent={<SearchBar value={searchFilter} setValue={handleChangeSearchFilter} />}
    >
      <div className="flex mb-8 justify-between items-end gap-3">
        <QuestionsViewFilters
          isGlobalAdmin={isGlobalAdmin}
          tags={tags ?? []}
          tagFilters={tagFilters}
          setTagFilters={newTags => handleChangeTagFilters(newTags)}
          questionTypesOptions={questionTypeDropdownOptions}
          questionTypeFilter={questionTypeFilter}
          setQuestionTypeFilter={newValue => handleChangeQuestionTypeFilter(newValue)}
          citiesOptions={questionsCities ?? []}
          cityFilter={cityFilter}
          setCityFilter={newValue => handleChangeCityFilter(newValue)}
          ownersOptions={ownersOptions}
          ownerFilter={ownerFilter}
          setOwnerFilter={newValue => handleChangeOwnerFilter(newValue)}
          isArcadeOptions={isArcadeOptions}
          arcadeFilter={arcadeFilter}
          setArcadeFilter={newValue => handleChangeArcadeFilter(newValue)}
        />

        <PrimaryButton onClick={handleGoToAddQuestion} className="w-fit self-end" prefixIcon={<PlusIcon size={16} />}>
          {t('questions.addQuestionBtn')}
        </PrimaryButton>
      </div>

      <QuestionsViewList
        questions={questions?.data ?? []}
        isLoading={isLoading}
        pageSize={PAGE_SIZE}
        page={page}
        onPageChange={newValue => handleChangePage(newValue)}
        rowCount={questions?.count ?? 0}
      />

      <div className="mt-8 mb-36">
        <QuestionsViewMap questions={questions?.data ?? []} />
      </div>
    </TitleWrapper>
  );
};

export default QuestionsView;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import useQuestionsCities from '../../../../../hooks/api/questions/useQuestionsCities';
import useTags from '../../../../../hooks/api/useTags';
import useQuestionTypeDropdownOptions from '../../../../../hooks/useQuestionTypeDropdownOptions';
import { colors } from '../../../../../styles/variables';
import { IQuestion } from '../../../../../types/ApiTypes';
import { DropdownOption } from '../../../../../types/Dropdown.types';
import { GameType, GameTypes } from '../../../../../types/GlobalTypes';
import { CallbackDefault } from '../../../../../types/Types';
import { individualGameQuestionTypes } from '../../../../../utils/questions/getIndividualGamePossibleQuestions/getIndividualGamePossibleQuestions';
import AutocompleteListWrapper from '../../../../common/autocompleteList/AutocompleteListWrapper';
import { PrimaryButton } from '../../../../common/buttons/PrimaryButton';
import FilterChip from '../../../../common/dropdowns/FilterDropdown/components/FilterChip';
import FilterDropdown from '../../../../common/dropdowns/FilterDropdown/FilterDropdown';
import ArrowRight from '../../../../icons/ArrowRight';
import FilterIcon from '../../../../icons/FilterIcon';
import { TemplateQuestionsFilterProps } from '../../types/TemplateFormTypes';
import SingleQuestionTile from './components/SingleQuestionTile';
import { useOwnerFilter } from './hooks/useOwnerFilter';

interface AvailableQuestionsProps {
  availableQuestions: IQuestion[];
  preSelectedQuestions: IQuestion[];
  selectedQuestions: IQuestion[];
  togglePreSelectedQuestion: (event: MouseEvent, question: IQuestion) => void;
  onAddToGame: CallbackDefault;
  filtersProps: TemplateQuestionsFilterProps;
  currentGameType: GameType;
}

const AvailableQuestions = ({
  availableQuestions,
  preSelectedQuestions,
  togglePreSelectedQuestion,
  onAddToGame,
  filtersProps,
  currentGameType,
}: AvailableQuestionsProps) => {
  const { t } = useTranslation();
  const { ownerFilter, ownersOptions, setOwnerFilter, activeOwnerIds } = useOwnerFilter();

  const questionsWithArcadesOnTop = useMemo(
    () =>
      availableQuestions.sort((a, b) => {
        if (!a.coordinates) {
          return -1;
        } else if (!b.coordinates) {
          return 1;
        } else {
          return 0;
        }
      }),
    [availableQuestions],
  );

  const { questionTypeDropdownOptions } = useQuestionTypeDropdownOptions();
  const { tags } = useTags({ searchValue: '', queryMinLength: 0 });
  const { questionsCities } = useQuestionsCities();

  const isSomeFilterActive =
    !!filtersProps.city ||
    !!filtersProps.questionType ||
    filtersProps.tags.length > 0 ||
    !!filtersProps.isArcade ||
    !!ownerFilter;

  const availableQuestionTypes =
    currentGameType === GameTypes.INDIVIDUAL
      ? questionTypeDropdownOptions.filter(question => individualGameQuestionTypes.includes(question.id))
      : questionTypeDropdownOptions;

  const handleChangeTagFilters = (option: DropdownOption | null) => {
    if (!option?.value) {
      return;
    }

    const isExisting = filtersProps.tags.some(tagItem => tagItem === option.value);

    if (isExisting) {
      const remainingTagString = filtersProps.tags.filter(item => item !== option.value);
      filtersProps.setTags(remainingTagString);
    } else {
      const newTagString = [...filtersProps.tags, option.value];
      filtersProps.setTags(newTagString);
    }
  };

  const handleDeleteTagFilter = (tagToRemove: string) => {
    const remainingTagString = filtersProps.tags.filter(item => item !== tagToRemove);
    filtersProps.setTags(remainingTagString);
  };

  return (
    <>
      <p className="text-black900 text-sm mb-3">{t('templateForm.questions.availableQuestions')}</p>

      <AutocompleteListWrapper
        searchValue={filtersProps.searchValue}
        setSearchValue={filtersProps.setSearchValue}
        selectedItemsNumber={preSelectedQuestions?.length}
        buttons={
          <PrimaryButton
            onClick={onAddToGame}
            className="w-fit"
            disabled={!(preSelectedQuestions?.length > 0)}
            sufixIcon={<ArrowRight color={colors.white900} />}
          >
            {t('templateForm.questions.addToGameBtn')}
          </PrimaryButton>
        }
        filter={
          <div className="absolute left-0 top-0">
            <FilterDropdown
              renderButton={() => <FilterIcon color={isSomeFilterActive ? colors.red500 : colors.grey700} />}
              className="border-none"
              optionGroups={[
                {
                  id: uuidv4(),
                  label: t('questions.table.filters.tag'),
                  options:
                    tags?.map(tag => ({
                      id: tag.tagName,
                      value: tag.tagName,
                    })) ?? [],
                  onSetNewOption: handleChangeTagFilters,
                  activeOptionIds: [...filtersProps.tags],
                },
                {
                  id: uuidv4(),
                  label: t('questions.table.filters.questionType'),
                  options: availableQuestionTypes,
                  onSetNewOption: option => filtersProps.setQuestionType(option?.id ?? ''),
                  activeOptionIds: [filtersProps.questionType],
                },
                {
                  id: uuidv4(),
                  label: t('questions.table.filters.city'),
                  options:
                    questionsCities?.map(city => ({
                      id: city,
                      value: city,
                    })) ?? [],
                  onSetNewOption: option => filtersProps.setCity(option?.value ?? ''),
                  activeOptionIds: [filtersProps.city],
                },
                {
                  id: uuidv4(),
                  label: t('questions.table.filters.localization'),
                  options: [{ id: 'isArcade', value: t('questions.table.filters.arcade') }],
                  onSetNewOption: option => filtersProps.setIsArcade(option?.id ?? ''),
                  activeOptionIds: [filtersProps.isArcade],
                },
                {
                  id: uuidv4(),
                  label: t('questions.table.filters.owner'),
                  options:
                    ownersOptions?.map(owner => ({
                      id: owner.id,
                      value: owner.email,
                    })) ?? [],
                  onSetNewOption: (option: DropdownOption | null) => setOwnerFilter(option?.value ?? ''),
                  activeOptionIds: activeOwnerIds,
                },
              ]}
            />
          </div>
        }
        filterChipSection={
          <div className={`flex gap-2 overflow-x-scroll no-scrollbar ${isSomeFilterActive && 'mt-4'}`}>
            {!!filtersProps.questionType && (
              <FilterChip
                label={t(`questionTypes.${filtersProps.questionType}`)}
                onDelete={() => filtersProps.setQuestionType('')}
              />
            )}
            {!!filtersProps.city && <FilterChip label={filtersProps.city} onDelete={() => filtersProps.setCity('')} />}
            {filtersProps.tags.length > 0 &&
              filtersProps.tags.map(singleTag => (
                <FilterChip key={singleTag} label={singleTag} onDelete={() => handleDeleteTagFilter(singleTag)} />
              ))}
            {!!filtersProps.isArcade && (
              <FilterChip label={t('questions.table.filters.arcade')} onDelete={() => filtersProps.setIsArcade('')} />
            )}
            {!!ownerFilter && (
              <FilterChip label={t('questions.table.filters.owner')} onDelete={() => setOwnerFilter('')} />
            )}
          </div>
        }
      >
        <>
          {questionsWithArcadesOnTop.length === 0 ? (
            <div className="w-full h-full flex justify-center items-center">
              <p className="font-medium text-grey500 max-w-xs text-center">
                {t('templateForm.questions.noAvailableQuestionsMessage')}
              </p>
            </div>
          ) : (
            <ul className="p pr-3">
              {questionsWithArcadesOnTop.map(question => {
                const isSelected = preSelectedQuestions.some(
                  preselectedQuestion => preselectedQuestion.id === question.id,
                );

                return (
                  <SingleQuestionTile
                    key={question.id}
                    question={question}
                    onClick={(event: MouseEvent) => togglePreSelectedQuestion(event, question)}
                    isSelected={isSelected}
                    isArcade={question.isArcade}
                  />
                );
              })}
            </ul>
          )}
        </>
      </AutocompleteListWrapper>
    </>
  );
};

export default AvailableQuestions;

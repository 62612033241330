import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiTemplateDetails, ITemplateDetails } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { CallbackDefault } from '../../../types/Types';
import { transformSingleTemplate } from '../../../utils/api/dataTransformers/transformTemplate/transformSingleTemplate';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface useSingleTemplateProps {
  templateId: string;
  enabled?: boolean;
  onErrorCb?: CallbackDefault;
}

const useSingleTemplate = ({ templateId, enabled = true, onErrorCb }: useSingleTemplateProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.SINGLE_TEMPLATE(templateId)],
    () => axiosInstance.get(api.endpoints.SINGLE_TEMPLATE({ id: templateId })),
    {
      cacheTime: 1,
      enabled,
      onError: () => {
        if (!!onErrorCb) {
          onErrorCb();
        }
      },
    },
  );

  const apiTemlate: ApiTemplateDetails | undefined = data?.data;

  const iTemplate: ITemplateDetails | undefined = !!apiTemlate ? transformSingleTemplate(apiTemlate) : undefined;

  return { template: iTemplate, refetch, isSuccess, isLoading, isError };
};

export default useSingleTemplate;

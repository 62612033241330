import { v4 as uuidv4 } from 'uuid';
import { Langs } from '../../../../types/GlobalTypes';

const initId = uuidv4();

export const multilangStringDefault = [
  { lang: Langs.PL, value: '' },
  { lang: Langs.EN, value: '' },
  { lang: Langs.UA, value: '' },
];

export const answersDefault = [
  { lang: Langs.PL, value: '', answerId: initId },
  { lang: Langs.EN, value: '', answerId: initId },
  { lang: Langs.UA, value: '', answerId: initId },
];

import { colors } from '../../../styles/variables';
import { CallbackDefault } from '../../../types/Types';
import XMarkIcon from '../../icons/XmarkIcon';
import IconButton from './IconButton';

interface XMarkButtonProps {
  onClick: CallbackDefault;
  className?: string;
}

const XMarkButton = ({ onClick, className }: XMarkButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      icon={<XMarkIcon color={colors.black700} />}
      className={`bg-grey100 w-8 min-w-[2rem] h-8 ${className}`}
    />
  );
};

export default XMarkButton;

import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutocompleteListItem from '../../../../../../../components/common/autocompleteList/AutocompleteListItem';
import AutocompleteListWrapper from '../../../../../../../components/common/autocompleteList/AutocompleteListWrapper';
import { HorizontalDivider } from '../../../../../../../components/common/dividers/HorizontalDivider';
import FormSectionTitle from '../../../../../../../components/common/formSectionTitle/FormSectionTitle';
import useRelatedUsers from '../../../../../../../hooks/api/users/useRelatedUsers';
import { IRelatedUser } from '../../../../../../../types/ApiTypes';

const getUserDisplayValue = (email: string) => email.split('@')[0];

interface TemplatesListTableStartGameModalUsersProps {
  selectedUsers: IRelatedUser[];
  setSelectedUsers: Dispatch<SetStateAction<IRelatedUser[]>>;
}

const TemplatesListTableStartGameModalUsers = ({
  selectedUsers,
  setSelectedUsers,
}: TemplatesListTableStartGameModalUsersProps) => {
  const { t } = useTranslation();

  const { relatedUsers } = useRelatedUsers();

  const [searchValue, setSearchValue] = useState('');

  const handleToggleSelectedUser = (newUser: IRelatedUser) => {
    setSelectedUsers(prevUsers => {
      const isSelected = prevUsers.some(prevUser => prevUser.id === newUser.id);

      if (isSelected) {
        return prevUsers.filter(prevUser => prevUser.id !== newUser.id);
      } else {
        return [...prevUsers, newUser];
      }
    });
  };

  const filteredUsers = relatedUsers?.filter(user => user.login.includes(searchValue));

  return (
    <>
      <FormSectionTitle className="mt-9" title={t('templates.startGameModal.devices.title')} />
      <p className="mt-6 mb-3">{t('templates.startGameModal.devices.subtitle')}</p>
      <AutocompleteListWrapper
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedItemsNumber={selectedUsers.length}
      >
        <ul>
          {filteredUsers?.map((user, index) => (
            <Fragment key={user.id}>
              <AutocompleteListItem
                onClick={() => handleToggleSelectedUser(user)}
                isActive={selectedUsers.some(selectedUser => selectedUser.id === user.id)}
              >
                {getUserDisplayValue(user.login)}
              </AutocompleteListItem>
              {index !== filteredUsers?.length - 1 && <HorizontalDivider className="my-2" />}
            </Fragment>
          ))}
        </ul>
      </AutocompleteListWrapper>
    </>
  );
};

export default TemplatesListTableStartGameModalUsers;

import { ApiGame, IGame } from '../../../../types/ApiTypes';
import { getGameStatusByDate } from '../../../games/getGameStatusByDate/getGameStatusByDate';
import { transformAllTemplatesItem } from '../transformTemplate/transformAllTemplatesItem';

export const transformGame = (apiGame: ApiGame) => {
  const startDate = new Date(apiGame.startDate);
  const endDate = new Date(apiGame.endDate);

  const iGame: IGame = {
    id: apiGame.id,
    startDate,
    endDate,
    isAvailableToJoin: apiGame.isAvailableToJoin,
    code: apiGame.code,
    numberOfPlayers: apiGame.numberOfPlayers,
    status: getGameStatusByDate({ startDate, endDate }),
    template: transformAllTemplatesItem(apiGame.template),
    owner: apiGame.owner,
    questionUnlockDistance: apiGame.questionUnlockDistance,
    spectatorToken: apiGame.spectacorToken,
    partnerLogo: apiGame.partnerLogo,
  };

  return iGame;
};

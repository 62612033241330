import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import withLoading from '../../../../hoc/withLoading';
import useAddQuestionImages from '../../../../hooks/api/questions/useAddQuestionImages';
import useSingleQuestion from '../../../../hooks/api/questions/useSingleQuestion';
import useUpdateQuestion from '../../../../hooks/api/questions/useUpdateQuestion';
import { routes } from '../../../../static/routes';
import { prepareQuestionRequestData } from '../../../../utils/questions/prepareQuestionRequestData/prepareQuestionRequestData';
import QuestionForm from '../../../forms/questionForm/QuestionForm';
import { OnSubmitFormProps } from '../../../forms/questionForm/types/QuestionFormTypes';
import SaveIcon from '../../../icons/SaveIcon';
import XMarkIcon from '../../../icons/XmarkIcon';
import { Modal } from '../Modal';

const QuestionFormWithLoading = withLoading(QuestionForm);

interface EditQuestionModalProps {
  questionId: number;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSucceess?: (id: number) => void;
}

const EditQuestionModal = ({ questionId, isOpen, setIsOpen, onSucceess }: EditQuestionModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { questionResponse, isLoading: isLoadingQuestion } = useSingleQuestion({
    enabled: isOpen,
    questionId: questionId.toString(),
  });
  const question = questionResponse?.question;
  const { updateQuestion, isLoading: isLoadingSubmit } = useUpdateQuestion();
  const { addQuestionImages, isLoading: isLoadingAddImages } = useAddQuestionImages();

  const handleEditQuestion = async ({
    formData,
    mainImage,
    correctAnswerImage,
    incorrectAnswerImage,
    markerIcon,
    goToRelatedQuestion,
    isDirtyForm,
    isDirtyAnyImage,
  }: OnSubmitFormProps) => {
    if (isDirtyForm) {
      const requestData = {
        ...prepareQuestionRequestData(formData),
        questionType: question?.questionType.code as string,
      };

      await updateQuestion({ id: questionId.toString(), data: requestData });
    }

    if (isDirtyAnyImage) {
      addQuestionImages({
        questionId: questionId.toString(),
        mainImage,
        correctAnswerImage,
        incorrectAnswerImage,
        markerIcon: markerIcon,
      });
    }

    if (!!onSucceess) {
      onSucceess(questionId);
    }

    if (!goToRelatedQuestion) {
      setIsOpen(false);
      return;
    }

    const currentRelatedQuestion = questionResponse?.relatedQuestion;

    const newRoute = !!currentRelatedQuestion
      ? routes.editRelatedQuestion({
          editedQuestionId: currentRelatedQuestion.id.toString(),
          parentQuestionId: question!.id.toString(),
        })
      : routes.addRelatedQuestion({ parentQuestionId: question!.id.toString() });

    navigate(newRoute);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} contentClassName="w-[50rem] min-w-[50rem]">
      <div>
        <div className="p-6 relative border-b border-grey300">
          <h2 className="text-center text-[1.375rem] font-medium">{t('questionForm.editModal.title')}</h2>
          <div
            onClick={() => setIsOpen(false)}
            className="w-8 h-8 min-w-8 absolute top-6 right-6 lg:left-6 flex justify-center items-center cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>

        <div className="py-6 px-6">
          <QuestionFormWithLoading
            isLoading={isLoadingQuestion}
            onSubmitForm={handleEditQuestion}
            isLoadingSubmit={isLoadingSubmit || isLoadingAddImages}
            submitBtnIcon={<SaveIcon />}
            submitBtnText={t('questionForm.editModal.submitBtn')}
            initialQuestion={question}
            doesRelatedQuestionExist={!!questionResponse?.relatedQuestion}
            isEditing
            isModal
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditQuestionModal;

import { Logo } from '../../components/navigation/components/Logo';

// TODO: delete temp solution
const PrivacyPolicyView = () => {
  return (
    <div className="px-8 md:px-60 py-8 flex flex-col">
      <div className="flex flex-col items-center">
        <Logo />
        <h1 className="mt-12 text-black font-bold text-3xl">POLITYKA PRYWATNOŚCI APLIKACJI MOBILNEJ</h1>
      </div>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">1. Anonimowy identyfikator aplikacji mobilnej</h2>
        <p>
          Na urządzeniu mobilnym przechowywany jest anonimowy identyfikator aplikacji mobilnej (tworzony w momencie
          rejestracji aplikacji mobilnej po stronie TamTu), aż do momentu usunięcia aplikacji mobilnej z urządzenia
          mobilnego.
        </p>

        <h2 className="mt-4 text-lg font-semibold">2. Weryfikacja autentyczności aplikacji mobilnej</h2>
        <p>
          Anonimowy identyfikator aplikacji mobilnej oraz informacje udostępniane przez systemy operacyjne urządzenia
          mobilnego (marka, model, identyfikator sprzętowy urządzenia mobilnego) są wysyłane do TamTu w momencie
          aktywacji urządzenia w aplikacji mobilnej oraz w celu weryfikacji autentyczności aplikacji mobilnej. Aplikacja
          mobilna porozumiewa się z TamTu przy użyciu mechanizmów szyfrujących.
        </p>
      </section>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">3. Uprawnienia aplikacji mobilnej</h2>
        <p>Aplikacja mobilna może uzyskać dostęp do następujących uprawnień na urządzeniu mobilnym, m.in.:</p>
        <ul className="list-disc list-inside">
          <li> danych technicznych urządzenia, w tym numeru IMEI</li>
          <li> pamięci wewnętrznej</li>
          <li> mikrofonu</li>
          <li> lokalizacji</li>
          <li> kontaktów</li>
          <li> aparatu fotograficznego</li>
        </ul>
      </section>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">4. Numer IMEI urządzenia</h2>
        <p>
          Numer IMEI urządzenia jest przechowywany w celu zapobieganiu uruchamiania gry na wielu urządzeniach na raz.
        </p>
      </section>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">5. Dane wymagane przez TamTu</h2>
        <p>TamTu do działania potrzebuje poniższych danych:</p>
        <ul className="list-disc list-inside">
          <li>a) Nick</li>
          <li>b) adres email</li>
          <li>c) zdjęcie profilowe (opcjonalnie)</li>
          <li>d) dostęp do lokalizacji urządzenia mobilnego</li>
          <li>
            e) dostęp do bluetooth w celu odnajdywania urządzeń NFC, BLE w najbliższej okolicy do realizacji wyzwań
            związanych z grą.
          </li>
        </ul>
        <p>w celu m.in.:</p>
        <ul className="list-disc list-inside">
          <li> umożliwienia udziału w grach (geolokalizacja pytań i urządzeń)</li>
          <li> dopasowania do potrzeb użytkowników określonych produktów i usług</li>
          <li> doskonalenia naszych produktów i usług</li>
          <li> dostarczenia adresów zadań</li>
          <li> wykonywania zadań</li>
          <li> skanowania kodów QR</li>
          <li> wykonywania mikropłatności</li>
        </ul>
      </section>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">6. Przetwarzanie danych przez TamTu</h2>
        <p>
          TamTu przetwarza powyższe dane w celu realizacji gier terenowych, usługi e-przewodników za pomocą urządzenia
          mobilnego.
        </p>
        <p>
          Nick (w serwisach połączonych za pomocą logowania typu FACEBOOK, GOOGLE lub APPLE mogą to być IMIĘ oraz
          NAZWISKO) wykorzystujemy do:
        </p>
        <ul className="list-disc list-inside">
          <li>
            a) identyfikacji użytkownika na liście uczestników, wyświetlania jego pozycji w rankingu i komunikacji
            wewnętrznej.
          </li>
        </ul>
        <p>Adres e-mail wykorzystujemy do:</p>
        <ul className="list-disc list-inside">
          <li>a) komunikacji</li>
          <li>
            b) unikalnej identyfikacji użytkownika w trakcie działania aplikacji. Do każdego adresu e-mail może być
            przypisany tylko jeden użytkownik.
          </li>
        </ul>
        <p>
          Zdjęcie profilowe, pobierane z powiązanego serwisu logowania lub wgrywane przez użytkownika, wykorzystujemy
          do:
        </p>
        <ul className="list-disc list-inside">
          <li>a) prezentacji graficznej gracza na liście rankingowej</li>
        </ul>
        <p>Dostęp do lokalizacji wykorzystujemy do:</p>
        <ul className="list-disc list-inside">
          <li>
            a) realizacji podstawowego działania aplikacji TamTu, czyli zgodnie z założonym scenariuszem gry lub
            przewodnika, sprawdzanie pozycji za pomocą GPS użytkownika, sprawdzanie jej poprawności i nawigowanie do
            celu.
          </li>
        </ul>
        <p>Dostęp do bluetooth, BLE, kamery urządzenia mobilnego, NFC wykorzystujemy do:</p>
        <ul className="list-disc list-inside">
          <li>
            a) realizacji scenariusza gry lub przewodnika wymagającego sprawdzenia otoczenia pod względem przebywania w
            bliskości z urządzeniami założonymi w scenariuszu gry lub przewodniku. Kamera służy do realizacji
            specjalnego typu zagadki.
          </li>
        </ul>
      </section>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">7. Zarządzanie uprawnieniami</h2>
        <p>
          Wszystkie uprawnienia (funkcje i informacje urządzenia mobilnego), które użytkownicy nadają aplikacji
          mobilnej, są widoczne w panelu ustawień ich urządzenia mobilnego. W panelu ustawień można zarządzać tymi
          ustawieniami. Uprawnienia aplikacji mobilnej można odwołać przez zmianę ustawień na urządzeniu mobilnym lub
          przez odinstalowanie aplikacji mobilnej.
        </p>
      </section>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">8. Zbieranie danych osobowych przez aplikację mobilną</h2>
        <p>
          Aplikacja mobilna zbiera dane osobowe i informacje o aktywności użytkowników, które zostają wykorzystane do
          świadczenia usług zabawy elektronicznej, a także do dostosowania treści marketingowych do zainteresowań i
          potrzeb użytkownika na podstawie udzielonych przez niego zgód marketingowych. Daną osobową jest informacja,
          przy pomocy której możemy zidentyfikować naszego użytkownika.
        </p>
        <p>
          Nasza aplikacja mobilna wykorzystuje również mobilne identyfikatory reklam (Google Advertising ID i Apple
          IDFA) oraz inne informacje zgodnie z ustawieniami systemu operacyjnego Twojego urządzenia, np. dane techniczne
          lub koordynaty geograficzne. Są one wykorzystywane do właściwej obsługi aplikacji oraz profilowania treści
          marketingowych. Możesz wyłączyć wykorzystywanie identyfikatorów, a także innych informacji w ustawieniach
          urządzenia – szczegółowe informacje znajdują się w poniższych instrukcjach producentów: Android, Apple.
        </p>
      </section>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">9. Administrator danych osobowych</h2>
        <p>
          Administratorem danych osobowych użytkowników jest Acora Sp. z o.o., adres: ul. Tramwajowa 17B/4, 92-440 Łódź,
          zarejestrowana w KRS 0000871672, REGON 387600852, NIP: 725-230-44-01.
        </p>
      </section>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">10. Akceptacja polityki prywatności</h2>
        <p>
          Użytkownik instalując aplikację mobilną jednocześnie akceptuje treść niniejszej Polityki prywatności. W
          przeciwnym razie prosimy o niezatwierdzanie zgody na instalację albo odinstalowanie aplikacji mobilnej.
        </p>
      </section>

      <section className="mt-8 text-gray-700">
        <h2 className="text-lg font-semibold">11. Kontakt w razie wątpliwości</h2>
        <p>
          W razie wątpliwości co do któregokolwiek z zapisów niniejszej polityki prywatności jesteśmy do dyspozycji pod
          adresem{' '}
          <a href="mailto:tamtu@tamtu.com.pl" className="text-blue-500">
            tamtu@tamtu.com.pl
          </a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicyView;

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BreadcrumbsProps } from '../../components/common/breadcrumbs/Breadcrumbs';
import QuestionForm from '../../components/forms/questionForm/QuestionForm';
import { OnSubmitFormProps } from '../../components/forms/questionForm/types/QuestionFormTypes';
import SaveIcon from '../../components/icons/SaveIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import withLoading from '../../hoc/withLoading';
import useAddQuestionImage from '../../hooks/api/questions/useAddQuestionImages';
import useSingleQuestion from '../../hooks/api/questions/useSingleQuestion';
import useUpdateQuestion from '../../hooks/api/questions/useUpdateQuestion';
import useToast from '../../hooks/useToast';
import { routes } from '../../static/routes';
import { Langs } from '../../types/GlobalTypes';
import { findValueByLang } from '../../utils/findValueByLang/findValueByLang';
import { prepareQuestionRequestData } from '../../utils/questions/prepareQuestionRequestData/prepareQuestionRequestData';

const QuestionFormWithLoading = withLoading(QuestionForm);

const EditQuestionView = () => {
  const { t, i18n } = useTranslation();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const prevPath = location.state?.prevPath;
  const params = useParams();
  const questionId = params.questionId as string;

  const { questionResponse, isLoading: isLoadingQuestion } = useSingleQuestion({
    questionId,
    onErrorCb: () => {
      showErrorToast(t('errorMessages.somethingWentWrong'));
      navigate(routes.questions());
    },
  });
  const question = questionResponse?.question;
  const { updateQuestion, isLoading: isLoadingSubmit } = useUpdateQuestion();
  const { addQuestionImages, isLoading: isLoadingAddImages } = useAddQuestionImage();

  const handleEditQuestion = async ({
    formData,
    mainImage,
    correctAnswerImage,
    incorrectAnswerImage,
    markerIcon,
    goToRelatedQuestion,
    isDirtyForm,
    isDirtyAnyImage,
  }: OnSubmitFormProps) => {
    if (isDirtyForm || !formData.coordinates) {
      const requestData = {
        ...prepareQuestionRequestData(formData),
        questionType: question?.questionType.code as string,
      };

      await updateQuestion({ id: questionId, data: requestData });
    }

    if (isDirtyAnyImage) {
      addQuestionImages({
        questionId: questionId as string,
        mainImage,
        correctAnswerImage,
        incorrectAnswerImage,
        markerIcon: markerIcon,
      });
    }

    if (!goToRelatedQuestion) {
      let redirectRoute = routes.questions();

      if (!!prevPath && typeof prevPath === 'string') {
        redirectRoute = prevPath;
      }

      navigate(redirectRoute);
      return;
    }

    const currentRelatedQuestion = questionResponse?.relatedQuestion;

    const newRoute = !!currentRelatedQuestion
      ? routes.editRelatedQuestion({
          editedQuestionId: currentRelatedQuestion.id.toString(),
          parentQuestionId: question!.id.toString(),
        })
      : routes.addRelatedQuestion({ parentQuestionId: question!.id.toString() });

    navigate(newRoute);
  };

  const questionName = findValueByLang({ lang: i18n.resolvedLanguage ?? Langs.PL, array: question?.name ?? [] });

  const breadcrumbsProps: BreadcrumbsProps = {
    limit: 2,
    extraBreadcrumbs: [{ text: questionName ?? '' }],
  };

  return (
    <TitleWrapper title={t('editQuestion.title')} breadcrumbsProps={breadcrumbsProps}>
      <div className="pb-20">
        <QuestionFormWithLoading
          isLoading={isLoadingQuestion}
          onSubmitForm={handleEditQuestion}
          isLoadingSubmit={isLoadingSubmit || isLoadingAddImages}
          submitBtnIcon={<SaveIcon />}
          submitBtnText={t('editQuestion.submitBtn')}
          initialQuestion={question}
          doesRelatedQuestionExist={!!questionResponse?.relatedQuestion}
          isEditing
        />
      </div>
    </TitleWrapper>
  );
};

export default EditQuestionView;

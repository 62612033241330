import { twMerge } from 'tailwind-merge';
import { CallbackDefault } from '../../../types/Types';

const Switch = ({
  isChecked,
  onChange,
  className,
}: {
  isChecked: boolean;
  onChange: CallbackDefault;
  className?: string;
}) => {
  const onClick = () => {
    onChange();
  };

  return (
    <div
      onClick={onClick}
      className={twMerge(
        `w-[3.25rem] h-[2rem] rounded-full relative transition-all  duration-200 cursor-pointer ${
          isChecked ? 'bg-red-700' : 'bg-gray-300'
        } ${className}`,
      )}
    >
      <span
        className={twMerge(
          `w-6 h-6 rounded-full absolute top-1/2 left-1 bg-white900 translate-y-[-50%] ${
            isChecked ? 'translate-x-5' : 'translate-x-0'
          }`,
        )}
      ></span>
    </div>
  );
};

export default Switch;

import { useContext } from 'react';
import { AxiosContext } from '../../context/AxiosContextProvider';

const useAxiosInstance = () => {
  const { axiosInstance } = useContext(AxiosContext);

  return axiosInstance;
};

export default useAxiosInstance;

import * as yup from 'yup';
import * as Schema from '../schemaFields';

export const templateValidationSchema = yup.object({
  tags: yup.array(Schema.dropdownOption),
  name: yup.array(Schema.multilangString).required('required'),
  gameCharacter: Schema.dictionaryDropdownOption,
  gameType: Schema.dictionaryDropdownOption,
  isActive: yup.boolean(),
  city: Schema.requiredString,
  coordinates: Schema.coordinatesString,
  estimatedDuration: yup
    .number()
    .nullable()
    .positive('positiveNumber')
    .transform(value => (isNaN(value) || value === null || value === undefined ? null : value)),
});

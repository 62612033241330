import { useTranslation } from 'react-i18next';
import LinearProgressBar from '../../../../../components/charts/linearProgressBar/LinearProgressBar';
import BorderCard from '../../../../../components/common/cards/BorderCard';
import { ISingleGameStatistics } from '../../../../../types/ApiTypes';

const SingleGameStatisticsCard = ({ gameStatistics }: { gameStatistics: ISingleGameStatistics }) => {
  const { t } = useTranslation();

  const givenAnswers = gameStatistics.correctAnswers + gameStatistics.incorrectAnswers;
  const totalQuestions = gameStatistics.totalQuestions;
  const correctAnswers = gameStatistics.correctAnswers;
  const incorrectAnswers = gameStatistics.incorrectAnswers;
  const totalAnswers = gameStatistics.totalAnswers;
  const scoredPoints = gameStatistics.aquiredPoints;
  const maxPointsToScore = gameStatistics.maxPoints;
  const activeGamers = gameStatistics.activeGamers;
  const totalGamers = gameStatistics.totalGamers;

  return (
    <>
      <BorderCard className="mt-8">
        <div className="flex flex-col gap-10">
          <LinearProgressBar
            label={`${t('singleActiveGame.info.stats.givenAnswers')} / ${t(
              'singleActiveGame.info.stats.totalQuestions',
            )}`}
            scored={givenAnswers}
            total={totalQuestions}
          />

          <LinearProgressBar
            label={`${t('singleActiveGame.info.stats.correctAnswers')} / ${t(
              'singleActiveGame.info.stats.totalAnswers',
            )}`}
            scored={correctAnswers}
            total={totalAnswers}
          />

          <LinearProgressBar
            label={`${t('singleActiveGame.info.stats.incorrectAnswers')} / ${t(
              'singleActiveGame.info.stats.totalAnswers',
            )}`}
            scored={incorrectAnswers}
            total={totalAnswers}
          />

          <LinearProgressBar
            label={`${t('singleActiveGame.info.stats.scoredPoints')} / ${t(
              'singleActiveGame.info.stats.maxPointsToScore',
            )}`}
            scored={scoredPoints}
            total={maxPointsToScore}
          />

          <LinearProgressBar
            label={`${t('singleActiveGame.info.stats.activeGamers')} / ${t('singleActiveGame.info.stats.totalGamers')}`}
            scored={activeGamers}
            total={totalGamers}
          />
        </div>
      </BorderCard>
    </>
  );
};

export default SingleGameStatisticsCard;

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { min1NumberRegex, min1SpecialCharRegex, min1UppercaseRegex } from '../../../static/regex';
import { resetPasswordValidationSchema } from '../../../static/validation/schemas/resetPasswordValidationSchema';
import { ApiResetPasswordBody } from '../../../types/ApiTypes';
import getInputState from '../../../utils/getInputState/getInputState';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { SecondaryButton } from '../../common/buttons/SecondaryButton';
import { LabeledStateInput } from '../../common/inputs/LabeledStateInput';
import PasswordCriteriaItem from '../passwordCriteriaItem/PasswordCriteriaItem';

type IFormInput = ApiResetPasswordBody;

interface ResetPasswordFormProps {
  onFormSubmit: (data: ApiResetPasswordBody) => void;
  isLoading: boolean;
  onCancel?: () => void;
  onCancelText?: string;
  passwordLabel: string;
  confirmationPasswordLabel: string;
  submitBtnText: string;
}

const ResetPasswordForm = ({
  onFormSubmit,
  isLoading,
  onCancel,
  onCancelText,
  passwordLabel,
  confirmationPasswordLabel,
  submitBtnText,
}: ResetPasswordFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onSubmit',
    resolver: yupResolver(resetPasswordValidationSchema),
  });

  const onSubmit: SubmitHandler<IFormInput> = data => {
    onFormSubmit(data);

    reset();
  };

  const passwordCurrentValue = watch('newPassword');

  const getErrorMessage = (field: keyof IFormInput) => get(errors, field);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-8 w-full">
      <LabeledStateInput
        id="reset-password-new-password"
        state={getInputState(getErrorMessage('newPassword'))}
        labeledProps={{
          wrapperClassName: 'mb-8',
          label: passwordLabel,
          errorMessage: t(`errorMessages.${getErrorMessage('newPassword')?.message}`),
        }}
        inputProps={{
          type: 'password',
          register: {
            ...register('newPassword'),
          },
        }}
      />

      <LabeledStateInput
        id="reset-password-confirmation-password"
        state={getInputState(getErrorMessage('confirmationPassword'))}
        labeledProps={{
          wrapperClassName: 'mb-8',
          label: confirmationPasswordLabel,
          errorMessage: t(`errorMessages.${getErrorMessage('confirmationPassword')?.message}`),
        }}
        inputProps={{
          type: 'password',
          register: {
            ...register('confirmationPassword'),
          },
        }}
      />

      <div className="mb-10">
        <PasswordCriteriaItem
          isSuccess={min1UppercaseRegex.test(passwordCurrentValue)}
          text={t('errorMessages.min1Uppercase')}
        />
        <PasswordCriteriaItem
          isSuccess={min1NumberRegex.test(passwordCurrentValue)}
          text={t('errorMessages.min1Number')}
        />
        <PasswordCriteriaItem
          isSuccess={min1SpecialCharRegex.test(passwordCurrentValue)}
          text={t('errorMessages.min1SpecialChar')}
        />
      </div>
      <div className="flex justify-center gap-10 items-center">
        {!!onCancel && (
          <SecondaryButton onClick={onCancel} className="min-w-[10rem]">
            {onCancelText}
          </SecondaryButton>
        )}
        <PrimaryButton type="submit" disabled={isLoading} loading={isLoading}>
          {submitBtnText}
        </PrimaryButton>
      </div>
    </form>
  );
};

export default ResetPasswordForm;

import { twMerge } from 'tailwind-merge';
import i18n, { defaultLang } from '../../../i18n';
import { supportedLanguages } from '../../../static/supportedLanguages';
import { DropdownOption } from '../../../types/Dropdown.types';
import Dropdown from '../../common/dropdowns/Dropdown';

export const locales: DropdownOption[] = supportedLanguages;

type expandDirectionOptions = 'bottom' | 'top';

interface LanguageSwitcherProps {
  className?: string;
  optionsExpandDirection?: expandDirectionOptions;
  prefixIcon?: React.ReactNode;
}

const LanguageSwitcher = ({ className, optionsExpandDirection = 'bottom', prefixIcon }: LanguageSwitcherProps) => {
  const currentLang = i18n.resolvedLanguage;

  const currentLangOption: DropdownOption = {
    id: currentLang ?? defaultLang.id,
    value: currentLang?.toUpperCase() ?? defaultLang.value,
  };

  const handleChangeLanguage = (newLangOption: DropdownOption) => {
    i18n.changeLanguage(newLangOption.id);
  };

  return (
    <div className={twMerge(`flex p-5 gap-2 items-center ${className}`)}>
      {prefixIcon}
      <Dropdown
        options={locales}
        currentOption={currentLangOption}
        setNewOption={newOption => handleChangeLanguage(newOption)}
        wrapperClassName="outline-0 pl-0"
        optionsClassName={optionsExpandDirection === 'top' ? 'bottom-16 top-auto' : ''}
        buttonClassName="justify-start"
      />
    </div>
  );
};

export default LanguageSwitcher;

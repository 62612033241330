import { CircularProgress } from '@mui/material';
import { CallbackDefault } from '../../../../../types/Types';

interface GameContentDownloadBtnProps {
  isLoading: boolean;
  icon: React.ReactNode;
  onDownloadClick: CallbackDefault;
  label: string;
}

const GameContentDownloadBtn = ({ isLoading, icon, onDownloadClick, label }: GameContentDownloadBtnProps) => {
  return (
    <div className="mt-6 flex items-center gap-2">
      {isLoading ? (
        <CircularProgress size={50} />
      ) : (
        <button onClick={onDownloadClick} className="border border-grey400 rounded-full p-3">
          {icon}
        </button>
      )}

      <p className="text-black700 text-sm font-normal">{label}</p>
    </div>
  );
};

export default GameContentDownloadBtn;

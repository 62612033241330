import { colors } from '../../styles/variables';

const CheckIcon = ({ color, width, height }: { color?: string; width?: number; height?: number }) => {
  return (
    <svg width={width ?? 17} height={height ?? 16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4831 4L6.14974 11.3333L2.81641 8"
        stroke={color ?? colors.white900}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;

import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import { ApiGameCode, IGameCode } from '../../../types/ApiTypes';
import { transformGameCode } from '../../../utils/api/dataTransformers/transformGameCode/transformGameCode';

const useCreateNewCodes = ({ onSuccess }: { onSuccess?: (newCodes: IGameCode[]) => void } = {}) => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast, showSuccessToast } = useToast();
  const { t } = useTranslation();

  const createCodes = async ({ gameId }: { gameId: string }) => {
    const response = await axiosInstance.post(api.endpoints.NEW_CODES({ gameId }));
    const apiCodes: ApiGameCode[] = response.data;
    const iCodes: IGameCode[] = apiCodes.map(code => transformGameCode(code));
    return iCodes;
  };

  const { mutateAsync, isLoading } = useMutation(createCodes, {
    onSuccess: data => {
      if (onSuccess) {
        onSuccess(data);
      }
      showSuccessToast(t('successMessages.createCodesSuccess'));
    },
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.createCodesFailed');
      showErrorToast(errorMessage);
    },
  });

  return { createGameCodes: mutateAsync, isLoading };
};

export default useCreateNewCodes;

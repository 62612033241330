import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import MaterialTable from '../../../../components/dataTable/MaterialTable/MaterialTable';
import PlusIcon from '../../../../components/icons/PlusIcon';
import useGameUsers from '../../../../hooks/api/games/useGameUsers';
import useSingleGame from '../../../../hooks/api/games/useSingleGame';
import useUpdateGame from '../../../../hooks/api/games/useUpdateGame';
import useRelatedUsers from '../../../../hooks/api/users/useRelatedUsers';
import { routes } from '../../../../static/routes';
import { Coordinates } from '../../../../types/GlobalTypes';
import { UserTypes } from '../../../../types/user/userTypes';
import SingleActiveGamePageWrapper from '../../SingleActiveGamePageWrapper';
import EditGameDevicesModal from '../../components/EditGameDevicesModal';
import useActiveGamePlayersHeaders from './hooks/useActiveGamePlayersHeaders';

const SingleActiveGamePlayersView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const gameId = params.id as string;

  const [isEditDevicesModalOpen, setIsEditDevicesModalOpen] = useState(false);

  const { game } = useSingleGame({ gameId });
  const { gameUsers, isLoading: isLoadingGameUsers, refetch: refetchGameUsers } = useGameUsers({ gameId });
  const { relatedUsers } = useRelatedUsers();

  const currentGameUsers = gameUsers?.filter(
    gameUser => gameUser.userGame.user.role === UserTypes.GUEST || gameUser.userGame.user.role === UserTypes.GAMER,
  );
  const currentGameUsersSimplified =
    currentGameUsers?.map(gameUser => ({
      id: gameUser.userGame.user.id,
      teamName: gameUser.userGame.teamName,
    })) ?? [];
  const currentGameUsersWithoutRelated = currentGameUsersSimplified.filter(
    currGameUser => !relatedUsers.some(relatedUser => relatedUser.id === currGameUser.id),
  );

  const { updateGame, isLoading: isLoadingUpdateGame } = useUpdateGame({
    onSuccess: async () => {
      setIsEditDevicesModalOpen(false);
      refetchGameUsers();
    },
  });

  const handleEditUsers = async ({ users }: { users: number[] }) => {
    updateGame({
      id: gameId,
      data: {
        users: users,
      },
    });
  };

  const handleMessageTeam = ({ userId }: { userId: number }) => {
    navigate(routes.singleActiveGame.messages({ id: gameId, messageTo: userId }));
  };

  const handleShowTeamLocation = ({ coordinates }: { coordinates: Coordinates }) => {
    navigate(routes.singleActiveGame.map({ id: gameId, center: `${coordinates.lat},${coordinates.lng}` }));
  };

  const handleDeleteTeam = ({ userId }: { userId: number }) => {
    const newUsers = gameUsers
      ?.filter(gameUser => gameUser.userGame.user.id !== userId)
      ?.map(gameUser => gameUser.userGame.user.id);

    if (!!newUsers) {
      handleEditUsers({ users: newUsers });
    }
  };

  const { headers } = useActiveGamePlayersHeaders({
    onMessageClick: handleMessageTeam,
    onLocationClick: handleShowTeamLocation,
    onDeleteClick: handleDeleteTeam,
    isLoadingDelete: isLoadingUpdateGame,
  });

  const allowEditUsers = !!game && !!gameUsers && !!relatedUsers;

  return (
    <>
      <SingleActiveGamePageWrapper>
        <div className="mt-6">
          <MaterialTable
            columns={headers}
            rows={gameUsers ?? []}
            loading={isLoadingGameUsers}
            getRowId={row => row.userGame.id}
          />

          {allowEditUsers && (
            <div className="flex justify-end mt-5">
              <PrimaryButton
                onClick={() => setIsEditDevicesModalOpen(true)}
                prefixIcon={<PlusIcon />}
                className="w-fit"
              >
                {t('singleActiveGame.players.addPlayerBtn')}
              </PrimaryButton>
            </div>
          )}
        </div>
      </SingleActiveGamePageWrapper>

      {allowEditUsers && (
        <EditGameDevicesModal
          isOpen={isEditDevicesModalOpen}
          setIsOpen={setIsEditDevicesModalOpen}
          initAllowJoinByCode={game?.isAvailableToJoin}
          initUsersIds={gameUsers.map(gameUser => gameUser.userGame.user.id)}
          relatedUsers={relatedUsers}
          currentGameUsers={currentGameUsersWithoutRelated}
          onSubmitForm={handleEditUsers}
          isLoading={isLoadingUpdateGame}
          hideJoinByCodeCheckbox
        />
      )}
    </>
  );
};

export default SingleActiveGamePlayersView;

import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface ApiCity {
  city: string;
}

const useTemplatesCities = () => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.TEMPLATES_CITIES],
    () => axiosInstance.get(`${api.endpoints.TEMPLATES_CITIES}`),
    {
      refetchOnMount: true,
    },
  );

  const apiTemplatesCities: ApiCity[] | undefined = data?.data;

  const iTemplatesCities: string[] | undefined = !!apiTemplatesCities
    ? apiTemplatesCities.map(city => city.city)
    : undefined;

  return { templatesCities: iTemplatesCities, refetch, isSuccess, isLoading, isError };
};

export default useTemplatesCities;

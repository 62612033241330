import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { routes } from '../../../static/routes';

interface LogoProps {
  width?: number;
  height?: number;
}

export const Logo = ({ width, height }: LogoProps) => {
  return (
    <Link to={routes.home}>
      <img src={logo} alt="tamtu logo" width={width ?? 180} height={height ?? 90} />
    </Link>
  );
};

import { ApiGameUser, IGameUser } from '../../../../types/ApiTypes';
import { generateColorById } from '../../../colors/generateColorById/generateColorById';

export const transformGameUser = ({ apiUser, index }: { apiUser: ApiGameUser; index: number }) => {
  const allCoordinatesSorted = apiUser.userGame.route
    .sort((pointA, pointB) => new Date(pointA.createdAt).getTime() - new Date(pointB.createdAt).getTime())
    .map(routeItem => ({
      lat: +routeItem.latitude,
      lng: +routeItem.longitude,
    }));

  const currentCoordinates = allCoordinatesSorted[allCoordinatesSorted.length - 1];

  const iUser: IGameUser = {
    userGame: {
      id: apiUser.userGame.id,
      teamName: apiUser.userGame.teamName,
      teamImage: apiUser.userGame.teamImage,
      firstQuestionId: apiUser.userGame.firstQuestionId,
      user: {
        id: apiUser.userGame.user.id,
        email: apiUser.userGame.user.email,
        role: apiUser.userGame.user.role,
      },
      points: apiUser.userGame.points,
      color: generateColorById(index),
      allCoordinates: allCoordinatesSorted,
      currentCoordinates,
    },
    totalAnswers: apiUser.totalAnswers,
    userAnswers: apiUser.userAnswers,
  };

  return iUser;
};

// TODO: delete temp solution
const RegulationsView = () => {
  return (
    <div className="px-8 md:px-60 py-8 flex flex-col">
      <h1 className="mt-12 text-black font-bold text-3xl text-center">Regulamin</h1>

      <h2 className="mt-8  text-xl font-semibold text-gray-700">Zasady Gry</h2>

      <section className="mt-4 text-gray-700">
        <h3 className="text-lg font-semibold">Gra biznesowa</h3>
        <p>
          1. Na mapie widoczne są ikonki pytań, które aktywują się po dotarciu do miejsca zadania; dezaktywują się po
          oddaleniu od tego miejsca.
          <br />
          2. Punktacja zadań określona jest kolorami, a rodzaje zadań - ikonami. W grze biznesowej są dwa typy pytań
          sprawdzane przez administratora oraz pytania sprawdzane automatycznie.
          <br />
          3. W tej grze nie ma jednej wyznaczonej trasy, każdy decyduje, które zadania wykona i jaką trasę przejdzie w
          czasie gry.
          <br />
          4. W lewym dolnym rogu widoczny jest timer odliczający czas pozostały do końca gry; po zakończeniu gry, nie ma
          już możliwości wykonania zadań.
          <br />
          5. Każdy gracz widzi na mapie swoją lokalizację.
          <br />
          6. W lewym górnym rogu można sprawdzić swoją punktację oraz miejsce w rankingu; podgląd rankingu jest
          wyłączany 30 minut przed końcem gry.
        </p>
      </section>

      <section className="mt-8 text-gray-700">
        <h3 className="text-lg font-semibold">Gra publiczna bezpłatna</h3>
        <p>
          1. Na mapie widoczne są ikonki pytań, które aktywują się po dotarciu do miejsca zadania; dezaktywują się po
          oddaleniu od tego miejsca.
          <br />
          2. Punktacja zadań określona jest kolorami, a rodzaje zadań - ikonami.
          <br />
          3. W tej grze nie ma jednej wyznaczonej trasy, każdy decyduje, które zadania wykona i jaką trasę przejdzie w
          czasie gry.
          <br />
          4. W lewym dolnym rogu widoczny jest timer odliczający czas pozostały do końca gry; po zakończeniu gry, nie ma
          już możliwości wykonania zadań.
          <br />
          5. Każdy gracz widzi na mapie swoją lokalizację.
          <br />
          6. W lewym górnym rogu można sprawdzić swoją punktację oraz miejsce w rankingu; podgląd rankingu jest
          wyłączany 30 minut przed końcem gry.
        </p>
      </section>

      <section className="mt-8 text-gray-700">
        <h3 className="text-lg font-semibold">Gra indywidualna</h3>
        <p>
          1. Na mapie widoczne są ikonki pytań, które aktywują się po dotarciu do miejsca zadania; dezaktywują się po
          oddaleniu od tego miejsca.
          <br />
          2. Punktacja zadań określona jest kolorami, a rodzaje zadań - ikonami. W grze indywidualnej są tylko pytania
          sprawdzane automatycznie.
          <br />
          3. W tej grze nie ma jednej wyznaczonej trasy, każdy decyduje, które zadania wykona i jaką trasę przejdzie w
          czasie gry.
          <br />
          4. W lewym dolnym rogu widoczny jest timer odliczający czas pozostały do końca gry; po zakończeniu gry, nie ma
          już możliwości wykonania zadań.
          <br />
          5. Każdy gracz widzi na mapie swoją lokalizację.
          <br />
          6. W lewym górnym rogu można sprawdzić swoją punktację oraz miejsce w rankingu; podgląd rankingu jest
          wyłączany 30 minut przed końcem gry.
        </p>
      </section>

      <section className="mt-8 text-gray-700">
        <h3 className="text-xl font-semibold">Usuwanie konta</h3>
        <p>
          Użytkownicy aplikacji mogą usuwać swoje konta w ustawieniach w aplikacji mobilnej lub poprzez wysłanie prośby
          na adres e-mail support@brainforgeit.com. Operacja jest nieodwracalna, a z systemu zostaną usunięte wszelkie
          dane użytkownika, takie jak nazwa użytkownika czy postępy z ukończonych gier.
        </p>
      </section>
    </div>
  );
};

export default RegulationsView;

import TableCell from '../../../components/dataTable/components/TableCell';
import { UserStatuses } from '../../../types/user/userStatuses';
import VerifyPartnerButton from './VerifyPartnerButton';

const PartnerStatusTableCell = ({
  status,
  partnerId,
}: {
  status: { value: string; status: string };
  partnerId: number;
}) => {
  if (status.value === UserStatuses.NOT_VERIFIED.value) {
    return (
      <TableCell>
        <VerifyPartnerButton partnerId={partnerId} />
      </TableCell>
    );
  }
  return <TableCell>{status.status}</TableCell>;
};

export default PartnerStatusTableCell;

import { colors } from '../../../styles/variables';

const GameOngoingIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1.5V3.16667M8 12V14.6667M3.83333 8H1.5M14.1667 8H13.1667M12.3047 12.3047L11.8333 11.8333M12.4428 3.61052L11.5 4.55333M3.28105 12.719L5.16667 10.8333M3.41912 3.47245L4.83333 4.88667"
        stroke={color ?? colors.success}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GameOngoingIcon;

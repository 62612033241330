import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { IAllGamesStatsSummary } from '../../../../types/ApiTypes';
import { QueryAliases } from '../../../../types/QueryAliases';
import useAxiosInstance from '../../../axios/useAxiosInstance';

interface ApiStats {
  averageUserGamesCount: number;
  relatedUsersCount: number;
  totalDistanceInKm: number;
  totalGameTimeInMinutes: number;
  totalSteps: number;
}

interface useAllGamesStatisticsProps {
  params: {
    email?: string;
    gameType?: string;
  };
}

const useAllGamesStatistics = ({ params }: useAllGamesStatisticsProps) => {
  const axiosInstance = useAxiosInstance();

  const { email, gameType } = params;

  const getQueryParams = () => ({
    ...(!!email && { email }),
    ...(!!gameType && { gameType }),
  });

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.ALL_GAME_STATISTICS.SUMMARY, email, gameType],
    () =>
      axiosInstance.get(api.endpoints.ALL_GAME_STATISTICS.SUMMARY(), {
        params: getQueryParams(),
      }),
  );

  const apiGamesStatistics: ApiStats | undefined = data?.data;

  const iGamesStatistics: IAllGamesStatsSummary | undefined = !!apiGamesStatistics
    ? {
        averageUserGamesCount: apiGamesStatistics.averageUserGamesCount,
        relatedUsersCount: apiGamesStatistics.relatedUsersCount,
        totalDistanceInKm: apiGamesStatistics.totalDistanceInKm,
        totalGameTimeInMinutes: apiGamesStatistics.totalGameTimeInMinutes,
        totalSteps: apiGamesStatistics.totalSteps,
      }
    : undefined;

  return {
    gamesStatistics: iGamesStatistics,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useAllGamesStatistics;

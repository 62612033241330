import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TemplateStatsModal from '../../../../../components/common/modals/templateStatsModal/TemplateStatsModal';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import { colors } from '../../../../../styles/variables';
import { ITranslation } from '../../../../../types/ApiTypes';

const TemplatesListTableStatsBtn = ({
  templateId,
  templateName,
}: {
  templateId: string;
  templateName: ITranslation[];
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('templates.table.buttons.stats')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <ChartBarSquareIcon width={24} color={isHovered ? colors.red500 : colors.grey700} />
          </button>
        )}
      </HoverableTooltip>

      <TemplateStatsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        templateId={templateId}
        templateName={templateName}
      />
    </>
  );
};

export default TemplatesListTableStatsBtn;

import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import FormSectionTitle from '../../../common/formSectionTitle/FormSectionTitle';
import SingleImagePicker from '../../../common/imagePickers/singleImagePicker/SingleImagePicker';

interface QuestionFormMarkerIconProps {
  oldMarkerIconUrl?: string | null;
  newMarkerIcon: File | null;
  setNewMarkerIcon: Dispatch<SetStateAction<File | null>>;
  onDeletedImage?: Dispatch<SetStateAction<boolean>>;
}

const QuestionFormMarkerIcon = ({
  oldMarkerIconUrl,
  newMarkerIcon,
  setNewMarkerIcon,
  onDeletedImage,
}: QuestionFormMarkerIconProps) => {
  const { t } = useTranslation();

  return (
    <div className="pb-7 max-w-lg">
      <FormSectionTitle className="mb-6 mt-10" title={t('questionForm.markerIcon.sectionTitle')} />

      <SingleImagePicker
        title={t('questionForm.markerIcon.title')}
        subtitle={t('questionForm.markerIcon.subtitle')}
        oldImageUrl={oldMarkerIconUrl}
        selectedFile={newMarkerIcon}
        setSelectedFile={setNewMarkerIcon}
        onDeletedImage={onDeletedImage}
      />
    </div>
  );
};

export default QuestionFormMarkerIcon;

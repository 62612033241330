import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import TypeToConfirmModal from '../../../../../components/common/modals/TypeToConfirmModal';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import TrashIcon from '../../../../../components/icons/TrashIcon';
import useDeleteTemplateQuestion from '../../../../../hooks/api/templates/useDeleteTemplateQuestion';
import { colors } from '../../../../../styles/variables';
import { QueryAliases } from '../../../../../types/QueryAliases';

interface DeleteQuestionBtnProps {
  questionId: number;
  templateId: number;
  gameId: number;
}

const DeleteQuestionBtn = ({ questionId, templateId, gameId }: DeleteQuestionBtnProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { deleteTemplateQuestion, isLoading } = useDeleteTemplateQuestion({
    onSuccess: () => {
      queryClient.refetchQueries([QueryAliases.GAME_QUESTIONS(gameId.toString())]);
    },
  });

  const handleDeleteQuestion = () => {
    deleteTemplateQuestion({ templateId, questionId });
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <HoverableTooltip tooltipContent={<p>{t('singleActiveGame.questions.table.buttons.delete')}</p>}>
        {isHovered => (
          <button
            className="p-3"
            onClick={e => {
              e.preventDefault();
              setIsDeleteModalOpen(true);
            }}
          >
            <TrashIcon color={isHovered ? colors.red500 : colors.grey700} width={24} height={24} />
          </button>
        )}
      </HoverableTooltip>

      <TypeToConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        title={t('singleActiveGame.questions.table.delete.title')}
        textToType={t('singleActiveGame.questions.table.delete.textToType')}
        confirmBtnText={t('singleActiveGame.questions.table.delete.confirmBtnText')}
        confirmBtnAction={handleDeleteQuestion}
        cancelBtnText={t('singleActiveGame.questions.table.delete.cancelBtnText')}
        isConfirmBtnDisabled={isLoading}
        isConfirmBtnLoading={isLoading}
      />
    </>
  );
};

export default DeleteQuestionBtn;

interface ImagePickerCardProps {
  children: React.ReactNode;
}

const ImagePickerCard = ({ children }: ImagePickerCardProps) => {
  return (
    <div className="relative bg-grey100 border border-grey300 rounded-md flex justify-center items-center w-[102px] h-[102px] max-w-[102px] max-h-[102px] overflow-hidden">
      {children}
    </div>
  );
};

export default ImagePickerCard;

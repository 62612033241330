import { ApiGameQuestion, IGameQuestion } from '../../../../types/ApiTypes';
import { transformQuestion } from '../transformQuestion/transformQuestion';

export const transformGameQuestion = (apiGameQuestion: ApiGameQuestion) => {
  const iGameQuestion: IGameQuestion = {
    answers: apiGameQuestion.answers,
    id: apiGameQuestion.id,
    index: apiGameQuestion.index,
    coordinates: {
      lat: +apiGameQuestion.coordinates.latitude,
      lng: +apiGameQuestion.coordinates.longitude,
    },
    question: transformQuestion(apiGameQuestion.question),
  };

  return iGameQuestion;
};

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from '../../../queryClient';
import { api } from '../../../static/api';
import { QueryAliases } from '../../../types/QueryAliases';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useDeleteBundle = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast, showSuccessToast } = useToast();
  const { t } = useTranslation();

  const { mutateAsync, isSuccess, isLoading } = useMutation(
    (id: number) => axiosInstance.delete(api.endpoints.SINGLE_BUNDLE({ id })),
    {
      async onSuccess() {
        await queryClient.refetchQueries([QueryAliases.ALL_BUNDLES]);
        const successMessage = t('bundle.toasts.deleteBundleSuccess');
        showSuccessToast(successMessage);
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('bundle.toasts.deleteBundleError');

        showErrorToast(errorMessage);
      },
    },
  );

  return { deleteBundle: mutateAsync, isSuccess, isLoading };
};

export default useDeleteBundle;

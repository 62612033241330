export function getMapTypeOptions() {
  if (!google) {
    return [];
  }

  return [
    { value: google.maps.MapTypeId.ROADMAP, label: 'Roadmap' },
    { value: google.maps.MapTypeId.SATELLITE, label: 'Satellite' },
  ] as const;
}

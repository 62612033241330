import { useQueryClient } from 'react-query';
import { removeAccessToken, removeRefreshToken } from '../utils/cookieHelpers/cookieHelpers';

export const useLogout = () => {
  const queryClient = useQueryClient();

  const handleLogout = () => {
    removeAccessToken();
    removeRefreshToken();
    queryClient.removeQueries();
  };

  return { handleLogout };
};

import * as yup from 'yup';
import * as Schema from '../schemaFields';

export const bundleValidationSchema = yup.object({
  bundleName: Schema.requiredString,
  spotRadius: Schema.limitWithLimitValueString,
  activeGames: Schema.limitWithLimitValueString,
  gameCharacters: Schema.limitWithLimitValueArray,
  questionTypes: Schema.limitWithLimitValueArray,
  gamesPerMonth: Schema.limitWithLimitValueString,
  gamersPerGame: Schema.limitWithLimitValueString,
});

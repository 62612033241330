import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { IGooglePlaceDetails } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface ApiResponse {
  result: {
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
      viewport: {
        northeast: { lat: number; lng: number };
        southwest: { lat: number; lng: number };
      };
    };
    place_id: string;
  };
}

const transformResponseData = (responseData: ApiResponse) => {
  const iData: IGooglePlaceDetails = {
    location: responseData.result.geometry.location,
    viewport: responseData.result.geometry.viewport,
    placeId: responseData.result.place_id,
  };

  return iData;
};

interface useMapPlaceDetailsProps {
  placeId: string;
  onSuccess?: (data: IGooglePlaceDetails) => void;
}

const useMapPlaceDetails = ({ placeId, onSuccess }: useMapPlaceDetailsProps) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.GOOGLE_PLACE_DETAILS, placeId],
    () => axiosInstance.get(api.endpoints.GOOGLE_PLACE_DETAILS(placeId)),
    {
      enabled: !!placeId,
      onSuccess: data => {
        if (!!onSuccess) {
          const iData = transformResponseData(data.data);
          onSuccess(iData);
        }
      },
    },
  );

  const response: ApiResponse | undefined = data?.data;

  const iGooglePlaceDetails: IGooglePlaceDetails | undefined = !!response ? transformResponseData(response) : undefined;

  return { placeDetails: iGooglePlaceDetails, refetch, isSuccess, isLoading, isError };
};

export default useMapPlaceDetails;

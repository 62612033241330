import { IQuestion, ITemplateDetails } from '../../../../types/ApiTypes';

export const getInitialTemplateSelectedQuestions = (template: ITemplateDetails | undefined) => {
  return template?.questionToTemplate
    .sort(({ index: a }, { index: b }) => a - b)
    .map(questionToTemplate => {
      const question: IQuestion = {
        ...questionToTemplate.question,
        coordinates: questionToTemplate.coordinates,
      };
      return question;
    });
};

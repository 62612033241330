import { colors } from '../../styles/variables';

const QuestionsNavIcon = ({ color, width, height }: { color?: string; width?: number; height?: number }) => {
  return (
    <svg width={width ?? 22} height={height ?? 22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.75 9.25V13.625C19.75 18 18 19.75 13.625 19.75H8.375C4 19.75 2.25 18 2.25 13.625V8.375C2.25 4 4 2.25 8.375 2.25H12.75"
        stroke={color ?? colors.grey700}
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.75 9.25H16.25C13.625 9.25 12.75 8.375 12.75 5.75V2.25L19.75 9.25Z"
        stroke={color ?? colors.grey700}
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.625 11.875H11.875"
        stroke={color ?? colors.grey700}
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.625 15.375H10.125"
        stroke={color ?? colors.grey700}
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QuestionsNavIcon;

import { toString } from 'lodash';
import useFindTranslation from '../../../../hooks/useFindTranslation';
import { ApiPartner, InitialPartnerFormValues } from '../../../../types/ApiPartnerTypes';

const usePrepareInitialPartner = () => {
  const { findTranslation } = useFindTranslation();

  const prepareInitialPartner = (partner?: ApiPartner) => {
    let initialPartnerWithSubscription = undefined;
    let initialPartner = undefined;
    let initialSubscription = undefined;
    let initialTemplates: { id: string; value: string }[] = [];

    const subscription = partner?.subscription;

    const baseSubscriptionData = {
      bundle: {
        id: subscription ? toString(subscription.bundle.id) : '',
        value: subscription ? subscription.bundle.bundleName : '',
      },
      coordinates: subscription ? `${subscription.coordinates.latitude}, ${subscription.coordinates.longitude}` : '',
      validUntil: subscription ? new Date(subscription.validUntil) : '',
    };

    const basePartnerData = {
      email: partner ? partner.email : '',
      name: partner ? partner.name : '',
      phone: partner ? partner.phone : '',
    };

    if (partner) {
      initialPartner = { baseData: { ...basePartnerData }, id: partner.id, isActive: partner.isActive };
    } else {
      initialPartner = { baseData: basePartnerData, isActive: false };
    }

    if (subscription) {
      initialSubscription = { ...baseSubscriptionData, id: subscription.id };
    } else {
      initialSubscription = baseSubscriptionData;
    }

    const templates = partner?.templates;
    if (templates && templates.length !== 0) {
      const mapedTemplates = templates.map(template => ({
        id: toString(template.id),
        value: findTranslation(template.name.translations),
      }));

      initialTemplates = [...mapedTemplates];
    }

    initialPartnerWithSubscription = {
      ...initialPartner,
      subscription: { ...initialSubscription },
      templates: initialTemplates,
    } as InitialPartnerFormValues;
    return initialPartnerWithSubscription;
  };

  return { prepareInitialPartner };
};

export default usePrepareInitialPartner;

import { QuestionType, QuestionTypes } from '../../../types/GlobalTypes';

export const isQuestionTextAnswer = ({ questionType }: { questionType: QuestionType }) => {
  return (
    questionType === QuestionTypes.CLOSED ||
    questionType === QuestionTypes.OPEN_SHORT_TEXT ||
    questionType === QuestionTypes.OPEN_LONG_TEXT ||
    questionType === QuestionTypes.ANIMATOR
  );
};

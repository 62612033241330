import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import FilterDropdown from '../../components/common/dropdowns/FilterDropdown/FilterDropdown';
import FilterChip from '../../components/common/dropdowns/FilterDropdown/components/FilterChip';
import FilterIcon from '../../components/icons/FilterIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useTemplateGameTypes from '../../hooks/api/templates/useTemplateGameTypes';
import usePartners from '../../hooks/api/users/usePartners';
import useUserDetails from '../../hooks/api/users/useUserDetails';
import { colors } from '../../styles/variables';
import { DropdownOption } from '../../types/Dropdown.types';
import { UserTypes } from '../../types/user/userTypes';
import DashboardStatsCreatedGames from './components/DashboardStatsCreatedGames';
import DashboardStatsGamers from './components/DashboardStatsGamers';
import DashboardStatsQuestions from './components/DashboardStatsQuestions';
import DashboardStatsSummary from './components/DashboardStatsSummary';
import DashboardStatsTemplates from './components/DashboardStatsTemplates';

const DashboardView = () => {
  const { t } = useTranslation();

  const { user } = useUserDetails();
  const isGlobalAdmin = user?.role === UserTypes.GLOBAL_ADMIN;

  const { gameTypes } = useTemplateGameTypes();
  const { partners } = usePartners({ enabled: isGlobalAdmin });

  const [gameTypeFilter, setGameTypeFilter] = useState<DropdownOption | null>(null);
  const [ownerFilter, setOwnerFilter] = useState('');

  const ownersOptions = [
    ...(!!user ? [{ id: user.id.toString(), email: user.email }] : []),
    ...(partners?.map(partner => ({
      id: partner.id.toString(),
      email: partner.email,
    })) ?? []),
  ];

  return (
    <TitleWrapper title={t('dashboard.title')} breadcrumbsProps={{ limit: 1 }}>
      <div className="flex gap-4">
        <FilterDropdown
          renderButton={({ open }) => (
            <>
              <FilterIcon />
              <p className="text-black500 whitespace-nowrap px-1.5">{t('dashboard.filters.label')}</p>
              {open ? (
                <ChevronUpIcon color={colors.black700} className="w-8 h-4" />
              ) : (
                <ChevronDownIcon color={colors.black700} className="w-8 h-4" />
              )}
            </>
          )}
          optionGroups={[
            ...(isGlobalAdmin
              ? [
                  {
                    id: uuidv4(),
                    label: t('templates.table.filters.owner'),
                    options:
                      ownersOptions?.map(owner => ({
                        id: owner.id.toString(),
                        value: owner.email,
                      })) ?? [],
                    onSetNewOption: (option: DropdownOption | null) => setOwnerFilter(option?.value ?? ''),
                    activeOptionIds: [ownerFilter],
                  },
                ]
              : []),
            {
              id: uuidv4(),
              label: t('dashboard.filters.gameTypeLabel'),
              options:
                gameTypes?.map(gameType => ({
                  id: gameType.code,
                  value: gameType.value,
                })) ?? [],
              onSetNewOption: option => setGameTypeFilter(option),
              activeOptionIds: !!gameTypeFilter?.id ? [gameTypeFilter.id] : [],
            },
          ]}
        />
        {!!gameTypeFilter && <FilterChip label={gameTypeFilter.value} onDelete={() => setGameTypeFilter(null)} />}
        {!!ownerFilter && <FilterChip label={ownerFilter} onDelete={() => setOwnerFilter('')} />}
      </div>

      <div className="pb-20">
        <div className="mt-8">
          <DashboardStatsSummary ownerFilter={ownerFilter} gameTypeFilter={gameTypeFilter?.id ?? ''} />
        </div>

        <div className="mt-8">
          <DashboardStatsCreatedGames ownerFilter={ownerFilter} gameTypeFilter={gameTypeFilter?.id ?? ''} />
        </div>

        <div className="mt-8">
          <DashboardStatsGamers ownerFilter={ownerFilter} gameTypeFilter={gameTypeFilter?.id ?? ''} />
        </div>

        <div className="mt-8">
          <DashboardStatsQuestions ownerFilter={ownerFilter} gameTypeFilter={gameTypeFilter?.id ?? ''} />
        </div>

        <div className="mt-8">
          <DashboardStatsTemplates ownerFilter={ownerFilter} gameTypeFilter={gameTypeFilter?.id ?? ''} />
        </div>
      </div>
    </TitleWrapper>
  );
};

export default DashboardView;

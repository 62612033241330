import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCreatedQuestionsStatistics from '../../../hooks/api/games/statistics/useCreatedQuestionsStatistics';
import DashboardStatsChart from './common/DashboardStatsChart';

interface DashboardStatsQuestionsProps {
  ownerFilter: string;
  gameTypeFilter: string;
}

const DashboardStatsQuestions = ({ ownerFilter, gameTypeFilter }: DashboardStatsQuestionsProps) => {
  const { t } = useTranslation();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const { createdQuestionsStatistics, isLoading } = useCreatedQuestionsStatistics({
    params: { email: ownerFilter, gameType: gameTypeFilter, year: currentYear },
  });

  const chartData = createdQuestionsStatistics?.countByMonth;

  return (
    <DashboardStatsChart
      title={t('dashboard.questions.title')}
      currentYear={currentYear}
      onChangeCurrentYear={setCurrentYear}
      chartData={chartData ?? []}
      isChartDataLoading={isLoading}
      totalInfoLabel={t('dashboard.questions.totalInfo')}
      chartDataTotalCount={createdQuestionsStatistics?.totalCount}
    />
  );
};

export default DashboardStatsQuestions;

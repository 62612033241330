import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../components/common/buttons/PrimaryButton';
import PlusIcon from '../../components/icons/PlusIcon';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import { routes } from '../../static/routes';
import BundlesList from './components/BundlesList';

const BundlesView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <TitleWrapper title={t('bundle.bundlesList.title')}>
      <div className="flex mb-8 justify-end items-end">
        <PrimaryButton
          onClick={() => navigate(routes.addBundle)}
          className="w-fit self-end"
          prefixIcon={<PlusIcon size={16} />}
        >
          {t('bundle.bundlesList.addBundleButton')}
        </PrimaryButton>
      </div>
      <BundlesList />
    </TitleWrapper>
  );
};

export default BundlesView;

import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useToast from '../../../hooks/useToast';
import { templateValidationSchema } from '../../../static/validation/schemas/templateValidationSchema';
import { IAdvertisement } from '../../../types/ApiTypes';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import FormSectionTitle from '../../common/formSectionTitle/FormSectionTitle';
import LangTabSwitcher from '../../common/tabSwitchers/langTabSwitcher/LangTabSwitcher';
import PlusIcon from '../../icons/PlusIcon';
import TemplateFormMap from './components/TemplateFormMap/TemplateFormMap';
import AvailableQuestions from './components/questionsSelectors/AvailableQuestions';
import SelectedQuestions from './components/questionsSelectors/SelectedQuestions';
import TemplateFormAds from './components/templateFormAds/TemplateFormAds';
import TemplateFormGeneral from './components/templateFormGeneral/TemplateFormGeneral';
import TemplateFormTags from './components/templateFormTags/TemplateFormTags';
import useHandleTemplateQuestions from './hooks/useHandleTemplateQuestions';
import { TemplateFormInputs, TemplateFormInputsKeys, TemplateFormProps } from './types/TemplateFormTypes';
import { getDefaultTemplateValues } from './utils/getDefaultTemplateValues';
import { getInitialTemplateSelectedQuestions } from './utils/getInitialTemplateSelectedQuestions';
import { useTrackUnsavedChange } from '../../../context/FormChangeProvider';

const TemplateForm = ({
  onSubmitForm,
  isLoadingSubmit,
  submitBtnText,
  allQuestions,
  gameCharactersOptions,
  gameTypesOptions,
  initialTemplate,
  isEditing,
  questionFiltersProps,
}: TemplateFormProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const [isMapExpanded, setIsMapExpanded] = useState(false);

  const [oldAds, setOldAds] = useState<IAdvertisement[]>(initialTemplate?.advertisements ?? []);
  const [newAds, setNewAds] = useState<File[]>([]);

  const methods = useForm<TemplateFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(templateValidationSchema),
    defaultValues: getDefaultTemplateValues({ template: initialTemplate, gameCharactersOptions, gameTypesOptions }),
  });

  useTrackUnsavedChange(methods.formState.isDirty);

  const currentGameType = methods.watch('gameType')?.code;

  const {
    preSelectedQuestions,
    setPreSelectedQuestions,
    allPossibleQuestions,
    availableQuestions,
    selectedQuestions,
    setSelectedQuestions,
    togglePreSelectedQuestion,
    addToPreSelectedQuestions,
    removeFromPreSelectedQuestions,
    addToSelectedQuestions,
    removeFromSelectedQuestions,
    updateQuestionsCoordinates,
    totalPointsToScore,
    setMapInstance,
    updateSelectedQuestion,
  } = useHandleTemplateQuestions({
    allQuestions,
    initialSelectedQuestions: getInitialTemplateSelectedQuestions(initialTemplate) ?? [],
    currentGameType,
  });

  const onSubmit: SubmitHandler<TemplateFormInputs> = async data => {
    if (selectedQuestions.length === 0) {
      showErrorToast(t('templateForm.selectQuestionsError'));
      return;
    }

    if (selectedQuestions.some(selectedQuestion => !selectedQuestion.coordinates)) {
      showErrorToast(t('templateForm.questions.questionsCoordinatesError'));
      return;
    }

    const deletedAdIds = initialTemplate?.advertisements
      .filter(initialAd => !oldAds.find(ad => ad.id === initialAd.id))
      .map(ad => ad.id);

    onSubmitForm({ data, questions: selectedQuestions, newAds, deletedAdIds: deletedAdIds ?? [] });
  };

  const getErrorMessage = (field: TemplateFormInputsKeys) => get(methods.formState.errors, field);

  const handleRemoveOldAd = (id: number) => setOldAds(prevAds => prevAds.filter(prevAd => prevAd.id !== id));

  useEffect(() => {
    const isError = !_.isEmpty(methods.formState.errors);

    if (isError) {
      setIsMapExpanded(false);
    }
  }, [methods.formState.errors]);

  return (
    <LangTabSwitcher
      sufixElement={
        <PrimaryButton
          onClick={methods.handleSubmit(onSubmit)}
          disabled={isLoadingSubmit}
          loading={isLoadingSubmit}
          className="w-fit self-end"
          prefixIcon={<PlusIcon />}
        >
          {submitBtnText}
        </PrimaryButton>
      }
    >
      {({ activeLang }) => (
        <FormProvider {...methods}>
          <div className="bg-white900 pl-8 pb-20">
            <TemplateFormTags />

            <FormSectionTitle className="mb-6 mt-10" title={t('templateForm.general.title')} />
            <div className="flex justify-between gap-14">
              <div className={`w-full ${isMapExpanded ? 'hidden' : ''}`}>
                <TemplateFormGeneral
                  getErrorMessage={getErrorMessage}
                  activeLang={activeLang}
                  gameCharactersOptions={gameCharactersOptions}
                  gameTypesOptions={gameTypesOptions}
                  isGameCharacterDisabled={isEditing}
                  isGameTypeDisabled={isEditing}
                  initialCity={initialTemplate?.city ?? ''}
                />
              </div>
              <TemplateFormMap
                mapCenter={initialTemplate?.coordinates}
                isMapExpanded={isMapExpanded}
                setIsMapExpanded={setIsMapExpanded}
                allPossibleQuestions={allPossibleQuestions}
                preSelectedQuestions={preSelectedQuestions}
                setPreSelectedQuestions={setPreSelectedQuestions}
                selectedQuestions={selectedQuestions}
                onAddToGame={() => {
                  addToSelectedQuestions(preSelectedQuestions);
                }}
                addToPreSelectedQuestions={addToPreSelectedQuestions}
                removeFromPreSelectedQuestions={removeFromPreSelectedQuestions}
                removeFromSelectedQuestions={removeFromSelectedQuestions}
                updateQuestionsCoordinates={updateQuestionsCoordinates}
                onMapLoad={map => setMapInstance(map)}
              />
            </div>

            <FormSectionTitle className="mb-6 mt-14" title={t('templateForm.questions.title')} />

            <div className="flex justify-between gap-14">
              <div className="w-full max-w-[50%]">
                <AvailableQuestions
                  availableQuestions={availableQuestions}
                  preSelectedQuestions={preSelectedQuestions}
                  selectedQuestions={selectedQuestions}
                  togglePreSelectedQuestion={togglePreSelectedQuestion}
                  onAddToGame={() => {
                    addToSelectedQuestions(preSelectedQuestions);
                  }}
                  filtersProps={questionFiltersProps}
                  currentGameType={currentGameType}
                />
              </div>

              <div className="w-full max-w-[50%]">
                <SelectedQuestions
                  selectedQuestions={selectedQuestions}
                  onRemoveFromGame={removeFromSelectedQuestions}
                  setSelectedQuestions={setSelectedQuestions}
                  onUpdateQuestionCoordinates={updateQuestionsCoordinates}
                  onUpdateSelectedQuestion={updateSelectedQuestion}
                />
              </div>
            </div>

            {selectedQuestions.length !== 0 && (
              <div className="my-10 flex items-center gap-3">
                <h5 className="text-grey500 font-bold uppercase text-sm">
                  {t('templateForm.questions.totalPointsToScore')}
                </h5>
                <p className="text-black700 font-medium text-sm">{totalPointsToScore}</p>
              </div>
            )}

            <div className="mt-14">
              <TemplateFormAds
                existingAds={oldAds}
                onRemoveExistingAd={handleRemoveOldAd}
                newAds={newAds}
                setNewAds={newAds => setNewAds(newAds)}
              />
            </div>
          </div>
        </FormProvider>
      )}
    </LangTabSwitcher>
  );
};

export default TemplateForm;

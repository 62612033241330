import { useQuery } from 'react-query';
import { api } from '../../../../static/api';
import { ApiGame, IGame } from '../../../../types/ApiTypes';
import { QueryAliases } from '../../../../types/QueryAliases';
import { transformGame } from '../../../../utils/api/dataTransformers/transformGame/transformGame';
import useAxiosInstance from '../../../axios/useAxiosInstance';

const useSpectatorSingleGame = ({ gameId, token, enabled }: { gameId: string; token: string; enabled: boolean }) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.SPECTATOR.SINGLE_GAME(gameId)],
    () =>
      axiosInstance.get(api.endpoints.SPECTATOR.SINGLE_GAME({ gameId }), {
        params: {
          token,
        },
      }),
    {
      enabled,
    },
  );

  const apiGame: ApiGame | undefined = data?.data;

  const game: IGame | undefined = !!apiGame ? transformGame(apiGame) : undefined;

  return {
    game: game,
    refetch,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useSpectatorSingleGame;

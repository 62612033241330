import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useVerifyPartner = () => {
  const axiosInstance = useAxiosInstance();
  const { showErrorToast } = useToast();
  const { t } = useTranslation();

  const { mutateAsync, isLoading } = useMutation(
    (id: number) => axiosInstance.patch(api.endpoints.VERIFY_PARTNER({ id })),
    {
      onError(error: AxiosError) {
        const errorMessage = getApiErrorMessage(error) ?? t('partner.toasts.addPartnerError');
        showErrorToast(errorMessage);
      },
    },
  );

  return {
    verifyPartner: mutateAsync,
    isLoading,
  };
};

export default useVerifyPartner;

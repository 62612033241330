import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { searchParamsData } from '../../../../../static/searchParamsData';
import { DropdownOption } from '../../../../../types/Dropdown.types';

const useSetInitialMessageReceiver = ({
  usersDropdownOptions,
  setSelectedUsers,
}: {
  usersDropdownOptions: DropdownOption[];
  setSelectedUsers: Dispatch<SetStateAction<DropdownOption[]>>;
}) => {
  const [searchParams] = useSearchParams();
  const messageTo = searchParams.get(searchParamsData.messageTo);
  const useEffectHasRun = useRef<boolean>(false);

  const handleSelectUserFromUrl = useCallback(() => {
    if (!!messageTo) {
      const foundUser = usersDropdownOptions.find(option => option.id === messageTo);

      if (!!foundUser) {
        setSelectedUsers([foundUser]);
        useEffectHasRun.current = true;
      }
    }
  }, [messageTo, usersDropdownOptions, setSelectedUsers]);

  useEffect(() => {
    if (useEffectHasRun.current) {
      return;
    }

    handleSelectUserFromUrl();
  }, [handleSelectUserFromUrl]);
};

export default useSetInitialMessageReceiver;

import { colors } from './variables';

const borderDefault = `2px solid ${colors.grey500}`;
const borderActive = `2px solid ${colors.red500}`;

export const muiDatepickerStyles = {
  '.MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '6px',
    height: '3.5rem',
  },
  '.MuiOutlinedInput-input': {
    padding: '16px 16px',
  },
  '.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: borderDefault,
    '&:focus': {
      border: borderActive,
    },
  },
  '.Mui-error': {
    border: 'none',
    '&:focus': {
      border: borderActive,
    },
  },
  '.MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: borderDefault,
    '&:focus': {
      border: borderActive,
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: borderDefault,
    '&:hover': {
      border: 'none',
    },
    '&:focus': {
      border: borderActive,
    },
  },
  '.MuiOutlinedInput-root': {
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: borderDefault,
      },
    },
    '&:focus-within': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: borderActive,
      },
    },
  },
};

export const supportedLanguages = [
  {
    id: 'pl',
    value: 'PL',
    locale: 'pl-PL',
  },
  {
    id: 'en',
    value: 'EN',
    locale: 'en-GB',
  },
];
